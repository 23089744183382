/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { OutrosEventosContextType } from "./models/PropsOutrosEventosContext";
import { OutrosEventos } from "models/outrosEventos";
import { INI_VALUES } from "./defaults";
import { deleteEvents, getEventsProfile } from "services/OutrosEventos";
import { usePortfolio } from "context/Portfolio";
import Notify from "../../utils/Notification";
import appReducer from "./AppReducerOutrosEventos";

export const OutrosEventosContext = React.createContext<OutrosEventosContextType>(INI_VALUES);

export const OutrosEventosProvider: React.FC = ({ children }) => {
	const { state } = usePortfolio();
	const [outrosEventos, setOutrosEventos] = React.useState<OutrosEventos[]>([]);
	const [verification, setVerification] = React.useState<boolean>(true);
	const initialState = { outrosEventos: [] };
	const [stateReducer, dispatch] = React.useReducer(appReducer, initialState);

	function deleteOutrosEventos(id) {
		if (window.confirm("Deseja deletar este depoimento?")) {
			deleteEvents(id)
				.then(() => {
					Notify("Evento deletado com sucesso!");
					dispatch({
						type: "DELETE",
						payload: id,
					});
				})
				.catch(() => {
					Notify(
						"Falha ao deletar o evento! Tente novamente dentro de alguns instantes.",
					);
				});
		}
	}

	function updateOutrosEventos(updatedEvento) {
		dispatch({
			type: "UPDATE",
			payload: updatedEvento,
		});
	}

	function addOutrosEventos(addEvento) {
		dispatch({
			type: "ADD",
			payload: addEvento,
		});
	}

	useEffect(() => {
		getEventsProfile(state.id)
			.then(({ data }) => {
				setOutrosEventos(data);
				dispatch({
					type: "INITIALIZING",
					payload: {
						...initialState,
						outrosEventos: data
					}
				})
			})
			.catch(() => {
				setVerification(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.id])

	return (
		<OutrosEventosContext.Provider
			value={{
				outrosEventos,
				setOutrosEventos,
				verification,
				setVerification,
				stateReducer,
				deleteOutrosEventos,
				updateOutrosEventos,
				addOutrosEventos,
			}}
		>
			{children}
		</OutrosEventosContext.Provider>
	);
};
