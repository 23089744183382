import React, { useState } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import ArrowBack from "@material-ui/icons/ArrowBack";

import Calendario from "./components/Calendario";
import Listas from "./components/Listas";

// import Footer from "components/Footer";

export default function CalendarScreen(props: {
	open: boolean;
	onClosed: (e: boolean) => void;
}): any {
	const [id, setId] = useState(0);
	const [reload, setReload] = useState(false);

	return (
		<Dialog
			open={props.open}
			onClose={() => {
				props.onClosed(false);
			}}
			fullScreen
		>
			<AppBar position='static'>
				<Toolbar className='navbar_header'>
					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						<IconButton
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={() => {
								props.onClosed(false);
							}}
						>
							<ArrowBack />
						</IconButton>

						<img src='/logo_white.svg' alt='Imagem UNINTA' className='logo' />
					</Box>
				</Toolbar>
			</AppBar>

			<DialogContent>
				<Container>
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						spacing={8}
					>
						<Grid container item xs={12} sm={4} spacing={3}>
							<Listas setId={setId} reload={reload} />
						</Grid>
						<Grid item xs={12} sm={8} spacing={3}>
							<Calendario id={id} reload={reload} setReload={setReload} />
						</Grid>
					</Grid>
				</Container>
			</DialogContent>
		</Dialog>
	);
}
