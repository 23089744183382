import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import ArrowBack from "@material-ui/icons/ArrowBack";
import Message from "@material-ui/icons/Message";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Menu from "@material-ui/icons/Menu";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Close from "@material-ui/icons/Close";
import Report from "@material-ui/icons/Report";

import ButtonInfo from "../ButtonsInfo";
import DialogViewQuestion from "../Questions/DialogViewQuestion";

import {
	getCountAnswer,
	getCountQuestion,
	getListQuestionsByAuthor,
	getListAnswersByAuthor,
} from "../dialogquestionsanswers.services";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";
import { useAuth } from "context/Auth/hook";

import { HeaderProps } from "../Header/header.model";

import "./styles.scss";
import AvatarProfile from "components/AvatarProfile";

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
}));

export default function HeaderMobile(props: HeaderProps): React.ReactElement {
	const { user, nivelDeEnsino } = useAuth();
	const { updateNumQuestions, updateNumAnswers } = usePedagogicalCommunication();
	const classes = useStyles();
	const colorsTheme = useTheme();

	const [openDrawer, setOpenDrawer] = React.useState(false);
	const [activeMenuDrawer, setActiveMenuDrawer] = React.useState<
		"menu" | "questions" | "answers"
	>("menu");

	const [numQuestions, setNumQuestions] = React.useState(0);
	const [numAnswers, setNumAnswers] = React.useState(0);

	const [openViewQuestion, setOpenViewQuestion] = React.useState(false);

	const [listQuestions, setListQuestions] = React.useState([]);
	const [listAnswer, setListAnswer] = React.useState([]);
	const [idQuestionSelected, setIdQuestionSelected] = React.useState("");

	const handleActiveNavigate = (value: "question" | "response" | "report") => {
		props.setComponentOpen(value);
		setOpenDrawer(false);
	};

	React.useEffect(() => {
		getCountQuestion(user.ra ?? "")
			.then((data) => {
				setNumQuestions(data.count);
			})
			.catch((e) => {
				console.log(e);
			});

		getListQuestionsByAuthor(user.ra ?? "")
			.then((data) => {
				const results = data.data.map((dt: any) => {
					return {
						title: dt.pergunta.replace(/(&nbsp;|<([^>]+)>)/gi, ""),
						actionClick: () => {
							setOpenViewQuestion(true);
							setIdQuestionSelected(dt.id);
						},
					};
				});

				setListQuestions(results);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [user.ra, updateNumQuestions]);

	React.useEffect(() => {
		getCountAnswer(user.ra ?? "")
			.then((data) => {
				setNumAnswers(data.count);
			})
			.catch((e) => {
				console.log(e);
			});

		getListAnswersByAuthor(user.ra ?? "")
			.then((data) => {
				const results = data.data.map((dt) => {
					return {
						title: dt.resposta_titulo,
						actionClick: () => {
							setOpenViewQuestion(true);
							setIdQuestionSelected(dt.pergunta_id);
						},
					};
				});

				setListAnswer(results);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [user.ra, updateNumAnswers]);

	return (
		<>
			<AppBar position='static' className={classes.appBar}>
				<Toolbar
					className='navbar_question_answers'
					style={{ background: colorsTheme.palette.primary.main }}
				>
					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						<IconButton
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={() => props.setOpen(false)}
						>
							<ArrowBack />
						</IconButton>

						<img
							src={`${
								nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
								nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO"
									? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg"
									: "/logo_white.svg"
							}`}
							className='logo'
							alt='Logo branca'
						/>
					</Box>

					<IconButton>
						<Menu style={{ color: "#fff" }} onClick={() => setOpenDrawer(true)} />
					</IconButton>
				</Toolbar>
			</AppBar>

			<Drawer
				open={openDrawer}
				onClose={() => {
					setOpenDrawer(false);
					setActiveMenuDrawer("menu");
				}}
			>
				<Toolbar style={{ minWidth: "10vw" }} />
				<List>
					<ListItem>
						<Box className='infoPerfil'>
							<AvatarProfile
								classname='img_perfil_question_mobile'
								isTutor={user.typeUser === "tutor"}
							/>
							<Typography className='name_studenty'>
								Maycon Douglas Soares da Silva
							</Typography>
							<Typography className='ra_studenty'>RA: 40005000</Typography>

							<Box
								display='flex'
								justifyContent='flex-start'
								alignItems='center'
								style={{ marginTop: 12 }}
							>
								{user.typeUser === "student" && (
									<ButtonInfo
										startIconButton={
											<HelpOutline className='iconButtonAppBar' />
										}
										titleButton='Perguntas Feitas'
										value={numQuestions}
										actionButton={() => {
											setActiveMenuDrawer(
												activeMenuDrawer === "questions"
													? "menu"
													: "questions",
											);
										}}
									/>
								)}

								<ButtonInfo
									startIconButton={<Message className='iconButtonAppBar' />}
									titleButton='Respostas Dada'
									value={numAnswers}
									actionButton={() => {
										setActiveMenuDrawer(
											activeMenuDrawer === "answers" ? "menu" : "answers",
										);
									}}
								/>
							</Box>
						</Box>
					</ListItem>
				</List>
				<Divider />
				{activeMenuDrawer === "menu" ? (
					<List>
						<ListItem
							id='itemNavQuestion'
							button
							onClick={() => handleActiveNavigate("question")}
						>
							<ListItemIcon>
								<HelpOutline
									className={`iconNavigate ${
										props.componentOpen === "question" ? "active" : ""
									}`}
								/>
							</ListItemIcon>
							<Typography
								className={`itemNavigate ${
									props.componentOpen === "question" ? "active" : ""
								}`}
							>
								Perguntar
							</Typography>
						</ListItem>

						<ListItem
							id='itemNavAnswer'
							button
							onClick={() => handleActiveNavigate("response")}
						>
							<ListItemIcon>
								<Message
									className={`iconNavigate ${
										props.componentOpen === "response" ? "active" : ""
									}`}
								/>
							</ListItemIcon>
							<Typography
								className={`itemNavigate ${
									props.componentOpen === "response" ? "active" : ""
								}`}
							>
								Responder
							</Typography>
						</ListItem>

						{user.typeUser === "tutor" && (
							<ListItem button onClick={() => handleActiveNavigate("report")}>
								<ListItemIcon>
									<Report
										className={`iconNavigate ${
											props.componentOpen === "report" ? "active" : ""
										}`}
									/>
								</ListItemIcon>
								<Typography
									className={`itemNavigate ${
										props.componentOpen === "report" ? "active" : ""
									}`}
								>
									Denúncias
								</Typography>
							</ListItem>
						)}
					</List>
				) : activeMenuDrawer === "questions" ? (
					<Box className='area_notify_header_mobile'>
						{listQuestions.length <= 0 ? (
							<Typography className='nullValue'>( Vazio )</Typography>
						) : (
							<List component='nav'>
								<ListItem
									style={{
										display: "flex",
										justifyContent: "space-between",
										padding: 0,
									}}
								>
									<Typography
										style={{
											paddingLeft: "0.6rem",
											paddingRight: "0.6rem",
											fontWeight: "bold",
											color: "#bd0e0e",
											border: "1px solid #a5a5a5cc",
											borderRadius: "0 4px 4px 0",
										}}
									>
										Suas Perguntas
									</Typography>
									<Button
										variant='text'
										onClick={() => setActiveMenuDrawer("menu")}
									>
										<Close style={{ color: "#bd0e0e" }} />
									</Button>
								</ListItem>
								{listQuestions.map((item: any, index: number) => (
									<ListItem
										key={index}
										button
										className='item_list_request'
										onClick={item.actionClick}
									>
										<FiberManualRecord />
										<Typography className='text_list_request'>
											{item.title}
										</Typography>
									</ListItem>
								))}
							</List>
						)}
					</Box>
				) : (
					<Box className='area_notify_header_mobile'>
						{listAnswer.length <= 0 ? (
							<Typography className='nullValue'>( Vazio )</Typography>
						) : (
							<List component='nav'>
								<ListItem
									style={{
										display: "flex",
										justifyContent: "space-between",
										padding: 0,
									}}
								>
									<Typography
										style={{
											paddingLeft: "0.6rem",
											paddingRight: "0.6rem",
											fontWeight: "bold",
											color: "#bd0e0e",
											border: "1px solid #a5a5a5cc",
											borderRadius: "0 4px 4px 0",
										}}
									>
										Suas Respostas
									</Typography>
									<Button
										variant='text'
										onClick={() => setActiveMenuDrawer("menu")}
									>
										<Close style={{ color: "#bd0e0e" }} />
									</Button>
								</ListItem>
								{listAnswer.map((item: any, index: number) => (
									<ListItem
										key={index}
										button
										className='item_list_request'
										onClick={item.actionClick}
									>
										<FiberManualRecord />
										<Typography className='text_list_request'>
											{item.title}
										</Typography>
									</ListItem>
								))}
							</List>
						)}
					</Box>
				)}
			</Drawer>

			<DialogViewQuestion
				id={idQuestionSelected}
				setId={setIdQuestionSelected}
				open={openViewQuestion}
				setOpen={setOpenViewQuestion}
			/>
		</>
	);
}
