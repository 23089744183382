import { centralPedagogica } from "services/api";

export async function getSubject() {
	return await centralPedagogica
		.get("listarAssuntos")
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSubjectListSpecific(id: number) {
	return await centralPedagogica
		.get(`/tutor/listarAgendamentos/assunto/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getScheduling(id?: number) {
	if (id) {
		return await centralPedagogica
			.get(`/tutor/listarAgendamentos/assunto/${id}`)
			.then(({ data }) => {
				return data;
			})
			.catch((e) => {
				return e;
			});
	} else {
		return await centralPedagogica
			.get("tutor/listarAgendamentos")
			.then(({ data }) => {
				return data;
			})
			.catch((e) => {
				return e;
			});
	}
}

export async function putSubjectCancel(id: number, reason: string, statusReason: string) {
	return await centralPedagogica
		.put(`/tutor/atenderAgendamento/${id}`, { parecer: reason, situacao: statusReason })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
