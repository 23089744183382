import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import ArrowBack from "@material-ui/icons/ArrowBack";
import Menu from "@material-ui/icons/Menu";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import EventNote from "@material-ui/icons/EventNote";

import DialogScheduleConfirmation from "../DialogScheduleConfirmation";
import DialogHistoric from "../../DialogHistoric";
import AvatarProfile from "components/AvatarProfile";

import { getSchedules, getNumSchedule } from "../scheduletime.services";
import { HeaderMobileProps } from "./headermobile.model";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import "./styles.scss";

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
}));

export default function HeaderMobile(props: HeaderMobileProps): React.ReactElement {
	const classes = useStyles();
	const { user, nivelDeEnsino } = useAuth();
	const { updateNumSchedule } = usePedagogicalCommunication();
	const colorsTheme = useTheme();

	const [open, setOpen] = React.useState(false);
	const [openReview, setOpenReview] = React.useState(false);
	const [openHistoric, setOpenHistoric] = React.useState(false);
	const [idSolicitation, setIdSolicitation] = React.useState("");
	const [listSolicitations, setListSolicitations] = React.useState<any[]>([]);
	const [numSolicitations, setNumSolicitations] = React.useState(0);

	const handleClick = (id: string) => {
		setIdSolicitation(id);
		setOpenReview(true);
	};

	React.useEffect(() => {
		getSchedules(user?.ra, "pendente")
			.then((data) => {
				setListSolicitations(data.data);
			})
			.catch((e) => {
				console.log(e);
			});

		getNumSchedule(user?.ra).then((data: any) => {
			setNumSolicitations(data?.qntAgendamentos);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openReview, updateNumSchedule]);

	return (
		<>
			<AppBar position='static' className={classes.appBar}>
				<Toolbar
					className='navbar_question_answers'
					style={{ background: colorsTheme.palette.primary.main }}
				>
					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						<IconButton
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={() => props.onClosed(false)}
						>
							<ArrowBack />
						</IconButton>

						<img
							src={`${
								nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
								nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO"
									? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg"
									: "/logo_white.svg"
							}`}
							className='logo'
							alt='Logo branca'
						/>
					</Box>

					<IconButton>
						<Menu style={{ color: "#fff" }} onClick={() => setOpen(true)} />
					</IconButton>
				</Toolbar>
			</AppBar>

			<Drawer open={open} onClose={() => setOpen(false)}>
				<Toolbar style={{ minWidth: "10vw" }} />
				<List>
					<ListItem>
						<Box className='infoPerfil'>
							<AvatarProfile classname='img_perfil_schedule' />
							<Typography className='name_studenty'>{user?.nome}</Typography>
							<Typography className='ra_studenty'>RA: {user?.ra}</Typography>
						</Box>
					</ListItem>
				</List>
				<Typography className='title'>Meus Agendamentos</Typography>
				<Box className='sub_area'>
					<Typography className='numSolicitations'>
						<EventNote /> {numSolicitations}
					</Typography>
					<button
						onClick={() => {
							setOpenHistoric(true);
							setOpen(false);
						}}
					>
						Visualizar Historico
					</button>
				</Box>

				{listSolicitations?.length <= 0 ? (
					<Typography className='nullValue'>(Nenhum Agendamento)</Typography>
				) : (
					<List>
						{listSolicitations?.map((item) => {
							let colorIcon = "#346599";

							if (item.situacao === "deferido" || item.situacao === "concluido") {
								colorIcon = "#429a25";
							}

							if (item.situacao === "indeferido" || item.situacao === "cancelado") {
								colorIcon = "#aa1122";
							}

							return (
								<React.Fragment key={item.id}>
									<ListItem
										button
										className='menu_item'
										onClick={() => handleClick(item.id)}
									>
										<FiberManualRecord style={{ color: colorIcon }} />
										<Typography className='item'>
											{item?.assunto_nome}
										</Typography>
									</ListItem>
									<Divider />
								</React.Fragment>
							);
						})}
					</List>
				)}
			</Drawer>

			<DialogScheduleConfirmation
				open={openReview}
				idSchedule={idSolicitation}
				setOpen={setOpenReview}
			/>

			<DialogHistoric
				open={openHistoric}
				type='schedule'
				device='mobile'
				title='Listagem de Agendamentos'
				setOpen={setOpenHistoric}
			/>
		</>
	);
}
