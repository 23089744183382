import React from "react";

import { useAuth } from "context/Auth/hook";

// import LoggedRoutes from './LoggedRoutes';
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

export default function Routes(): JSX.Element {
	const { user } = useAuth();

	return user.signed ? <PrivateRoutes /> : <PublicRoutes />;
}
