import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Container,
	CssBaseline,
	Dialog,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
	Typography,
	useTheme,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useAuth } from "context/Auth/hook";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Notify from "utils/Notification";
import React from "react";
import "./styles.scss";

// -- Lottie
import animationData from "../../assets/Lottie/animacao.json";
import Lottie from "lottie-react";

// -- Inserindo Array com diferentes níveis de ensino
export const niveisDeEnsino = [
	"faculdade-alencarina",
	"alencarina",
	"5-de-julho",
	"julho",
	"faculdade-5-de-julho",
	"f5",
	"fal",
	"inta",
	"uninta",
	"bonus",
	"uninta-fortaleza",
	"fortaleza",	
];

export function SignInView(): JSX.Element {
	const theme = useTheme();
	const [values] = useSearchParams();
	const { Login, setNivelDeEnsino, nivelDeEnsino } = useAuth();
	const useStyles = makeStyles((theme) => ({
		root: {
			color: theme?.palette.secondary.main,
		},
		uninta: {
			color: "#bd0e0e",
		},
		textField: {
			"& label.Mui-focused": {
				color: "black",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });

	const classes = useStyles();

	React.useEffect(() => {
		if (values) {
			if (
				values.get("nivelDeEnsino") &&
				niveisDeEnsino.includes(values.get("nivelDeEnsino") || "uninta")
			) {				
				console.log(values.get("nivelDeEnsino"));
				switch (values.get("nivelDeEnsino")) {
					case "faculdade-alencarina":
						setNivelDeEnsino(
							JSON.parse(
								"{\"codigo_nivel_ensino\":0,\"nome_nivel_ensino\":\"FACULDADE ALENCARINA\",\"codigo_curso\":\"\",\"nome_curso\":\"\",\"periodo\":0,\"situacao\":\"\",\"idhabilitacaofilial\":\"\",\"idperlet\":\"\"}",
							),
						);
						break;
					case "faculdade-5-de-julho":
						setNivelDeEnsino(
							JSON.parse(
								"{\"codigo_nivel_ensino\":0,\"nome_nivel_ensino\":\"FACULDADE ALENCARINA\",\"codigo_curso\":\"\",\"nome_curso\":\"\",\"periodo\":0,\"situacao\":\"\",\"idhabilitacaofilial\":\"\",\"idperlet\":\"\"}",
							),
						);
						break;
					case "uninta-fortaleza":
						setNivelDeEnsino(
							JSON.parse(
								"{\"codigo_nivel_ensino\":345,\"nome_nivel_ensino\":\"FACULDADE UNINTA FORTALEZA\",\"codigo_curso\":\"\",\"nome_curso\":\"\",\"periodo\":0,\"situacao\":\"\",\"idhabilitacaofilial\":\"\",\"idperlet\":\"\"}",
							),
						);
						break;
					default:
						break;				
			}
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [loadingLogin, setLoadingLogin] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	interface LoginData {
		ra: string;
		password: string;
	}

	const onSubmit = (data: LoginData) => {
		if (!data.ra) {
			Notify("Campo de RA obrigatório.", "warning");
			return;
		}

		if (!data.password) {
			Notify("Campo de Senha obrigatório.", "warning");
			return;
		}

		setLoadingLogin(true);

		Login({ ...data })
			.then((data) => {
				if (!data.data) {
					setLoadingLogin(false);
				}
			})
			.catch(() => {
				setLoadingLogin(false);
			});
	};

	return (
		<Dialog 
			open={true} 
			fullScreen
			className='container-fullscreen'>
			<Container className='container-login' 
				style={{
					display: "flex",
					justifyContent: "center",
				}}>
				<CssBaseline />

				<Grid container spacing={2}>
					<Grid item xs={12} md={7} xl={7} className='seccao-banner-login'>
						<div className='banner-login' id='logo'>
							<img
								src={
									(nivelDeEnsino.codigo_nivel_ensino !== 351)								&&
									(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE ALENCARINA")			&&
									(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO - EAD")	&&
									(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO")
										? "uninta_simbolo.svg"
										: "f5_simbolo.png"
								}
								alt='Banner da tela de login do Ecossitema'
								style={{
									height: "45%",
								}}
							/>
							<Lottie
								animationData={animationData}
								loop={true}
								id='lottie'
								style={{
									height: "auto",
									minWidth: "210px",
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									marginBottom: "24px",
								}}
							/>
						</div>

						<div className='textos-banner-login'>
							<Typography variant='h3'>
								ECOSSISTEMA DE{" "}
								<span
									className={
										nivelDeEnsino.nome_nivel_ensino !==
											"FACULDADE ALENCARINA" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
											? classes.uninta
											: classes.root
									}
								>
									APRENDIZAGEM
								</span>
							</Typography>
							<Typography component='p'>
								Para conectar pessoas e formar comunidades de práticas.
							</Typography>
						</div>
					</Grid>

					<Grid item xs={12} md={5} xl={5} className='login-side'>
						<div className='seccao-formulario-login'>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "12px",
									marginBottom: "24px",
								}}
							>
								{/* <img
									src='https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-01.png'
									alt='Logo Uninta'
									width='200'
								/> */}
								<img
									src={
									(nivelDeEnsino.codigo_nivel_ensino !== 351)								&&
									(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE ALENCARINA")			&&
									(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO - EAD")	&&
									(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO")
											? (nivelDeEnsino.codigo_nivel_ensino === 345) ? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/fortaleza_horizontal_vermelha.png" : "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-01.png"
											: "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5-+logos_Prancheta_1.svg"
									}
									alt="Logo da Instituição de Ensino"
									width='200'
								/>
								<Typography
									component='h5'
									style={{
										// color: theme.palette.primary.main,
										fontSize: "1.6rem",
										fontWeight: "normal",
										marginTop: "12px",
									}}
								>
									Entrar no Ecossistema
								</Typography>
							</div>

							<form
								onSubmit={handleSubmit((data) => {
									onSubmit({ ra: data.ra, password: data.password });
								})}
							>
								<FormControl variant='outlined' fullWidth>
									<label htmlFor=''>Digite seu usuário*</label>
									<TextField
										variant='outlined'
										className={classes.textField}
										type='text'
										fullWidth
										placeholder='Identificação de usuário'
										autoFocus
										disabled={loadingLogin}
										{...register("ra", { required: true })}
										error={errors.ra?.type === "required"}
										helperText={
											errors.ra?.type === "required" && (
												<span>
													O campo "Digite seu usuário" é obrigatório!
												</span>
											)
										}
									/>
								</FormControl>

								<FormControl variant='outlined' fullWidth>
									<label htmlFor=''>Senha*</label>
									<TextField
										className={classes.textField}
										type={showPassword ? "text" : "password"}
										variant='outlined'
										fullWidth
										autoFocus
										placeholder='Insira aqui a sua senha'
										{...register("password", { required: true })}
										error={errors.password?.type === "required"}
										helperText={
											errors.password?.type === "required" && (
												<span>O campo "Senha" é obrigatório!</span>
											)
										}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={handleClickShowPassword}
														onMouseDown={(e) => {
															e.preventDefault();
														}}
														edge='end'
													>
														{showPassword ? (
															<VisibilityOff />
														) : (
															<Visibility />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</FormControl>

								<div className='footer-formulario-login'>
									<FormControlLabel
										style={{ color: "#0000008A" }}
										control={<Checkbox value='remember' color='primary' />}
										label='Mantenha-me conectado'
										disabled={loadingLogin}
									/>
								</div>

								<Button
									type='submit'
									size='large'
									fullWidth
									variant='contained'
									color='primary'
									disableElevation
									disabled={loadingLogin}
									id='btnLogin'
									style={{
										backgroundColor: theme.palette.primary.main,
										textTransform: "capitalize",
										fontSize: "1.1rem",
									}}
								>
									{loadingLogin ? (
										<CircularProgress
											style={{
												color: "#bd0e0e",
												width: "1.5rem",
												height: "1.5rem",
											}}
											thickness={4.4}
										/>
									) : (
										"Entrar"
									)}
								</Button>

								<div className='footer-formulario-login'>
									<Typography
										variant='body2'
										component='a'
										style={{ color: "grey", cursor: "pointer" }}
										onClick={() =>
											window.open(
												"https://uninta.vc/ava-recuperar-senha",
												"ForgetPass",
												"STATUS=NO, TOOLBAR=NO, LOCATION=NO, DIRECTORIES=NO, RESISABLE=NO, SCROLLBARS=YES, TOP=10, LEFT=10, WIDTH=400, HEIGHT=260",
											)
										}
									>
										Esqueceu o seu usuário ou senha?{" "}
									</Typography>
									<Typography variant='body2' component='a'>
										<a
											href='https://ead.uninta.edu.br/suporte-ao-ambiente-virtual/?sis=QW1iaWVudGUgVmlydHVhbA=='
											target='_blank'
											style={{ color: "grey" }}
											rel='noreferrer'
										>
											Problemas com acesso? Clique aqui?
										</a>
									</Typography>
								</div>
							</form>
						</div>
					</Grid>
				</Grid>
			</Container>
			<Box
				style={{
					textAlign: "center",
					color: "grey",
					padding: "12px",
				}}
				className='footer-login'
			>
				<Typography variant='body1'>
					{" "}
					2022. Todos os direitos reservados. 
					{
						nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
						nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
							? <a href="https://uninta.edu.br" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main }}> Centro Universitário Uninta.</a>
							: <a href="https://faculdadef5.com.br/" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main }}> Faculdade 5 de Julho.</a>
					}
					{" "}
				</Typography>
			</Box>
		</Dialog>
	);
}
