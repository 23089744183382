import { Container } from "@material-ui/core";
import HomepageView from "./Homepage.view";

/**
 *
 * @returns {React.ReactElement} Tela inicial do sistema para o aluno
 * Retorna a tela de disciplinas e a tela de boas vindas
 * Logo abaixo exibe a interface de busca, que é um componente separado
 */

export default function Homepage(): React.ReactElement {
	return (
		<Container>
			<HomepageView />
		</Container>
	);
}
