import { useContext } from "react";
import { DisciplinasContext } from "./context";

/**
 *
 * @returns Hook para consumir disciplinas do usuário
 */
export default function useDisciplinas() {
	return useContext(DisciplinasContext);
}
