import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

import LocalOffer from "@material-ui/icons/LocalOffer";
import Label from "@material-ui/icons/Label";
import Today from "@material-ui/icons/Today";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Book from "@material-ui/icons/Book";
import School from "@material-ui/icons/School";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import AttachFile from "@material-ui/icons/AttachFile";
import TextFields from "@material-ui/icons/TextFields";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Person from "@material-ui/icons/Person";

import ContainerBoxDetails from "./ContainerBoxDetails";
import BoxDetails from "./BoxDetails";

import { formatDate } from "utils/formatDate";
import Notify from "utils/Notification";

import {
	getSolicitationTutor,
	toMeetSolicitation,
} from "../../DialogReviewQuestion/FormReviewQuestion/formreviewquestion.services";

import { DialogDetailsProps } from "./dialogdetails.model";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import "./styles.scss";

export default function DialogDetails(props: DialogDetailsProps) {
	const { user } = useAuth();
	const { updateNumReviews, setUpdateNumReviews } = usePedagogicalCommunication();

	const [dataReview, setDataReview] = React.useState<any>([]);

	const [dataForm, setDataForm] = React.useState({
		response: "",
		situation: "",
	});

	const handleDataForm = (position: string, value: string) => {
		setDataForm({ ...dataForm, [position]: value });
	};

	const toMeet = (e: React.FormEvent) => {
		e.preventDefault();

		toMeetSolicitation(
			props.idReview,
			dataForm.response,
			dataForm.situation,
			user.ra,
			user.nome,
		)
			.then((data) => {
				if (data.isUpdated) {
					Notify("Foi atendido");
					props.setOpen(false);
					props.setReload(!props.reload);
					setUpdateNumReviews(!updateNumReviews);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	React.useEffect(() => {
		if (props.open) {
			getSolicitationTutor(props.idReview)
				.then((data) => {
					setDataReview(data);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [props.open, props.idReview]);

	return (
		<Dialog
			open={props.open}
			onClose={() => {
				props.setOpen(false);
			}}
			fullWidth
			maxWidth='md'
		>
			<DialogTitle className='area_title_details'>
				<Typography className='title_details_list_solicitations'>Detalhes</Typography>

				<Grid container>
					<BoxDetails
						isHeader
						icon={<LocalOffer />}
						title='ID:'
						value={dataReview.data?.id}
						xs={6}
						md={2}
					/>

					<BoxDetails
						isHeader
						isCopy
						icon={<AssignmentInd />}
						title='RA:'
						value={dataReview.data?.ra}
						xs={6}
						md={4}
					/>

					<BoxDetails
						isHeader
						icon={<Person />}
						title='Nome:'
						value={dataReview.data?.nome}
						xs={12}
						md={6}
					/>
				</Grid>
			</DialogTitle>

			<DialogContent dividers>
				<Grid container>
					<ContainerBoxDetails>
						<BoxDetails
							icon={<Label />}
							title='Status:'
							value={dataReview.data?.situacao}
							xs={12}
							md={3}
						/>

						<BoxDetails
							icon={<Today />}
							title='Solicitado em:'
							value={formatDate(dataReview.data?.created_at)}
							xs={12}
							md={3}
						/>

						<BoxDetails
							icon={<Today />}
							title='Atendido em:'
							value={
								dataReview.data?.situacao === "pendente"
									? "-"
									: formatDate(dataReview.data?.updated_at)
							}
							xs={12}
							md={3}
						/>

						<BoxDetails
							icon={<RecordVoiceOver />}
							title='Atendido Por:'
							value={
								dataReview.data?.situacao === "pendente"
									? "-"
									: dataReview.data?.tutor_nome
							}
							xs={12}
							md={3}
						/>
					</ContainerBoxDetails>

					<ContainerBoxDetails>
						<BoxDetails
							icon={<AccountBalance />}
							title='Polo:'
							value={dataReview.data?.polo_nome}
							xs={12}
							md={12}
						/>

						<BoxDetails
							icon={<School />}
							title='Curso/Disciplina:'
							value={`${dataReview.data?.curso_nome} - ${dataReview.data?.disciplina_nome}`}
							xs={12}
							md={12}
						/>

						<BoxDetails
							icon={<Book />}
							title='Avaliação:'
							value={dataReview.data?.avaliacao}
							xs={12}
							md={4}
						/>

						<BoxDetails
							icon={<FormatListNumbered />}
							title='Questão:'
							value={dataReview.data?.questao}
							xs={12}
							md={4}
						/>

						<BoxDetails
							icon={<AttachFile />}
							title='Arquivo:'
							value='Clique para abrir'
							xs={12}
							md={4}
							isFile
							onOpenFile={() => {
								window.open(dataReview?.link, "_blank");
							}}
						/>

						<BoxDetails
							isCopy
							icon={<TextFields />}
							title='Justificativa:'
							value={dataReview.data?.justificativa}
							xs={12}
							md={12}
						/>
					</ContainerBoxDetails>
				</Grid>
			</DialogContent>

			{dataReview.data?.situacao === "pendente" && (
				<DialogActions className='to_meet'>
					<form onSubmit={toMeet} style={{ width: "100%" }}>
						<Grid container>
							<Grid item xs={12}>
								<Typography className='title_to_meet'>
									Atender Solicitação
								</Typography>
							</Grid>

							<Grid item xs={12} md={2} style={{ padding: 4 }}>
								<FormControl variant='outlined' required fullWidth>
									<InputLabel>Status:</InputLabel>
									<Select
										label='Status: *'
										onChange={(e) =>
											handleDataForm("situation", e.target.value as string)
										}
									>
										<MenuItem value='deferido'>Deferido</MenuItem>
										<MenuItem value='indeferido'>Indeferido</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} md={10} style={{ padding: 4 }}>
								<TextField
									required
									variant='outlined'
									label='Resposta:'
									multiline
									minRows={4}
									maxRows={4}
									fullWidth
									onChange={(e) =>
										handleDataForm("response", e.target.value as string)
									}
								/>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Button variant='contained' color='primary' type='submit'>
								Confirmar
							</Button>
						</Grid>
					</form>
				</DialogActions>
			)}
		</Dialog>
	);
}
