import { AxiosResponse } from "axios";
import { OutrosEventos } from "models/outrosEventos";

import { portfólio } from "services/api";
// const BASE_URL= `${process.env.REACT_APP_API_PORTFOLIO_URL}`

export async function postEvents(data): Promise<AxiosResponse<Array<any>>> {
	return await portfólio.post(`/api/alunos/v1/events`, data)
}

export async function getEventsProfile(idProfile): Promise<AxiosResponse<Array<any>>> {
	return await portfólio.get(`/api/alunos/v1/events/profile/${idProfile}`)
}

export async function getEvent(idEvento): Promise<AxiosResponse<OutrosEventos>> {
	return await portfólio.get(`/api/alunos/v1/events/${idEvento}`)
}

export async function putEvent(idEvento, data): Promise<AxiosResponse<Array<any>>> {
	return await portfólio.put(`/api/alunos/v1/events/${idEvento}`, data)
}

export async function deleteEvents(idEvento): Promise<AxiosResponse<Array<any>>> {
	return await portfólio.delete(`/api/alunos/v1/events/${idEvento}`)
}
