import { centralPedagogica, ecossistema } from "services/api";

export async function getCoursesStudent(ra: string | undefined) {
	return await ecossistema
		.get(`nti/cursos/${ra}`)
		.then(({ data }: any) => {
			return data.map((dt) => {
				return {
					id: dt?.codigo_curso,
					name: dt?.nome_curso,
				};
			});
		})
		.catch((e) => {
			console.log(e);
		});
}

export async function getSubjectsStudent(ra: string | undefined) {
	return await ecossistema
		.get(`nti/disciplinas/${ra}`)
		.then(({ data }: any) => {
			return data;
		})
		.catch((e) => {
			console.log(e);
		});
}

export async function getListQuestions(type?: string, value?: string, page = 0) {
	const complement = type && value ? `${type}/${value}` : "";

	return await centralPedagogica
		.get(`aluno/listarPerguntas/${complement}`, {
			params: {
				page,
			},
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListQuestionsByAuthor(ra: string) {
	return await centralPedagogica
		.get(`aluno/minhasPerguntas/${ra}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListQuestionsCourseAndSubject(
	course?: string,
	subject?: string,
	page = 0,
) {
	return await centralPedagogica
		.get(`aluno/buscarPerguntas/${course}/${subject}`, {
			params: {
				page,
			},
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListAnswers(idQuestion: string, authorId: string) {
	const complement = idQuestion && authorId ? `${idQuestion}/${authorId}` : "";

	return await centralPedagogica
		.get(`aluno/listarRespostas/${complement}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListAnswersByAuthor(authorId: string) {
	return await centralPedagogica
		.get(`aluno/buscarRespostas/autor_id/${authorId}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListComments(idResponse: string) {
	return await centralPedagogica
		.get(`aluno/listarComentarios/${idResponse}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListReport() {
	return centralPedagogica
		.get("tutor/listarDenuncias")
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getDetailsReport(type: "pergunta" | "resposta", idReport: string) {
	return centralPedagogica
		.get(`tutor/detalhesDenuncia/${type}/${idReport}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getQuestion(identify: string) {
	return await centralPedagogica
		.get(`aluno/verPergunta/${identify}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getCountQuestion(ra: string) {
	return await centralPedagogica
		.get(`aluno/contarPerguntas/autor_id/${ra}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getCountAnswer(ra: string) {
	return await centralPedagogica
		.get(`aluno/contarRespostas/autor_id/${ra}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeQuestion(ra: string, question: string, subject: string, course: string) {
	return await centralPedagogica
		.post("aluno/criarPergunta", {
			ra,
			pergunta: question,
			disciplina: subject,
			curso: course,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeAnswer(
	ra: string,
	questionId: string,
	course: string,
	answer: string,
	isTutor: "0" | "1",
) {
	return await centralPedagogica
		.post("aluno/criarResposta", {
			ra,
			pergunta_id: questionId,
			resposta: answer,
			curso: course,
			tutor: isTutor,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeFavorited(authorId: string, idResponse: string) {
	return await centralPedagogica
		.post("aluno/favoritarResposta", {
			autor_id: authorId,
			id: idResponse,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeRating(authorId: string, idResponse: string, rate: number) {
	return await centralPedagogica
		.post("aluno/avaliarResposta", {
			autor_id: authorId,
			id: idResponse,
			nota: rate,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeComment(
	authorId: string,
	idResponse: string,
	rate: string,
	isTutor: "0" | "1",
) {
	return await centralPedagogica
		.post("aluno/criarComentario", {
			ra: authorId,
			resposta_id: idResponse,
			comentario: rate,
			tutor: isTutor,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeReport(
	type: "pergunta" | "resposta",
	id: string,
	authorId: string,
	authorName: string,
	reason: string,
) {
	return await centralPedagogica
		.post("aluno/denunciar", {
			autor_id: authorId,
			autor_nome: authorName,
			type: type,
			reg_id: id,
			motivo: reason,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function aproveReport(type: "pergunta" | "resposta", idReport: string) {
	return await centralPedagogica
		.get(`tutor/aprovarDenuncias/${type}/${idReport}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function clearReports(type: "pergunta" | "resposta", idReport: string) {
	return await centralPedagogica
		.get(`tutor/removerDenuncias/${type}/${idReport}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
