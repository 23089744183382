import { createTheme } from "@material-ui/core/styles";

export const vermelho = createTheme({
	// spacing: 4,
	shape: {
		borderRadius: 4,
	},
	palette: {
		type: "light",
		primary: {
			main: "#BD0E0E",
		},
		secondary: {
			main: "#FFC000",
		},
	},
});

export const azul = createTheme({
	// spacing: 4,
	shape: {
		borderRadius: 4,
	},
	palette: {
		type: "light",
		primary: {
			main: "#001D7E",
		},
		secondary: {
			main: "#DA7800",
		},
	},
});

// -- Defini o tema abaixo para checar como contraste dentro do ecossistema
export const verde = createTheme({
	palette: {
		type: "light",
		primary: {
			main: "#0F9D58",
		},
		secondary: {
			main: "#d81b60",
		},
	},
});
