export default function reducer(state, action) {
    switch (action.type) {
        case 'INITIALIZING':
            return action.payload;
        case "DELETE":
            return {
                ...state,
                outrosEventos: state.outrosEventos.filter((evento) => evento.id !== action.payload),
            };
        case "UPDATE":
            const updatedEvento = action.payload;

            const updatedEventos = state.outrosEventos.map((evento) => {
                if (evento.id === updatedEvento.id) {
                    return updatedEvento;
                }
                return evento;
            });
            return {
                ...state,
                outrosEventos: updatedEventos,
            };
        case "ADD":
            return {
                ...state,
                outrosEventos: [...state.outrosEventos, action.payload],
            };
    }
}

