import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import Label from "@material-ui/icons/Label";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Person from "@material-ui/icons/Person";
import Delete from "@material-ui/icons/Delete";
import Check from "@material-ui/icons/Check";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import ContainerBoxDetails from "./ContainerBoxDetails";
import BoxDetails from "./BoxDetails";

import { formatDate } from "utils/formatDate";
import Notify from "utils/Notification";

import { DialogDetailsProps, DetailsReportProps } from "./dialogdetails.model";

import {
	getDetailsReport,
	aproveReport,
	clearReports,
} from "../../dialogquestionsanswers.services";

import "./styles.scss";

export default function DialogDetails(props: DialogDetailsProps) {
	const [detailsReport, setDetailsReport] = React.useState<DetailsReportProps>();

	const [openReason, setOpenReason] = React.useState(false);
	const [reason, setReason] = React.useState("");

	const onAproveReport = () => {
		aproveReport(props.typeReported, props.idReported)
			.then((data) => {
				if (data.success) {
					props.setOpen(false);
					props.setReload(!props.reload);
					Notify("A denúncia foi deferida!");
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const onRemoveReports = () => {
		clearReports(props.typeReported, props.idReported)
			.then((data) => {
				if (data.success) {
					props.setOpen(false);
					props.setReload(!props.reload);
					Notify("As denúncias foram indeferidas!");
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	React.useEffect(() => {
		if (props.open) {
			getDetailsReport(props.typeReported, props.idReported)
				.then((data) => {
					console.log(data);
					if (data.success) {
						setDetailsReport({
							authorId: data.data.details.autor_id,
							authorName: data.data.details.autor_nome,
							authorEmail: data.data.details.autor_email,
							courseName: data.data.details.curso_nome,
							subjectName: data.data.details.disciplina_nome,
							content: data.data.details.pergunta,
							reports: data.data.reports.map((report: any) => {
								return {
									authorId: report.ra,
									authorName: report.nome,
									dateReport: formatDate(report.created_at),
									reason: "Nesta pergunta feita por um aluno, ele falou coisas indevidas onde machuca os direitos de outros seres. Por esta questão estou vindo denunciar este acontecimentos para as autoridades da instituição, pelo menos a que monitoram este tipo de comportamento indevido na plataforma.",
									btnReason: (
										<Button
											variant='contained'
											style={{ background: "#0090ff", color: "#fff" }}
											onClick={() => {
												setReason(report.motivo);
												setOpenReason(true);
											}}
										>
											Motivo
										</Button>
									),
								};
							}),
						});
					}
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [props.open, props.typeReported, props.idReported]);

	return (
		<>
			<Dialog
				open={props.open}
				onClose={() => {
					props.setOpen(false);
				}}
				fullWidth
				maxWidth='md'
			>
				<DialogTitle className='area_title_details'>
					<Typography className='title_details_list_solicitations'>Detalhes</Typography>
				</DialogTitle>

				<DialogContent dividers>
					<Grid container>
						<ContainerBoxDetails>
							<BoxDetails
								isCopy
								icon={<AssignmentInd />}
								title='RA:'
								value={detailsReport?.authorId ?? "-"}
								xs={6}
								md={4}
							/>

							<BoxDetails
								icon={<Person />}
								title='Nome:'
								value={detailsReport?.authorName ?? "-"}
								xs={12}
								md={8}
							/>

							<BoxDetails
								icon={<Label />}
								title='Tipo:'
								value={props.typeReported}
								xs={6}
								md={4}
							/>

							<BoxDetails
								icon={<Label />}
								title='E-mail:'
								value={detailsReport?.authorEmail ?? "-"}
								xs={6}
								md={8}
							/>

							<BoxDetails
								icon={<Label />}
								title='Curso:'
								value={detailsReport?.courseName ?? "-"}
								xs={12}
								md={12}
							/>

							<BoxDetails
								icon={<Label />}
								title='Disciplina:'
								value={detailsReport?.subjectName ?? "-"}
								xs={12}
								md={12}
							/>

							<BoxDetails
								icon={<QuestionAnswer />}
								title='Conteúdo:'
								value={detailsReport?.content ?? "-"}
								xs={12}
								md={12}
							/>
						</ContainerBoxDetails>

						{detailsReport?.reports && detailsReport?.reports.length > 0 ? (
							<ContainerBoxDetails>
								<Grid item xs={12}>
									<Typography className='textConfirmReport'>
										{" "}
										O conteúdo denuciado é impróprio ?
									</Typography>
									<Box
										display='flex'
										justifyContent='center'
										alignItems='center'
										gridGap={8}
									>
										<Button
											variant='outlined'
											className='btnNoReport'
											startIcon={<Delete />}
											onClick={() => onAproveReport()}
										>
											Sim, Excluir!
										</Button>

										<Button
											variant='outlined'
											className='btnYesReport'
											startIcon={<Check />}
											onClick={() => onRemoveReports()}
										>
											Não, Limpar denúncias!
										</Button>
									</Box>
								</Grid>
							</ContainerBoxDetails>
						) : (
							<></>
						)}

						<ContainerBoxDetails>
							<div className='datatable-responsive-demo'>
								<DataTable
									value={detailsReport?.reports}
									paginator
									rows={10}
									className='p-datatable-responsive-demo'
									emptyMessage='Nenhum registro encontrado...'
								>
									<Column
										header='Data'
										field='dateReport'
										style={{ textAlign: "center" }}
									/>
									<Column
										header='RA'
										field='authorId'
										style={{ textAlign: "center" }}
									/>
									<Column
										header='Nome'
										field='authorName'
										style={{ textAlign: "center" }}
									/>
									<Column
										header='Ações'
										field='btnReason'
										style={{ textAlign: "center" }}
									/>
								</DataTable>
							</div>
						</ContainerBoxDetails>
					</Grid>
				</DialogContent>
			</Dialog>

			<Dialog
				open={openReason}
				onClose={() => {
					setOpenReason(false);
				}}
				fullWidth
				maxWidth='sm'
			>
				<DialogTitle>
					<Typography className='textReason'>{reason}</Typography>
				</DialogTitle>
			</Dialog>
		</>
	);
}
