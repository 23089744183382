import React from "react";
import DialogAlteracaoOutrosEventosView from "./DialogAlteracaoOutrosEventos.view";

export default function DialogAlteracaoOutrosEventos(props): React.ReactElement {
	return (
		<DialogAlteracaoOutrosEventosView
			outrosEventos={props.outrosEventos}
			id={props.id}
		/>
	);
}
