import { Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

export const ShowProfileLoadingStep = (step: number): React.ReactElement => {
	switch (step) {
		case 1:
			return (
				<Alert severity='info' style={{ marginBottom: "12px" }}>
					<AlertTitle>Obtendo informações</AlertTitle>
					<Typography variant='body2' component={"small"} style={{ color: "grey" }}>
						Aguarde enquanto o perfil é carregado.
					</Typography>
				</Alert>
			);
		case 2:
			return (
				<Alert severity='success' style={{ marginBottom: "12px" }}>
					<AlertTitle>O perfil está disponível</AlertTitle>
					<Typography variant='body2' component={"small"} style={{ color: "grey" }}>
						O perfil do aluno(a) foi carregado corretamente. Siga as orientações a
						seguir para efetivar a mudança.
					</Typography>
				</Alert>
			);
		case 3:
			return (
				<Alert severity='warning'>
					<AlertTitle>
						Atenção, <strong>é necessário clicar em continuar</strong> para efetuar a
						mudança de perfil.
					</AlertTitle>
					<Typography variant='body2' component={"small"} style={{ color: "grey" }}>
						<AlertTitle>
							Atenção, <strong>é necessário clicar em continuar</strong> para efetuar
							a mudança de perfil.
						</AlertTitle>
					</Typography>
				</Alert>
			);
		case 4:
			return (
				<>
					<Alert severity='success'>
						<AlertTitle>Perfil disponível!</AlertTitle>
						<Typography variant='body2' component={"small"} style={{ color: "grey" }}>
							O perfil solicitado está disponível, siga as orientações para
							prosseguir.
						</Typography>
					</Alert>
					<br />
					<Alert severity='warning'>
						<AlertTitle>Efetue a mudança de perfil:</AlertTitle>
						<Typography variant='body2' component={"small"} style={{ color: "grey" }}>
							{/* Ao clicar em continuar você terá acesso ao nome e outras informações públicas do(a) aluno(a). */}
							Atenção, <strong>é necessário clicar em continuar</strong> para efetuar
							a mudança de perfil.
						</Typography>
					</Alert>
				</>
			);
		case 5:
			return (
				<Alert severity='warning'>
					<AlertTitle>
						Atenção, <strong>é necessário clicar em continuar</strong> para efetuar a
						mudança de perfil.
					</AlertTitle>
					<Typography variant='body2' component={"small"} style={{ color: "grey" }}>
						{/* Ao clicar em continuar você terá acesso ao nome e outras informações públicas do(a) aluno(a). */}
						<AlertTitle>
							Atenção, <strong>é necessário clicar em continuar</strong> para efetuar
							a mudança de perfil.
						</AlertTitle>
					</Typography>
				</Alert>
			);
		case 0:
			return (
				<Alert severity='info'>
					<AlertTitle> Definindo o perfil padrão </AlertTitle>
					<Typography variant='body2' component={"small"} style={{ color: "grey" }}>
						Ao clicar em continuar você terá acesso ao nome e outras informações
						públicas do(a) aluno(a).
					</Typography>
				</Alert>
			);
		default:
			return (
				<Alert severity='info'>
					<AlertTitle> Insira o RA do estudante </AlertTitle>
					<Typography variant='body2' component={"small"} style={{ color: "grey" }}>
						Ao clicar em continuar você terá acesso ao nome e outras informações
						públicas do(a) aluno(a).
					</Typography>
				</Alert>
			);
	}
};
