import React from "react";
import DialogAlteracaoPublicacoesView from "./DialogAlteracaoPublicacoes.view";

export default function DialogAlteracaoPublicacoes(props): React.ReactElement {
	return (
		<DialogAlteracaoPublicacoesView
			publicacao={props.publicacao}
			id={props.id}
		/>
	);
}
