import { useAuth } from "context/Auth/hook";

import { makeStyles } from "@material-ui/styles";

import React from "react";

import "./styles.scss";
// import { Button } from "@material-ui/core";
// import WbSunnyIcon from '@material-ui/icons/WbSunny';
// import NightsStayIcon from '@material-ui/icons/NightsStay';

const Box = React.lazy(() => import("@material-ui/core/Box"));
const Typography = React.lazy(() => import("@material-ui/core/Typography"));

const useStyles = makeStyles((theme: any) => ({
	root: {
		color: theme?.palette.secondary.main,
	},
	uninta: {
		color: "#bd0e0e",
	},
}));

export default function Welcome() {
	const classes = useStyles();
	// const { user, nivelDeEnsino, setNivelDeEnsino, setUnintaTheme } = useAuth();
	const { user, nivelDeEnsino } = useAuth();
	return (
		<Box className='welcome'>
			<Typography variant='h3' className='text_welcome'>
				Olá{" "}
				<span
					className={
						(nivelDeEnsino.codigo_nivel_ensino !== 351)								&&
						(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE ALENCARINA")			&&
						(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO - EAD")	&&
						(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO")
							? classes.uninta
							: classes.root
					}
				>
					{user.nome
						.toLowerCase()
						.split(" ")
						.map((name) => name.charAt(0).toUpperCase() + name.slice(1))
						.join(" ")}
				</span>
				, seja bem-vindo(a) ao
			</Typography>
			<Typography variant='h3' className='text_application'>
				ECOSSISTEMA DE{" "}
				<span
					className={
						(nivelDeEnsino.codigo_nivel_ensino !== 351)								&&
						(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE ALENCARINA")			&&
						(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO - EAD")	&&
						(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO")
							? classes.uninta
							: classes.root
					}
				>
					APRENDIZAGEM
				</span>
			</Typography>
			<Typography variant='h3' className='text_subtitle'>
				Para conectar pessoas e formar comunidades de práticas.
			</Typography>
			{/* <Button onClick={()=>{
				if(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" && nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"){
					setNivelDeEnsino({
						"codigo_nivel_ensino":2,
						"nome_nivel_ensino":"FACULDADE ALENCARINA",
						"codigo_curso":"DID_ENSUP",
						"nome_curso":"DIDÁTICA DO ENSINO SUPERIOR",
						"periodo":1,"situacao":"ATIVO",
						"idhabilitacaofilial":"2411",
						"idperlet":"354"
					});
					setUnintaTheme(false);
					sessionStorage.setItem("nivelDeEnsino", JSON.stringify({
						"codigo_nivel_ensino":2,
						"nome_nivel_ensino":"FACULDADE ALENCARINA",
						"codigo_curso":"DID_ENSUP",
						"nome_curso":"DIDÁTICA DO ENSINO SUPERIOR",
						"periodo":1,"situacao":"ATIVO",
						"idhabilitacaofilial":"2411",
						"idperlet":"354"
					}));
				} else {
					setNivelDeEnsino({
						"codigo_nivel_ensino":2,
						"nome_nivel_ensino":"POLO DIGITAL - BRASIL",
						"codigo_curso":"DID_ENSUP",
						"nome_curso":"DIDÁTICA DO ENSINO SUPERIOR",
						"periodo":1,"situacao":"ATIVO",
						"idhabilitacaofilial":"2411",
						"idperlet":"354"
					});
					setUnintaTheme(true);
					sessionStorage.setItem("nivelDeEnsino", JSON.stringify({
						"codigo_nivel_ensino":2,
						"nome_nivel_ensino":"POLO DIGITAL - BRASIL",
						"codigo_curso":"DID_ENSUP",
						"nome_curso":"DIDÁTICA DO ENSINO SUPERIOR",
						"periodo":1,"situacao":"ATIVO",
						"idhabilitacaofilial":"2411",
						"idperlet":"354"
					}));
				}
			}}
			style={{
				backgroundColor: nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" && nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO" ? "#bd0e0e" : "#001D7E",
				color: "#fff",
				borderRadius: "24px",
				textTransform: "capitalize",
				padding: "12px 24px",
				marginTop: "24px",
				fontWeight: "bold",
				transition: "ease-in-out 0.2s",
			}}> 
				{
					nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" && nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"	
					? <WbSunnyIcon style={{marginRight: "8px"}}/>
					: <NightsStayIcon style={{marginRight: "8px"}}/>
				}							
				Personalizar a exibição
			</Button> */}
		</Box>
	);
}
