import React from "react";
import { Grid, Typography, TextField, Button, FormControl, useTheme, makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useModalContext } from "context/modal/hooks/useModal";
import { useForm } from "react-hook-form";
import Notify from "../../../../utils/Notification";
import { postPublications } from "services/Publicacoes";
import MenuItem from "@material-ui/core/MenuItem";
import { usePortfolio } from "context/Portfolio";
import { usePublicacoesContext } from "context/Publicacoes/hooks/usePublicacoes";

export default function FormularioCadastroOutrosEventosView(): React.ReactElement {
	const {
		register,
		handleSubmit, setValue,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	const { handleCloseCadastro, handleRefresh } = useModalContext();
	const { state } = usePortfolio();
	const { addPublicacao } = usePublicacoesContext();

	const [disabled, setDisabled] = React.useState(false);

	const theme = useTheme();
	const useStyles = makeStyles((theme) => ({
		root: {
			color: theme?.palette.secondary.main,
		},
		uninta: {
			color: "#bd0e0e",
		},
		select: {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey ",
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey !important",
			},
			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: "black !important",
			},
		},
		inputLabel: {
			"&.Mui-focused": {
				color: "black !important",
			},
		},
		textField: {
			"& label.Mui-focused": {
				color: "black",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const classes = useStyles();

	async function cadastroPublicacoes(data) {
		setDisabled(true);
		postPublications(data).then(({ data }) => {
			if (data !== undefined) {
				addPublicacao(data)
				Notify("Publicação cadastrada com sucesso!");
				handleCloseCadastro();
				handleRefresh();
			} else {
				Notify("Evento não foi cadastrado!", "error");
				handleCloseCadastro();
				handleRefresh();
			}
		}).catch((e) => {
			Notify("Evento não foi cadastrado!", "error");
			handleCloseCadastro();
			handleRefresh();
		});;
	}

	function Integer(v) {
		return v.replace(/\D/g, "");
	}

	return (
		<form
			onSubmit={handleSubmit((data) =>
				cadastroPublicacoes({
					type: data.type,
					title: data.title,
					date: data.date,
					url: data.url,
					workload: data.workload,
					local: data.local,
					profile_id: state.id,
				}),
			)}
		>
			<Box mt={3} mb={3}>
				<Typography variant='body1'>DADOS CADASTRAIS</Typography>
			</Box>
			<Box mt={3}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<FormControl size='small' fullWidth>
							<TextField
								select
								variant='outlined'
								className={classes.textField}
								label='Tipo'
								style={{
									width: "100%",
								}}
								{...register("type", { required: true })}
								error={errors.type?.type === "required"}
								helperText={
									errors.type?.type === "required" &&
									"O campo 'Tipo' é obrigatório"
								}
							>
								<MenuItem
									value={
										"Artigo publicado em revista com Qualis reconhecido pela CAPES"
									}
								>
									Artigo publicado em revista com Qualis reconhecido pela CAPES
								</MenuItem>
								<MenuItem
									value={"Apresentação oral de Trabalhos Científicos em Eventos."}
								>
									Apresentação oral de Trabalhos Científicos em Eventos.
								</MenuItem>
								<MenuItem
									value={
										"Apresentação em pôster de Trabalhos Científicos em eventos (Colóquio / Banner de Estágio)"
									}
								>
									Apresentação em pôster de Trabalhos Científicos em eventos
									(Colóquio / Banner de Estágio)
								</MenuItem>
								<MenuItem value={"Publicação em Anais de Eventos Científicos."}>
									Publicação em Anais de Eventos Científicos.
								</MenuItem>
								<MenuItem
									value={
										"Participação em concursos, exposições e mostras como expositor."
									}
								>
									Participação em concursos, exposições e mostras como expositor.
								</MenuItem>
							</TextField>
						</FormControl>

						<FormControl size='small' fullWidth>
							<TextField
								label='Título'
								variant='outlined'
								className={classes.textField}
								style={{
									width: "100%",
								}}
								{...register("title", { required: true })}
								error={errors.title?.type === "required"}
								helperText={
									errors.title?.type === "required" &&
									"O campo 'Título' é obrigatório"
								}
							/>
						</FormControl>
						<FormControl size='small' fullWidth>
							<TextField
								label='Publicado em'
								variant='outlined'
								className={classes.textField}
								type='date'
								style={{
									width: "100%",
								}}
								InputLabelProps={{
									shrink: true,
								}}
								{...register("date", { required: true })}
								error={errors.date?.type === "required"}
								helperText={
									errors.date?.type === "required" &&
									"O campo 'Publicado em' é obrigatório"
								}
							/>
						</FormControl>
						<FormControl size='small' fullWidth>
							<TextField
								label='Endereço'
								variant='outlined'
								className={classes.textField}
								style={{
									width: "100%",
								}}
								type='url'
								{...register("url", { required: true })}
								error={errors.url?.type === "required"}
								helperText={
									errors.url?.type === "required" &&
									"O campo 'Endereço' é obrigatório"
								}
							/>
						</FormControl>
						<FormControl size='small' fullWidth>
							<TextField
								label='Carga Horária'
								placeholder="Em Horas"
								variant='outlined'
								className={classes.textField}
								style={{
									width: "100%",
								}}
								{...register("workload", { required: true })}
								onChange={(e) => { setValue("workload", Integer(e.target.value)) }}
								error={errors.workload?.type === "required"}
								helperText={
									errors.workload?.type === "required" &&
									"O campo 'Carga Horária' é obrigatório"
								}
							/>
						</FormControl>
						<FormControl size='small' fullWidth>
							<TextField
								label='Local'
								variant='outlined'
								className={classes.textField}
								style={{
									width: "100%",
								}}
								{...register("local", { required: true })}
								error={errors.local?.type === "required"}
								helperText={
									errors.local?.type === "required" &&
									"O campo 'Local' é obrigatório"
								}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Box>
							<Button
								style={{
									backgroundColor: theme.palette.primary.main,
									color: "white",
									width: "100%",
								}}
								disableElevation
								variant='contained'
								type={"submit"}
								disabled={disabled}
							>
								{" "}
								Cadastrar{" "}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
}
