import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import DialogRequestConfirmation from "../DialogReviewQuestion/FormReviewQuestion/DialogRequestConfirmation";
import DialogScheduleConfirmation from "../DialogScheduleTime/DialogScheduleConfirmation";
import DialogHistoric from "../DialogHistoric";

import { PopupListProps } from "./popup.model";
// import { DataReviewReturn } from "../DialogReviewQuestion/FormReviewQuestion/DialogRequestConfirmation/requestConfirmation.model";
import { getSolicitations } from "../DialogReviewQuestion/FormReviewQuestion/formreviewquestion.services";
import { getSchedules } from "../DialogScheduleTime/scheduletime.services";

import { useAuth } from "context/Auth/hook";
import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import "./styles.scss";
export default function PopupList(props: PopupListProps): React.ReactElement {
	const { user } = useAuth();
	const { updateNumReviews, updateNumSchedule } = usePedagogicalCommunication();

	const [loadingGetSolicitations, setLoadingGetSolicitations] = React.useState(true);

	const [openHistoric, setOpenHistoric] = React.useState(false);
	const [openReview, setOpenReview] = React.useState(false);
	const [idSolicitation, setIdSolicitation] = React.useState("");
	const [listSolicitationsSimple, setListSolicitationsSimple] = React.useState<any[]>([]);

	const handleClick = (id: string) => {
		setIdSolicitation(id);
		setOpenReview(true);
		props.setOpen(false);
	};

	React.useEffect(() => {
		if (props.open && !openReview) {
			if (props.type === "review") {
				getSolicitations(user?.ra, "pendente")
					.then((data) => {
						setListSolicitationsSimple(data.data ? data.data : []);
						setLoadingGetSolicitations(false);
					})
					.catch((e) => {
						console.log(e);
					});
			}

			if (props.type === "schedule") {
				getSchedules(user?.ra, "pendente")
					.then((data) => {
						setListSolicitationsSimple(data.data ? data.data : []);
						setLoadingGetSolicitations(false);
					})
					.catch((e) => {
						console.log(e);
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open, openReview, updateNumReviews, updateNumSchedule]);

	return (
		<>
			{props.open && (
				<Box className='popup'>
					<Box display='flex' justifyContent='space-between' alignItems='center'>
						<Typography variant='subtitle2'>{props.title}</Typography>
						<IconButton onClick={() => props.setOpen(false)}>
							<Close />
						</IconButton>
					</Box>

					{loadingGetSolicitations ? (
						<Box display='flex' justifyContent='center' alignItems='center' padding={2}>
							<CircularProgress />
						</Box>
					) : (
						<>
							<Box className='area_notify'>
								{listSolicitationsSimple?.length <= 0 ? (
									<Typography className='nullValue'>
										(Nenhuma Solicitação)
									</Typography>
								) : (
									<>
										{listSolicitationsSimple
											?.map((request, index) => {
												let colorIcon = "#346599";

												if (
													request.situacao === "deferido" ||
													request.situacao === "concluido"
												) {
													colorIcon = "#429a25";
												}

												if (
													request.situacao === "indeferido" ||
													request.situacao === "cancelado"
												) {
													colorIcon = "#aa1122";
												}

												return (
													<Box
														className='item_list_request'
														key={index}
														onClick={() => {
															handleClick(request.id);
														}}
														title={request.situacao}
													>
														<FiberManualRecord
															style={{ color: colorIcon }}
														/>
														<Typography className='text_list_request'>
															{props.type === "review"
																? request?.justificativa
																: props.type === "schedule"
																? request.assunto_nome
																: ""}
														</Typography>
													</Box>
												);
											})
											.reverse()}
									</>
								)}
							</Box>

							{listSolicitationsSimple?.length > 0 && (
								<Box className='area_btn_all'>
									<button
										onClick={() => {
											setOpenHistoric(true);
											props.setOpen(false);
										}}
									>
										Visualizar Histórico
									</button>
								</Box>
							)}
						</>
					)}
				</Box>
			)}

			{props.type === "review" ? (
				<DialogRequestConfirmation
					open={openReview}
					idSolicitation={idSolicitation}
					setOpen={setOpenReview}
					setIdSolicitation={setIdSolicitation}
				/>
			) : props.type === "schedule" ? (
				<DialogScheduleConfirmation
					open={openReview}
					idSchedule={idSolicitation}
					setOpen={setOpenReview}
				/>
			) : (
				<></>
			)}

			<DialogHistoric
				open={openHistoric}
				title={
					props.type === "review"
						? "Listagem de Solicitações"
						: props.type === "schedule"
						? "Listagem de Agendamentos"
						: ""
				}
				type={props.type}
				setOpen={setOpenHistoric}
			/>
		</>
	);
}
