import { AxiosResponse } from "axios";
import {
	Disciplina,
	BlocoNotas,
	UnidadeDisciplina,
	DataListaContribuicoes,
} from "models/tabelaDisciplinas";
import { blocoNotasArquivos } from "services/api";

export async function getDisciplina(idDisciplina: string): Promise<AxiosResponse<Disciplina>> {
	return blocoNotasArquivos.get(`disciplina/${idDisciplina}`);
}

export async function getUnidadesDisciplina(idDisciplinaCuradoria: number): Promise<AxiosResponse<UnidadeDisciplina[]>> {
	return blocoNotasArquivos.get(`disciplina/${idDisciplinaCuradoria}/unidades`);
}

export async function getBlocoNotas(
	idUnidadeDisciplina: string,
): Promise<AxiosResponse<BlocoNotas>> {
	return blocoNotasArquivos.get(`unidade/${idUnidadeDisciplina}/bloco-de-notas`);
}

export async function postBlocoNotas(
	idUnidadeDisciplina: string,
	dados: object,
): Promise<AxiosResponse<object>> {
	return blocoNotasArquivos.post(`unidade/${idUnidadeDisciplina}/bloco-de-notas`, dados);
}

export async function putBlocoNotas(
	idUnidadeDisciplina: string,
	dados: object,
): Promise<AxiosResponse<object>> {
	return blocoNotasArquivos.put(`unidade/${idUnidadeDisciplina}/bloco-de-notas`, dados);
}

export async function getContribuicoes(
	idUnidadeDisciplina: string,
): Promise<AxiosResponse<DataListaContribuicoes>> {
	return blocoNotasArquivos.get(`/unidade/${idUnidadeDisciplina}/contribuicao/?page=1`);
}
