import React, { useRef } from "react";

// Importando dependências do Material UI

import { Box, IconButton } from "@material-ui/core";

// Importando estilos

import "./styles.scss";

// Importando ícones

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// Import mock -- Card Mock
// import { cardMock } from "./mock";
// Importando services

// import axios from "axios";

export default function Cards(props: any): React.ReactElement {
	const left = useRef<HTMLDivElement>(null);
	// const [disciplinas, setDisciplinas] = useState<any[]>([]);

	const leftScroll = () =>
		left.current ? (left.current.scrollLeft -= props.right ? props.right : 275) : null;
	const rightScroll = () =>
		left.current ? (left.current.scrollLeft += props.right ? props.right : 275) : null;

	// useEffect(()=>{
	// 	ecossistema.get("notas/40005000")
	// 		.then((res)=>{
	// 			console.log(res.data[0].disciplinas);
	// 			setDisciplinas(res.data[0].disciplinas);
	// 		});
	// },[]);
	return (
		<>
			{/* {*/}
			{/*	props.children[0].length > 0 || props.children[1].length > 0*/}
			{/*		? <>*/}
			<Box
				style={{
					marginLeft: "70px",
				}}
				mb={3}
			></Box>
			<Box display='flex' flexDirection='row' alignItems='center'>
				<IconButton size='small' onClick={leftScroll} className='btn_move_scroll_carousel'>
					<ChevronLeftIcon />
				</IconButton>

				<div className='row' ref={left}>
					{props.children}
				</div>

				<IconButton size='small' onClick={rightScroll} className='btn_move_scroll_carousel'>
					<ChevronRightIcon />
				</IconButton>
			</Box>
			{/* </>*/}
			{/* // 	:   <></>*/}
			{/* // }*/}
		</>
	);
}
