import React from "react";

export default function Vimeo() {
	return (
		<svg
			id='Camada_1'
			data-name='Camada 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 433.18 433.56'
		>
			<defs></defs>
			<title>vimeo-logo</title>
			<path d='M335.83,35.32c13.48,10,26.77,20.13,38.28,32.44A218.34,218.34,0,0,1,425.9,273.05a220.69,220.69,0,0,1-25.81,59,216,216,0,0,1-33.16,40.89c-18.85,18.47-40.63,32.55-64.74,43a214.29,214.29,0,0,1-85.55,17.64A211.67,211.67,0,0,1,164,427c-43.26-10.92-79.89-33-109.5-66.18A213.18,213.18,0,0,1,5,264.05c-5.09-22.74-6-45.62-4.1-68.76,2.21-26.46,9.79-51.4,21.33-75.08C30.33,103.62,41,88.6,53.05,74.51A215.3,215.3,0,0,1,160.14,7.22C182.6,1,205.53-.64,228.69.21c12.59.47,24.94,3.08,37.38,4.86a1.32,1.32,0,0,0,.77.24l14.18,4,14,5c10.6,4.48,20.92,9.52,31,15Zm-39.8,90a66.72,66.72,0,0,0-14.95,5c-28.86,12.09-45.46,38.62-46.74,58.42a23.31,23.31,0,0,1,2.67-1.46c8.56-3.28,17.07-7.83,26.09-1.2,5.76,4.85,9,10.95,8.75,18.57-.09,3.11-.64,6.21-1,9.32-9.75,22.17-20.27,43.87-37.93,61.13-6,3-9.35,1.92-12.42-4a51.93,51.93,0,0,1-2.42-5.45c-10.83-28.62-16-58.62-21-88.6C195,165,192.81,153,187.06,142c-6-11.42-13.86-16.07-25.67-14.69a46.44,46.44,0,0,0-16.88,5.65c-22.68,12.43-41.7,29.42-58.86,48.49-7.53,8.38-6.81,8.69-.58,18.57,2.7,4.27,4.94,5,8.89,1.81a37,37,0,0,1,16.59-7.41c5.55-1,9,.6,10.5,5,2.42,6.86,4.84,13.72,7.08,20.63,6,18.41,11.82,36.84,17.72,55.26,2.74,9.17,5.06,18.5,8.31,27.49,5.4,15,8.25,31.14,18.63,44,8.54,10.58,18.24,19.38,33.27,18.34a28.53,28.53,0,0,0,3.93-.31c10.42-2.26,18-9.28,26.08-15.53a346.76,346.76,0,0,0,45.05-44.07c2.8-3,5.77-5.93,8.39-9.12,7.43-9.06,15.58-17.56,21.69-27.66,6.44-7.84,11.48-16.59,16.06-25.57,11.27-22.1,19.05-45.27,21.79-70.1,2.14-19.41-6.34-36.42-23-44.4-7.56-3.68-15.6-4.55-23.87-4Z' />
		</svg>
	);
}
