import React, { ReactNode } from "react";

import { useTheme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import "./styles.scss";

interface ButtonsInfoProps {
	startIconButton: React.ReactElement;
	titleButton: string;
	value: string | number;
	children?: ReactNode;
	actionButton?: () => void;
	id?: string;
}

export default function ButtonsInfo({
	startIconButton,
	titleButton,
	value,
	children,
	actionButton,
	id,
}: ButtonsInfoProps): React.ReactElement {
	const colorsTheme = useTheme();

	return (
		<Box style={{ position: "relative" }}>
			<Button
				className='button_info'
				startIcon={startIconButton}
				title={titleButton}
				onClick={actionButton}
				style={{
					color: colorsTheme.palette.primary.main,
				}}
				id={id}
			>
				{value}
			</Button>

			{children}
		</Box>
	);
}
