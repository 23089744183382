// Importando interfaces
import { CardInterface } from "./card.model";
import CardTutorialMobileView from "./CardTutorialMobile.view";

export default function CardTutorialMobile({
	etapa,
	setEtapa,
	etapaTutorialMobile,
	setOpenDialog,
	qtdEtapas
}: CardInterface) {
	return (
		<CardTutorialMobileView
			etapa={etapa}
			setEtapa={setEtapa}
			qtdEtapas={qtdEtapas}
			etapaTutorialMobile={etapaTutorialMobile}
			setOpenDialog={setOpenDialog}
		/>
	);
}
