import { IconButton, List } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React from "react";
import { Typography } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import "../style.scss";
import DialogCadatroOutrosEventos from "components/Dialogs/Cadastro/DialogCadastroOutrosEventos";
import DialogAlteracaoOutrosEventos from "components/Dialogs/Alteracao/AlteracaoOutrosEventos";
// import { deleteEvents } from "services/OutrosEventos";
import { useOutrosEventosContext } from "context/OutrosEventos/hooks/useOutrosEventos";

export default function OutrosEventosView(props): React.ReactElement {
	const { stateReducer, deleteOutrosEventos, verification } = useOutrosEventosContext();

	// function deletarEvento(id) {
	// 	if (window.confirm("Deseja deletar este depoimento?")) {
	// 		const newOutrosEventos = props.outrosEventos.filter((event) => event.id !== id);
	// 		props.setOutrosEventos(newOutrosEventos);
	// deleteEvents(id)
	// 			.then(() => {
	// 				Notify("Evento deletado com sucesso!");
	// 			})
	// 			.catch(() => {
	// 				Notify(
	// 					"Falha ao deletar o evento! Tente novamente dentro de alguns instantes.",
	// 				);
	// 			});
	// 		return;
	// 	}
	// }

	function components() {
		if (verification) {
			return (
				<List>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							width: "100%",
							height: "30px",
						}}
					>
						<Typography style={{ paddingLeft: "17px" }} className='subCategoria'>
							Outros Eventos
						</Typography>
						<DialogCadatroOutrosEventos />
					</div>
					{stateReducer.outrosEventos.map((event) => (
						<>
							<ListItem className='listItemPrimary' alignItems='flex-start'>
								<ListItemText
									className='listItemPrimaryNa'
									primary={
										<div className='divlistItemPrimaryNa'>
											<Typography
												component='span'
												variant='body2'
												className='typographyItemPrimaryNa'
												style={{
													fontSize: "16px",
												}}
											>
												{event.name}
											</Typography>
											<div className='buttonAltDelete'>
												<DialogAlteracaoOutrosEventos
													outrosEventos={stateReducer.outrosEventos}
													id={event.id}
												/>
												<IconButton>
													<DeleteForeverIcon
														onClick={() => {
															deleteOutrosEventos(event.id);
														}}
													/>
												</IconButton>
											</div>
										</div>
									}
									secondary={
										<React.Fragment>
											<div className='dadosEventosNa'>
												<Typography
													component='span'
													variant='body2'
													className='typographyTituloNa'
												>
													Realização: &nbsp;
												</Typography>
												<Typography component='span' variant='body2' color='textPrimary'>
													{event.promoted}
												</Typography>
											</div>
											<div className='dadosEventosNa secondaryNa'>
												<div className='divSecondaryNa'>
													<Typography
														component='span'
														variant='body2'
														className='typographyTituloNa'
													>
														Certificação emitida em: &nbsp;
													</Typography>
													<Typography component='span' variant='body2' color='textPrimary'>
														{date(event.start_date)} à {date(event.end_date)}
													</Typography>
												</div>
												<div className='divSecondaryNa'>
													<Typography
														component='span'
														variant='body2'
														className='typographyTituloNa'
													>
														Carga horaria: &nbsp;
													</Typography>
													<Typography component='span' variant='body2' color='textPrimary'>
														{event.workload}H
													</Typography>
												</div>
												<div className='divSecondaryNa'>
													<Typography
														component='span'
														variant='body2'
														className='typographyTituloNa'
													>
														Local: &nbsp;
													</Typography>
													<Typography component='span' variant='body2' color='textPrimary'>
														{event.local}
													</Typography>
												</div>
											</div>
										</React.Fragment>
									}
								/>
							</ListItem>
							<Divider variant='inset' component='li' />
						</>
					))}
				</List>
			);
		} else {
			return <></>;
		}
	}

	return <div style={{ margin: "20px 0px 20px 0px" }}>{components()}</div>;
}


function date(data_impressao: string) {
	for (let index = 0; index < data_impressao?.length; index++) {
		if (data_impressao[index] === "-") {
			const arrayData = data_impressao.split("-");
			const arrayDataNew = arrayData.reverse().join("/");
			return arrayDataNew;
		}
	}
	return data_impressao;
}
