import React from "react";
import { Grid, Typography, TextField, Button, FormControl, useTheme, makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { putEvent } from "services/OutrosEventos";
import { useModalContext } from "context/modal/hooks/useModal";
import Notify from "../../../../utils/Notification";
import { usePortfolio } from "context/Portfolio";
import { OutrosEventos } from "models/outrosEventos";
import { useOutrosEventosContext } from "context/OutrosEventos/hooks/useOutrosEventos";

export default function FormularioAlteracaoOutrosEventosView(props): React.ReactElement {
	const { handleSubmit, reset, getValues, setValue, control, formState: { errors }, } = useForm({ mode: "onBlur" });
	const [evento, setEvento] = React.useState({} as OutrosEventos);
	const { handleCloseAlterar, handleRefresh } = useModalContext();
	const idEvento = Number(sessionStorage.getItem("idEvento") as string);
	const theme = useTheme();
	const { state } = usePortfolio();
	const [disabled, setDisabled] = React.useState(false);
	const { updateOutrosEventos } = useOutrosEventosContext();

	const useStyles = makeStyles((theme) => ({
		root: {
			color: theme?.palette.secondary.main,
		},
		uninta: {
			color: "#bd0e0e",
		},
		select: {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey ",
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey !important",
			},
			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: "black !important",
			},
		},
		inputLabel: {
			"&.Mui-focused": {
				color: "black !important",
			},
		},
		textField: {
			"& label.Mui-focused": {
				color: "black",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));

	const classes = useStyles();

	function Integer(v) {
		return v.replace(/\D/g, "");
	}


	React.useEffect(() => {
		const data = props.outrosEventos.filter((value) => value.id === idEvento)
		reset(data[0]);
		setEvento(data[0])
	}, [idEvento, reset, props.outrosEventos]);

	return (
		<form
			onSubmit={handleSubmit((data, event) => {
				event?.preventDefault();

				if (JSON.stringify(evento) === JSON.stringify(data)) {
					Notify(
						"Para efetuar esta ação atualize pelo menos uma informação!",
						"warning",
					);
					return;
				} else {
					if (data.end_date < data.start_date) {
						Notify("Atenção, a data de início deve anteceder a data de encerramento!", "warning");
						return;
					} else {
						setDisabled(true);
						putEvent(idEvento, {
							name: data.name,
							promoted: data.promoted,
							start_date: data.start_date,
							end_date: data.end_date,
							workload: data.workload,
							local: data.local,
							profile_id: state.id,

						}).then(() => {
							updateOutrosEventos(data)
							Notify("Evento cadastrado com sucesso!");
							handleCloseAlterar();
							handleRefresh();
						}).catch((e) => {
							console.log(e);
						});
					}
				}
			})}
		>
			<Box mt={3} mb={3}>
				<Typography variant='body1'>DADOS CADASTRAIS</Typography>
			</Box>

			<Box mt={3}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<FormControl size='small' fullWidth>
							<Controller
								name="name"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => (
									<TextField
										className={classes.textField}
										label='Nome de Evento'
										variant='outlined'
										fullWidth
										value={getValues("name") ? value : ""}
										onChange={(e) => { onChange(e) }}
										error={errors.name?.type === "required"}
										helperText={errors.name?.type === "required" && (<span style={{ color: "red" }}>O campo 'Nome de Evento' é obrigatório</span>)}
									/>
								)}
							/>
						</FormControl>


						<FormControl size='small' fullWidth>
							<Controller
								name="promoted"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => (
									<TextField
										className={classes.textField}
										label='Realização'
										variant='outlined'
										fullWidth
										value={getValues("promoted") ? value : ""}
										onChange={(e) => { onChange(e) }}
										error={errors.promoted?.type === "required"}
										helperText={errors.promoted?.type === "required" && (<span style={{ color: "red" }}>O campo 'Realização' é obrigatório</span>)}
									/>
								)}
							/>
						</FormControl>


						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<FormControl size='small' fullWidth style={{ marginRight: "5px" }}>

								<Controller
									name="start_date"
									control={control}
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<TextField
											className={classes.textField}
											label='Data inicial'
											variant='outlined'
											type='date'
											fullWidth
											InputLabelProps={{ shrink: true, }}
											value={getValues("start_date") ? value : ""}
											onChange={(e) => { onChange(e) }}
											error={errors.start_date?.type === "required"}
											helperText={errors.start_date?.type === "required" && (<span style={{ color: "red" }}>O campo 'Data inicial' é obrigatório</span>)}
										/>
									)} />

							</FormControl>


							<FormControl size='small' fullWidth>
								<Controller
									name="end_date"
									control={control}
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<TextField
											className={classes.textField}
											label='Data final'
											variant='outlined'
											type='date'
											fullWidth
											InputLabelProps={{ shrink: true, }}
											value={getValues("end_date") ? value : ""}
											onChange={(e) => { onChange(e) }}
											error={errors.end_date?.type === "required"}
											helperText={errors.end_date?.type === "required" && (<span style={{ color: "red" }}>O campo 'Data final' é obrigatório</span>)}
										/>
									)} />
							</FormControl>
						</div>


						<FormControl size='small' fullWidth>
							<Controller
								name="workload"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => (
									<TextField
										className={classes.textField}
										label='Carga horaria'
										variant='outlined'
										fullWidth
										value={getValues("workload") ? value : ""}
										onChange={(e) => { setValue("workload", Integer(e.target.value)) }}
										error={errors.workload?.type === "required"}
										helperText={errors.workload?.type === "required" && (<span style={{ color: "red" }}>O campo 'Carga horaria' é obrigatório</span>)}
									/>
								)} />
						</FormControl>


						<FormControl size='small' fullWidth>
							<Controller
								name="local"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => (
									<TextField
										className={classes.textField}
										label='Local'
										variant='outlined'
										fullWidth
										value={getValues("local") ? value : ""}
										onChange={(e) => { onChange(e) }}
										error={errors.local?.type === "required"}
										helperText={errors.local?.type === "required" && (<span style={{ color: "red" }}>O campo 'Local' é obrigatório</span>)}
									/>
								)} />
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Box>
							<Button
								style={{
									backgroundColor: theme.palette.primary.main,
									color: "white",
								}}
								disableElevation
								variant='contained'
								type={"submit"}
								disabled={disabled}
								fullWidth
							>
								{" "}
								Cadastrar{" "}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
}
