import { AuthContextType } from "models/auth";

// Valores iniciais do contexto de autenticação
export const INI_VALUES: AuthContextType = {
	openDrawer: false,
	setOpenDrawer: () => {
		return;
	},
	runReactJoyride: false,
	setRunReactJoyride: () => {
		return;
	},
	user: {
		signed: false,
		typeUser: undefined,
		image: "",
		ra: "",
		nome: "Visitante",
	},
	setUser: () => {
		return;
	},
	cursos: null,
	nivelDeEnsino: {
		codigo_nivel_ensino: "",
		nome_nivel_ensino: "",
		codigo_curso: "",
		nome_curso: "",
		periodo: "",
		situacao: "",
		idhabilitacaofilial: "",
		idperlet: "",
	},
	unintaTheme: true,	
	Login: async (props: { ra?: string; password?: string }) => {
		return;
	},
	Logout: async () => {
		return;
	},
	setNivelDeEnsino: async () => {
		return;
	},
	setUnintaTheme: async () => {
		return;
	},	
	setCursos: async () => {
		return;
	},
};
