import React from "react";

// Adicionando importações do Material UI 4
import {
	Badge,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Typography,
	useTheme,
} from "@material-ui/core";

// Importando estilos

import { NotificationsOutlined } from "@material-ui/icons";

export default function CardLateralMentoriaView(): React.ReactElement {
	const theme = useTheme();

	return (
		<Card className='card-mentoria-lateral'>
			<CardHeader
				className='box-header'
				subheader={
					<>
						<span id='subtitulo'>Agendamentos de Mentoria</span>												
						<Badge badgeContent='4' color='secondary' overlap="rectangular">
							<NotificationsOutlined fontSize='large' />
						</Badge>
					</>
				}
			/>

			<CardMedia
				component='img'
				src='imagens-oportunidades/mentoria.png'
				title='Imagem aleatória'
			/>

			<CardContent className='box-content-card-mentoria-lateral'>
				<Typography variant='body2' component='h2'>
					Mentoria Profissional
				</Typography>
				<Typography variant='body2' color='textSecondary' component='p'>
					Tenha a possibilidade de conversar com profissionais especialistas no
					desenvolvimento de carreiras e receba orientações de como ingressar no mercado
					de trabalho.
				</Typography>
			</CardContent>

			<CardActions className='box-botao-card-mentoria-lateral'>
				<Button className='botao' style={{ backgroundColor: theme.palette.primary.main }}>
					Agendar mentoria
				</Button>
			</CardActions>
		</Card>
	);
}
