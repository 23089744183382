import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import ArrowBack from "@material-ui/icons/ArrowBack";
import Message from "@material-ui/icons/Message";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Report from "@material-ui/icons/Report";

import ButtonInfo from "../ButtonsInfo";
import PopupNotifications from "../PopupNotifications";
import DialogViewQuestion from "../Questions/DialogViewQuestion";
import AvatarProfile from "components/AvatarProfile";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";
import { useAuth } from "context/Auth/hook";

import { HeaderProps } from "./header.model";

import {
	getCountAnswer,
	getCountQuestion,
	getListAnswersByAuthor,
	getListQuestionsByAuthor,
} from "../dialogquestionsanswers.services";

import "./styles.scss";

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
}));

export default function Header(props: HeaderProps): React.ReactElement {
	const { user, nivelDeEnsino } = useAuth();
	const { updateNumQuestions, updateNumAnswers } = usePedagogicalCommunication();
	const classes = useStyles();
	const colorsTheme = useTheme();

	const [numQuestions, setNumQuestions] = React.useState(0);
	const [numAnswers, setNumAnswers] = React.useState(0);

	const [openViewQuestion, setOpenViewQuestion] = React.useState(false);
	const [openListQuestions, setOpenListQuestions] = React.useState(false);
	const [openListResponse, setOpenListResponse] = React.useState(false);
	const [listQuestions, setListQuestions] = React.useState([]);
	const [listAnswer, setListAnswer] = React.useState([]);
	const [idQuestionSelected, setIdQuestionSelected] = React.useState("");

	const [titlePopup, setTitlePopup] = React.useState("");

	const handleActiveNavigate = (value: "question" | "response" | "report") => {
		props.setComponentOpen(value);
	};

	React.useEffect(() => {
		getCountQuestion(user.ra ?? "")
			.then((data) => {
				setNumQuestions(data.count);
			})
			.catch((e) => {
				console.log(e);
			});

		getListQuestionsByAuthor(user.ra ?? "")
			.then((data) => {
				const results = data.data.map((dt: any) => {
					return {
						title: dt.pergunta.replace(/(&nbsp;|<([^>]+)>)/gi, ""),
						actionClick: () => {
							setOpenViewQuestion(true);
							setIdQuestionSelected(dt.id);
							setOpenListQuestions(false);
						},
					};
				});

				setListQuestions(results);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [user.ra, updateNumQuestions]);

	React.useEffect(() => {
		getCountAnswer(user.ra ?? "")
			.then((data) => {
				setNumAnswers(data.count);
			})
			.catch((e) => {
				console.log(e);
			});

		getListAnswersByAuthor(user.ra ?? "")
			.then((data) => {
				const results = data.data.map((dt) => {
					return {
						title: dt.resposta_titulo,
						actionClick: () => {
							setOpenViewQuestion(true);
							setIdQuestionSelected(dt.pergunta_id);
							setOpenListQuestions(false);
						},
					};
				});

				setListAnswer(results);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [user.ra, updateNumAnswers]);

	return (
		<>
			<AppBar position='static' className={classes.appBar}>
				<Toolbar
					className='navbar_question_answers'
					style={{ background: colorsTheme.palette.primary.main }}
				>
					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						<IconButton
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={() => props.setOpen(false)}
						>
							<ArrowBack />
						</IconButton>

						<img
							src={`${
								nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
								nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO"
									? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg"
									: "/logo_white.svg"
							}`}
							className='logo'
							alt='Logo UNINTA'
						/>
					</Box>

					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						{user.typeUser === "student" && (
							<ButtonInfo
								startIconButton={
									<HelpOutline
										className='iconButtonAppBar'
										style={{
											color: colorsTheme.palette.secondary.main,
											background: colorsTheme.palette.primary.main,
										}}
									/>
								}
								titleButton='Perguntas Feitas'
								value={numQuestions}
								actionButton={() => {
									if (openListResponse) {
										setOpenListResponse(false);
									}

									setOpenListQuestions(!openListQuestions);
									setTitlePopup("Suas Perguntas");
								}}
								id='btnQtdQuestions'
							>
								<PopupNotifications
									title={titlePopup}
									list={listQuestions}
									open={openListQuestions}
									setOpen={setOpenListQuestions}
								/>
							</ButtonInfo>
						)}

						<ButtonInfo
							startIconButton={
								<Message
									className='iconButtonAppBar'
									style={{
										color: colorsTheme.palette.secondary.main,
										background: colorsTheme.palette.primary.main,
									}}
								/>
							}
							titleButton='Respostas Dada'
							value={numAnswers}
							actionButton={() => {
								if (openListQuestions) {
									setOpenListQuestions(false);
								}

								setOpenListResponse(!openListResponse);
								setTitlePopup("Suas Respostas");
							}}
							id='btnQtdResponses'
						>
							<PopupNotifications
								title={titlePopup}
								list={listAnswer}
								open={openListResponse}
								setOpen={setOpenListResponse}
							/>
						</ButtonInfo>
					</Box>
				</Toolbar>
			</AppBar>

			<Drawer variant='permanent'>
				<Toolbar style={{ minWidth: "15vw", maxWidth: "15vw" }} />
				<List style={{ minWidth: "15vw", maxWidth: "15vw" }}>
					<ListItem>
						<Box className='infoPerfil'>
							<AvatarProfile
								classname='img_perfil_questions'
								isTutor={user.typeUser === "tutor"}
							/>
							<Typography className='name_studenty'>{user.nome}</Typography>
							<Typography className='ra_studenty'>RA: {user.ra}</Typography>
						</Box>
					</ListItem>
				</List>
				<Divider />
				<List style={{ minWidth: "15vw", maxWidth: "15vw" }}>
					<ListItem
						id='itemNavQuestion'
						button
						onClick={() => handleActiveNavigate("question")}
					>
						<ListItemIcon>
							<HelpOutline
								className='iconNavigate'
								style={{
									color:
										props.componentOpen === "question"
											? colorsTheme.palette.primary.main
											: "#707070",
								}}
							/>
						</ListItemIcon>
						<Typography
							className='itemNavigate'
							style={{
								color:
									props.componentOpen === "question"
										? colorsTheme.palette.primary.main
										: "#707070",
							}}
						>
							Perguntar
						</Typography>
					</ListItem>

					<ListItem
						id='itemNavAnswer'
						button
						onClick={() => handleActiveNavigate("response")}
					>
						<ListItemIcon>
							<Message
								className='iconNavigate'
								style={{
									color:
										props.componentOpen === "response"
											? colorsTheme.palette.primary.main
											: "#707070",
								}}
							/>
						</ListItemIcon>
						<Typography
							className='itemNavigate'
							style={{
								color:
									props.componentOpen === "response"
										? colorsTheme.palette.primary.main
										: "#707070",
							}}
						>
							Responder
						</Typography>
					</ListItem>

					{user.typeUser === "tutor" && (
						<ListItem button onClick={() => handleActiveNavigate("report")}>
							<ListItemIcon>
								<Report
									className={`iconNavigate ${
										props.componentOpen === "report" ? "active" : ""
									}`}
								/>
							</ListItemIcon>
							<Typography
								className={`itemNavigate ${
									props.componentOpen === "report" ? "active" : ""
								}`}
							>
								Denúncias
							</Typography>
						</ListItem>
					)}
				</List>
			</Drawer>

			<DialogViewQuestion
				id={idQuestionSelected}
				setId={setIdQuestionSelected}
				open={openViewQuestion}
				setOpen={setOpenViewQuestion}
			/>
		</>
	);
}
