import { Depoimento, DepoimentosContextType } from "models/depoimento";
import { useContext, createContext, useState, useEffect } from "react";
import { getDepoimentosAluno, getDepoimentosTutor } from "services/Oportunidades";

const INI_VALUES: DepoimentosContextType = {
	depoimentos: [
		{
			id: 0,
			ra: "",
			nome: "",
			curso_id: "",
			curso_nome: "",
			cidade: "",
			depoimento: "",
			situacao: "",
			created_at: "",
		},
	],
	setDepoimentos: () => {},
	refreshSeccaoDepoimentos: false,
	excecaoNaRequisicao: false,
	setExcecaoNaRequisicao: () => {},
	setRefreshSeccaoDepoimentos: () => {},
	enableDialogCadastroDepoimento: false,
	setEnableDialogCadastroDepoimento: () => {},
};

const DepoimentosContext = createContext<DepoimentosContextType>(INI_VALUES);

export const DepoimentosProvider: React.FC = ({ children }) => {
	const [excecaoNaRequisicao, setExcecaoNaRequisicao] = useState<boolean>(false);
	const [depoimentos, setDepoimentos] = useState<Depoimento[]>([]);
	const [refreshSeccaoDepoimentos, setRefreshSeccaoDepoimentos] = useState(
		INI_VALUES.refreshSeccaoDepoimentos,
	);
	const [enableDialogCadastroDepoimento, setEnableDialogCadastroDepoimento] = useState(
		INI_VALUES.enableDialogCadastroDepoimento,
	);
	const tipoDoUsuarioLogado = JSON.parse(
		sessionStorage.getItem("@Application:User") as string,
	).typeUser;

	useEffect(() => {
		if (tipoDoUsuarioLogado === "student") {
			getDepoimentosAluno()
				.then((res) => {
					setDepoimentos(res.data.data);
					setExcecaoNaRequisicao(false);
				})
				.catch(() => {
					setExcecaoNaRequisicao(true);
				});
		} else {
			getDepoimentosTutor()
				.then((res) => {
					setDepoimentos(res.data.data);
					setExcecaoNaRequisicao(false);
				})
				.catch(() => {
					setExcecaoNaRequisicao(true);
				});
		}
	}, [setDepoimentos, refreshSeccaoDepoimentos, tipoDoUsuarioLogado, setExcecaoNaRequisicao]);

	return (
		<DepoimentosContext.Provider
			value={{
				depoimentos,
				setDepoimentos,
				excecaoNaRequisicao,
				setExcecaoNaRequisicao,
				refreshSeccaoDepoimentos,
				setRefreshSeccaoDepoimentos,
				enableDialogCadastroDepoimento,
				setEnableDialogCadastroDepoimento,
			}}
		>
			{children}
		</DepoimentosContext.Provider>
	);
};

export function useDepoimentos(): DepoimentosContextType {
	const context = useContext(DepoimentosContext);
	return context;
}
