import { Dispatch, SetStateAction } from "react";

/**
 * Armazena uma lista de disciplinas para o usuário
 */
export interface SubjectInterface {
	id: string | number;
	nome: string;
	idturmadisc_rm: string | number;
}

/**
 * Coleção de disciplinas: possui um título/nome descritivo e uma lista de disciplinas
 */
export interface SubjectCollectionInterface {
	title: string | null;
	subjects: SubjectInterface[] | [];
}

/**
 * Interface que define a tipagem de uma coleção de disciplinas
 */
export interface SubjectCollectionContextInterface {
	curso: any;
	cursos: any[]|any;
	disciplina: any;
	disciplinas: any;
	minhasDisciplinas: any[]|any;
	setCurso: Dispatch<SetStateAction<any>>;
	setCursos: Dispatch<SetStateAction<any[]|any>>;
	setDisciplina: Dispatch<SetStateAction<any>>;
	setDisciplinas: Dispatch<SetStateAction<any[]|any>>;
	setMinhasDisciplinas: Dispatch<SetStateAction<any[]|any>>;
}

/**
 * Valores iniciais para coleção de disciplinas
 */
export const INI_VALUES: SubjectCollectionContextInterface = {
	curso: null,
	cursos: [],
	disciplina: null,
	disciplinas: [],
	minhasDisciplinas: [],
	setCurso: () => {},
	setCursos: () => {},
	setDisciplina: () => {},
	setDisciplinas: () => {},
	setMinhasDisciplinas: () => {},
};
