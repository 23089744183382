import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";

export default function Suspense(): React.ReactElement {
	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			style={{
				// background: "#f6f6f6",
				background: "#f6f6f6",
				width: "100%",
				height: "100vh",
			}}
		>
			<Box mb={3} style={{ textAlign: "center" }}>
				<Typography variant='h4' style={{ color: "#333" }}>
					ECOSSISTEMA DE APRENDIZAGEM
				</Typography>
				<Typography variant='body1' style={{ color: "#333" }}>
					Para conectar pessoas e formar comunidades de práticas
				</Typography>
			</Box>
			<CircularProgress />
		</Box>
	);
}
