import { EventosPublicacoesProvider } from "context/EventosPublicacoes/eventos.context";
import React from "react";
import EstagiosProjetosNotasView from "./EstagiosProjetosNotas.view";

export default function EstagiosProjetosNotas(): React.ReactElement {
	return (
		<EventosPublicacoesProvider>
			<EstagiosProjetosNotasView />
		</EventosPublicacoesProvider>
	);
}
