import React from "react";

// -- Importando os componentes do React Router
import { Navigate, Route, Routes } from "react-router-dom";

// -- Importando telas da aplicação
import MockScreen from "pages/Subjects";
import PedagogicalCommunication from "pages/PedagogicalCommunication";
import DisciplinaScreen from "pages/Disciplina";
import CalendarSchedule from "pages/CalendarSchedule";
import NotFound from "pages/NotFound";
import Homepage from "pages/Private/Homepage";
import SearchResult from "pages/SearchResult/SearchResult";
import EstagiosProjetosNotas from "pages/Portfolio/EstagiosProjetosNotas";
import EventosPublicacoes from "pages/Portfolio/EventosPublicacoes";
import LayoutTesting from "pages/LayoutTesting";
import Portfolio from "pages/Private/Portfolio/index";
import Anchievements from "pages/Anchievements";

// import { useAuth } from "context/Auth/hook";

/**
 * Define as rotas privadas da aplicação e as retorna para que possam ser alternadas
 * em seu componente de alto nível
 * @returns <Routes> com as rotas privadas da aplicação
 */
export default function PrivateRoutes(): JSX.Element {
	// const { devPreview } = useAuth();
	return (
		<Routes>
			<Route path={"/"} element={<Homepage />} />
			<Route path={"/login"} element={<Navigate to={"/"} />} />
			<Route path={"/search/result/:id"} element={<SearchResult />} />
			<Route path='/homepage' element={<MockScreen />} />
			<Route path='/disciplina/:id' element={<DisciplinaScreen />} />
			<Route path='/comunicacao-pedagogica' element={<PedagogicalCommunication />} />
			<Route path='/comunicacao-pedagogica/:area' element={<PedagogicalCommunication />} />
			<Route path='/calendario' element={<CalendarSchedule />} />
			<Route
				path='/ecossistema/nti/certificados/estagio'
				element={<EstagiosProjetosNotas />}
			/>
			<Route path='/ecossistema/nti/certificados' element={<EventosPublicacoes />} />
			<Route path='*' element={<NotFound />} />
			<Route path='/layout-testing' element={<LayoutTesting />} />
			<Route path='/portfolio' element={<Portfolio />} />
			<Route path='/selos-competencias' element={<Anchievements />} />
		</Routes>
	);
}
