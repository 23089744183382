export function getNameInitials(name: string): string {
	if (name) {
		const names = name.split(" ");
		const firstInitial = names[0].split("")[0];
		const secondInitial = names[1].split("")[0];

		return `${firstInitial}${secondInitial}`.toUpperCase();
	}

	return "";
}
