import { Box, Typography } from "@material-ui/core";

export default function AnchievementItem(props: any) {
    return(
        <Box
            style={{
                border: "solid #ccc 1px",
                borderRadius: "8px",
                width: "100%",
                height: "256px",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                padding: "6px",
            }}>
                {
                    !props.icon ?
                        <Box style={{
                            marginTop: "36px",
                            marginBottom: "20px",
                            width: "100px",
                            height: "100px",
                            border: "dashed grey 2px",
                            borderRadius: "50px"
                        }}></Box>
                        : <img
                            alt='Sistema Acadêmico'
                            src={props.icon}
                            style={{
                                margin: "12px"
                            }}
                        />
                }
            <Typography 
                variant="body1" 
                component="b"
                style={{
                    // fontWeight: "bold",
                    // marginTop: "6px"
                }}>
                    {props.name}
                    
                </Typography>
        </Box> 
    )
}