import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

import School from "@material-ui/icons/School";

import CardsMenuStudent from "./components/CardsMenuStudent";
import CardsMenuTutor from "./components/CardsMenuTutor";

import { useAuth } from "context/Auth/hook";

import "./styles.scss";

export default function PedagogicalCommunication(): JSX.Element {
	const { user } = useAuth();
	const colorsTheme = useTheme();

	// useEffect(()=>{
	// 	apiLogsDoEcossistema.post('/',
	// 		{
	// 			ra: user.ra as string,
	// 			module : CENTRAL_PEDAGOGICA,
	// 			evento: {		
	// 				label : USUARIO_ACESSA_COMUNICACAO_PEDAG,
	// 				log_info: {
	// 					page: 'Página de comunicação pedagógica',
	// 					description: `O usuário ${user.ra} do tipo ${user.typeUser} acesso a ${CENTRAL_PEDAGOGICA} nos dias ${new Date().toLocaleDateString()}`
	// 				}
	// 			},
	// 		}
	// 	)
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// },[])
	
	return (
		<Container maxWidth='lg'>
			<Grid container>
				<Grid item xs={12}>
					<Box className='header_page'>
						<School
							className='iconSchool'
							style={{
								backgroundColor: colorsTheme.palette.primary.main,
							}}
						/>
						<Typography className='title_page'>Comunicação</Typography>
						<Typography className='title_page'>Pedagógica</Typography>

						{user.typeUser === "tutor" ? (
							<Typography className='description_page'>
								Este é o espaço destinado aos atendimentos pedagógicos como:
								Esclarecimentos de dúvidas de conteúdo; Acompanhamento de
								agendamentos de videoconferências de mentoria e Atendimento de
								solicitação de revisão de questões.
							</Typography>
						) : (
							<Typography className='description_page'>
								Este é o espaço destinado a realização de solicitações de
								atendimento pedagógico como: Esclarecimentos de dúvidas de conteúdo
								com professores e colegas; Chat com o tutor ; Agendamento de
								videoconferências de mentoria e solicitação de revisão de
								avaliações.
							</Typography>
						)}
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Grid container>
						{user.typeUser === "tutor" ? <CardsMenuTutor /> : <CardsMenuStudent />}
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
}
