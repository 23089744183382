import { Typography, useTheme } from "@material-ui/core";
import AvatarProfile from "components/AvatarProfile";
import React from "react";
import "./styles.scss";

export default function LayoutTesting(): React.ReactElement {
	const theme = useTheme();
	return (
		<div className='painting-portfolio'>
			<div
				className='painting-banner'
				style={{
					backgroundColor: theme.palette.primary.main,
				}}
			>
				<img
					src={
						theme.palette.primary.main === "#BD0E0E"
							? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-06.svg"
							: "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-08.svg"
					}
					style={{
						marginTop: "24px",
					}}
					alt='logo'
				/>
				<div className='banner-text'>
					<div className='text' style={{ textAlign: "center" }}>
						<Typography variant='h4'>BSC, Computer Engineer</Typography>
						<Typography variant='h5'>Lucas Gabriel G. dos Santos</Typography>
					</div>
				</div>
			</div>

			{/* Profile */}
			<div className='profile-section regular'>
				<div className='profile-container'>
					<div className='profile-empty'>
						<AvatarProfile classname='imgPerfil' />
						<Typography variant='h5' style={{ marginTop: "12px" }}>
							Felipe Soares
						</Typography>
						<Typography variant='body1'>BSC, Computer Engineer</Typography>
					</div>
					<div className='profile-full'>
						<div className='profile-pic'>
							<AvatarProfile classname='imgPerfil' />
						</div>
						<div className='profile-info'>
							<Typography variant='body1'>BSC, Computer Engineer </Typography>
							<Typography variant='body2'>BSC, Computer Engineer </Typography>
						</div>
					</div>
				</div>
			</div>

			{/* Work Experience */}
			<div className='work-section regular'>
				<div className='work-container'>
					<Typography variant='h5'>Experiências profissionais</Typography>
				</div>
			</div>

			{/* Chart data */}
			<div className='chart-section regular'>
				<div className='work-container'>
					<Typography variant='h5'>Análise de desempenho</Typography>
				</div>
			</div>

			<div className='professional-section regular'>
				<div className=''>
					<Typography variant='h5'>Imersão profissional</Typography>
				</div>
			</div>
		</div>
	);
}
