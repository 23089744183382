// import React, { useEffect, useState } from "react";
import { Vaga } from "models/vaga";
import ListaVagasView from "./ListaVagas.view";

interface PropsInterface {
	dados: {
		vagasBusca: Vaga[];
	};
}

export default function ListaVagas({ dados }: PropsInterface): JSX.Element {
	return <ListaVagasView dados={dados} />;
}
