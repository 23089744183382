import React from "react";
import parse from "html-react-parser";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { CENTRAL_PEDAGOGICA } from "defaults/modules";
import { ALUNO_RESPONDE_DUVIDA_CONTEUDO } from "defaults/events";
import { apiLogsDoEcossistema } from "services/Logs";

import Notify from "utils/Notification";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";
import { useAuth } from "context/Auth/hook";

import { storeAnswer } from "../../dialogquestionsanswers.services";
import { DialogAnswerProps } from "./dialoganswer.model";
import AvatarProfile from "../../../../../../components/AvatarProfile";

export default function DialogAnswer(props: DialogAnswerProps) {
	const { user, nivelDeEnsino } = useAuth();
	const { updateNumAnswers, setUpdateNumAnswers } = usePedagogicalCommunication();

	const [answer, setAnswer] = React.useState("");
	const [loadingSendAnswer, setLoadingSendAnswer] = React.useState(false);

	const handleCloseDialog = () => {
		props.setOpen(false);
		setAnswer("");
	};

	const sendAnswer = () => {
		if (!answer || !user.ra || !props.answerId || !props.course) {
			Notify("Preencha todos campos", "warning");
			return;
		}

		if (answer.length > 1000) {
			Notify("O texto da resposta ultrapassou 1000 caracteres.", "warning");
			return;
		}

		setLoadingSendAnswer(true);
		storeAnswer(
			user.ra ?? "",
			props.answerId ?? "",
			props.course,
			answer,
			user.typeUser === "tutor" ? "1" : "0",
		)
			.then((data) => {
				if (data.success) {
					Notify("Sua resposta foi dada!");
					setUpdateNumAnswers(!updateNumAnswers);
					handleCloseDialog();
					apiLogsDoEcossistema.post('/',
						{
							ra: user.ra as string,
							module: CENTRAL_PEDAGOGICA,
							evento: {
								label: ALUNO_RESPONDE_DUVIDA_CONTEUDO,
								log_info: {
									codigo_nivel_ensino: nivelDeEnsino.codigo_nivel_ensino,
									nome_nivel_ensino: nivelDeEnsino.nome_nivel_ensino,
									nome_curso: nivelDeEnsino.nome_curso,
									periodo: nivelDeEnsino.periodo,
								}
							},
						}
					)
				}
				setLoadingSendAnswer(false);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<Dialog open={props.open} onClose={() => handleCloseDialog()} fullWidth maxWidth='md'>
			<DialogTitle style={{ padding: 0 }}>
				<Box className='questions_area_response'>
					<Box className='question_author'>
						<AvatarProfile
							isAuthor={props.authorId === user.ra}
							urlImage={props.authorAvatar}
							authorName={props.authorName ?? ""}
						/>

						<Box className='author_name_course'>
							<Typography className='author_name'>
								{props.authorName ? props.authorName.toLocaleLowerCase() : ""}
							</Typography>
							<Typography className='author_course'>
								{props.authorCourse} - {props.authorSubject}
							</Typography>
						</Box>
					</Box>

					<Box className='question_view'>
						<Typography variant='subtitle2'>{parse(props.answer ?? "")}</Typography>
					</Box>
				</Box>
			</DialogTitle>

			<DialogContent dividers style={{ padding: "0.6rem" }}>
				<FormHelperText
					error={answer.length > 1000}
					style={{ margin: 0, padding: 0, textAlign: "right" }}
				>
					{answer.length} de 1000 caracteres
				</FormHelperText>
				<TextField
					label='Resposta:'
					placeholder='Digite sua resposta aqui'
					variant='outlined'
					fullWidth
					multiline
					minRows={4}
					maxRows={4}
					value={answer}
					onChange={(e) => setAnswer(e.target.value)}
					disabled={loadingSendAnswer}
					id='inputNewResponse'
					inputProps={{ maxLength: 1000 }}
					error={answer.length > 1000}
					style={{ margin: 0, padding: 0 }}
				/>

				<Box display='flex' justifyContent='center'>
					<Button
						variant='contained'
						color='primary'
						onClick={() => sendAnswer()}
						disabled={loadingSendAnswer}
						id='btnNewResponse'
					>
						{loadingSendAnswer ? (
							<CircularProgress
								style={{ color: "#bd0e0e", width: "1.5rem", height: "1.5rem" }}
								thickness={4.4}
							/>
						) : (
							"Enviar Resposta"
						)}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
}
