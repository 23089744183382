/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { PublicacoesContextType } from "./models/PropsPublicacoesContext";
import { Publicacoes } from "models/publicacoes";
import { INI_VALUES } from "./defaults";
import { deletePublication, getPublicationsProfile } from "services/Publicacoes";
import { usePortfolio } from "context/Portfolio";
import Notify from "../../utils/Notification";
import appReducer from "./AppReducerPublicacoes";

export const PublicacoesContext = React.createContext<PublicacoesContextType>(INI_VALUES);

export const PublicacoesProvider: React.FC = ({ children }) => {
	const { state } = usePortfolio();
	const [publicacoes, setPublicacoes] = React.useState<Publicacoes[]>([]);
	const [verification, setVerification] = React.useState<boolean>(true);
	const initialState = { publicacoes: [] };
	const [stateReducer, dispatch] = React.useReducer(appReducer, initialState);

	function deletePublicacao(id) {
		if (window.confirm("Deseja deletar este depoimento?")) {
			deletePublication(id)
				.then(() => {
					Notify("Evento deletado com sucesso!");
					dispatch({
						type: "DELETE",
						payload: id,
					});
				})
				.catch(() => {
					Notify(
						"Falha ao deletar o evento! Tente novamente dentro de alguns instantes.",
					);
				});
		}
	}

	function updatePublicacao(updatedPublicacao) {
		dispatch({
			type: "UPDATE",
			payload: updatedPublicacao,
		});
	}

	function addPublicacao(addPublic) {
		dispatch({
			type: "ADD",
			payload: addPublic,
		});
	}

	useEffect(() => {
		getPublicationsProfile(state.id)
			.then(({ data }) => {
				setPublicacoes(data);
				dispatch({
					type: "INITIALIZING",
					payload: {
						...initialState,
						publicacoes: data
					}
				})
			})
			.catch(() => {
				setVerification(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.id])

	return (
		<PublicacoesContext.Provider
			value={{
				publicacoes,
				setPublicacoes,
				verification,
				setVerification,
				stateReducer,
				deletePublicacao,
				updatePublicacao,
				addPublicacao,
			}}
		>
			{children}
		</PublicacoesContext.Provider>
	);
};
