import React from "react";
import SearchEngineV2 from "components/SearchEngineV2";
import Disciplinas from "./Subjects";
import Welcome from "components/Welcome";
import { motion, AnimatePresence } from "framer-motion";
import TutorWidgetsPane from "components/TutorWidgetsPane";
import { useAuth } from "context/Auth/hook";


/**
 *
 * @returns {React.ReactElement} Tela inicial do usuário de nível Aluno
 * Esta tela retorna a lista de disciplinas do estudante, o componente de <Welcome /> e
 * o componente de buscas de mídias do Ecossistema de Aprendizagem.
 */
export default function HomepageView(): React.ReactElement {
	const { user } = useAuth();
	// useEffect(()=>{
	// 	try {
	// 		apiLogsDoEcossistema.post('/',
	// 			{
	// 				ra: user.ra as string,
	// 				module : HOMEPAGE,
	// 				evento: {		
	// 					label : USUARIO_ACESSA_HOMEPAGE,
	// 					log_info: [
	// 						{
	// 							page: 'Página inicial do novo ecossistema',
	// 							description: `O usuário ${user.ra} do tipo ${user.typeUser} acesso a HOMEPAGE nos dias ${new Date().toLocaleDateString()}`
	// 						}
	// 					]		
	// 				},
	// 			}
	// 		)
	// 	} catch (e) {
	// 		throw new Error();
	// 	}
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// },[])
	return (
		<React.Fragment>
			<AnimatePresence>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0}}					
				>
					<Disciplinas />
				</motion.div>
			</AnimatePresence>				
			{ user.typeUser === "tutor" &&  <TutorWidgetsPane /> }				
			<Welcome />
			<SearchEngineV2 />
		</React.Fragment>
	);
}