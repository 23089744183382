import React, { Dispatch, SetStateAction } from "react";

import DialogOportunidadesView from "./DialogOportunidades.view";

interface DialogOportunidadesProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function DialogOportunidades(props: DialogOportunidadesProps): React.ReactElement {
	return <DialogOportunidadesView open={props.open} setOpen={props.setOpen} />;
}
