import React from "react";
import EventosInternacionais from "components/Eventos/EventosInternacionais";
import EventosNacionais from "components/Eventos/EventosNacionais";
import Banner from "../Banner";
import { Box, Container, Grid } from "@material-ui/core";
import "./style.scss";

/**
 *
 * @returns React.ReactElement com página de Eventos e Publicaçõe
 * Esta página deverá aparecer no portfólio, junto ao banner de portfólio (à esquerda)
 * O componente deverá exibir os dados após receber a resposta da API.
 */
export default function EventosPublicacoesView(): React.ReactElement {
	return (
		<div
			style={{
				// backgroundColor: "#ddd",
				backgroundColor: "#f8f8f8",
				// paddingTop: "10vh",
			}}
		>
			<Container
				className="containerEventosPublicacoes"
				maxWidth='xl'
				style={{
					paddingTop: "5vh",
					paddingBottom: "10vh",
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
						<Banner />
					</Grid>
					<Grid item xs={12} sm={12} md={10} lg={10} xl={10} className="gridContainerEventosPublicacoes">
						<Box>
							<React.Fragment>
								<EventosInternacionais />
								<EventosNacionais />
							</React.Fragment>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}
