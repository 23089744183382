import {
	Box,
	Typography,
	Button,
	Card,
	CardMedia,
	CardContent,
	CardActions,
	useTheme,
} from "@material-ui/core";
import "./styles.scss";
import CardLateralMentoria from "components/Oportunidades/CardsOportunidades/CardLateralMentoria";

export default function SeccaoOficinaCurriculoView() {
	const theme = useTheme();

	return (
		<Box className='seccao-oficina-curriculo'>
			<Box className='box-card-mentoria-lateral'>
				<CardLateralMentoria />
			</Box>

			<Box className='box-oficina-curriculo'>
				<Box className='box-header'>
					<Typography component='h2'>Oficina de Currículo</Typography>
				</Box>

				<Box className='box-content'>
					<Box className='box-card-mentoria'>
						<Card className='card-mentoria'>
							<CardMedia
								component='img'
								src='imagens-oportunidades/video-curriculo.png'
								title='Imagem aleatória'
							/>

							<CardContent className='box-content-card-mentoria'>
								<Typography variant='body2' component='h2'>
									Vídeo currículo: o que é, como fazer?
								</Typography>
								<Typography variant='body2' color='textSecondary' component='p'>
									A imagem é o jeito mais dinâmico de conhecer as habilidades
									interpessoais de um profissional. Podemos ajudar você a contar a
									sua história.
								</Typography>
							</CardContent>

							<CardActions className='box-botao-card-mentoria'>
								<Button
									className='botao'
									style={{ backgroundColor: theme.palette.primary.main }}
								>
									Acessar curso
								</Button>
							</CardActions>
						</Card>
					</Box>

					<Box className='box-card-mentoria'>
						<Card className='card-mentoria'>
							<CardMedia
								component='img'
								src='imagens-oportunidades/palco-profissional.png'
								title='Imagem aleatória'
							/>
							<CardContent className='box-content-card-mentoria'>
								<Typography variant='body2' component='h2'>
									Palco Profissional
								</Typography>
								<Typography variant='body2' color='textSecondary' component='p'>
									Crie o seu currículo de vídeo de forma fácil e com todo suporte
									e orientação que você precisa na plataforma palco profissional.
								</Typography>
							</CardContent>
							<CardActions className='box-botao-card-mentoria'>
								<Button
									className='botao'
									style={{ backgroundColor: theme.palette.primary.main }}
								>
									Acessar
								</Button>
							</CardActions>
						</Card>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
