import React, { createContext, Dispatch, SetStateAction, useState } from "react";
import { MediaInterface } from "models/media";

// Tipos de dados para seleção

interface MetaInterface {
	current_page?: number | string;
	first_page?: number | string;
	first_page_url?: string;
	last_page?: number | string;
	last_page_url?: number | string;
	next_page_url?: string | undefined | null;
	per_page?: number | string;
	previous_page_url?: string | undefined | null;
	total?: number | string;
}

type SearchContextType = {
	results: MediaInterface[] | undefined | null | any;
	meta: MetaInterface | undefined | any;
	setMeta: Dispatch<SetStateAction<SearchContextType>> | any;
	setResults: Dispatch<SetStateAction<SearchContextType>> | any;
};

// Valores iniciais para o contexto de seleção

const INITIAL_SEARCH_VALUES: SearchContextType = {
	results: undefined,
	meta: {},
	setResults: (results) => console.log(results),
	setMeta: (meta) => console.log(meta),
};

// Adicionando o contexto para armazenar dados de consultas

export const SearchContext = createContext({
	results: INITIAL_SEARCH_VALUES.results,
	meta: INITIAL_SEARCH_VALUES.meta,
	setResults: INITIAL_SEARCH_VALUES.setResults,
	setMeta: INITIAL_SEARCH_VALUES.setMeta,
});

export const SearchProvider: React.FC = ({ children }) => {
	const [results, setResults] = useState<SearchContextType>(INITIAL_SEARCH_VALUES.results);
	const [meta, setMeta] = useState<SearchContextType>(INITIAL_SEARCH_VALUES.meta);

	// useEffect(()=>{
	// 	const res: string  = localStorage.getItem("@App:results");
	// 	const meta: string = localStorage.getItem("@App:meta");
	// 	setResults(JSON.parse(res));
	// 	setResults(JSON.parse(meta));

	// },[setResults, setMeta]);

	return (
		<SearchContext.Provider
			value={{
				results: results,
				meta: meta,
				setMeta: setMeta,
				setResults: setResults,
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};

export function useSearch(): SearchContextType {
	const context = React.useContext(SearchContext);
	return context;
}
