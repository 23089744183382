import React, { createContext, Dispatch, useContext, useEffect, useState } from "react";
import ProfessionalProfileInterface from "../../pages/Portfolio/Models/Profile.interface";
import { AxiosResponse } from "axios";
import { useAuth } from "context/Auth/hook";

import { portfolioApi } from "../../pages/Portfolio/api";
// import { createProfessionalProfile } from "./Perfil/perfil.service";

type PortfolioContextProps = {
	refresh: boolean;
	setRefresh: Dispatch<React.SetStateAction<boolean>>;
	state: ProfessionalProfileInterface;
	setState: Dispatch<React.SetStateAction<ProfessionalProfileInterface>>;
	profileExists: boolean;
	getUserProfile: any;
};

const INITIAL_VALUES: PortfolioContextProps = {
	refresh: false,
	state: {
		id: 0,
		ra: "",
		name: "",
		email: "",
		avatar_path: "",
		birth_date: "",
		city_address: "",
		public_place_address: "",
		uf_address: "",
		number_address: "",
		zip_code_address: "",
		bio: "",
		phone: "",
	},
	profileExists: false,
	setRefresh: () => {},
	setState: () => {},
	getUserProfile: () => {},
};

const PortfolioContext = createContext<PortfolioContextProps>(INITIAL_VALUES);

export const PortfolioProvider: React.FC = ({ children }) => {
	const [refresh, setRefresh] = useState(false);
	const [state, setState] = useState(INITIAL_VALUES.state);
	const [profileExists, setProfileExists] = useState<boolean>(false);
	const { user } = useAuth();

	const getUserProfile = async (): Promise<any> => {
		await portfolioApi
			.get(`api/alunos/v1/profiles/${user.ra}`)
			.then((response: AxiosResponse<any>) => {
				const res = {
					id: response.data.id,
					ra: response.data.ra,
					name: response.data.name,
					email: response.data.email,
					avatar_path: response.data.avatar_path,
					birth_date: response.data.birth_date,
					city_address: response.data.city_address,
					public_place_address: response.data.public_place_address,
					uf_address: response.data.uf_address,
					number_address: response.data.number_address,
					zip_code_address: response.data.zip_code_address,
					bio: response.data.bio,
					phone: response.data.phone,
				};
				setState(res);
			});
	};

	useEffect(() => {
		if (user.ra) {			
			portfolioApi
				.get(`api/alunos/v1/profiles/${user.ra}`)
				.then((response: AxiosResponse<any>) => {
					const res = {
						id: response.data.id,
						ra: response.data.ra,
						name: response.data.name,
						email: response.data.email,
						avatar_path: response.data.avatar_path,
						birth_date: response.data.birth_date,
						city_address: response.data.city_address,
						public_place_address: response.data.public_place_address,
						uf_address: response.data.uf_address,
						number_address: response.data.number_address,
						zip_code_address: response.data.zip_code_address,
						bio: response.data.bio,
						phone: response.data.phone,
					};
					setState(res);
					setProfileExists(true);
				})
				.catch((error) => {
					const formData = new FormData();

					if(user.ra)
						formData.append("ra", user.ra);
						
					if(user.nome) {
						formData.append("name", user.nome);
					
						portfolioApi
							.post("api/alunos/v1/profiles/", formData)
							.then((response: AxiosResponse<any>) => {
								setState(response.data);
							})
							.catch((error) => {
								return {
									message: "Falha ao cadastrar!",
								};
							});
					}
				});
			
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileExists, user.ra, refresh]);

	return (
		<PortfolioContext.Provider
			value={{
				refresh,
				setRefresh,
				state,
				setState,
				profileExists,
				getUserProfile,
			}}
		>
			{children}
		</PortfolioContext.Provider>
	);
};

export const usePortfolio = () => {
	return useContext(PortfolioContext);
};

export default PortfolioContext;
