export function formatDate(date: Date | undefined, withTime = false): string {
	if (!date) {
		return "";
	}

	const [getDate, getTime] = new Date(date).toISOString().split("T");

	let dateFormat: string[] | string = getDate.split("-");
	dateFormat = `${dateFormat[2]}/${dateFormat[1]}/${dateFormat[0]}`;

	let timeFormat: string[] | string = getTime.split(":");
	timeFormat = `${timeFormat[0]}:${timeFormat[0]}`;

	return `${dateFormat} ${withTime ? timeFormat : ""}`;
}
