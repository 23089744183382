import { ecossistema } from "services/api";

export async function getCursos(ra: string) {
	return await ecossistema
		.get(`nti/cursos/${ra}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getNivelEnsino(nivel: string) {
	return await ecossistema
		.get("level-educations/")
		.then(({ data }: any) => {
			const data_enchuta: any = [];
			// eslint-disable-next-line array-callback-return
			data?.map((retorno: any) => {
				if (retorno.name === nivel) {
					data_enchuta.push(retorno.id);
				}
			});
			return data_enchuta;
		})
		.catch((e) => {
			return e;
		});
}

export async function getCalendario(id: number) {
	return await ecossistema
		.get(`academic-calendars/level-education/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
