import React from "react";
import Banner from "../Banner";
import { Container, Grid } from "@material-ui/core";
import Cursos from "components/EstagiosProjetosNotas/Cursos";
import LaboratoriosDePesquisa from "components/EstagiosProjetosNotas/LaboratoriosDePesquisa";
import { Refresh } from "@material-ui/icons";
import { Box, Button, Typography } from "@material-ui/core";
import "./style.scss";
import { useEventosContext } from "context/EventosPublicacoes/hooks/useEventos";

export default function EstagiosProjetosNotasView(): React.ReactElement {
	const { request } = useEventosContext();

	return (
		<div
			style={{
				backgroundColor: "#f5f5f5",
				paddingTop: "5vh",
			}}
		>
			<Container maxWidth='xl' style={{ marginTop: "0vh" }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
						<Banner />
					</Grid>
					<Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
						{request ?
							<div className='containerEstagioProjetosNotas'>
								<LaboratoriosDePesquisa />
								<Cursos />
							</div> :
							<>
								<Box
									style={{
										border: "2px solid rgba(0, 0, 0, 0.2)",
										borderRadius: "4px",
										display: "flex",
										width: "95%",
										height: "500px",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										minHeight: "40vh",
										margin: "20px",
										padding: "20px",
									}}
								>
									<Typography variant='h5'>
										Não foi possível carregar seus certificados
									</Typography>
									<Typography variant='body1'>
										Clique aqui para recarregar os certificados ou entre em contato com o
										administrador do sistema
									</Typography>
									<div>
										<Button
											variant='contained'
											disableElevation
											color='primary'
											size='large'
											style={{ marginTop: "36px", borderRadius: "48px" }}
										>
											<Refresh />
											Recarregar
										</Button>
									</div>
								</Box>
							</>}
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}
