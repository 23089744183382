import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ArrowBack from "@material-ui/icons/ArrowBack";
import Replay from "@material-ui/icons/Replay";
import School from "@material-ui/icons/School";
import Search from "@material-ui/icons/Search";
import LabelImportant from "@material-ui/icons/LabelImportant";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import DialogDetails from "./DialogDetails";

import { getSolicitationsTutor } from "../DialogReviewQuestion/FormReviewQuestion/formreviewquestion.services";
import { getListCourses } from "./listsolicitationreview.services";

import "./styles.scss";

export default function ListSolicitationsReviewQuestion(props: {
	open: boolean;
	setOpen: (e: boolean) => void;
}): React.ReactElement {
	const [reviewsStorage, setReviewsStorage] = React.useState([]);
	const [reviewsOnView, setReviewOnView] = React.useState([]);

	// Dialogo de Detalhes
	const [openDetails, setOpenDetails] = React.useState(false);
	const [idReview, setIdReview] = React.useState(0);
	const [reload, setReload] = React.useState(false);

	// Filters
	const [globalFilter, setGlobalFilter] = React.useState("");
	const [filtersDatatable, setFiltersDatatable] = React.useState<any>({});
	const [filterStatus, setFilterStatus] = React.useState("pendente");
	const [allCourses, setAllCourses] = React.useState([]);
	const [courseFiltered, setCourseFiltered] = React.useState<any[]>([]);
	const [nameFilteredCourse, setNameFilteredCourse] = React.useState("");
	const [courseSelected, setCourseSelected] = React.useState({
		id: "",
		name: "",
	});

	const [openFilterCourse, setOpenFilterCourse] = React.useState(false);

	const renderHeader = () => {
		return (
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<span>
					<Button
						variant='contained'
						color='primary'
						onClick={() => {
							setFiltersDatatable({});
							sessionStorage.setItem(
								"@Filter:solicitacoes_revisoes_tutor",
								JSON.stringify({}),
							);
						}}
					>
						Limpar Filtros
					</Button>
				</span>
				<span>
					<InputText
						type='search'
						onChange={(e) => setGlobalFilter(e.target.value)}
						placeholder='Pesquisa Global'
					/>
				</span>
			</div>
		);
	};

	const onHandleFilterHead = (type: "status" | "course", value: any) => {
		let courseForView: any;

		if (type === "status") {
			setFilterStatus(value as string);

			if (value === "all" && !courseSelected?.id) {
				courseForView = reviewsStorage;
			}

			if (value === "all" && courseSelected?.id) {
				courseForView = reviewsStorage.filter(
					(e: any) => e.course_id === courseSelected?.id,
				);
			}

			if (value !== "all" && courseSelected?.id) {
				courseForView = reviewsStorage.filter((e: any) => {
					return e.course_id === courseSelected?.id && e.situation === value;
				});
			}

			if (value !== "all" && !courseSelected?.id) {
				courseForView = reviewsStorage.filter((e: any) => e.situation === value);
			}
		} else if (type === "course") {
			setCourseSelected(value);
			setOpenFilterCourse(false);
			handleFilter({ target: { value: "" } });

			if (filterStatus === "all" && !value?.id) {
				courseForView = reviewsStorage;
			}

			if (filterStatus === "all" && value?.id) {
				courseForView = reviewsStorage.filter((e: any) => e.course_id === value?.id);
			}

			if (filterStatus !== "all" && value?.id) {
				courseForView = reviewsStorage.filter((e: any) => {
					return e.course_id === value?.id && e.situation === filterStatus;
				});
			}

			if (filterStatus !== "all" && !value?.id) {
				courseForView = reviewsStorage.filter((e: any) => e.situation === filterStatus);
			}
		}

		setReviewOnView(courseForView);
	};

	const onChangeFilter = (position: string, value: string) => {
		const newFilter = { ...filtersDatatable, [position]: { value, matchMode: "contains" } };

		setFiltersDatatable(newFilter);
		sessionStorage.setItem("@Filter:solicitacoes_revisoes_tutor", JSON.stringify(newFilter));
	};

	const handleFilter = (search: any) => {
		setNameFilteredCourse(search.target.value);

		if (search.target.value) {
			const lowerSearch = search.target.value.toLowerCase();
			const results = allCourses.filter((data: any) => {
				return data.nome.toLowerCase().includes(lowerSearch);
			});
			setCourseFiltered(results);
		} else {
			setCourseFiltered(allCourses);
		}
	};

	React.useEffect(() => {
		if (props.open) {
			setFilterStatus("pendente");
			setCourseSelected({ id: "", name: "" });

			getSolicitationsTutor()
				.then((data) => {
					const results = data.data
						.map((dt: any) => {
							let dateFormat = dt.created_at.split("T")?.[0];
							dateFormat = dateFormat.split("-");
							dateFormat = `${dateFormat[2]}/${dateFormat[1]}/${dateFormat[0]}`;

							const situationColor =
								dt.situacao === "deferido"
									? "deferred"
									: dt.situacao === "indeferido"
									? "rejected"
									: "";

							return {
								date: dateFormat,
								subject: dt.disciplina_nome,
								course: dt.curso_nome,
								ra: dt.ra,
								situation: dt.situacao,
								course_id: dt.curso_id,
								status: (
									<FormLabel className={`tag_situation ${situationColor}`}>
										{dt.situacao}
									</FormLabel>
								),
								clerk:
									dt.tutor_nome && dt.tutor_nome !== "NULL" ? dt.tutor_nome : "-",
								actions: (
									<Button
										variant='contained'
										style={{ background: "#1488aa", color: "#fff" }}
										onClick={() => {
											setIdReview(dt.id);
											setOpenDetails(true);
										}}
									>
										Detalhes
									</Button>
								),
							};
						})
						.reverse();

					setReviewsStorage(results);
					setReviewOnView(
						results.filter((e: any) => {
							return e.situation === "pendente";
						}),
					);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [props.open, reload]);

	React.useEffect(() => {
		getListCourses()
			.then((data) => {
				// console.log(data);
				setAllCourses(data);
				setCourseFiltered(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	// Carregar os filtros caso exista
	React.useEffect(() => {
		if (props.open) {
			const getFilterSession = sessionStorage.getItem("@Filter:solicitacoes_revisoes_tutor");

			if (getFilterSession) {
				setFiltersDatatable(JSON.parse(getFilterSession));
			}
		}
	}, [props.open]);

	return (
		<>
			<Dialog
				open={props.open}
				onClose={() => {
					props.setOpen(false);
				}}
				fullScreen
			>
				<AppBar position='static'>
					<Toolbar className='navbar_header'>
						<Box display='flex' justifyContent='flex-start' alignItems='center'>
							<IconButton
								edge='start'
								color='inherit'
								aria-label='menu'
								onClick={() => {
									props.setOpen(false);
								}}
							>
								<ArrowBack />
							</IconButton>

							<img src='/logo_white.svg' alt='Imagem UNINTA' className='logo' />
						</Box>
					</Toolbar>
				</AppBar>

				<DialogContent>
					<Box className='area_title'>
						<Typography className='title'>Solicitações de Revisão</Typography>
					</Box>

					<Box className='filters_header'>
						<FormControl variant='standard' style={{ minWidth: 100 }}>
							<InputLabel>Status:</InputLabel>
							<Select
								label='Status:'
								value={filterStatus}
								onChange={(e) =>
									onHandleFilterHead("status", e.target.value as string)
								}
							>
								<MenuItem value='all'>Todos</MenuItem>
								<MenuItem value='pendente'>Pendente</MenuItem>
								<MenuItem value='deferido'>Deferido</MenuItem>
								<MenuItem value='indeferido'>Indeferido</MenuItem>
							</Select>
						</FormControl>

						<Box className='divider_filter' />

						<Button
							variant='text'
							startIcon={<School />}
							onClick={() => setOpenFilterCourse(true)}
						>
							{courseSelected.id ? courseSelected.name : "Filtrar por Curso"}
						</Button>

						<Box className='divider_filter' />

						<Button
							variant='text'
							startIcon={<Replay />}
							onClick={() => {
								setReload(!reload);
							}}
						>
							Recarregar Dados
						</Button>
					</Box>

					<div className='datatable-responsive-demo'>
						<DataTable
							value={reviewsOnView}
							paginator
							rows={10}
							className='p-datatable-responsive-demo'
							header={renderHeader()}
							globalFilter={globalFilter}
							filters={filtersDatatable}
							onFilter={(e) => {
								setFiltersDatatable(e.filters);
							}}
							emptyMessage='Nenhum registro encontrado...'
						>
							<Column
								header='Data'
								field='date'
								filter
								filterMatchMode='contains'
								filterElement={
									<InputText
										value={filtersDatatable?.date?.value ?? ""}
										onChange={(e) => {
											onChangeFilter("date", e.target.value);
										}}
									/>
								}
								style={{ textAlign: "center" }}
							/>
							<Column
								header='Curso'
								field='course'
								filter
								filterMatchMode='contains'
								filterElement={
									<InputText
										value={filtersDatatable?.subject?.value ?? ""}
										onChange={(e) => {
											onChangeFilter("subject", e.target.value);
										}}
									/>
								}
								style={{ textAlign: "center" }}
							/>
							<Column
								header='Disciplina'
								field='subject'
								filter
								filterMatchMode='contains'
								filterElement={
									<InputText
										value={filtersDatatable?.subject?.value ?? ""}
										onChange={(e) => {
											onChangeFilter("subject", e.target.value);
										}}
									/>
								}
								style={{ textAlign: "center" }}
							/>
							<Column
								header='Estudante'
								field='ra'
								filter
								filterMatchMode='contains'
								filterElement={
									<InputText
										value={filtersDatatable?.student?.value ?? ""}
										onChange={(e) => {
											onChangeFilter("student", e.target.value);
										}}
									/>
								}
								style={{ textAlign: "center" }}
							/>
							<Column
								header='Status'
								field='status'
								style={{ textAlign: "center" }}
							/>
							<Column
								header='Atendido por'
								field='clerk'
								filter
								filterMatchMode='contains'
								filterElement={
									<InputText
										value={filtersDatatable?.clerk?.value ?? ""}
										onChange={(e) => {
											onChangeFilter("clerk", e.target.value);
										}}
									/>
								}
								style={{ textAlign: "center" }}
							/>
							<Column
								header='Ações'
								field='actions'
								style={{ textAlign: "center" }}
							/>
						</DataTable>
					</div>
				</DialogContent>
			</Dialog>

			<DialogDetails
				open={openDetails}
				idReview={idReview}
				reload={reload}
				setOpen={setOpenDetails}
				setReload={setReload}
			/>

			{/** ***
                Dialogo para filtrar por curso, depois tornar este
                dialogo um componente separado 
            *****/}
			<Dialog
				open={openFilterCourse}
				onClose={() => {
					setOpenFilterCourse(false);
					handleFilter({ target: { value: "" } });
				}}
				fullWidth
				maxWidth='sm'
			>
				<DialogTitle style={{ background: "#bd0e0e" }}>
					<Typography
						style={{
							fontSize: 24,
							fontWeight: "bold",
							color: "#fff",
							textAlign: "center",
						}}
					>
						Cursos
					</Typography>
				</DialogTitle>

				<DialogContent>
					<FormControl variant='outlined' fullWidth>
						<OutlinedInput
							style={{ fontSize: 18 }}
							id='outlined-adornment-weight'
							endAdornment={<Search />}
							aria-describedby='outlined-weight-helper-text'
							placeholder='Digite o nome do curso...'
							onChange={(e) => {
								handleFilter(e);
							}}
							value={nameFilteredCourse}
						/>
					</FormControl>

					<List>
						<ListItem
							button
							onClick={() => {
								onHandleFilterHead("course", { id: 0, name: "" });
							}}
						>
							<Typography
								className={`item_menu_course ${!courseSelected?.id && "selected"}`}
							>
								<LabelImportant style={{ color: "#555" }} />
								Todos
							</Typography>
						</ListItem>

						{courseFiltered?.map((curso: any, index: number) => {
							return (
								<ListItem
									key={index}
									button
									onClick={() => {
										onHandleFilterHead("course", {
											id: curso.codigo,
											name: curso.nome,
										});
									}}
								>
									<Typography
										className={`item_menu_course ${
											courseSelected?.id === curso.codigo && "selected"
										}`}
									>
										<LabelImportant style={{ color: "#555" }} />
										{curso.nome.toLowerCase()}
									</Typography>
								</ListItem>
							);
						})}
					</List>
				</DialogContent>
			</Dialog>
		</>
	);
}
