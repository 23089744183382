import { Button, ListItem, ListItemText } from "@material-ui/core";
// import { useAuth } from "context/Auth/hook";
import { Contribuicao } from "models/tabelaDisciplinas";
import "./styles.scss";

interface PropType {
	contribuicao: Contribuicao;
}

export default function ItemListaContribuicoesView(props: PropType) {
	// const theme = useTheme();
	// const { nivelDeEnsino } = useAuth();
	const nomeDisciplina = localStorage.getItem("nomeDisciplina") as string;

	return (
		<ListItem className='item-lista-contribuicoes'>
			{/* <div className="item-lista-imagem-lateral" style={{ backgroundColor: theme.palette.primary.main }}>

                {
                    nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" && nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 JULHO"
                        ?
                        <img src="logo_white.svg" alt="Logo branca" />
                        :
                        <img src="https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg" alt="" />
                }

            </div> */}

			<ListItemText
				className='item-lista-conteudo'
				primary={
					<h2>
						<strong>{props.contribuicao.titulo}</strong>
					</h2>
				}
				secondary={
					<>
						<p className='item-lista-texto'>{props.contribuicao.descricao}</p>
						<div className='item-lista-box-botao'>
							<Button
								href={props.contribuicao.link}
								target='_blank'
								style={{ backgroundColor: "#292B2D", color: "#FFFFFF" }}
							>
								Acessar
							</Button>
						</div>
						<hr />
						<div className='item-lista-subconteudo'>
							<span>
								<strong style={{ color: "#000000" }}>R.A: </strong>
								<span className='texto'>{props.contribuicao.ra_aluno}</span>
							</span>
							<br />
							<span>
								<strong style={{ color: "#000000" }}>Disciplina: </strong>
								<span className='texto'>{nomeDisciplina}</span>
							</span>
						</div>
					</>
				}
			/>
		</ListItem>
	);
}
