import { IconButton, List } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import "../style.scss";
import DialogCadastroPublicacoes from "components/Dialogs/Cadastro/DialogCadastroPublicacoes";
import DialogAlteracaoPublicacoes from "components/Dialogs/Alteracao/AlteracaoPublicacoes";
import { Typography } from "@material-ui/core";
import { usePublicacoesContext } from "context/Publicacoes/hooks/usePublicacoes";
// import toast, { Toaster } from 'react-hot-toast';

export default function PublicacoesView(): React.ReactElement {
   const { stateReducer, deletePublicacao, verification } = usePublicacoesContext();
   // const notify2 = () => toast(<span>Here is your toast.</span>);

   function components() {
      if (verification) {
         return (
            <List>
               <div
                  style={{
                     display: "flex",
                     flexDirection: "row",
                     alignItems: "center",
                     width: "100%",
                     height: "30px",
                  }}
               >
                  <Typography style={{ paddingLeft: "17px" }} className='subCategoria'>
                     Publicações
                  </Typography>
                  <DialogCadastroPublicacoes />
               </div>
               {stateReducer.publicacoes.map((event) => (
                  <>
                     <ListItem className='listItemPrimary' alignItems='flex-start'>
                        <ListItemText
                           className='listItemPrimaryNa'
                           primary={
                              <div className='divlistItemPrimaryNa'>
                                 <div style={{ display: "flex" }} className='divTypographyPrimary'>
                                    <Typography className='typographyPrimary'>Tipo: &nbsp;</Typography>
                                    <Typography className='typographyPrimary2'>
                                       {
                                          String(event.type).trim().replace(/\(Colóquio \/ Banner de Estágio\)/ig, "")
                                       }{"."}
                                    </Typography>
                                 </div>
                                 <div className='buttonAltDelete'>
                                    <DialogAlteracaoPublicacoes
                                       id={event.id}
                                       publicacao={stateReducer.publicacoes}
                                    />
                                    <IconButton >
                                       <DeleteForeverIcon
                                          onClick={() => {
                                             deletePublicacao(event.id);
                                          }}
                                       />
                                    </IconButton>
                                 </div>
                              </div>
                           }
                           secondary={
                              <React.Fragment>
                                 <div className='dadosEventosNa'>
                                    <Typography
                                       component='span'
                                       variant='body2'
                                       className='typographyTituloNa'
                                    >
                                       Título: &nbsp;
                                    </Typography>
                                    <Typography component='span' variant='body2' color='textPrimary'>
                                       {event.title}
                                    </Typography>
                                 </div>

                                 <div className='dadosEventosNa secondaryNa' >
                                    <div className='divSecondaryNa'>
                                       <Typography
                                          className='typographyTituloNa'
                                          component='span'
                                          variant='body2'
                                       >
                                          Local: &nbsp;
                                       </Typography>
                                       <Typography component='span' variant='body2' color='textPrimary'>
                                          {event.local}
                                       </Typography>
                                    </div>
                                    <div className='divSecondaryNa'>
                                       <Typography
                                          component='span'
                                          variant='body2'
                                          className='typographyTituloNa'
                                       >
                                          Carga Horária: &nbsp;
                                       </Typography>
                                       <Typography component='span' variant='body2' color='textPrimary'>
                                          {event.workload}H
                                       </Typography>
                                    </div>
                                    <div className='divSecondaryNa'>
                                       <Typography
                                          component='span'
                                          variant='body2'
                                          className='typographyTituloNa'
                                       >
                                          Públicado em: &nbsp;
                                       </Typography>
                                       <Typography component='span' variant='body2' color='textPrimary'>
                                          {date(event.date)}
                                       </Typography>
                                    </div>
                                 </div>

                                 <div style={{ marginTop: "10px" }} >
                                    <Typography
                                       component='span'
                                       variant='body2'
                                       className='typographyTituloNa'
                                    >
                                       Endereço: &nbsp;
                                    </Typography>
                                    <a
                                       href={`${event.url}`}
                                       target='_blank'
                                       rel='noreferrer'
                                       className='link'
                                    >
                                       <Typography component='span' variant='body2' color='primary'>
                                          {event.url}
                                       </Typography>
                                    </a>
                                 </div>
                              </React.Fragment>
                           }
                        />
                     </ListItem>
                     <Divider variant='inset' component='li' />
                  </>
               ))
               }
            </List >
         );
      } else {
         return <></>;
      }
   }

   return <div style={{ margin: "20px 0px 20px 0px" }}>{components()}</div>;
}

function date(data_impressao: string) {
   for (let index = 0; index < data_impressao?.length; index++) {
      if (data_impressao[index] === "-") {
         const arrayData = data_impressao.split("-");
         const arrayDataNew = arrayData.reverse().join("/");
         return arrayDataNew;
      }
   }
   return data_impressao;
}