import { Box, Typography, TextField, InputAdornment, IconButton } from "@material-ui/core";
import { LocationOn, Search } from "@material-ui/icons";
import { Carousel } from "primereact/carousel";
import "./styles.scss";
import CardVaga from "../../CardsOportunidades/CardVaga";
import { Vaga } from "../../../../models/vaga";
import React, { useEffect, useState } from "react";
import { getVagas, getVagasBusca, getVagasBuscaLocalidade } from "services/Oportunidades";
import ListaVagas from "components/Oportunidades/ListasOportunidades/ListaVagas";
import NoContentAvailable from "components/NoContentAvailableTeste";

export default function SeccaoVagasView() {
	const [textoDaBuscaPorNome, setTextoDaBuscaPorNome] = useState<string>("");
	const [textoDaBuscaPorLocalidade, setTextoDaBuscaPorLocalidade] = useState<string>("");
	const [exibirLista, setExibirLista] = useState<boolean>(false);
	const [vagas, setVagas] = useState<Vaga[]>([]);
	const [vagasBusca, setVagasBusca] = useState<Vaga[]>([]);
	const [refreshSeccaoVagas, setRefreshSeccaoVagas] = useState<boolean>(false);
	const [refreshBuscaPorNome, setRefreshBuscaPorNome] = useState<boolean>(false);
	const [refreshBuscaPorLocalidade, setRefreshBuscaPorLocalidade] = useState<boolean>(false);
	const [excecaoNaRequisicao, setExcecaoNaRequisicao] = useState<boolean>(false);

	useEffect(() => {
		getVagas()
			.then((res) => {
				setVagas(res.data);
				setExcecaoNaRequisicao(false);
			})
			.catch(() => {
				setExcecaoNaRequisicao(true);
			});
	}, [refreshSeccaoVagas]);

	useEffect(() => {
		getVagasBusca(textoDaBuscaPorNome)
			.then((res) => {
				setVagasBusca(res.data);
				setExcecaoNaRequisicao(false);
			})
			.catch(() => {
				setExcecaoNaRequisicao(true);
			});
	}, [refreshSeccaoVagas, refreshBuscaPorNome, textoDaBuscaPorNome]);

	useEffect(() => {
		getVagasBuscaLocalidade(textoDaBuscaPorLocalidade)
			.then((res) => {
				setVagasBusca(res.data);
				setExcecaoNaRequisicao(false);
			})
			.catch(() => {
				setExcecaoNaRequisicao(true);
			});
	}, [refreshSeccaoVagas, refreshBuscaPorLocalidade, textoDaBuscaPorLocalidade]);

	const responsiveOptions = [
		{
			breakpoint: "1024px",
			numVisible: 2,
			numScroll: 2,
		},
		{
			breakpoint: "768px",
			numVisible: 1,
			numScroll: 1,
		},
		{
			breakpoint: "560px",
			numVisible: 1,
			numScroll: 1,
		},
	];

	function itemTemplate(vaga: Vaga) {
		return <CardVaga dados={vaga} />;
	}

	return (
		<>
			<Box className='seccao-busca-vagas'>
				<Box className='box-header'>
					<Typography component='h2'>Vagas</Typography>
				</Box>
				{excecaoNaRequisicao ? (
					<></>
				) : (
					<Box className='box-content'>
						<TextField
							type='text'
							className='input01'
							placeholder='Pesquisar vagas'
							size='small'
							variant='outlined'
							onChange={(event) => {
								setTextoDaBuscaPorNome(event.target.value);
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<IconButton
											onClick={() => {
												setRefreshBuscaPorNome(!refreshBuscaPorNome);
												setExibirLista(true);
											}}
										>
											<Search style={{ color: "rgba(0, 0, 0, 0.54)" }} />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<TextField
							id='localidade'
							type='text'
							className='input02'
							placeholder='Inserir a localidade'
							size='small'
							variant='outlined'
							onChange={(event) => {
								setTextoDaBuscaPorLocalidade(event.target.value);
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<IconButton
											onClick={() => {
												setRefreshBuscaPorLocalidade(
													!refreshBuscaPorLocalidade,
												);
												setExibirLista(true);
											}}
										>
											<LocationOn style={{ color: "rgba(0, 0, 0, 0.54)" }} />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				)}
			</Box>

			<Box className='seccao-principais-vagas'>
				<Box className='box-header'>
					<Typography component='h2'>Principais vagas na sua área de formação</Typography>
				</Box>

				<Box className='box-content'>
					{exibirLista === true ? (
						excecaoNaRequisicao ? (
							<NoContentAvailable
								refresh={refreshSeccaoVagas}
								setRefresh={setRefreshSeccaoVagas}
							/>
						) : (
							<React.Fragment>
								<Typography component='span'>
									{`${vagasBusca.length} Resultados de vagas${
										textoDaBuscaPorNome === "" ||
										textoDaBuscaPorLocalidade === ""
											? ""
											: (textoDaBuscaPorNome !== "" &&
													textoDaBuscaPorLocalidade) === ""
											? ` para ${textoDaBuscaPorNome}`
											: textoDaBuscaPorNome === "" &&
											  textoDaBuscaPorLocalidade !== ""
											? ` para ${textoDaBuscaPorLocalidade}`
											: ""
									}`}
								</Typography>
								<ListaVagas dados={{ vagasBusca: vagasBusca }} />
							</React.Fragment>
						)
					) : excecaoNaRequisicao ? (
						<NoContentAvailable
							refresh={refreshSeccaoVagas}
							setRefresh={setRefreshSeccaoVagas}
						/>
					) : (
						<React.Fragment>
							<Carousel
								value={vagas}
								itemTemplate={itemTemplate}
								numVisible={3}
								numScroll={1}
								responsiveOptions={responsiveOptions}
							/>
							<hr />
						</React.Fragment>
					)}
				</Box>
			</Box>
		</>
	);
}
