import { Grid, Box, Typography, useTheme, Paper } from "@material-ui/core";
import { useAuth } from "context/Auth/hook";

import "./styles.scss";
// import {useEffect, useState} from "react";
// import { usePortfolio } from "../context";
// import { ecossistema } from "services/api";

export function BannerView() {
	const theme = useTheme();
	const { user, cursos, nivelDeEnsino } = useAuth();
	// const { state } = usePortfolio();
	// const [course, setCourse] = useState<any|any[]|null>(null);

	// useEffect(()=>{
	//     ecossistema.get(`nti/cursos/${user.ra}`).then(({ data }: any) => {
	//             return data.map((dt) => {
	//                 setCourse({
	//                     id: dt?.codigo_curso,
	//                     name: dt?.nome_curso,
	//                 });
	//                 return {
	//                     id: dt?.codigo_curso,
	//                     name: dt?.nome_curso,
	//                 }
	//             });
	//         }).catch((e) => {
	//             console.log(e);
	//         });
	// },[user]);
	return (
		<Paper
			className='banner'
			elevation={0}
			style={{
				backgroundColor: theme.palette.primary.main,
				borderRadius: theme.shape.borderRadius,
			}}
		>
			<Box p={3} m={0}>
				<Grid container className={"banner-container"}>
					<Grid item md={12}>
						<Box p={3} display='flex' justifyContent='center' alignItems='center'>
							{/* <img src="https://md.uninta.edu.br/ecossistema/assets/images/logos_ies_ava_uninta.png"  width="156px" height="auto" alt="Logo Uninta"/> */}
							{nivelDeEnsino.codigo_nivel_ensino !== 351 &&
							nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
							nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD" &&
							nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO" ? (
								<a href={"/"}>
									<img
										src={
											nivelDeEnsino.codigo_nivel_ensino === 345
												? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/fortaleza_vertical_branca.png"
												: "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-06.svg"
										}
										className='logo_banner'
										alt='Logo branca'
									/>
								</a>
							) : (
								<a href={"/"}>
									<img
										src='https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-08.svg'
										className='logo_banner'
										alt='Logo branca'
									/>
								</a>
							)}
						</Box>
					</Grid>
					<Grid item md={12}>
						<Box
							display='flex'
							flexDirection='row'
							minWidth='0'
							alignItems='center'
							justifyContent='center'
							flex='0 0 15px'
							className='name-container'
						>
							<Box
								className='name'
								display='flex'
								flexDirection='column'
								justifyContent='center'
								p={3}
								style={{
									alignItems: "center",
								}}
							>
								<Typography
									variant='h4'
									component='h4'
									style={{
										color: "white",
									}}
								>
									{/* {user.nome.split(" ").map( name => name.charAt(0).toUpperCase() + name.slice(1)).join(" ")} */}
									{user.nome
										?.toLowerCase()
										.split(" ")
										.map((name) => name.charAt(0).toUpperCase() + name.slice(1))
										.join(" ")}
								</Typography>
								<Typography
									variant='h5'
									component='h5'
									style={{
										color: "white",
									}}
								>
									{
										cursos && 
										cursos[cursos.length > 1 ? cursos.length - 1 : 0] &&
										cursos[cursos.length > 1 ? cursos.length - 1 : 0]
											.nome_curso
											.toLowerCase()
											.split(" ")
											.map((name) => name.charAt(0).toUpperCase() + name.slice(1))
											.join(" ")
									}
									{/* {course?.name.toLowerCase().split(" ").map( name => name.charAt(0).toUpperCase() + name.slice(1)).join(" ")} */}
									{/* {nivelDeEnsino.nome_curso
										.toLowerCase()
										.split(" ")
										.map((name) => name.charAt(0).toUpperCase() + name.slice(1))
										.join(" ")} */}
								</Typography>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
}
