import { DepoimentosProvider } from "context/depoimentos";
import SeccaoDepoimentosView from "./SeccaoDepoimentos.view";

export default function SeccaoDepoimentos() {
	return (
		<DepoimentosProvider>
			<SeccaoDepoimentosView />
		</DepoimentosProvider>
	);
}
