import { AppletInterface } from "components/Applets/applet.model";
import React, { createContext, Dispatch, SetStateAction } from "react";
import { ecossistema } from "services/api";

import { CompetenciesInterface } from "../models/media";

// interface CompetenceInterface {
// 	id? : number | string;
// 	name?:string | any;
// }

type ProjectsContextType = {
	applets: AppletInterface[] | undefined | null | any;
	knowledgeAreas: undefined | null | any;
	subjects: undefined | null | any;
	cognitiveDomain: undefined | null | any;
	competence: CompetenciesInterface[] | undefined | null | any;
	setProjects: Dispatch<SetStateAction<ProjectsContextType>>;
};

const INITIAL_VALUES: ProjectsContextType = {
	applets: undefined,
	knowledgeAreas: undefined,
	subjects: undefined,
	cognitiveDomain: undefined,
	competence: undefined,
	setProjects: (applets) => console.log(applets),
};

export const ProjectsContext = createContext<ProjectsContextType>({
	applets: INITIAL_VALUES.applets,
	knowledgeAreas: INITIAL_VALUES.knowledgeAreas,
	subjects: INITIAL_VALUES.subjects,
	cognitiveDomain: INITIAL_VALUES.cognitiveDomain,
	competence: INITIAL_VALUES.competence,
	setProjects: INITIAL_VALUES.setProjects,
});

export const ProjectsProvider: React.FC = ({ children }) => {
	const [applets, setApplets] = React.useState<ProjectsContextType>(INITIAL_VALUES.applets);

	const [knowledgeAreas, setKnowledgeAreas] = React.useState<ProjectsContextType>(
		INITIAL_VALUES.knowledgeAreas,
	);

	const [subjects, setSubjects] = React.useState<ProjectsContextType>(INITIAL_VALUES.subjects);

	const [cognitiveDomain, setCognitiveDomain] = React.useState<ProjectsContextType>(
		INITIAL_VALUES.cognitiveDomain,
	);

	const [competence, setCompetence] = React.useState<ProjectsContextType>(
		INITIAL_VALUES.competence,
	);

	React.useEffect(() => {
		// TODO: Implements method to get projects
		ecossistema.get("/projects").then((response) => {
			setApplets(response.data);
		});
		ecossistema.get("/knowledge-areas").then((response) => {
			setKnowledgeAreas(response.data);
		});
		ecossistema.get("/subjects").then((response) => {
			setSubjects(response.data);
		});
		ecossistema.get("/cognitive-domains").then((response) => {
			setCognitiveDomain(response.data);
		});
		ecossistema.get("/competencies").then((response) => {
			setCompetence(response.data);
		});
	}, [setApplets, setCognitiveDomain, setCompetence, setKnowledgeAreas, setSubjects]);

	return (
		<ProjectsContext.Provider
			value={{
				applets: applets,
				knowledgeAreas: knowledgeAreas,
				subjects: subjects,
				cognitiveDomain: cognitiveDomain,
				competence: competence,
				setProjects: setApplets,
			}}
		>
			{children}
		</ProjectsContext.Provider>
	);
};

export function useProjects(): ProjectsContextType {
	const context = React.useContext(ProjectsContext);
	return context;
}
