import React, { useState } from "react";
import parse from "html-react-parser";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { useTheme } from "@material-ui/core/styles";

import Star from "@material-ui/icons/Star";
import Favorite from "@material-ui/icons/Favorite";

import { Paginator } from "primereact/paginator";

import DialogQuestionsAnswers from "./DialogNewQuestion";
import DialogViewQuestion from "./DialogViewQuestion";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import {
	getListQuestions,
	getListQuestionsCourseAndSubject,
} from "../dialogquestionsanswers.services";
import {
	getListCourses,
	getListSubjects,
} from "../../ListSolicitationsReviewQuestion/listsolicitationreview.services";

import "./styles.scss";
import { CircularProgress } from "@material-ui/core";

export default function Questions(): React.ReactElement {
	const { user } = useAuth();
	const { reloadListQuestions } = usePedagogicalCommunication();
	const colorsTheme = useTheme();

	// Paginação
	const [currentPage, setCurrentPage] = React.useState(0);
	const [totalQuestions, setTotalQuestions] = React.useState(0);

	const [loadingPage, setLoadingPage] = React.useState(true);

	const [filterCourse, setFilterCourse] = useState("todos");
	const [filterClass, setFilterClass] = useState("todos");

	const [openNewQuestion, setOpenNewQuestion] = useState(false);
	const [openViewQuestion, setOpenViewQuestion] = useState(false);
	const [idQuestion, setIdQuestion] = React.useState("");

	const [listQuestions, setListQuestionsView] = useState([]);
	const [listCourses, setListCourses] = useState([]);
	const [listSubjects, setListSubjects] = useState([]);

	const handleFilterListQuestions = (
		key: "curso_id" | "disciplina_id",
		value: string,
		page?: number,
	) => {
		setLoadingPage(true);

		if (!page) {
			setCurrentPage(0);
		}

		let course = filterCourse;
		let subject = filterClass;

		if (key === "curso_id") {
			course = value;
		}

		if (key === "disciplina_id") {
			subject = value;
		}

		if (course !== "todos" && subject !== "todos") {
			getListQuestionsCourseAndSubject(course, subject, page).then((data) => {
				setListQuestionsView(data.data);
				setListQuestionsView(data.data.data);
				setTotalQuestions(data.data.total);
				setLoadingPage(false);
			});
		} else {
			let newKey: string = key;
			let newValue: string = value;

			if (key === "curso_id" && value === "todos") {
				if (filterClass === "todos") {
					newKey = "";
					newValue = "";
				} else {
					newKey = "disciplina_id";
					newValue = filterClass;
				}
			}

			if (key === "disciplina_id" && value === "todos") {
				if (filterCourse === "todos") {
					newKey = "";
					newValue = "";
				} else {
					newKey = "curso_id";
					newValue = filterCourse;
				}
			}

			getListQuestions(newKey, newValue, page)
				.then((data) => {
					setListQuestionsView(data.data.data);
					setListQuestionsView(data.data.data);
					setTotalQuestions(data.data.total);
					setLoadingPage(false);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	// Busca questões
	const getQuestionsPaginator = (page = 1) => {
		setLoadingPage(true);
		getListQuestions("", "", page)
			.then((data) => {
				setLoadingPage(false);
				setListQuestionsView(data.data.data);
				setTotalQuestions(data.data.total);
				setLoadingPage(false);
				setFilterCourse("todos");
				setFilterClass("todos");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	React.useEffect(() => {
		getListCourses()
			.then((data) => {
				setListCourses(data);
			})
			.catch((e) => {
				console.log(e);
			});

		getListSubjects()
			.then((data) => {
				setListSubjects(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	React.useEffect(() => {
		const page = currentPage / 10 + 1;

		getQuestionsPaginator(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadListQuestions]);

	return (
		<Container>
			{user.typeUser === "student" && (
				<Box className='header_questions'>
					<Typography className='salutation'>
						Olá{" "}
						<span style={{ color: colorsTheme.palette.primary.main }}>
							{user.nome.toLowerCase()}
						</span>{" "}
						!
					</Typography>
					<Typography className='what'>O que você precisa saber ?</Typography>
					<Button
						id='btnSendQuestion'
						variant='contained'
						style={{
							backgroundColor: colorsTheme.palette.primary.main,
							color: "white",
						}}
						onClick={() => {
							setOpenNewQuestion(true);
						}}
					>
						Enviar dúvida de conteúdo
					</Button>
				</Box>
			)}

			<Box className='body_questions'>
				<Box className='filters'>
					<FormControl variant='outlined'>
						<InputLabel>Curso:</InputLabel>
						<Select
							id='selectCourseQuestions'
							value={filterCourse}
							onChange={(e) => {
								setFilterCourse(e.target.value as string);
								handleFilterListQuestions("curso_id", e.target.value as string);
							}}
							label='Curso:'
							disabled={loadingPage}
						>
							<MenuItem value='todos'>Todos</MenuItem>
							{listCourses.map((course: any) => (
								<MenuItem value={course.codigo} key={course.codigo}>
									{course.nome}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl variant='outlined'>
						<InputLabel>Disciplina:</InputLabel>
						<Select
							id='selectSubjectQuestions'
							value={filterClass}
							onChange={(e) => {
								setFilterClass(e.target.value as string);
								handleFilterListQuestions(
									"disciplina_id",
									e.target.value as string,
								);
							}}
							label='Disciplina:'
							disabled={loadingPage}
						>
							<MenuItem value='todos'>Todos</MenuItem>
							{listSubjects.map((subject: any) => (
								<MenuItem value={subject.codigo} key={subject.codigo}>
									{subject.nome}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>

				<Box className='views_questions'>
					<Typography variant='h5'>Perguntas com melhor avaliação</Typography>

					{loadingPage ? (
						<Box display='flex' justifyContent='center' alignItems='center'>
							<CircularProgress />
						</Box>
					) : (
						<>
							{listQuestions?.length > 0 ? (
								<>
									{listQuestions?.map((question: any, index) => (
										<Box
											className='view_question'
											key={index}
											id='boxQuestionItem'
											onClick={() => {
												setOpenViewQuestion(true);
												setIdQuestion(question.id);
											}}
										>
											<Typography variant='subtitle1'>
												{parse(question.pergunta)}
											</Typography>

											<Box className='footer_question_view'>
												<Typography variant='subtitle1'>
													{question.qnt_respostas} Respostas
												</Typography>

												<Box className='avaliations'>
													<Typography variant='subtitle1'>
														<Star className='iconStar' />{" "}
														{parseFloat(question.nota)
															.toFixed(1)
															.replace(".", ",")}
													</Typography>
													<Typography variant='subtitle1'>
														<Favorite className='iconHeart' />{" "}
														{question.favoritos}
													</Typography>
												</Box>
											</Box>
										</Box>
									))}

									<Paginator
										rows={10}
										totalRecords={totalQuestions}
										first={currentPage}
										onPageChange={(e) => {
											setCurrentPage(e.first);

											if (
												filterClass === "todos" &&
												filterCourse !== "todos"
											) {
												handleFilterListQuestions(
													"curso_id",
													filterCourse as string,
													e.page + 1,
												);
											} else if (filterClass !== "todos") {
												handleFilterListQuestions(
													"disciplina_id",
													filterClass as string,
													e.page + 1,
												);
											} else {
												getQuestionsPaginator(e.page + 1);
											}
										}}
										style={{
											background: "#f7f7f7",
											marginTop: 10,
										}}
									></Paginator>
								</>
							) : (
								<Box className='nothing'>
									<Typography className='text_no_find'>
										Nenhum resultado encontrado
									</Typography>
									<Typography className='subtext_no_find'>
										Tente filtrar por outro curso ou disciplina
									</Typography>
								</Box>
							)}
						</>
					)}
				</Box>
			</Box>

			<DialogQuestionsAnswers open={openNewQuestion} setOpen={setOpenNewQuestion} />

			<DialogViewQuestion
				open={openViewQuestion}
				id={idQuestion}
				setOpen={setOpenViewQuestion}
				setId={setIdQuestion}
			/>
		</Container>
	);
}
