import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import historicoReducer from "../features/Historico/historicoSlice";
import buscaReducer from "../features/Busca/buscaSlice";
import subjectReducer from "../features/Disciplinas/DisciplinaSlice";

export const store = configureStore({
	reducer: {
		historico: historicoReducer,
		busca: buscaReducer,
		subject: subjectReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
