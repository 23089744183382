import "./styles.scss";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Box, Button, CircularProgress, Typography, useTheme } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { useTabelaDisciplinas } from "context/tabelaDisciplinas";
import UnidadesDisciplina from "components/BlocoNotasArquivos/UnidadesDisciplina";
import { Disciplina } from "models/tabelaDisciplinas";
import { useAuth } from "context/Auth/hook";
import axios, { AxiosResponse } from "axios";
import Notification from "utils/Notification";
import { useNavigate } from "react-router-dom";
import { ecossistema } from "services/api";

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
});

function Row(props) {
	const theme = useTheme();
	const { disciplina } = props;
	const classes = useRowStyles();
	const { nivelDeEnsino } = useAuth();
	const [open, setOpen] = useState<boolean>(false);
	const { openGeral, setOpenGeral } = useTabelaDisciplinas();
	const [enableCollapse, setEnableCollapse] = useState<boolean>(true);
	const [disciplinaCuradoria, setDisciplinaCuradoria] = useState({} as Disciplina);
	const history = useNavigate();

	useEffect(() => {
		if (disciplina && disciplina.id_curso_moodle) {

				//TOFIX: O trecho abaixo deveria ser reescrito fazendo uso da instância de ecossistema disponível em services/api
				ecossistema.get(`/avas/${disciplina?.id_curso_moodle}`)
					.then((response: AxiosResponse<any[]>) => {

						if (response.data.length !== 0 && response.data.length === 1) {
							axios.get(`${process.env.REACT_APP_API_BLOCO_NOTAS_ARQUIVOS}/disciplina/${response.data[0]?.id_curadoria}`)
								.then((response: AxiosResponse<Disciplina>) => {
									setEnableCollapse(true);
									setDisciplinaCuradoria(response.data);
									console.log(response.data);
								});
						} else {
							setEnableCollapse(false);
							setDisciplinaCuradoria({
								id: 0,
								nome: disciplina.nome,
								capa: "disciplina_default.png",
								link_partilhando_ideias: "",
								curso_id: 0,
								orientacoes_avaliacao: "",
							});
						}

					});

		} else {
			setEnableCollapse(false);
			setDisciplinaCuradoria({
				id: 0,
				nome: disciplina.nome,
				capa: "disciplina_default.png",
				link_partilhando_ideias: "",
				curso_id: 0,
				orientacoes_avaliacao: "",
			});
		}
	}, [disciplina, disciplina.id_curso_moodle]);

	useEffect(() => {
		if (openGeral === true) {
			localStorage.setItem("nomeDisciplina", disciplinaCuradoria.nome);
			setOpen(true);
		} else if (openGeral === false) {
			localStorage.removeItem("nomeDisciplina");
			setOpen(false);
		}
	}, [openGeral, disciplinaCuradoria.nome]);

	useEffect(() => {
		setOpenGeral(undefined);
	}, [open, setOpenGeral]);

	function exibirUnidadesDaDisciplina() {
		if (openGeral === undefined) {
			return open;
		} else {
			return openGeral;
		}
	}

	return (
		<>
			<TableRow className={`${classes.root} rowDisciplina`}>
				<TableCell
					align='left'
					colSpan={2}
					style={{ textTransform: "uppercase", padding: "0px" }}
				>
					<div style={{ display: "flex", alignItems: "center" }}>
						{
							enableCollapse ?

								<IconButton
									aria-label='expand row'
									size='small'
									onClick={() => {
										if (openGeral === undefined || openGeral !== undefined) {
											if (open === true) {
												localStorage.removeItem("nomeDisciplina");
												setOpen(false);
											} else {
												localStorage.setItem("nomeDisciplina", disciplinaCuradoria.nome);
												setOpen(true);
											}
										}
									}}
								>
									{exibirUnidadesDaDisciplina() ? (
										<KeyboardArrowUpIcon />
									) : (
										<KeyboardArrowDownIcon />
									)}
								</IconButton> :
								<IconButton
									size='small'
									onClick={() => {
										Notification("Conteúdo não disponível!", "warning");
									}}
								>
									<KeyboardArrowDownIcon />
								</IconButton>
						}

						{disciplinaCuradoria.nome}
					</div>
				</TableCell>

				<TableCell
					align='center'
					style={{ padding: "0px auto", display: "flex", justifyContent: "center" }}
				>
					<img
						src={disciplinaCuradoria.capa ? disciplinaCuradoria.capa : "disciplina_default.png"}
						alt='Capa da Disciplina'
						style={{
							display: "block",
							borderRadius: "5px",
							width: "184px",
							height: "52px",
						}}
					/>
				</TableCell>

				<TableCell align='center' style={{ textTransform: "uppercase" }}>
					{nivelDeEnsino.nome_curso}
				</TableCell>

				<TableCell align='center'>
					{
						enableCollapse ?

							<Button
								style={{
									backgroundColor: theme.palette.primary.main,
									color: "#FFFFFF",
									textTransform: "capitalize",
								}}
								onClick={() => {
									history(`/disciplina/${disciplina.id_curso_moodle}`);
								}}
							>
								<Launch style={{ marginRight: "5px" }} /> Recursos
							</Button>

							:

							<Button
								style={{
									backgroundColor: theme.palette.primary.main,
									color: "#FFFFFF",
									textTransform: "capitalize",
								}}
								onClick={() => {
									Notification("Conteúdo não disponível!", "warning");
								}}
							>
								<Launch style={{ marginRight: "5px" }} /> Recursos
							</Button>
					}
				</TableCell>
			</TableRow>

			{
				enableCollapse ?
					<TableRow className={`${classes.root} rowDisciplina`}>
						<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
							<Collapse in={exibirUnidadesDaDisciplina()} timeout='auto' unmountOnExit>
								<UnidadesDisciplina idDisciplinaCuradoria={disciplinaCuradoria.id} />
							</Collapse>
						</TableCell>
					</TableRow> :
					<></>
			}

		</>
	);
}

export default function TabelaDisciplinasView() {

	const theme = useTheme();
	const { disciplinas, exibirDisciplinas, exibirConteudoNaoDisponivelDisciplinas } = useTabelaDisciplinas();

	return (
		<>
			<TableContainer component={Paper} className='containerBlocoNotasArquivos'>
				<Table aria-label='collapsible table' stickyHeader={true}>
					<TableHead>
						<TableRow>
							<TableCell align='left' colSpan={2}>
								<div style={{ display: "flex", alignItems: "center" }}>
									Disciplina
								</div>
							</TableCell>
							<TableCell align='center'>Capa</TableCell>
							<TableCell align='center'>Curso</TableCell>
							<TableCell align='center'>Acesso aos recursos</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{
							exibirDisciplinas ?

								disciplinas.map((disciplina) => {
									return (
										disciplina.id === 0 ? <></> : <Row disciplina={disciplina} key={disciplina.id} />
									)
								})

								:

								<></>

						}
					</TableBody>
				</Table>
			</TableContainer>

			{
				exibirDisciplinas ?
				<></>
				:
				<Box
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						minHeight: "90vh",
						maxHeight: "90vh",
						width: "100%",
						backgroundColor: "#fff",
					}}
				>
					{
						
						exibirConteudoNaoDisponivelDisciplinas ?
						
						<Typography variant="h5">Nenhum conteúdo disponível</Typography>
						
						:

						<CircularProgress style={{ color: theme.palette.primary.main }} />
					}
				</Box>
			}
		</>
	);
}
