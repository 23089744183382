// import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { getNameInitials } from "utils/getNameInitials";
import { useAuth } from "context/Auth/hook";
import { usePortfolio } from "context/Portfolio";
import "./styles.scss";

/**
 * @param classname  = Receberá o className que foi feito para a imagem em determinado local;
 * @param isAuthor   = Criado para ser usado na tela de Q&A pois o mesmo componente será usado para quando
 *                     for mostrar a imagem de perfil do usuário logado ou as iniciais do mesmo e também será usado para mostrar
 *                     a imagem ou as iniciais de outro usuário, o valor dele é do tipo boolean;
 * @param urlImage   = Em caso de o parametro isAuthor seja FALSE o parametro urlImage receberá a imagem do usuário;
 * @param authorName = Em caso de o parametro isAuthor seja FALSE o parametro authorName receberá o nome do usuário;
 * @param isTutor    = Em caso de o usuário logado for do tipo tutor, será setado;
 */
export default function AvatarProfile({
	isTutor = false,
	classname = "",
	isAuthor = true,
	urlImage = "",
	authorName = "",
}) {
	const { user } = useAuth();
	const { state } = usePortfolio();

	return (
		<>
			{(state.avatar_path && isAuthor) || (!isAuthor && urlImage) || isTutor ? (
				<Avatar
					src={isAuthor ? state.avatar_path : urlImage}
					alt='Perfil'
					className={classname}
				/>
			) : (
				<Box className={`avatar_initials_name ${classname}`}>
					<Typography variant='subtitle1'>
						{getNameInitials(isAuthor ? user.nome : authorName)}
					</Typography>
				</Box>
			)}
		</>
	);
}
