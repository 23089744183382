import React from "react";

import DialogQuestionsAnswers from "../DialogQuestionsAnswers";
import DialogReviewQuestion from "pages/PedagogicalCommunication/components/DialogReviewQuestion";
import DialogSchuduleTime from "../DialogScheduleTime";
import CardNavigate from "../CardNavegate";
import DialogIsis from "../DialogIsis";

import Forum from "@material-ui/icons/Forum";
import VoiceChat from "@material-ui/icons/VoiceChat";
import ListAlt from "@material-ui/icons/ListAlt";

export default function CardsMenuStudent() {
	const [openReviewQuestion, setOpenReviewQuestion] = React.useState(false);
	const [openScheduleTime, setOpenScheduleTime] = React.useState(false);
	const [openIsis, setOpenIsis] = React.useState(false);
	const [openQA, setOpenQA] = React.useState(false);

	return (
		<>
			<CardNavigate
				id='btnQuestionsAnswers'
				imageCard='/perguntas-respostas.png'
				titleCard='Dúvidas de conteúdo'
				iconStartButton={<Forum style={{ fontSize: 32 }} />}
				titleButton='Perguntar e Responder'
				actionButton={() => {
					setOpenQA(true);
				}}
			/>

			<CardNavigate
				id='btnSpeak'
				imageCard='/isis.png'
				titleCard='Dúvidas acadêmicas'
				iconStartButton={<ListAlt style={{ fontSize: 32 }} />}
				titleButton='Falar com Tutor'
				actionButton={() => {
					setOpenIsis(true);
				}}
			/>

			<CardNavigate
				id='btnScheduleTutorate'
				imageCard='/agenda-horario.png'
				titleCard='Suporte à aprendizagem'
				iconStartButton={<VoiceChat style={{ fontSize: 32 }} />}
				titleButton='Agendar Mentoria'
				actionButton={() => {
					setOpenScheduleTime(true);
				}}
			/>

			<CardNavigate
				id='btnSolicitationReview'
				imageCard='/revisao-questao.jpg'
				titleCard='Revisão de avaliações'
				iconStartButton={<ListAlt style={{ fontSize: 32 }} />}
				titleButton='Solicitação de revisão'
				actionButton={() => {
					setOpenReviewQuestion(true);
				}}
			/>

			<DialogQuestionsAnswers open={openQA} setOpen={setOpenQA} />
			<DialogIsis open={openIsis} setOpen={setOpenIsis} />
			<DialogSchuduleTime open={openScheduleTime} setOpen={setOpenScheduleTime} />
			<DialogReviewQuestion open={openReviewQuestion} setOpen={setOpenReviewQuestion} />
		</>
	);
}
