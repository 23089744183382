import { Contribuicao } from "models/tabelaDisciplinas";
import ItemListaContribuicoesView from "./ItemListaContribuicoes.view";

interface PropType {
	contribuicao: Contribuicao;
}

export default function ItemListaContribuicoes(props: PropType) {
	return <ItemListaContribuicoesView contribuicao={props.contribuicao} />;
}
