import { PublicacoesContextType } from "./models/PropsPublicacoesContext";

/**
 * Define o estado inicial que vai conter os dados da requisição. Como também estados que são ultilizados para o Backdrop e para a verificação se a requisição foi completa com sucesso
 */
export const INI_VALUES: PublicacoesContextType = {
	publicacoes: [
		{
			type: "",
			title: "",
			date: "",
			url: "",
			workload: "",
			local: "",
			profile_id: 0,
			id: 0,
		},
	],
	setPublicacoes: () => { },
	verification: true,
	setVerification: () => { },
	stateReducer: {
		publicacoes: [
			{
				type: "",
				title: "",
				date: "",
				url: "",
				workload: "",
				local: "",
				profile_id: 0,
				id: 0,
			},

		]
	},
	deletePublicacao: () => { },
	updatePublicacao: () => { },
	addPublicacao: () => { },
};
