import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";

import BoxQuestion from "./BoxQuestion";
import BoxResponse from "./BoxResponse";

import { getQuestion, getListAnswers } from "../../dialogquestionsanswers.services";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import "./styles.scss";

interface DialogViewQuestionProps {
	open: boolean;
	id: string;
	setOpen: (e: boolean) => void;
	setId: (e: string) => void;
}

export default function DialogViewQuestion(props: DialogViewQuestionProps) {
	const { user } = useAuth();
	const { reloadListQuestions, setReloadListQuestions } = usePedagogicalCommunication();
	const colorsTheme = useTheme();
	const [onRealod, setOnReload] = React.useState(false);

	const [loadingQuestion, setLoadingQuestion] = React.useState(true);

	const [detailsQuestion, setDetailsQuestin] = React.useState<
		| {
				id: string;
				pergunta: string;
				autor_id: string;
				autor_avatar: string;
				autor_nome: string;
				curso_id: string;
				curso_nome: string;
				respostas: any[];
		  }
		| undefined
	>(undefined);

	React.useEffect(() => {
		if (props.open && props.id) {
			setLoadingQuestion(true);

			getQuestion(props.id)
				.then(async (data) => {
					let getDetails: any;
					const getDataDetails = data.data;

					await getListAnswers(getDataDetails.id, user.ra ?? "")
						.then((data) => {
							getDetails = { ...getDataDetails, respostas: data.data };

							setLoadingQuestion(false);
						})
						.catch((e) => {
							console.log(e);
						});

					setDetailsQuestin(getDetails);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [props.open, props.id, user.ra]);

	return (
		<Dialog
			fullWidth
			maxWidth='lg'
			open={props.open}
			onClose={() => {
				if (onRealod) {
					setReloadListQuestions(!reloadListQuestions);
				}
				props.setOpen(false);
			}}
		>
			<DialogTitle style={{ background: colorsTheme.palette.primary.main }}>
				<Typography className='titleDialogQuestion'>Questão Selecionada</Typography>
			</DialogTitle>

			<DialogContent>
				<Grid container>
					{loadingQuestion ? (
						<Grid item xs={12}>
							<Box
								display='flex'
								justifyContent='center'
								alignItems='center'
								flexDirection='column'
							>
								<Typography className='await_send_question'>
									Por favor, aguarde...
								</Typography>
								<CircularProgress
									style={{ color: "#bd0e0e", width: "1.5rem", height: "1.5rem" }}
									thickness={4.4}
								/>
							</Box>
						</Grid>
					) : (
						<>
							<BoxQuestion
								idQuestion={detailsQuestion?.id ?? ""}
								pergunta={detailsQuestion?.pergunta ?? ""}
								authorId={detailsQuestion?.autor_id ?? ""}
								author={detailsQuestion?.autor_nome ?? ""}
								authorAvatar={detailsQuestion?.autor_avatar ?? ""}
								course={detailsQuestion?.curso_nome ?? ""}
								setOnReload={setOnReload}
							/>

							<Box className='area_responses'>
								{detailsQuestion?.respostas &&
								detailsQuestion?.respostas.length <= 0 ? (
									<Box>
										<Typography className='nullValue'>
											( Sem Respostas e Comentários )
										</Typography>
									</Box>
								) : (
									<>
										{detailsQuestion?.respostas?.map((dt) => (
											<BoxResponse
												key={dt.resposta_id}
												idAnswer={dt.resposta_id}
												answer={dt.resposta}
												authorId={dt.autor_id}
												author={dt.autor_nome}
												authorAvatar={dt.autor_avatar}
												idCourse={dt.curso_id}
												course={dt.curso_nome}
												favorites={dt.favoritos}
												stars={dt.nota}
												numComments={dt.qnt_comentarios}
												numVotes={dt.nota}
												favorited={!!dt.avaliacoes.favorito}
												rated={!!dt.avaliacoes.nota}
												setOnReload={setOnReload}
											/>
										))}
									</>
								)}
							</Box>
						</>
					)}
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
