import { OutrosEventosContextType } from "./models/PropsOutrosEventosContext";

/**
 * Define o estado inicial que vai conter os dados da requisição. Como também estados que são ultilizados para o Backdrop e para a verificação se a requisição foi completa com sucesso
 */
export const INI_VALUES: OutrosEventosContextType = {
	outrosEventos: [
		{
			end_date: "",
			id: 0,
			local: "",
			name: "",
			profile_id: 0,
			promoted: "",
			start_date: "",
			workload: "",
		},
	],
	setOutrosEventos: () => { },
	verification: true,
	setVerification: () => { },
	stateReducer: {
		outrosEventos: [
			{
				end_date: "",
				id: 0,
				local: "",
				name: "",
				profile_id: 0,
				promoted: "",
				start_date: "",
				workload: "",
			},

		]
	},
	deleteOutrosEventos: () => { },
	updateOutrosEventos: () => { },
	addOutrosEventos: () => { },
};
