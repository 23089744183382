import React from "react";
import parse from "html-react-parser";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import MoreVert from "@material-ui/icons/MoreVert";
import Report from "@material-ui/icons/Report";

import DialogReport from "../DialogReport";
import AvatarProfile from "components/AvatarProfile";

import { BoxQuestionProps } from "./boxquestion.model";

import { useAuth } from "context/Auth/hook";

import "./styles.scss";

export default function BoxQuestion(props: BoxQuestionProps): React.ReactElement {
	const { user } = useAuth();

	const [openOptions, setOpenOptions] = React.useState<null | HTMLElement>(null);
	const [openReport, setOpenReport] = React.useState(false);
	const [idForReport, setIdForReport] = React.useState("");

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenOptions(event.currentTarget);
	};

	const handleClose = () => {
		setOpenOptions(null);
	};

	return (
		<Grid item xs={12}>
			<Box className='area_question'>
				<Box className='header'>
					<Box className='locale_question'>
						<Typography>Pergunta:</Typography>
						<Typography>{parse(props.pergunta)}</Typography>
					</Box>

					<IconButton
						title='Denunciar'
						aria-controls='simple-menu'
						aria-haspopup='true'
						onClick={handleClick}
					>
						<MoreVert />
					</IconButton>

					<Menu
						id='simple-menu'
						anchorEl={openOptions}
						keepMounted
						open={Boolean(openOptions)}
						onClose={handleClose}
					>
						<MenuItem
							style={{ color: "#BD0E0E" }}
							button
							onClick={() => {
								setIdForReport(props.idQuestion);
								setOpenReport(true);
								handleClose();
							}}
						>
							<Report />
							&nbsp;Denunciar
						</MenuItem>
					</Menu>
				</Box>

				<Box className='body'>
					<AvatarProfile
						isAuthor={props.authorId === user.ra}
						urlImage={props.authorAvatar}
						authorName={props.author ?? ""}
					/>
					<Typography>
						{props.author} - {props.course}
					</Typography>
				</Box>
			</Box>

			<Box className='divider_custom'>
				<Typography>respostas e comentários</Typography>
			</Box>

			<DialogReport
				typeReport='pergunta'
				idForReport={idForReport}
				open={openReport}
				setOpen={setOpenReport}
				setReload={props.setOnReload}
			/>
		</Grid>
	);
}
