import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AxiosResponse } from "axios";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Chip, AppBar, Toolbar, Typography, useTheme } from "@material-ui/core/";
import { Rating } from "@material-ui/lab";
import { Tooltip, Button, ButtonGroup, IconButton, Dialog } from "@material-ui/core";
import { MediaInterface, ProjectInterface } from "../../models/media";
import Box from "@material-ui/core/Box";
import CloseIcon from '@material-ui/icons/Close';
// import {useProjects} from "../../context/project";
// import {useSelected} from "../../context/selected";
// import IndexedResult from "../../components/IndexedResults";
// import {useSearch} from "../../context/search";
import {
	BookmarkBorder,
	Close,
	Star,
} from "@material-ui/icons";

import { BiBrain } from "react-icons/bi";
import { GiGraduateCap } from "react-icons/gi";
import { BiAward } from "react-icons/bi";

import "./index.scss";
// import SearchEngineV2 from "components/SearchEngineV2";
import ReactPlayer from "react-player";
import { ecossistema } from "services/api";
import { useAuth } from "context/Auth/hook";
// import Welcome from "components/Welcome";
// import theme from "theme";

// import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme: Theme) =>
// 	createStyles({
// 		popover: {
// 			pointerEvents: "none",
// 		},
// 		paper: {
// 			padding: theme.spacing(1),
// 		},
// 	}),
// );

export default function SearchResult(): React.ReactElement {
	const theme = useTheme();
	// const classes = useStyles();
	const { user } = useAuth();
	const { id } = useParams<{ id: string }>();
	// const { applets } = useProjects();
	// const { selected } = useSelected();
	const [results, setResults] = useState<any | string | null>(null);
	const [media, setSelected] = React.useState<MediaInterface | undefined | null>(undefined);
	const [project, setProject] = React.useState<undefined | null | ProjectInterface>(undefined);

	const [value, setValue] = useState<number | null>(0);
	console.log("🚀 ~ value", value)

	// Configurando modal
	const [open, setOpen] = useState<boolean>(false);
	// const [vote, setVote] = useState<number>(0);
	const [rating, setRating] = React.useState<number>(0);

	// const [submitVote, setSubmitVote] =  useState<boolean>(false);

	//
	// const [anchorEl, setAnchorEl] = React.useState<HTMLElement|null>(null);

	// const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
	// 	setAnchorEl(event.currentTarget);
	// };
	// const handlePopoverClose = () => {
	// 	setAnchorEl(null);
	// };

	// const opened = Boolean(anchorEl);

	const handleClose = () => {
		setOpen(false);
	};

	const [embed, setEmbed] = useState<boolean>(false);

	const handleEmbed = () => {
		setEmbed(false);
	};

	async function getMedia(id: number | undefined | null | any) {
		await ecossistema.get(`/ratings/media/${id}/avg`).then((response: AxiosResponse<any>) => {
			typeof response.data == "number" ? setValue(response.data) : setValue(0);
		});
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	});

	useEffect(() => {
		console.log(results);
		const res = localStorage.getItem("@app:results");
		if (res) {
			setResults(JSON.parse(res));
		}
		ecossistema.get(`/medias/${id}`)
			.then((response: AxiosResponse<MediaInterface>) => {
				setSelected(response.data);
				return response;
			})
			.then((response) => {
				console.log(response.data.id_project);
				ecossistema.get(`/projects/${response.data.id_project}`).then(
					(response: AxiosResponse<ProjectInterface>) => {
						setProject(response.data);
					},
				);
			});
		getMedia(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function rateThisThing(id: number | undefined | string, value: number) {
		ecossistema
			.post("/ratings", {
				idMedia: Number(id),
				value: value,
				ra: user.ra,
			})
			.then(() => {
				getMedia(id);
			});
	}

	return (
		<>
			<Container
				style={{
					// marginTop: "5vh",
					borderRadius: "12px",
					// backgroundColor: "#f8f8f8",
					padding: "12px",
				}}
			>

				<Grid container spacing={2}>
					<Grid item lg={4} md={4} sm={12} xs={12}>
						{project ? (
							project.id === 3 || project.id === 5 || (project.id === 4 && media?.path_media.includes('eventosvirtuais.aiamis.com.br')) ? (
								<Box
									display={"flex"}
									flexDirection={"row"}
									alignItems={"center"}
									justifyContent={"center"}
									className='search--project'
									mb={3}
									mt={3}
									style={{
										minHeight: "256px",
									}}
								>
									{
										media?.path_media.includes('eventosvirtuais.aiamis.com.br')
											?
											<div>
												<iframe
													width="300"
													height="300"
													src={media?.path_media}
													title={media?.title}
													frameBorder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
													allowFullScreen></iframe>
											</div>

											:
											// {media && media.path_media.replace("https://vimeo.com/","https://player.vimeo.com/video/")}
											<ReactPlayer
												url={media?.path_media}
												height='315px'
												width='100%'
												controls={true}
												style={{
													backgroundColor: "#333",
													borderRadius: "8px",
													overflow: "hidden",
												}}
											/>
									}

									{/* <iframe 
									width="100%" 
									height="212" 
									src={
										media?.path_media.includes("https://youtu.be/")
											? media.path_media.replace("https://youtu.be/", "https://www.youtube.com/embed/")
											:media?.path_media.includes("youtube") 
												? media?.path_media.replace("watch?v=","embed/")
												: media?.path_media.includes("vimeo") 
													? media.path_media.replace("https://vimeo.com/","https://player.vimeo.com/video/") + "?h=a66be2d5fa"
													: ""
										// : media?.path_media.includes("anchor") 
										// 	? media?.path_media.replace("episodes", "embed/episodes") + "/a-a612gmr"
										// 	: ""
									}
										
									title={media?.title}
									frameBorder="0" 
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
									allowFullScreen></iframe> */}
								</Box>
							) : (
								<Box
									display={"flex"}
									flexDirection={"row"}
									alignItems={"center"}
									justifyContent={"center"}
									className='search--project'
									mt={3}
									style={{
										backgroundColor: project?.color || "",
										minHeight: "256px",
										alignItems: "center",
										borderRadius: "8px",
									}}
								>
									<img
										src={project?.link_image}
										alt={""}
										style={{ width: "60%" }}
									/>
								</Box>
							)
						) : (
							<></>
						)}
						<Box
							mb={3}
							mt={1}
							display={"flex"}
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
								{/* <Rating value={value} readOnly name={"rating"} /> */}
								<Typography variant='subtitle1' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
									<Star className='iconStar' />{"  "}
									{parseFloat(String(value)).toFixed(1).replace(".", ",")}
								</Typography>
								<Button
									variant={"outlined"}
									disableElevation
									// color={"primary"}
									style={{
										backgroundColor: theme.palette.primary.main,
										color: "white",
									}}
									onClick={() => {
										setOpen(true);
									}}
								>
									Avaliar
								</Button>
							</div>
							<IconButton>
								<BookmarkBorder />
							</IconButton>
						</Box>
					</Grid>
					<Grid item lg={8} md={8} sm={12} xs={12}>
						<Box mb={3} mt={3}>
							<h1>{media?.title ? media.title : null}</h1>
							<br />
						</Box>
						<Box mb={3} mt={3}>
							{project ? (
								(project.id === 4 && !media?.path_media.includes('eventosvirtuais.aiamis.com.br')) ? (
									<iframe
										src={
											media?.path_media.replace(
												"episodes",
												"embed/episodes",
											) + "/a-a612gmr"
										}
										height='204px'
										width='100%'
										frameBorder='0'
										scrolling='no'
										title='Iframe de midia'
									></iframe>
								) : (
									<></>
								)
							) : (
								<></>
							)}
							{/* <p>{media?.description? media.description : "Nenhuma informação disponível"}</p>*/}
							{media?.description ? (
								<div dangerouslySetInnerHTML={{ __html: media.description }} />
							) : (
								<></>
							)}
						</Box>

						<Box mb={3} mt={3} display='flex' flexWrap='wrap'>
							{media?.knowledgeArea ? (
								<Tooltip title='Área do conhecimento' arrow>
									<div style={{ minWidth: "200px" }}>
										<Chip
											style={{
												backgroundColor: "#3F51B5",
												color: "white",
												margin: "6px",
												zoom: "1.2",
												minWidth: "196px",
											}}
											label={media.knowledgeArea.name}
											title='Área do conhecimento'
											icon={<GiGraduateCap style={{ color: "white" }} />}
										/>
									</div>
								</Tooltip>
							) : null}
							{media?.competencies
								? media.competencies.map((competence) => {
									return (
										<Tooltip title='Competência' key={competence.id} arrow>
											<div style={{ minWidth: "200px" }}>
												<Chip
													style={{
														backgroundColor: "#F50057",
														color: "white",
														margin: "6px",
														zoom: "1.2",
														minWidth: "196px",
													}}
													label={competence.name}
													icon={
														<BiBrain style={{ color: "white" }} />
													}
												/>
											</div>
										</Tooltip>
									);
								})
								: null}
							{media?.cognitiveDomains
								? media.cognitiveDomains.map((competencie) => {
									return (
										<Tooltip
											key={competencie.id}
											title='Domínio cognitivo'
											placement='bottom'
											arrow
										>
											<div style={{ minWidth: "196px" }}>
												<Chip
													style={{
														backgroundColor: "#0A7541",
														color: "white",
														margin: "6px",
														zoom: "1.2",
														minWidth: "196px",
													}}
													label={competencie.name}
													icon={
														<BiAward style={{ color: "white" }} />
													}
												/>
											</div>
										</Tooltip>
									);
								})
								: null}
						</Box>

						<Box mt={3}>
							{project ? (
								project.id === 4 || project.id === 3 || project.id === 5 ? (
									<></>
								) : (
									<>
										<p>
											<b>Certificado:</b>{" "}
											{media?.title_certification
												? media.title_certification
												: "Nenhuma informação disponível"}
										</p>
										<p>
											<b>Carga horária:</b>{" "}
											{media?.certification_hour
												? media.certification_hour
												: "Nenhuma informação disponível"}
										</p>
										<br />
									</>
								)
							) : (
								<></>
							)}
							<ButtonGroup>
								{/* <Button
								variant={"contained"}
								disableElevation
								color={"primary"}
								onClick={()=>setEmbed(true)}
							>Pré-visualização</Button> */}
								{project ? (
									project.id !== 4 && project.id !== 1 ? (
										<Button
											variant={"contained"}
											disableElevation
											style={{
												backgroundColor: theme.palette.primary.main,
												color: "white",
											}}
											onClick={() => {
												window.open(
													media?.path_media
														? `https://virtual.uninta.edu.br/enrol/index.php?id=${media?.id_ava_cert}`
														: "",
												);
											}}
										>
											Participar
										</Button>
									) : (
										<Button
											variant={"contained"}
											disableElevation
											style={{
												backgroundColor: theme.palette.primary.main,
												color: "white",
											}}
											onClick={() => {
												window.open(media?.path_media);
											}}
										>
											Participar
										</Button>
									)
								) : (
									<></>
								)}
							</ButtonGroup>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						style={{
							marginTop: "5vh",
						}}
					>
						{/* <Welcome /> */}
						{/* <SearchEngineV2 /> */}
						{/* <Box
						mb={3}
						mt={3}>
						<h1>Resultados semelhantes</h1>
					</Box>
					{results
						? results.map((result)=><IndexedResult key={result.id} id={result.id} {...result}/>)
						: <></>
					} */}
					</Grid>
				</Grid>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
					className='modal'
				>
					<div>
						<div className='body'>
							<div className='head' style={{
								backgroundColor: theme.palette.primary.main,
								color: "white",
							}}>
								<Typography variant='body1' style={{
									backgroundColor: theme.palette.primary.main,
									color: "white",
								}}>
									{" "}
									Avaliar conteúdo: {media?.title}{" "}
								</Typography>
								<CloseIcon
									style={{ marginLeft: "25px", cursor: "pointer" }}
									onClick={handleClose}
								/>
							</div>
							<div className='content'>
								<div>
									<Typography variant='h5' component='h5'>
										{" "}
										{media?.title}{" "}
									</Typography>
									<br />
									{/* <Typography variant="body1"> {media?.description ? <p dangerouslySetInnerHTML={{__html: media.description}} /> : null}  </Typography>
								<br /> */}
									<Typography variant='body1'>
										{" "}
										Clique para avaliar este conteúdo{" "}
									</Typography>
									<Box
										display={"flex"}
										flexDirection={"row"}
										alignItems={"center"}
										justifyContent={"space-between"}
										mt={3}
										mb={3}
									>
										<Rating
											name='simple-controlled'
											value={rating === 0 ? value : rating}
											onChange={(e, value) => {
												setRating(value ?? 0);
											}}
										/>
										{/* <div>
											{vote ? (
												<Rating
													readOnly
													value={vote}
													name={"rating"}
													size='large'
												/>
											) : (
												[1, 2, 3, 4, 5].map((item) => {
													return (
														<IconButton
															size='small'
															key={item}
															onClick={() => {
																// rateThisThing(media.id, item);
																setVote(item);
															}}
														>
															<StarBorderOutlined />
														</IconButton>
													);
												})
											)}
										</div> */}
									</Box>
								</div>
								<ButtonGroup>
									<Button
										disabled={rating === 0}
										// disableElevation
										variant={"contained"}
										// color={"primary"}
										style={{
											backgroundColor: rating === 0 ? "rgba(0, 0, 0, 0.26)" : theme.palette.primary.main,
											color: rating === 0 ? "rgba(0, 0, 0, 0.26)" : "white",
										}}
										onClick={() => {
											rateThisThing(id, rating);
											handleClose();
										}}
									>
										Enviar avaliação
									</Button>
									{/* <Button
										variant={"outlined"}
										onClick={() => {
											setVote(0);
										}}
									>
										Alterar avaliação
									</Button>
									<Button variant={"outlined"} onClick={handleClose}>
										Fechar
									</Button> */}
								</ButtonGroup>
							</div>
						</div>
					</div>
				</Dialog>
				<Dialog fullScreen open={embed} onClose={handleEmbed}>
					<AppBar
						style={{
							position: "relative",
						}}
					>
						<Toolbar>
							<IconButton
								edge='start'
								color='inherit'
								onClick={handleEmbed}
								aria-label='close'
							>
								<Close />
							</IconButton>
							<Typography variant='h6' component='div'>
								Detalhes
							</Typography>
							{/* <Button autoFocus color="inherit" onClick={handleClose}>
							save
						</Button> */}
						</Toolbar>
					</AppBar>
					<Box mt={3}>
						<Container>
							<Grid container>
								<Grid md={12}>
									<Typography variant='h4'>{media?.title}</Typography>
								</Grid>
								<Grid md={12}>
									<Box mt={3} mb={3}>
										{project ? (
											project.id === 3 ? (
												<iframe
													width='100%'
													height='560'
													src={
														media?.path_media.includes("youtube")
															? media?.path_media.replace(
																"watch?v=",
																"embed/",
															)
															: media?.path_media.includes("vimeo")
																? media.path_media.replace(
																	"https://vimeo.com/",
																	"https://player.vimeo.com/video/",
																)
																: ""
														// : media?.path_media.includes("anchor")
														// 	? media?.path_media.replace("episodes", "embed/episodes") + "/a-a612gmr"
														// 	: ""
													}
													title={media?.title}
													frameBorder='0'
													allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
													allowFullScreen
												></iframe>
											) : (
												<></>
											)
										) : (
											<></>
										)}
										{project ? (
											project.id === 4 ? (
												<iframe
													src={
														media?.path_media.replace(
															"episodes",
															"embed/episodes",
														) + "/a-a612gmr"
													}
													height='204px'
													width='100%'
													frameBorder='0'
													scrolling='no'
													title='Iframe de midia'
												></iframe>
											) : (
												<></>
											)
										) : (
											<></>
										)}
									</Box>
								</Grid>
								<Grid md={12}>
									<Box>
										{/* <p>{media?.description? media.description : "Nenhuma informação disponível"}</p>*/}
										{media?.description ? (
											<div
												dangerouslySetInnerHTML={{
													__html: media.description,
												}}
											/>
										) : (
											<></>
										)}
									</Box>

									<Box mb={3} mt={3}>
										<h1>Área do conhecimento</h1>
										<br />
										{media?.knowledgeArea ? (
											<Typography variant={"body1"}>
												{media.knowledgeArea.name}
											</Typography>
										) : null}
									</Box>
									<Box mb={3} mt={3}>
										<h1>Competências</h1>
										<br />
										{media?.competencies
											? media.competencies.map((competence) => {
												return (
													<button key={competence.id}>
														{competence.name}{" "}
													</button>
												);
											})
											: null}
									</Box>
									<Box mb={3} mt={3}>
										<h1>Domínios Cognitivos: </h1>
										<br />
										{media?.cognitiveDomains
											? media.cognitiveDomains.map((competencie) => {
												return (
													<Typography
														variant={"body1"}
														key={competencie.id}
													>
														{competencie.name}{" "}
													</Typography>
												);
											})
											: null}
									</Box>
								</Grid>
							</Grid>
						</Container>
					</Box>
				</Dialog>
			</Container>
		</>
	);
}
