import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";

import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import { PopupNotificationsProps } from "./popupnotifications.model";

import { formatDate } from "utils/formatDate";

import "./styles.scss";

export default function PopupList(props: PopupNotificationsProps): React.ReactElement {
	return (
		<Box className={`popup_notifications ${props.open && "open"}`}>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Typography variant='subtitle2'>{props.title}</Typography>

				<Box display='flex' alignItems='center' justifyContent='flex-end'>
					{props.clearNotification && (
						<Button
							color='primary'
							style={{
								fontWeight: "bold",
								fontSize: "0.7rem",
								padding: 0,
								margin: 0,
							}}
							onClick={props.onClearNotification}
							id='btnCleanListPopup'
						>
							Limpar
						</Button>
					)}

					<IconButton onClick={() => props.setOpen(false)} id='btnClosePopup'>
						<Close />
					</IconButton>
				</Box>
			</Box>

			<Box className='area_notify'>
				{props.list.length <= 0 ? (
					<Typography className='nullValue'>( Vazio )</Typography>
				) : (
					<List component='nav'>
						{props.list.map((item: any, index) => {
							return (
								<React.Fragment key={index}>
									<ListItem
										button
										className={`item_content ${item.readed ? "readed" : ""}`}
										onClick={item.actionClick}
										id={`itemListPopup${index}`}
									>
										{props.withDate && (
											<Typography className='date_notification'>
												{formatDate(item.date)}
											</Typography>
										)}

										<Box className='item_list_request'>
											<FiberManualRecord />
											<Typography
												className={`text_list_request_notification ${
													props.fullWidthText ? "" : "no_fullWidth_text"
												} ${item.readed ? "readed" : ""}`}
											>
												{item.title}
											</Typography>
										</Box>
									</ListItem>
									<Divider />
								</React.Fragment>
							);
						})}
					</List>
				)}
			</Box>
		</Box>
	);
}
