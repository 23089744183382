import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Hidden from "@material-ui/core/Hidden";

import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import Questions from "./Questions";
import Response from "./Response";
import ListReports from "./ListReports";

import "./styles.scss";

interface DialogQuestionsAnswersProps {
	open: boolean;
	setOpen: (e: boolean) => void;
}

export default function DialogQuestionsAnswers(
	props: DialogQuestionsAnswersProps,
): React.ReactElement {
	const [componentOpen, setComponentOpen] = React.useState<"question" | "response" | "report">(
		"question",
	);

	React.useEffect(() => {
		if (props.open) {
			setComponentOpen("question");
		}
	}, [props.open]);

	return (
		<Dialog
			open={props.open}
			onClose={() => {
				props.setOpen(false);
			}}
			fullScreen
		>
			<Hidden mdDown>
				<Header
					setOpen={props.setOpen}
					componentOpen={componentOpen}
					setComponentOpen={setComponentOpen}
				/>
			</Hidden>

			<Hidden lgUp>
				<HeaderMobile
					setOpen={props.setOpen}
					componentOpen={componentOpen}
					setComponentOpen={setComponentOpen}
				/>
			</Hidden>

			<DialogContent className='area_list_questions'>
				{componentOpen === "question" ? (
					<Questions />
				) : componentOpen === "response" ? (
					<Response />
				) : (
					<ListReports />
				)}
			</DialogContent>
		</Dialog>
	);
}
