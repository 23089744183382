import { Container, Box, Typography } from "@material-ui/core";
// import { Refresh } from "@material-ui/icons";
import { Dispatch, SetStateAction } from "react";

interface PropType {
	refresh: boolean;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function NoContentAvailable({ refresh, setRefresh }: PropType) {
	// const theme = useTheme();

	return (
		<Container style={{ marginTop: "10vh" }}>
			<Box
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						textAlign: "center",
					}}
				>
					<Typography variant='h5'> Nenhum conteúdo disponível </Typography>
					{/* <Typography variant="body1"> Nenhu </Typography>*/}
					{/* <Button */}
					{/*    variant="contained" */}
					{/*    disableElevation*/}
					{/*    style={{*/}
					{/*        backgroundColor: theme.palette.primary.main,*/}
					{/*        color: "white",*/}
					{/*        marginTop: "24px",*/}
					{/*        borderRadius: "48px"*/}
					{/*    }}*/}
					{/*    onClick={*/}
					{/*        () => {*/}
					{/*            setRefresh(!refresh);*/}
					{/*        }*/}
					{/*    }*/}
					{/*    > */}
					{/*        <Refresh style={{ marginRight: "12px" }} />*/}
					{/*        Recarregar */}
					{/* </Button>*/}
				</Box>
			</Box>
		</Container>
	);
}
