import { CircularProgress, Fade, Typography } from "@material-ui/core";
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import "./style.scss";
import { useEventosContext } from "../../../context/EventosPublicacoes/hooks/useEventos";

function date(data_impressao: string) {
	let arrayData = data_impressao.split(" ");
	arrayData.splice(1, 1);
	arrayData = arrayData[0].split("-");
	return arrayData.reverse().join("/");
}


const FadeSpinner = ({ loading }) => (
	<div className="fadeSpinner" style={{
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	}}>
		<Fade
			in={loading}
			style={{
				transitionDelay: loading ? "800ms" : "0ms"
			}}
			unmountOnExit
		>
			<CircularProgress />
		</Fade>
		<Typography style={{ marginTop: "10px" }}>
			Buscando Cursos
		</Typography>
	</div >
)

const Cursos = ({ certificados }) => (
	<>
		<Typography variant='body1' className='typographyEventoCursos'>
			CURSOS
		</Typography>
		<List className='listCursos'>
			{
				// eslint-disable-next-line array-callback-return
				certificados.map((certificado) => {
					if (certificado.categoria === "Curso Livre") {
						return (
							<>
								<ListItem alignItems='flex-start'>
									<ListItemText
										className='listItemPrimaryCursos'
										primary={String(certificado.nome_curso)
											.trim()
											.replace(/^d[a,o]/, "")}
										secondary={
											<React.Fragment>
												<div className='dadosEventosCursos'>
													<Typography
														component='span'
														variant='body2'
														className='typographyTituloCursos'
													>
														Realização: &nbsp;
													</Typography>
													<Typography
														component='span'
														variant='body2'
														color='textPrimary'
													>
														Centro Universitário Inta - UNINTA
													</Typography>
												</div>
												<div className='dadosEventosCursos secondaryCursos'>
													<div>
														<Typography
															component='span'
															variant='body2'
															className='typographyTituloCursos'
														>
															Certificação emitida em: &nbsp;
														</Typography>
														<Typography
															component='span'
															variant='body2'
															color='textPrimary'
														>
															{date(certificado.data_impressao)}
														</Typography>
													</div>
													<div className='dadosIndividuaisCursos'>
														<Typography
															component='span'
															variant='body2'
															className='typographyTituloCursos'
														>
															Carga horaria: &nbsp;
														</Typography>
														<Typography
															component='span'
															variant='body2'
															color='textPrimary'
														>
															{certificado.carga_horaria}
														</Typography>
													</div>
												</div>
												<div className='linkCertificadoCursos'>
													<Typography
														component='span'
														variant='body2'
														className='typographyTituloCursos'
													>
														Certificado: &nbsp;
													</Typography>
													<a
														href={`https://virtual.uninta.edu.br/mod/certificate/view.php?id=${certificado.id_modulo}&action=get`}
														target='_blank'
														rel='noreferrer'
													>
														<Typography
															component='span'
															variant='body2'
															color='primary'
														>
															Acesse aqui
														</Typography>
													</a>
												</div>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider variant='inset' component='li' />
							</>
						);
					}
				})
			}
		</List>
	</>
)


export default function CursosView(): React.ReactElement {
	const { certificados } = useEventosContext();
	const [loading,] = React.useState(true);

	return (
		<div className='containerEventosCursos cursos'>
			{(certificados.length > 0) ? <Cursos certificados={certificados} /> : <FadeSpinner loading={loading} />}
		</div>
	);
}
