import React from "react";
import OutrosEventosView from "./OutrosEventos.view";
import { ModalContextProvider } from "context/modal/modal.context";
import { OutrosEventosProvider } from "context/OutrosEventos/outroseventos.context";

export default function OutrosEventos(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<OutrosEventosProvider>
				<OutrosEventosView />
			</OutrosEventosProvider>
		</ModalContextProvider>
	);
}
