import React, { useState } from "react";

import {
	Container,
	Grid,
	Typography,
	Paper,
	Card,
	CardHeader,
	CardContent,
	Button,
	CircularProgress,
	Box,
} from "@material-ui/core";

import "./styles.scss";
import { EventAvailable, EventNote, Info, ZoomIn, ZoomOut } from "@material-ui/icons";

import Gantt from "react-gantt-antd-rocket-pt";
import "react-gantt-antd-rocket-pt/lib/css/style.css";
// import { useEffect } from 'preact/hooks';

import { formatDate } from "utils/formatDate";

import { getCursos, getNivelEnsino, getCalendario } from "./services/calendar.request";
import { useAuth } from "context/Auth/hook";
import { useTheme } from "@material-ui/core";


// -- Lottie
import animationData from "../../assets/Lottie/calendar.json";
import Lottie from "lottie-react";

interface ModeloDataPros {
	id: number;
	start_period: string;
	end_period: string;
	id_level_of_education: number;
	year: number;
	period: number;
	notation: string;
	event_calendar_url: string;
	calendarEvents: any[];
}

export default function CalendarSchedule() {
	const theme = useTheme();
	const { user, nivelDeEnsino } = useAuth();
	const [gantt, setGantt] = useState(false);
	const [zoom, setZoom] = useState(1);
	const [dataCalendario, setDataCalendario] = React.useState<any | ModeloDataPros>(undefined);
	const [projects, setProjects] = React.useState<any | any[]>(undefined);

	const ra = user.ra ?? "1080000005";

	React.useEffect(() => {
		// setando variaveis para o gráfico gantt
		// se liga no rolé; vou pegar o curso do aluno atravez do ra dele
		getCursos(ra)
			.then((data) => {
				// agora com o curso vou pegar o nivel de ensino e comparar com o do banco q tem o id dele.
				// note q estou procurando no valor do array maior pois é o mais atual
				const max = data.length - 1;
				getNivelEnsino(data[max].nome_nivel_ensino)
					.then((data) => {
						// agora com posse do id do nivel de ensino, vou pegar o calendário desse nivel de ensino
						getCalendario(data[0])
							.then((data) => {								
								setDataCalendario(data);
								setProjects(
									data.calendarEvents?.map((retorno: any) => {
										return {
											id: "project" + retorno.id,
											title: formatDate(retorno.start_event),
											tasks: [
												{
													id: retorno.id,
													title: retorno.event_name,
													start: new Date(retorno.start_event),
													end: new Date(retorno.end_event),
												},
											],
											isOpen: false,
										};
									}),
								);

								setGantt(true);
							})
							.catch((e) => {
								console.log(e);
							});
					})
					.catch((e) => {
						console.log(e);
					});
			})
			.catch((e) => {
				console.log(e);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function Zoom(estado: string) {
		if (estado === "mais" && zoom < 10) {
			setZoom(zoom + 1);
		} else if (estado === "menos" && zoom > 1) {
			setZoom(zoom - 1);
		}
	}

	const data_inicio = new Date(
		new Date(dataCalendario?.start_period).getTime() - 15 * 24 * 60 * 60 * 1000,
	);
	const data_termino = new Date(
		new Date(dataCalendario?.end_period).getTime() + 15 * 24 * 60 * 60 * 1000,
	);
	const data_hoje = new Date();

	return (
		<Container>
			<Grid container direction='row' justifyContent='center' alignItems='center' spacing={8}>				
				<Grid container item md={12} spacing={3}>
					<Paper elevation={3} className='calendario-paper'>
						<Box display='flex' justifyContent='center'>
							<Grid item md={1} xs={1} direction='row' className='icones'>
								<EventAvailable
									className='iconeCalendario'
									style={{ color: theme.palette.primary.main }}
								/>
							</Grid>
							<Grid
								item
								md={9}
								xs={9}
								direction='row'
								justifyContent='center'
								alignItems='center'
							>
								<Typography variant='h4' className='titulo'>
									Cronograma de Período Letivo
								</Typography>
							</Grid>
							<Grid item md={2} xs={2} direction='row' className='icones2'>
								<ZoomIn
									className='iconeZoom'
									style={{ color: theme.palette.primary.main }}
									onClick={() => {
										Zoom("mais");
									}}
								/>
								<ZoomOut
									className='iconeZoom'
									style={{ color: theme.palette.primary.main }}
									onClick={() => {
										Zoom("menos");
									}}
								/>
							</Grid>
						</Box>
						{gantt ? (
							<Grid container direction='row' item md={12} spacing={3}>
								<Grid item md={12} xs={12} style={{ display: "flex", justifyContent: "center" }}>
									{ dataCalendario.name === "Error" 
										? (<Lottie
											animationData={animationData}
											loop={true}
											style={{
												height: "auto",
												width: "65%",
												display: "flex",
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center",
												marginBottom: "24px",
											}}
										/>)
										: (<Gantt
											start={data_inicio}
											end={data_termino}
											now={data_hoje}
											zoom={zoom}
											projects={projects}
											enableSticky
											scrollToNow
										/>)
									}
								</Grid>
								<Grid item md={12}>
									<Card variant='outlined' color='secondary'>
										<CardHeader
											avatar={<Info className='iconeInfo' />}
											title={
												<Typography variant='h4'>
													Acesso à Agenda de Eventos
												</Typography>
											}
										/>
										<CardContent>
											<Typography variant='body1' component='p'>
												{dataCalendario?.notation ?? ""}
											</Typography>
										</CardContent>
									</Card>
								</Grid>
								<Grid item md={12}>
									{ 
									dataCalendario.name !== "Error" 
										&& <Button											
											variant='contained'
											style={{ marginRight: "12px", backgroundColor: theme.palette.secondary.main }}
											target={"_blank"}
											href={dataCalendario?.event_calendar_url ?? ""}
										>
											<EventNote /> Agenda de Eventos
										</Button>
									}
									<Button
										variant='contained'
										onClick={() => {
											window.open(
												(nivelDeEnsino.codigo_nivel_ensino !== 351) &&
												(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE ALENCARINA") &&
												(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO - EAD") &&
												(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO")																	
													? nivelDeEnsino.codigo_nivel_ensino === 345 
														? "https://dirin.s3.amazonaws.com/ecossistema_v5/arquivos/calendariounintafortaleza.pdf"
														: "https://dirin.s3.amazonaws.com/ecossistema_v5/arquivos/calendariouninta.pdf"
												: "https://dirin.s3.amazonaws.com/ecossistema_v5/arquivos/calendariof5.pdf"
											)
										}}>
										<EventNote /> Calendário Acadêmico
									</Button>
								</Grid>
							</Grid>
						) : (
							<Box display='flex' justifyContent='center'>
								<CircularProgress />
							</Box>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
}
