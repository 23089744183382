import { Box, Button, ButtonGroup, Container, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";

import "./styles.scss";
import animationData from "../../assets/Lottie/404.json";
import Lottie from "lottie-react";

export default function NotFound(): React.ReactElement {
	const history = useNavigate();

	return (
		<Container>
			<Grid container>
				<Grid item xs={12}>
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						p={2}
						style={{
							paddingTop: "10vh",
							paddingBottom: "10vh",
						}}
					>
						<Typography variant='h4'>ERRO 404</Typography>
						<Divider />
						<Box
							style={{
								marginTop: "24px",
							}}
						>
							<Typography variant='body1'>
								A página solicitada não pode ser encontrada, clique no botão abaixo
								para retomar a navegação.
							</Typography>
							<div
								style={{
									height: "400",
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Lottie animationData={animationData} loop={true} />
							</div>
						</Box>

						<ButtonGroup style={{ marginTop: "10vh" }}>
							<Button
								variant='contained'
								color='primary'
								disableElevation
								onClick={() => history("/")}
							>
								Início
							</Button>
							{/* <Button 
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => history.push('/')}>
                      Central Pedagógica
                </Button>
                <Button 
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => history.push('/')}>
                      Biblioteca Virtual
                </Button> */}
						</ButtonGroup>
					</Box>
				</Grid>
			</Grid>

			{/* <div className="error_action">
                <p>Erro 404</p>
                <button onClick={() => history.push('/')}>Início</button>
            </div> */}
		</Container>
	);
}
