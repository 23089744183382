import React, { useState } from "react";
import { MenuBook, SupervisedUserCircle } from "@material-ui/icons";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import DialogTutorGerenciaDisciplinas from "components/Dialogs/DialogTutorGerenciaDisciplinas";
import DialogTutorGerenciaPerfis from "components/Dialogs/DialogTutorGerenciaPerfis";

export default function TutorWidgetsPane(): React.ReactElement {    
    let [openSubjectsDialog, setOpenSubjectsDialog]                                 = useState<boolean>(false); // Abre e fecha o Dialog
    let [openProfileManagementDialog, setOpenProfileManagementDialog]               = useState<boolean>(false); // Abre e fecha o Dialog
        
    return<React.Fragment>
        <Box
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <ButtonGroup>
                <Button                
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={()=>{
                        setOpenSubjectsDialog(true)
                    }}>
                    <MenuBook />
                    Adicionar disciplina
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={()=>{                        
                        console.log(`[${new Date().toLocaleDateString()}] Open Status: false`)
                        setOpenProfileManagementDialog(true)
                    }}>
                    <SupervisedUserCircle />
                    Gerenciar perfis
                </Button>
            </ButtonGroup>
        </Box>        
        <DialogTutorGerenciaDisciplinas 
            open={openSubjectsDialog} 
            setOpen={setOpenSubjectsDialog} 
        />
        <DialogTutorGerenciaPerfis 
            open={openProfileManagementDialog} 
            setOpen={setOpenProfileManagementDialog} 
        />
    </React.Fragment>
}