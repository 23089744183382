// ACESSO
export const USUARIO_LOGIN = "USUARIO_LOGIN"
export const USUARIO_LOGOUT = "USUARIO_LOGOUT"
// Tutorial
export const USUARIO_CONCLUIU_TUTORIAL = "USUARIO_CONCLUIU_TUTORIAL"
// PORTFOLIO
export const USUARIO_ACESSA_PORTFOLIO = "USUARIO_ACESSA_PORTFOLIO"
export const USUARIO_ADD_NOVA_EXP_PRO = "USUARIO_ADD_NOVA_EXP_PRO"
export const USUARIO_EDIT_NOVA_EXP_PRO = "USUARIO_EDIT_NOVA_EXP_PRO"
// RECURSO_APRENDIZAGEM
export const USUARIO_ACESSA_RECURSO_APRENDZ_DISC = "USUARIO_ACESSA_RECURSO_APRENDZ_DISC"
export const USUARIO_ACESSA_MAT_DIDAT = "USUARIO_ACESSA_MAT_DIDAT"
export const USUARIO_ACESSA_RECURSO_AVA = "USUARIO_ACESSA_RECURSO_AVA"
export const USUARIO_ACESSA_AULAS_SINC = "USUARIO_ACESSA_AULAS_SINC"
export const USUARIO_ACESSA_RECURSO_LAB_VIRTUAL = "USUARIO_ACESSA_RECURSO_LAB_VIRTUAL"
export const USUARIO_ACESSA_RECURSO_SIMULADOS = "USUARIO_ACESSA_RECURSO_SIMULADOS"
export const USUARIO_ACESSA_RECURSO_VIDEOAULAS = "USUARIO_ACESSA_RECURSO_VIDEOAULAS"
export const USUARIO_ACESSA_RECURSO_VIDEOAULAS_PLAY_VIDEO = "USUARIO_ACESSA_RECURSO_VIDEOAULAS_PLAY_VIDEO"
// MATERIAL_DIDATICO
export const USUARIO_CONTRIBUA_COM_DOCUMENTO = "USUARIO_CONTRIBUA_COM_DOCUMENTO"
export const USUARIO_REALIZA_COMENTARIO = "USUARIO_REALIZA_COMENTARIO"
export const USUARIO_ACESSA_RECURSO_APRENDZ = "USUARIO_ACESSA_RECURSO_APRENDZ"
export const USUARIO_ACESSA_RECURSO_APROF_CONHECIMENTO = "USUARIO_ACESSA_RECURSO_APROF_CONHECIMENTO"
export const USUARIO_EDIT_SAVE_BLOCO_DE_NOTAS = "USUARIO_EDIT_SAVE_BLOCO_DE_NOTAS"
export const USUARIO_AVALIA_UNIDADES_DE_ESTUDO = "USUARIO_AVALIA_UNIDADES_DE_ESTUDO"
export const USUARIO_RESPONDE_PESQUISA_AVA_QUALIDADE_MAT_DIDAT = "USUARIO_RESPONDE_PESQUISA_AVA_QUALIDADE_MAT_DIDAT"
export const USUARIO_AVALIA_CONTRIB_COM_DOCUMENTO = "USUARIO_AVALIA_CONTRIB_COM_DOCUMENTO"
// CENTRAL_PEDAGOGICA
export const ALUNO_ENVIA_DUVIDA_CONTEUDO    = "ALUNO_ENVIA_DUVIDA_CONTEUDO"
export const ALUNO_RESPONDE_DUVIDA_CONTEUDO = "ALUNO_RESPONDE_DUVIDA_CONTEUDO"
export const ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO = "ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO"
export const ALUNO_AVALIA_RESPOSTA_DUVIDA_CONTEUDO = "ALUNO_AVALIA_RESPOSTA_DUVIDA_CONTEUDO"
export const ALUNO_SOLICITA_AGENDAMENTO_DEFER_INDEFER = "ALUNO_SOLICITA_AGENDAMENTO_DEFER_INDEFER"
export const USUARIO_SOLICITA_REVISAO_DEFER_INDEFER = "USUARIO_SOLICITA_REVISAO_DEFER_INDEFER"
// HOMEPAGE
export const USUARIO_ACESSA_HOMEPAGE = "USUARIO_ACESSA_HOMEPAGE"
// 
export const USUARIO_ACESSA_COMUNICACAO_PEDAG = "USUARIO_ACESSA_COMUNICACAO_PEDAG"