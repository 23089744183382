import React from "react";
import CourseView from "./Course.view";

/**
 *
 * @param curso
 * @returns View com informações do curso e link de acesso ao curso no AVA
 */
export default function Course(curso): React.ReactElement {
	return (
		<>
			<CourseView {...curso} />
		</>
	);
}
