import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@material-ui/core";

import ArrowBack from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";
import MoreVert from "@material-ui/icons/MoreVert";

import { LearningResourceInterface } from "./resource.model";
import { useAuth } from "context/Auth/hook";

import "./styles.scss";
import Notification from "utils/Notification";
import { apiLogsDoEcossistema } from "services/Logs";
import { USUARIO_ACESSA_AULAS_SINC, USUARIO_ACESSA_MAT_DIDAT, USUARIO_ACESSA_RECURSO_AVA, USUARIO_ACESSA_RECURSO_LAB_VIRTUAL, USUARIO_ACESSA_RECURSO_SIMULADOS, USUARIO_ACESSA_RECURSO_VIDEOAULAS } from "defaults/events";
import { MATERIAL_DIDATICO } from "defaults/modules";

const Grid = React.lazy(() => import("@material-ui/core/Grid"));
const Box = React.lazy(() => import("@material-ui/core/Box"));
const IconButton = React.lazy(() => import("@material-ui/core/IconButton"));
const Typography = React.lazy(() => import("@material-ui/core/Typography"));
const Hidden = React.lazy(() => import("@material-ui/core/Hidden"));
const Menu = React.lazy(() => import("@material-ui/core/Menu"));
const MenuItem = React.lazy(() => import("@material-ui/core/MenuItem"));

const Resource = React.lazy(() => import("./Components/Resource"));
const VideoClasses = React.lazy(() => import("./Components/VideoClasses"));

export default function LearningResource(props: LearningResourceInterface): React.ReactElement {
	const { unintaTheme, user, nivelDeEnsino } = useAuth();
	const theme = useTheme();

	const [openOptionsMobile, setOpenOptionsMobile] = React.useState<null | HTMLElement>(null);
	const [openVideoClasses, setOpenVideoClasses] = useState(false);
	const [displayData, setdisplayData] = useState(false);

	const history = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenOptionsMobile(event.currentTarget);
	};

	const handleClose = () => {
		setOpenOptionsMobile(null);
	};

	const onClickInDidaticMaterial = async() => {
		const token: any = localStorage.getItem("token");

		if (token) {
			const md = window.btoa(
				JSON.stringify({
					AccessToken: JSON.parse(token),
					idSubject: props.id_curadoria,
					ra: user.ra,
					theme: unintaTheme ? "uninta" : "fal",
					sharingIdeas: props.id_forum_partilhando_ideias,
				}),
			);
			await apiLogsDoEcossistema.post('/',
				{
					ra: user.ra as string,
					module: MATERIAL_DIDATICO,
					evento: {		
						label : USUARIO_ACESSA_MAT_DIDAT,
						log_info: {
							idSubject: props.id_curadoria,							
							user_type: user.typeUser,
							info: user.nome
						}		
					},
				}
			)
			window.location.href = `${process.env.REACT_APP_URL_MATERIAL_DIDATICO}/${props.disciplina.id}/ecossistema/${md}`;
		} else {
			Notification("Para esta ação é necessário estar autenticado.");
		}
	};

	useEffect(() => {
		const verifyVideos = () => {
			const tmp = props.disciplina.unidades.map((item) => {
				return item.videos.length > 0 ? item.videos.length : null;
			});
			const val = tmp.filter((item) => {
				return item ? (item > 0 ? true : false) : false;
			});
			val.length > 0 ? setdisplayData(true) : setdisplayData(false);
		};
		verifyVideos();
	}, [props.disciplina.unidades]);

	return (
		<div>
			<Hidden mdDown>
				<Box
					display='flex'
					flexDirection={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
					p={1}
				>
					<Typography variant='body1' style={{ fontWeight: "bold" }}>
						{" "}
						Minhas Disciplinas{" "}
					</Typography>
					<Box>
						<IconButton
							onClick={() => {
								history("/");
							}}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
			</Hidden>
			<Box
				component='div'
				className='learning_resources'
				style={{
					borderRadius: theme.shape.borderRadius,
				}}
			>
				<Grid container>
					<Grid
						item
						xs={12}
						style={{
							display: openVideoClasses ? "none" : "block",
							marginTop: "24px",
							marginBottom: "24px",
						}}
					>
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
							className='header'
						>
							<Box
								display='flex'
								justifyContent='flex-start'
								alignItems='flex-start'
								// className="area_close"
							>
								<IconButton
									onClick={() => {
										history("/");
									}}
								>
									<ArrowBack className='iconClose' />
								</IconButton>
							</Box>

							<Box
								display='flex'
								justifyContent='center'
								alignItems='center'
								flexDirection='column'
								className='area_title_learning_resourses'
							>
								<Typography variant='h5' style={{ fontWeight: "bold" }}>
									{props.disciplina.name
										? props.disciplina.name.toUpperCase()
										: ""}
								</Typography>
								<Typography variant='body1'>
									Acesse os Recursos de Aprendizagem
								</Typography>
							</Box>

							{/* <Hidden mdDown> */}
							{/* <Button variant="contained" disableElevation style={{ backgroundColor: theme.palette.primary.main, color: "white" }} fullWidth onClick={()=>{window.open("https://view.genial.ly/60df74ce3d2af40d5e922951");}}>Como Estudar?</Button>

								<Button variant="contained" disableElevation style={{ backgroundColor: theme.palette.primary.main, color: "white" }} fullWidth>Plano de Ensino</Button> */}
							<IconButton
								aria-controls='simple-menu'
								aria-haspopup='true'
								onClick={handleClick}
							>
								<MoreVert />
							</IconButton>
							<Menu
								id='simple-menu'
								anchorEl={openOptionsMobile}
								keepMounted
								open={Boolean(openOptionsMobile)}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() => {
										window.open(
											"https://view.genial.ly/60df74ce3d2af40d5e922951",
										);
									}}
								>
									Como Estudar?
								</MenuItem>
								<MenuItem onClick={handleClose}>Plano de Ensino</MenuItem>
							</Menu>
							{/* </Hidden> */}
							{/* <Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							flexDirection="column"
							className="area_buttons"
						>							
							<Hidden mdUp>
								<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
									<MoreVert style={{ color: "#BD0E0E" }} />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={openOptionsMobile}
									keepMounted
									open={Boolean(openOptionsMobile)}
									onClose={handleClose}
								>
									<MenuItem style={{ color: "#BD0E0E" }} onClick={handleClose}>Como Estudar?</MenuItem>
									<MenuItem style={{ color: "#BD0E0E" }} onClick={handleClose}>Plano de Ensino</MenuItem>
								</Menu>
							</Hidden>
						</Box> */}
						</Box>
					</Grid>

					<Resource
						title='Material Didático'
						subtitle='Acesse aqui o seu material didático em curadoria digital.'
						backgroundColor='#007AD9'
						display={openVideoClasses ? "none" : "flex"}
						actionClick={onClickInDidaticMaterial}
					/>

					{displayData ? (
						<Resource
							title='VideoAulas'
							subtitle='Acesse as videoaulas da sua disciplina.'
							backgroundColor='#B00F14'
							actionClick={
								async() => {
									setOpenVideoClasses(true);
									await apiLogsDoEcossistema.post('/',
										{
											ra: user.ra as string,
											module: MATERIAL_DIDATICO,
											evento: {		
												label : USUARIO_ACESSA_RECURSO_VIDEOAULAS,
												log_info: {
													idSubject: props.id_curadoria,							
													user_type: user.typeUser,
													info: user.nome
												}
											},
										}
									)
								}
							}
							display={openVideoClasses ? "none" : "flex"}
						/>
					) : null}

					{props.disciplina.id_simulado ? (
						<Resource
							title='Simulados'
							subtitle='Acesse os testes de simulados.'
							backgroundColor='#2C2C2C'
							display={openVideoClasses ? "none" : "flex"}
							actionClick={async() => {
								await apiLogsDoEcossistema.post('/',
										{
											ra: user.ra as string,
											module: MATERIAL_DIDATICO,
											evento: {		
												label : USUARIO_ACESSA_RECURSO_SIMULADOS,
												log_info: {
													idSubject: props.id_curadoria,							
													user_type: user.typeUser,
													info: user.nome
												}		
											},
										}
									)
								window.open(
									`https://virtual.uninta.edu.br/mod/quiz/view.php?id=${props.disciplina.id_simulado}`,
								);
							}}
							// actionClick={()=>{window.open(`https://virtual.uninta.edu.br/course/view.php?id=${props.disciplina.id_simulado}`);}}
							// https://virtual.uninta.edu.br/mod/quiz/view.php?id=:IdSIMULADO
						/>
					) : (
						<></>
					)}

					<Resource
						title='Avaliações'
						subtitle='Realize as avaliações da sua disciplina.'
						backgroundColor='#007AD9'
						display={openVideoClasses ? "none" : "flex"}
						actionClick={async() => {
							await apiLogsDoEcossistema.post('/',
										{
											ra: user.ra as string,
											module: MATERIAL_DIDATICO,
											evento: {		
												label : USUARIO_ACESSA_RECURSO_AVA,
												log_info: {
													idSubject: props.id_curadoria,							
													user_type: user.typeUser,
													info: user.nome
												}		
											},
										}
									)
							window.open(
								`https://virtual.uninta.edu.br/course/view.php?id=${props.id_ava}`,
							);
						}}
					/>

					{
						(nivelDeEnsino.codigo_nivel_ensino !== 351)								&&
						(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE ALENCARINA")			&&
						(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO - EAD")	&&
						(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO") 			&& 
						props.disciplina.link_aula_sincrona ? (
							<Resource
								title='Aulas Síncronas'
								subtitle='Acompanhe os momentos síncronos.'
								backgroundColor='#B00F14'
								display={openVideoClasses ? "none" : "flex"}
								actionClick={async() => {
									await apiLogsDoEcossistema.post('/',
										{
											ra: user.ra as string,
											module: MATERIAL_DIDATICO,
											evento: {		
												label : USUARIO_ACESSA_AULAS_SINC,
												log_info: {
													idSubject: props.id_curadoria,							
													user_type: user.typeUser,
													info: user.nome
												}		
											},
										}
									)
									window.open(`${props.disciplina.link_aula_sincrona}`);
								}}
							/>
						) : (
							<></>
						)
					}

					{props.disciplina.link_playlist_lab ? (
						<Resource
							title='Laboratórios'
							subtitle='Acesse os recursos para as aulas práticas.'
							backgroundColor='#0A7541'
							display={openVideoClasses ? "none" : "flex"}
							actionClick={async() => {
								await apiLogsDoEcossistema.post('/',
										{
											ra: user.ra as string,
											module: MATERIAL_DIDATICO,
											evento: {		
												label : USUARIO_ACESSA_RECURSO_LAB_VIRTUAL,
												log_info: {
													idSubject: props.id_curadoria,							
													user_type: user.typeUser,
													info: user.nome
												}		
											},
										}
									)
								window.open(`${props.disciplina.link_playlist_lab}`);
							}}
						/>
					) : (
						<></>
					)}

					<Grid item xs={12} style={{ background: "#b00f14" }}>
						<VideoClasses
							open={openVideoClasses}
							setOpen={setOpenVideoClasses}
							display={openVideoClasses ? "block" : "none"}
							unidades={props.disciplina.unidades}
							nome_disciplina={props.disciplina?.name}
						/>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}
