import React from "react";
import { Grid, Typography, TextField, Button, FormControl, useTheme, makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useModalContext } from "context/modal/hooks/useModal";
import Notify from "../../../../utils/Notification";
import { putPublication } from "services/Publicacoes";
import MenuItem from "@material-ui/core/MenuItem";
import { usePortfolio } from "context/Portfolio";
import { Publicacoes } from "models/publicacoes";
import { usePublicacoesContext } from "context/Publicacoes/hooks/usePublicacoes";

export default function FormularioAlteracaoPublicacoesView(props): React.ReactElement {
	const { handleSubmit, control, getValues, setValue, reset, formState: { errors }, } = useForm({ mode: "onBlur" });
	const [publicacao, setPublicacao] = React.useState({} as Publicacoes);
	const [disabled, setDisabled] = React.useState(false);

	const idPublicacao = Number(sessionStorage.getItem("idPublicacao") as string);

	const { state } = usePortfolio();
	const { handleCloseAlterar, handleRefresh } = useModalContext();
	const { updatePublicacao } = usePublicacoesContext();

	const theme = useTheme();
	const useStyles = makeStyles((theme) => ({
		root: {
			color: theme?.palette.secondary.main,
		},
		uninta: {
			color: "#bd0e0e",
		},
		select: {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey ",
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey !important",
			},
			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: "black !important",
			},
		},
		inputLabel: {
			"&.Mui-focused": {
				color: "black !important",
			},
		},
		textField: {
			"& label.Mui-focused": {
				color: "black",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const classes = useStyles();

	React.useEffect(() => {
		const data = props.publicacao.filter((value) => value.id === idPublicacao)
		reset(data[0]);
		setPublicacao(data[0])
	}, [idPublicacao, props.publicacao, reset]);

	function Integer(v) {
		return v.replace(/\D/g, "");
	}

	return (
		<form
			onSubmit={handleSubmit((data, event) => {
				event?.preventDefault();
				if (JSON.stringify(publicacao) !== JSON.stringify(data)) {
					setDisabled(true);
					putPublication(idPublicacao, {
						type: data.type,
						title: data.title,
						date: data.date,
						url: data.url,
						workload: data.workload,
						local: data.local,
						profile_id: state.id
					}).then(() => {
						updatePublicacao(data)
						Notify("Evento cadastrado com sucesso!");
						handleCloseAlterar();
						handleRefresh();
					}).catch((e) => {
						console.log(e);
					});
				} else {
					Notify(
						"Para efetuar esta ação atualize pelo menos uma informação!",
						"warning",
					);
				}
			})}
		>
			<Box mt={3} mb={3}>
				<Typography variant='body1'>DADOS CADASTRAIS</Typography>
			</Box>
			<Box mt={3}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>

						<FormControl size='small' fullWidth>
							<Controller
								name='type'
								control={control}
								rules={{ required: true }}
								render={({
									field: { value, onChange },
								}) => (
									<TextField
										className={classes.textField}
										select
										label='Tipo'
										variant='outlined'
										fullWidth
										value={getValues("type") ? value : ""}
										onChange={(e) => { onChange(e) }}
										error={errors.type?.type === "required"}
										helperText={errors.type?.type === "required" && (<span style={{ color: "red" }}>O campo 'Tipo' é obrigatório</span>)}
									>
										<MenuItem value={"Artigo publicado em revista com Qualis reconhecido pela CAPES"}>Artigo publicado em revista com Qualis reconhecido pela CAPES</MenuItem>
										<MenuItem value={"Apresentação oral de Trabalhos Científicos em Eventos."}>Apresentação oral de Trabalhos Científicos em Eventos.</MenuItem>
										<MenuItem value={"Apresentação em pôster de Trabalhos Científicos em eventos (Colóquio / Banner de Estágio)"}>
											Apresentação em pôster de Trabalhos Científicos em eventos
											(Colóquio / Banner de Estágio)
										</MenuItem>
										<MenuItem value={"Publicação em Anais de Eventos Científicos."}>Publicação em Anais de Eventos Científicos.</MenuItem>
										<MenuItem value={"Participação em concursos, exposições e mostras como expositor."}>Participação em concursos, exposições e mostras como expositor.
										</MenuItem>
									</TextField>
								)} />
						</FormControl>


						<FormControl size='small' fullWidth>
							<Controller
								name="title"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => (
									<TextField
										className={classes.textField}
										label='Título'
										type="text"
										variant='outlined'
										fullWidth
										value={getValues("title") ? value : ""}
										onChange={(e) => { onChange(e) }}
										error={errors.title?.type === "required"}
										helperText={errors.title?.type === "required" && (<span style={{ color: "red" }}>O campo 'Título' é obrigatório</span>)}
									/>
								)} />
						</FormControl>


						<FormControl size='small' fullWidth style={{ marginRight: "5px" }}>
							<Controller
								name="date"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => (
									<TextField
										className={classes.textField}
										label='Publicado em'
										variant='outlined'
										type='date'
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										value={getValues("date") ? value : ""}
										onChange={(e) => { onChange(e) }}
										error={errors.date?.type === "required"}
										helperText={errors.date?.type === "required" && (<span style={{ color: "red" }}>O campo 'Publicado em' é obrigatório</span>)}
									/>
								)}
							/>
						</FormControl>


						<FormControl size='small' fullWidth>
							<Controller
								name="url"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => (
									<TextField
										className={classes.textField}
										label='Endereço da publicação'
										variant='outlined'
										fullWidth
										type="url"
										value={getValues("url") ? value : ""}
										onChange={(e) => { onChange(e) }}
										error={errors.url?.type === "required"}
										helperText={errors.url?.type === "required" && (<span style={{ color: "red" }}>O campo 'Endereço da publicação' é obrigatório</span>)}
									/>
								)} />
						</FormControl>

						<FormControl size='small' fullWidth>
							<Controller
								name="workload"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => (
									<TextField
										className={classes.textField}
										label='Carga horaria'
										type="text"
										variant='outlined'
										fullWidth
										value={getValues("workload") ? value : ""}
										onChange={(e) => { setValue("workload", Integer(e.target.value)) }}
										error={errors.workload?.type === "required"}
										helperText={errors.workload?.type === "required" && (<span style={{ color: "red" }}>O campo 'Carga horaria' é obrigatório</span>)}
									/>
								)} />
						</FormControl>

						<FormControl size='small' fullWidth>
							<Controller
								name="local"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => (
									<TextField
										className={classes.textField}
										label='Local'
										variant='outlined'
										fullWidth
										value={getValues("local") ? value : ""}
										onChange={(e) => { onChange(e) }}
										error={errors.local?.type === "required"}
										helperText={errors.local?.type === "required" && (<span style={{ color: "red" }}>O campo 'Local' é obrigatório</span>)}
									/>
								)} />
						</FormControl>

					</Grid>
					<Grid item xs={12}>
						<Box>
							<Button
								style={{
									backgroundColor: theme.palette.primary.main,
									color: "white",
								}}
								disableElevation
								variant='contained'
								type={"submit"}
								fullWidth
								disabled={disabled}
							>
								{" "}
								Alterar{" "}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</form >
	);
}
