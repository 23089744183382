import React, { useEffect } from "react";
import { Box, Chip, FormControl, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { SearchOutlined, Close, Tune } from "@material-ui/icons";
import { useState } from "react";
import Result from "./Result";
// import { getMediaWithParams } from "./service";

import { useProjects } from "context/project";

import "./styles.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllMedia } from "./service";
import Pagination from "@material-ui/lab/Pagination";

// -- Lottie
import animationData from "../../assets/Lottie/data.json";
import Lottie from "lottie-react";

/**
 *
 * @returns {JSX.Element} Deve retornar o componente de busca
 * Este componente deverá renderizar a si mesmo enquanto mantém seu estado interno, com os resulados
 * da pesquisa.
 * Atualmente não estou armazenando o estado interno nem persistindo-o localmente.
 *
 * TOFIX: Alguns problemas que ainda estou tentando resolver:
 *  - Carregamento lento de resultados
 *  - Exibição da paginação com o Pagination do próprio MUI
 *  - Armazenamento de metadados da consulta
 */
export default function SearchEngineV2View() {
	// -- Query Params

	const history = useNavigate();
	const [searchParams] = useSearchParams();

	const params = {
		title: searchParams.get("title"),
		idKnowledgeArea: searchParams.get("idKnowledgeArea"),
		idSubject: searchParams.get("idSubject"),
		idCognitiveDomain: searchParams.get("idCognitiveDomain"),
		idCompetency: searchParams.get("idCompetency"),
		idProject: searchParams.get("idProject"),
		page: searchParams.get("page"),
		perPage: searchParams.get("perPage"),
	};

	const [title, setTitle] = useState<any | null>("");
	const [idKnowledgeArea, setIdKnowledgeArea] = useState<any | null>("");
	const [idSubject, setIdSubject] = useState<any | null>("");
	const [idCognitiveDomain, setIdCognitiveDomain] = useState<any | null>("");
	const [idCompetency, setIdCompetency] = useState<any | null>("");
	const [page, setPage] = useState<any | null>(1);
	const [perPage] = useState<any | null>(10);
	const [idProject, setIdProject] = useState<any | null>("");

	const { applets, competence, knowledgeAreas, cognitiveDomain, subjects } = useProjects();

	// -- State
	const [searchResults, setSearchResults] = useState<any | any[]>(null);
	const [filterVisibility, setFilterVisibility] = useState<boolean>(false);
	const [meta, setMeta] = useState<any>(null);

	const searchTitle = React.useRef<any>(null);
	// -- Pagination

	const handlePaginationChange = async (event: React.ChangeEvent<unknown>, value: number) => {
		event.preventDefault();
		// Atualiza páginação
		setPage(value);

		// Verifica parametros
		// Faz a chamada na API com os queryParams com a paginção atualizada

		// await getMediaWithParams({...queryParams, page: value}).then((response)=>{
		if (params && Object.keys(params).length > 0) {
			history(
				`?title=${params.title}&idKnowledgeArea=${params.idKnowledgeArea}&idSubject=${params.idSubject}&idCognitiveDomain=${params.idCognitiveDomain}&idCompetency=${params.idCompetency}&idProject=${params.idProject}&page=${value}&perPage=${params.perPage}`,
			);
			await getAllMedia(
				`?title=${params.title}&idKnowledgeArea=${params.idKnowledgeArea}&idSubject=${params.idSubject}&idCognitiveDomain=${params.idCognitiveDomain}&idCompetency=${params.idCompetency}&idProject=${params.idProject}&page=${value}&perPage=${params.perPage}`,
			).then((response) => {
				setSearchResults(response.data);
				if (response.meta) {
					setMeta(response.meta);
					localStorage.setItem("searchResults", JSON.stringify(response.data));
					localStorage.setItem("meta", JSON.stringify(response.meta));
				}
			});
		} else {
			history(
				`?title=${params.title}&idKnowledgeArea=${params.idKnowledgeArea}&idSubject=${params.idSubject}&idCognitiveDomain=${params.idCognitiveDomain}&idCompetency=${params.idCompetency}&idProject=${params.idProject}&page=${value}&perPage=${params.perPage}`,
			);
			await getAllMedia(
				`?title=${params.title}&idKnowledgeArea=${params.idKnowledgeArea}&idSubject=${params.idSubject}&idCognitiveDomain=${params.idCognitiveDomain}&idCompetency=${params.idCompetency}&idProject=${params.idProject}&page=${value}&perPage=${params.perPage}`,
			).then((response) => {
				setSearchResults(response.data);
				if (response.meta) {
					setMeta(response.meta);
					localStorage.setItem("searchResults", JSON.stringify(response.data));
					localStorage.setItem("meta", JSON.stringify(response.meta));
				}
			});
		}
	};

	// -- Effects

	useEffect(() => {
		try {
			const res = localStorage.getItem("searchResults");
			const meta = localStorage.getItem("meta");
			if (res && meta) {
				setSearchResults(JSON.parse(res) as string);
				setMeta(JSON.parse(meta) as string);
			}
		} catch (e) {
			console.log(e);
		}
	}, []);

	// -- Retorna a interface do Componente

	return (
		<Grid
			container
			style={{
				marginBottom: "10vh",
			}}
		>
			<Grid item sm={12} xs={12} md={12} lg={12}>
				<Box
					style={{
						minHeight: "30vh",
						backgroundColor: "#fff",
						borderRadius: "10px",
					}}
				>
					{/* <form action="/search">                    */}
					<form
						onSubmit={(e) => {
							e.preventDefault();
							history(
								`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${idSubject}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${idCompetency}&idProject=${idProject}&page=${page}&perPage=${perPage}`,
							);
							getAllMedia(
								`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${idSubject}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${idCompetency}&idProject=${idProject}&page=${page}&perPage=${perPage}`,
							).then((response) => {
								setSearchResults(response.data);
								setMeta(response.meta);

								// -- Armazenando dados das buscas localmente
								localStorage.setItem(
									"searchResults",
									JSON.stringify(response.data),
								);
								localStorage.setItem("meta", JSON.stringify(response.meta));
							});
						}}
					>
						<Grid container>
							<Grid item sm={12} xs={12} md={12} lg={12} className="ecossistema-aprendizagem">
								<Typography
									variant='body1'
									style={{
										marginBottom: "24px",
										color: "gray",
									}}
								>
									Mais de 10 mil objetos de aprendizagem a sua
									disposição
								</Typography>
								<FormControl fullWidth>
									<TextField
										size='small'
										name='title'
										id='outlined-basic'
										label='Encontrar no Ecossistema'
										fullWidth
										variant='outlined'
										style={{
											flexGrow: 1,
										}}
										inputRef={searchTitle}
										onChange={(e) => setTitle(e.target.value)}
										defaultValue={params.title ? params.title : title}
										InputProps={{
											startAdornment: (
												<>
													<IconButton
														type='submit'
														style={{ flexGrow: 1 / 2 }}
													>
														<SearchOutlined />
													</IconButton>
												</>
											),
											endAdornment: (
												<>
													<IconButton
														style={{ flexGrow: 1 / 2 }}
														onClick={() => {
															if (searchTitle.current) {
																searchTitle.current!.value = "";
															}
															setTitle("");
															setPage(1);
															setSearchResults(null);
															setMeta(null);
															setIdKnowledgeArea("");
															setIdSubject("");
															setIdCognitiveDomain("");
															setIdCompetency("");
															setIdProject("");
															localStorage.removeItem(
																"searchResults",
															);
															localStorage.removeItem("meta");
															history("");
														}}
													>
														<Close />
													</IconButton>
													<IconButton
														style={{ flexGrow: 1 / 2 }}
														onClick={() => {
															console.log("ocultando filtros");
															setFilterVisibility(!filterVisibility);
														}}
													>
														<Tune />
													</IconButton>
												</>
											),
										}}
										onFocus={() => {
											console.log("Iniciando o retrabalho do campo de busca");
										}}
									/>
								</FormControl>
							</Grid>

							<Grid
								container
								spacing={1}
								style={{
									// marginTop: "12px",
									visibility: filterVisibility ? "visible" : "hidden",
									height: filterVisibility ? "auto" : "0px",
								}}
							>
								<Grid item xs={12} sm={12} md={12} xl={12}>
									<Typography variant='body2' style={{ color: "gray" }}>
										{" "}
										FILTROS{" "}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<FormControl
										size='small'
										fullWidth
										// style={{minWidth:"100%"}}
										variant={"outlined"}
									>
										{/* <InputLabel htmlFor="demo-controlled-open-select-label">Área do Conhecimento</InputLabel> */}
										<select
											// label={"Área do conhecimento"}
											// labelId={"demo-controlled-open-select-label"}
											// autoWidth={false}

											value={
												params && params.idKnowledgeArea
													? params.idKnowledgeArea
													: ""
											}
											name='idKnowledgeArea'
											style={{
												textOverflow: "ellipsis",
												minWidth: "32ch",
												padding: "12px 24px",
												border: "solid #ddd 1px",
												borderRadius: "4px",
											}}
											onChange={async (e) => {
												setIdKnowledgeArea(e.target.value);
												history(
													`?title=${title}&idKnowledgeArea=${
														e.target.value
													}&idSubject=${idSubject}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${idCompetency}&idProject=${idProject}&page=${1}&perPage=${perPage}`,
												);
												await getAllMedia(
													`?title=${title}&idKnowledgeArea=${
														e.target.value
													}&idSubject=${idSubject}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${idCompetency}&idProject=${idProject}&page=${1}&perPage=${perPage}`,
												).then((response) => {
													setSearchResults(response.data);
													setMeta(response.meta);

													// -- Armazenando dados das buscas localmente
													localStorage.setItem(
														"searchResults",
														JSON.stringify(response.data),
													);
													localStorage.setItem(
														"meta",
														JSON.stringify(response.meta),
													);
												});
											}}
										>
											<option value={""}>Área do conhecimento</option>

											{knowledgeAreas ? (
												knowledgeAreas.map((item, index) => {
													return (
														<option key={item.id} value={item.id}>
															{item.name}
														</option>
													);
												})
											) : (
												<></>
											)}
										</select>
									</FormControl>
								</Grid>

								{/* </select>  */}
								{/* <select name="idDiscipline">
                                    <option value="">Disciplina</option> */}
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<FormControl
										size='small'
										fullWidth
										style={
											{
												// minWidth:"100%"
											}
										}
										variant={"outlined"}
									>
										{/* <InputLabel htmlFor="disciplina">Disciplina</InputLabel> */}
										<select
											// label={"Disciplina"}
											// labelId={"disciplina"}
											// autoWidth={true}
											value={
												params && params.idSubject ? params.idSubject : ""
											}
											name='idSubject'
											style={{
												textOverflow: "ellipsis",
												minWidth: "32ch",
												padding: "12px 24px",
												border: "solid #ddd 1px",
												borderRadius: "4px",
											}}
											onChange={async (e) => {
												setIdSubject(e.target.value);
												history(
													`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${
														e.target.value
													}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${idCompetency}&idProject=${idProject}&page=${1}&perPage=${perPage}`,
												);
												await getAllMedia(
													`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${
														e.target.value
													}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${idCompetency}&idProject=${idProject}&page=${1}&perPage=${perPage}`,
												).then((response) => {
													setSearchResults(response.data);
													setMeta(response.meta);

													// -- Armazenando dados das buscas localmente
													localStorage.setItem(
														"searchResults",
														JSON.stringify(response.data),
													);
													localStorage.setItem(
														"meta",
														JSON.stringify(response.meta),
													);
												});
											}}
										>
											<option defaultValue={""}>Disciplinas</option>

											{subjects ? (
												subjects.map((item, index) => {
													return (
														<option key={item.id} value={item.id}>
															{item.name}
														</option>
													);
												})
											) : (
												<></>
											)}
										</select>
									</FormControl>
								</Grid>
								{/* </select>  */}
								{/* <select name="idCognitiveDomain">
                                        <option value="">Domínio cognitivo</option> */}
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<FormControl
										size='small'
										fullWidth
										// style={{minWidth:"100%"}}
										variant={"outlined"}
									>
										{/* <InputLabel htmlFor="cognitiveDomain">Domínio Cognitivo</InputLabel> */}
										<select
											// label={"Domínio Cognitivo"}
											// labelId={"cognitiveDomain"}
											// autoWidth={false}
											value={
												params && params.idCognitiveDomain
													? params.idCognitiveDomain
													: ""
											}
											name='idCognitiveDomain'
											style={{
												textOverflow: "ellipsis",
												minWidth: "32ch",
												padding: "12px 24px",
												border: "solid #ddd 1px",
												borderRadius: "4px",
											}}
											onChange={async (e) => {
												setIdCognitiveDomain(e.target.value);
												history(
													`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${idSubject}&idCognitiveDomain=${
														e.target.value
													}&idCompetency=${idCompetency}&idProject=${idProject}&page=${1}&perPage=${perPage}`,
												);
												await getAllMedia(
													`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${idSubject}&idCognitiveDomain=${
														e.target.value
													}&idCompetency=${idCompetency}&idProject=${idProject}&page=${1}&perPage=${perPage}`,
												).then((response) => {
													setSearchResults(response.data);
													setMeta(response.meta);

													// -- Armazenando dados das buscas localmente
													localStorage.setItem(
														"searchResults",
														JSON.stringify(response.data),
													);
													localStorage.setItem(
														"meta",
														JSON.stringify(response.meta),
													);
												});
											}}
										>
											<option defaultValue={""}>Domínio Cognitivo</option>
											{cognitiveDomain ? (
												cognitiveDomain.map((item, index) => {
													return (
														<option key={item.id} value={item.id}>
															{item.name}
														</option>
													);
												})
											) : (
												<></>
											)}
										</select>
									</FormControl>
								</Grid>
								{/* </select>  */}
								{/* <select name="idCompetency">
                                        <option value="">Competência</option> */}
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<FormControl
										size='small'
										fullWidth
										// style={{minWidth:"100%"}}
										variant={"outlined"}
									>
										{/* <InputLabel htmlFor="cognitiveDomain">Competência</InputLabel> */}
										<select
											// label={"Competência"}
											// labelId={"competence"}
											// autoWidth={false}
											value={
												params && params.idCompetency
													? params.idCompetency
													: ""
											}
											name='idCompetency'
											style={{
												textOverflow: "ellipsis",
												minWidth: "32ch",
												padding: "12px 24px",
												border: "solid #ddd 1px",
												borderRadius: "4px",
											}}
											onChange={async (e) => {
												setIdCompetency(e.target.value);
												history(
													`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${idSubject}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${
														e.target.value
													}&idProject=${idProject}&page=${1}&perPage=${perPage}`,
												);
												await getAllMedia(
													`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${idSubject}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${
														e.target.value
													}&idProject=${idProject}&page=${1}&perPage=${perPage}`,
												).then((response) => {
													setSearchResults(response.data);
													setMeta(response.meta);

													// -- Armazenando dados das buscas localmente
													localStorage.setItem(
														"searchResults",
														JSON.stringify(response.data),
													);
													localStorage.setItem(
														"meta",
														JSON.stringify(response.meta),
													);
												});
											}}
										>
											<option defaultValue={""}>Competência</option>
											{competence ? (
												competence.map((item) => {
													return (
														<option key={item.id} value={item.id}>
															{item.name}
														</option>
													);
												})
											) : (
												<></>
											)}
										</select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={2} style={{ marginBottom: "12px" }}>
								<Grid item xs={12} sm={12} md={12} xl={12}>
									<Typography variant='body2' style={{ color: "gray" }}>
										{" "}
										PROJETOS{" "}
									</Typography>
								</Grid>
								{applets &&
									applets.map((projeto) => {
										return (
											<Grid item key={projeto.id} sm={3} md={2} xs={6} lg={2}>
												<label className='labl'>
													<input
														type='radio'
														name='idProject'
														id={projeto.id}
														key={projeto.id}
														value={projeto.id}
														checked={
															idProject && idProject === projeto.id
														}
														onChange={() => console.log("change!")}
														onClick={async (e) => {
															console.log(projeto.id);
															setIdProject(projeto.id);
															history(
																`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${idSubject}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${idCompetency}&idProject=${
																	projeto.id
																}&page=${1}&perPage=${perPage}`,
															);
															getAllMedia(
																`?title=${title}&idKnowledgeArea=${idKnowledgeArea}&idSubject=${idSubject}&idCognitiveDomain=${idCognitiveDomain}&idCompetency=${idCompetency}&idProject=${
																	projeto.id
																}&page=${1}&perPage=${perPage}`,
															).then((response) => {
																setSearchResults(response.data);
																setMeta(response.meta);

																// -- Armazenando dados das buscas localmente
																localStorage.setItem(
																	"searchResults",
																	JSON.stringify(response.data),
																);
																localStorage.setItem(
																	"meta",
																	JSON.stringify(response.meta),
																);
															});
														}}
													></input>
													{searchResults && searchResults.length > 0 ? (
														<Chip
															label={projeto.name}
															variant={"outlined"}
															style={{
																width: "100%",
																border: "solid 1px #ccc",
															}}
														/>
													) : (
														<Box
															display={"flex"}
															justifyContent={"center"}
															alignItems={"center"}
															flexDirection={"column"}
															style={{
																backgroundColor: projeto.color,
																height: "176px",
																borderRadius: "12px",
															}}
														>
															<img
																src={projeto.link_image}
																alt='Icone do projeto'
																style={{
																	width: "80%",
																}}
															/>
														</Box>
													)}
												</label>
											</Grid>
										);
									})}
							</Grid>

							<Grid item sm={12} xs={12} md={12} lg={12}>
								<Typography
									variant='body2'
									style={{
										color: "gray",
										marginBottom: "24px",
										display: searchResults ? "" : "none",
									}}
								>
									{" "}
									{meta && meta.total} RESULTADOS ENCONTRADOS{" "}
								</Typography>
								<Box
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									flexDirection={"row"}
									style={{
										marginBottom: "24px",
										display:
											searchResults && searchResults.length > 0 ? "" : "none",
									}}
								>
									{searchResults && meta && (
										<Pagination
											count={meta.last_page}
											page={meta.current_page}
											onChange={handlePaginationChange}
										/>
									)}
								</Box>
								{searchResults && searchResults.length > 0 ? (
									searchResults.map((item, index) => {
										return <Result {...item} key={index} />;
									})
								) : (
									<>
										{meta ? (
											<>
												<Box
													style={{
														minHeight: "35vh",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														flexDirection: "column",
														backgroundColor: "#f8f8f8",
														borderRadius: "4px",
													}}
												>
													<Lottie
														animationData={animationData}
														loop={true}
														style={{
															height: "auto",
															width: "210px",
															display: "flex",
															flexDirection: "row",
															justifyContent: "center",
															alignItems: "center",
															marginBottom: "24px",
														}}
													/>
													<Typography
														variant='h5'
														style={{ color: "gray" }}
													>
														{" "}
														Nenhum resultado encontrado{" "}
													</Typography>
													<Typography
														variant='body1'
														style={{ color: "gray" }}
													>
														{" "}
														Não foi possível encontrar conteúdos com a
														combinação de filtros selecionada. Tente
														novamente com novos termos.{" "}
													</Typography>
												</Box>
											</>
										) : (
											<></>
										)}
									</>
								)}
								<Box
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									flexDirection={"row"}
									style={{
										marginBottom: "24px",
										display:
											searchResults && searchResults.length > 0 ? "" : "none",
									}}
								>
									{searchResults && meta && (
										<Pagination
											count={meta.last_page}
											page={meta.current_page}
											onChange={handlePaginationChange}
										/>
									)}
								</Box>
							</Grid>
						</Grid>
					</form>
				</Box>
			</Grid>
		</Grid>
	);
}
