import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { Typography, Dialog } from "@material-ui/core";
import { useModalContext } from "context/modal/hooks/useModal";
import { IconButton } from "@material-ui/core";
import FormularioAlteracaoOutrosEventos from "components/Formularios/Alteracao/FormularioAlteracaoOutrosEventos";
import { Edit } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';

export default function DialogAlteracaoOutrosEventosView(props): React.ReactElement {
	const { openAlterar, handleOpenAlterar, handleCloseAlterar } = useModalContext();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down(800));

	return (
		<>
			<IconButton
				onClick={() => {
					sessionStorage.setItem("idEvento", props.id.toString());
					handleOpenAlterar();
				}}
			>
				<Edit />
			</IconButton>

			<Dialog open={openAlterar} onClose={handleCloseAlterar} fullScreen={fullScreen}>
				<div>
					<div>
						<div
							style={{
								backgroundColor: theme.palette.primary.main,
								width: "100%",
								padding: "12px 24px",
								color: "white",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Typography variant='body1'> Alterar Evento </Typography>
							<CloseIcon
								style={{ marginLeft: "25px", cursor: "pointer" }}
								onClick={handleCloseAlterar}
							/>
						</div>
						<Box p={3} style={{ border: "none" }}>
							<div>
								<Box mb={3} mt={2}>
									<Typography variant='body1'>
										Preencha o formulário para alterar o seu Evento no
										Ecossistema de Aprendizagem
									</Typography>
								</Box>
								<Box>
									<FormularioAlteracaoOutrosEventos
										outrosEventos={props.outrosEventos}
									/>
								</Box>
							</div>
						</Box>
					</div>
				</div>
			</Dialog>
		</>
	);
}
