import {
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
	useTheme,
} from "@material-ui/core";
import { Bookmark, BookmarkBorderOutlined } from "@material-ui/icons";
import { useAuth } from "context/Auth/hook";
import { useState } from "react";

export default function ItemListaVagasView(props) {
	const { vaga } = props;
	const theme = useTheme();
	const { nivelDeEnsino } = useAuth();
	const [corDoBookMark, setCorDoBookMark] = useState<string>("#000000");

	return (
		<ListItem key={vaga.id} className='item-lista-vagas'>
			<ListItemAvatar>
				<div
					className='imagem-lateral-item-lista-vagas'
					style={{ backgroundColor: theme.palette.primary.main }}
				>
					{nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
					nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 JULHO" ? (
						<img src='logo_white.svg' alt='Logo branca' />
					) : (
						<img
							src='https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg'
							alt=''
						/>
					)}

					<Typography component='span'>Oportunidades</Typography>
				</div>
			</ListItemAvatar>
			<ListItemText
				className='texto-item-lista'
				primary={<Typography component='h2'>{vaga.nome}</Typography>}
				secondary={
					<>
						<span>{vaga.empresa}</span>
						<br />
						<span>{vaga.localizacao}</span>
					</>
				}
			/>
			<ListItemSecondaryAction>
				<IconButton
					className='icone-bookmark'
					edge='end'
					aria-label='delete'
					onClick={() => {
						if (corDoBookMark === "#F8BD00") {
							setCorDoBookMark("#000000");
						} else {
							setCorDoBookMark("#F8BD00");
						}
					}}
				>
					{corDoBookMark === "#F8BD00" ? (
						<Bookmark style={{ color: corDoBookMark }} />
					) : (
						<BookmarkBorderOutlined style={{ color: corDoBookMark }} />
					)}
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);
}
