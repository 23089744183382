import { ecossistema } from "services/api";

/**
 *
 * @param params params:string -> string que será o termo de busca
 * @returns Promise<any> -> retorna uma promise com o resultado da busca e metadados
 */
export const getAllMedia = async (params?: string): Promise<any> => {
	const response = await ecossistema.get(`/medias/search/${params}`);
	return response.data;
};

/**
 *
 * @param params params:Obj -> string que será o termo de busca
 * @returns Promise<any> -> retorna uma promise com o resultado da busca e metadados
 */
export const getMediaWithParams = async (params: any): Promise<any> => {
	const response = await ecossistema.get("/medias/search/", {
		params,
	});
	return response.data;
};
