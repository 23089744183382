import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import "./styles.scss";

const ContainerBoxDetails: React.FC = (props) => {
	return (
		<Box className='content'>
			<Grid container>{props.children}</Grid>
		</Box>
	);
};

export default ContainerBoxDetails;
