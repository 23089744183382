import React from "react";
import FullCalendar, { EventClickArg, EventContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import pt from "@fullcalendar/core/locales/pt-br";
import { getScheduling } from "../../services/calendar.request";
import DialogModal from "../DialogModal";
import { Avatar, Grid, Typography } from "@material-ui/core";
import LiveHelpTwoToneIcon from "@material-ui/icons/LiveHelpTwoTone";

// interface HomeProps {
//   title: string;
//   usuario?: {
//     nome: string;
//     email: string;
//   }
// }

interface CalendarioProps {
	id: number;
	reload: boolean;
	setReload: (e: boolean) => void;
}

export default function Calendario(props: CalendarioProps): React.ReactElement {
	const [data, setData] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [titulo, setTitulo] = React.useState("");
	const [corpo, setCorpo] = React.useState({
		id: 0,
		nome: "",
		idade: 0,
		ra: "",
		email: "",
		dificuldades: "",
		medicamentos: "",
		assunto_nome: "",
		curso_nome: "",
		polo_nome: "",
		telefone: "",
		link: "",
		situacao: "",
		queixa: "",
		medicamento: "",
		diagnostico: "",
		laudo: "",
	});

	React.useEffect(() => {
		getScheduling(props.id)
			.then((data) => {
				setData(data.data);
			})
			.catch(console.log);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id, props.reload]);

	const EventInput: any[] = [];
	let nomeClass: string;
	if (Array.isArray(data)) {
		// eslint-disable-next-line array-callback-return
		data.map((obj: any) => {
			if (obj.situacao === "pendente") {
				nomeClass = "pendente";
			} else if (obj.situacao === "concluido") {
				nomeClass = "concluido";
			} else {
				nomeClass = "cancelado";
			}
			EventInput.push({
				id: obj.id + "-" + obj.assunto,
				title: obj.nome,
				start: obj.horario,
				classNames: nomeClass,
				DragEvent: false,
				extendedProps: {
					id: obj.id,
					nome: obj.nome,
					ra: obj.ra,
					idade: obj.idade,
					email: obj.email,
					dificuldades: obj.dificuldades,
					assunto_nome: obj.assunto_nome,
					curso_nome: obj.curso_nome,
					polo_nome: obj.polo_nome,
					telefone: obj.telefone,
					link: obj.link,
					situacao: obj.situacao,
					queixa: obj.queixa,
					medicamento: obj.medicamento,
					diagnostico: obj.diagnostico,
					laudo: obj.laudo,
				},
			});
		});
	}

	const handleEventClick = (clickInfo: EventClickArg) => {
		setOpen(true);
		setTitulo(clickInfo.event.title);
		setCorpo({
			id: clickInfo.event.extendedProps.id,
			nome: clickInfo.event.extendedProps.nome,
			idade: clickInfo.event.extendedProps.idade,
			ra: clickInfo.event.extendedProps.ra,
			email: clickInfo.event.extendedProps.email,
			dificuldades: clickInfo.event.extendedProps.dificuldades,
			medicamentos: clickInfo.event.extendedProps.medicamentos,
			assunto_nome: clickInfo.event.extendedProps.assunto_nome,
			curso_nome: clickInfo.event.extendedProps.curso_nome,
			polo_nome: clickInfo.event.extendedProps.polo_nome,
			telefone: clickInfo.event.extendedProps.telefone,
			link: clickInfo.event.extendedProps.link,
			situacao: clickInfo.event.extendedProps.situacao,
			queixa: clickInfo.event.extendedProps.queixa,
			medicamento: clickInfo.event.extendedProps.medicamento,
			diagnostico: clickInfo.event.extendedProps.diagnostico,
			laudo: clickInfo.event.extendedProps.laudo,
		});
	};

	function renderEventContent(eventContent: EventContentArg) {
		return (
			<div className={"evento"}>
				<b>{eventContent.timeText}h</b> - <span>{eventContent.event.title}</span>
			</div>
		);
	}

	return (
		<>
			<FullCalendar
				plugins={[dayGridPlugin, interactionPlugin]}
				headerToolbar={{
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,dayGridWeek,dayGridDay",
				}}
				eventClick={handleEventClick}
				locale={pt}
				initialView='dayGridMonth'
				events={EventInput}
				eventContent={renderEventContent}
			/>
			<Grid container justifyContent='center' alignItems='center' className='footerBar'>
				<Grid container item md={4} xs={4}>
					<Grid item md={3} xs={12} container alignItems='center' justifyContent='center'>
						<Avatar className='pendente'>
							<LiveHelpTwoToneIcon />
						</Avatar>
					</Grid>
					<Grid item md={9} xs={12} container alignItems='center' justifyContent='center'>
						<Typography variant='caption'>Pendentes</Typography>
					</Grid>
				</Grid>

				<Grid item container md={4} xs={4}>
					<Grid item md={3} xs={12} container alignItems='center' justifyContent='center'>
						<Avatar className='concluido'>
							<LiveHelpTwoToneIcon />
						</Avatar>
					</Grid>
					<Grid item md={9} xs={12} container alignItems='center' justifyContent='center'>
						<Typography variant='caption'>Concluídos</Typography>
					</Grid>
				</Grid>

				<Grid item container md={4} xs={4}>
					<Grid item md={3} xs={12} container alignItems='center' justifyContent='center'>
						<Avatar className='cancelado'>
							<LiveHelpTwoToneIcon />
						</Avatar>
					</Grid>
					<Grid item md={9} xs={12} container alignItems='center' justifyContent='center'>
						<Typography variant='caption'>Cancelados</Typography>
					</Grid>
				</Grid>
			</Grid>
			<DialogModal
				titulo={titulo}
				corpo={corpo}
				open={open}
				setOpen={setOpen}
				reload={props.reload}
				setReload={props.setReload}
			/>
		</>
	);
}

export function createEventId() {
	return String(1);
}
