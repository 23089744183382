import { centralPedagogica } from "services/api";

export async function getListCourses() {
	return await centralPedagogica
		.get("listarCursos")
		.then(({ data }: any) => {
			return data?.data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListSubjects() {
	return await centralPedagogica
		.get("listarDisciplinas")
		.then(({ data }: any) => {
			return data?.data;
		})
		.catch((e) => {
			return e;
		});
}
