import { Avatar, Box, Button, ButtonGroup, Chip, Dialog, DialogTitle, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@material-ui/core";
import { AddOutlined, ChromeReaderMode, Delete, MenuBook } from "@material-ui/icons";
import React, { useEffect, Dispatch, SetStateAction } from "react";
import { AxiosResponse } from "axios";
import Notify from "utils/Notification";
import { Alert } from "@material-ui/lab";
import { PaperComponent } from "components/PaperComponent";
import useDisciplinas from "context/Disciplinas/hooks";
import { ecossistema } from "services/api";


interface DialogTutorGerenciaDisciplinasProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function DialogTutorGerenciaDisciplinas(
    props: DialogTutorGerenciaDisciplinasProps
): React.ReactElement {        
    let theme = useTheme(); // Busca o tema a partir do contexto global do Theme
    let { 
        curso,
        cursos,
        disciplina,
        disciplinas,
        minhasDisciplinas,
        setCurso,
        setCursos,
        setDisciplina,
        setDisciplinas,
        setMinhasDisciplinas
    }  = useDisciplinas();                                                         
    // let [curso, setCurso]                               = useState<any>(null);      // <Curso>:              Seleciona um curso
    // let [cursos, setCursos]                             = useState<any[]>([]);      // Array<Curso[]>:       Lista todos os cursos da API
    // let [disciplinas, setDisciplinas]                   = useState<any[]>([]);      // Array<Disciplina[]>:  Armazena as disciplinas do curso atual
    // let [disciplina, setDisciplina]                     = useState<any>(null);      // <Disciplina>:         Seleciona uma disciplina do curso atual
    // let [minhasDisciplinas, setMinhasDisciplinas]       = useState<any[]>([]);      // Array<Disciplina[]>:  Armazena as disciplinas adicionadas atualmente
        
	// Carrega cursos e disciplinas ao mudar o valor selecionado	 
    useEffect(() => {
        ecossistema.get("/cursos")
            .then((res: AxiosResponse<any>) => {			
                setCursos(res.data);
            });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    useEffect(() => {
		try {
			ecossistema.get(`/cursos/${curso}/disciplinas`)
				.then((res: AxiosResponse<any>) => {
					setDisciplinas(res.data);
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (e) {
			console.log("Erro ao carregar disciplinas");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [curso, setCurso]);

    // Add minhas disciplinas ao sessionStorage e ao recarregar as telas
	useEffect(() => {
		if (minhasDisciplinas.length > 0) {
			sessionStorage.setItem("@user:minhasDisciplinas", JSON.stringify(minhasDisciplinas));
		} else {
			try {
				const minhasDisciplinasLocais = sessionStorage.getItem("@user:minhasDisciplinas");
				if (minhasDisciplinasLocais !== null && minhasDisciplinasLocais.length > 0) {
					setMinhasDisciplinas(JSON.parse(minhasDisciplinasLocais));
				}
			} catch (e) {
				console.log(e);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minhasDisciplinas]);
        
    return (
        <Dialog
            open={props.open}
            onClose={()=>{
                props.setOpen(!props.open);
            }}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            maxWidth='lg'
            className=''
        >        
        <div
            className=''
            style={{
                backgroundColor: theme.palette.primary.main,
            }}
        >
            <DialogTitle
                    style={{ 
                        cursor: "move", 
                        color: "white",             
                    }}
                    id='draggable-dialog-title'
                >
                    <div
                    style={{                                   
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center"
                    }}>
                    <MenuBook />
                    <Typography variant="body1" style={{ marginLeft: "12px" }}>Gerenciar disciplinas</Typography>
                </div>
            </DialogTitle>
        </div>
        <div className='body'>            
            <Box p={3}>                
                <form
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ flexGrow: 2 }}>
                        <select
                            onChange={(e) => {
                                setCurso(e.target.value);
                                setDisciplina(null);
                            }}
                            style={{
                                padding: "12px",
                                border: "solid #ccc 1px",
                                borderRadius: "4px",
                                marginRight: "4px",
                            }}
                        >
                            <option value=''>Selecione o curso</option>
                            {cursos &&
                                cursos.map((curso, index) => {
                                    return (<option value={curso.id} key={index}>
                                                {curso.nome_curso}
                                            </option>);
                                })
                            }
                        </select>
                        {curso && disciplinas ? (
                            <select
                                onChange={(e) => {
                                    setDisciplina(e.target.value);
                                }}
                                style={{
                                    padding: "12px",
                                    border: "solid #ccc 1px",
                                    borderRadius: "4px",
                                    marginRight: "4px",
                                }}
                            >
                                <option value=''>Selecione a disciplina</option>
                                {disciplinas &&
                                    disciplinas.map((disciplina, index) => {
                                        return (
                                            <>
                                                <option
                                                    value={JSON.stringify(disciplina)}
                                                    key={index}
                                                >
                                                    {disciplina.name}
                                                </option>
                                            </>
                                        );
                                    })}
                            </select>
                        ) : null}
                    </div>
                    <Box
                        style={{                                
                            // padding: "16px",
                            marginTop: "16px",
                            marginBottom: "6px",
                            borderRadius: "4px"
                        }}>
                            <Box style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "12px"
                            }}>
                                <Typography variant="body1">
                                    { 
                                        disciplina && 
                                        JSON.parse(disciplina).name &&
                                        JSON.parse(disciplina).name 
                                    }
                                </Typography>
                            </Box>
                            {
                                !curso && <Alert severity="error">                                    
                                            Nenhum curso selecionado
                                    </Alert>
                            }
                            {
                                curso && 
                                !disciplina && <Alert severity="error">                                    
                                            Nenhuma disciplina selecionada
                                    </Alert>
                            }
                            { 
                                curso &&
                                disciplina && 
                                JSON.parse(disciplina).avas 
                                    ? JSON.parse(disciplina).avas.length > 0 
                                        ? JSON.parse(disciplina).avas.map((ava, index) => {
                                            return <Box key={index} m={1} style={{ display: "flex", flexDirection: "row", justifyContent:  "flex-start" }}>
                                                    <Chip
                                                        avatar={<Avatar>
                                                                <ChromeReaderMode />
                                                            </Avatar>
                                                        } 
                                                        label={`ID Ava: ${ava.id_ava}`} 
                                                        disabled/>
                                                </Box>
                                        }) 
                                        : <Alert severity="error">                                    
                                                Esta disciplina não pode ser adicionada!
                                        </Alert>
                                    : <></>
                            }
                            {/* <pre>
                                { JSON.stringify(JSON.parse(disciplina), null, 4) }
                            </pre> */}
                    </Box>
                    <ButtonGroup
                        style={{ flexGrow: 1, marginTop: "12px", marginBottom: "12px" }}
                    >
                        <Button
                            variant={"contained"}
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                color: "white",
                            }}
                            disableElevation
                            onClick={() => {
                                console.log("Running");
                                if (disciplina) {
                                    console.log(JSON.parse(disciplina).avas);
                                    if (JSON.parse(disciplina).avas.length === 0) {
                                        Notify(
                                            "A disciplina não pode ser adicionada",
                                            "error",
                                        );
                                    } else {                                                                                
                                        setMinhasDisciplinas([
                                            ...minhasDisciplinas,
                                            JSON.parse(disciplina),
                                        ]);
                                        Notify(
                                            "Disciplina adicionada com sucesso",
                                            "success",
                                        );
                                    }
                                }                                
                            }}
                        >
                            <AddOutlined />
                            Adicionar
                        </Button>
                        <Button
                            variant={"text"}
                            style={{
                                backgroundColor: "transparent",
                                color: theme.palette.primary.main,
                            }}
                            disableElevation
                            onClick={() => {
                                setMinhasDisciplinas([]);
                                sessionStorage.removeItem("@user:minhasDisciplinas");
                                Notify("Todas as disciplinas foram removidas", "success");
                            }}
                        >
                            <Delete />
                            Limpar seleção
                        </Button>
                    </ButtonGroup>
                </form>
                <Divider />
                <Box>
                    <Typography
                        variant='body1'
                        style={{ marginBottom: "12px", marginTop: "12px" }}
                    >
                        Disciplinas selecionadas
                    </Typography>                    
                    <TableContainer component={Paper}>
                        <Table size='small' aria-label='a dense table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Disciplina</TableCell>
                                    <TableCell>ID AVA</TableCell>
                                    <TableCell>Ação</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {minhasDisciplinas &&
                                    minhasDisciplinas.map((disciplina, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <a
                                                        target='_blank'
                                                        href={`https://virtual.uninta.edu.br/course/view.php?id=${disciplina.avas.length > 0
                                                                ? disciplina.avas[0].id_ava
                                                                : "Sem ID AVA"
                                                            }`}
                                                        rel='noreferrer'
                                                        style={{
                                                            color: "#333",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        <Typography variant='body2'>
                                                            {disciplina.name.toUpperCase()}{" "}
                                                        </Typography>
                                                    </a>
                                                </TableCell>
                                                <TableCell>
                                                    {disciplina.avas.length > 0
                                                        ? disciplina.avas[0].id_ava
                                                        : "Sem ID AVA"}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => {                                                            
                                                            if (
                                                                minhasDisciplinas.length ===
                                                                1
                                                            ) {
                                                                setMinhasDisciplinas([]);
                                                                sessionStorage.removeItem(
                                                                    "@user:minhasDisciplinas",
                                                                );
                                                            } else {
                                                                const minhasNovasDisciplinas =
                                                                    minhasDisciplinas.filter(
                                                                        (
                                                                            disciplina,
                                                                            indice,
                                                                        ) => {
                                                                            return (
                                                                                indice !==
                                                                                index
                                                                            );
                                                                        },
                                                                    );
                                                                setMinhasDisciplinas(
                                                                    minhasNovasDisciplinas,
                                                                );
                                                            }
                                                            Notify(
                                                                "Disciplina removida com sucesso",
                                                                "success",
                                                            );
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </div>
    </Dialog>
    );
}