function useNivelEnsino(nivelDeEnsino: any, ra: null | number = null) {
    console.log("🚀 ~ nivelDeEnsino", nivelDeEnsino)

    switch (nivelDeEnsino.nome_nivel_ensino) {
        case "FACULDADE ALENCARINA":
            return ('https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg');


        case "FACULDADE 5 DE JULHO - EAD":
            return ('https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg');


        case "FACULDADE 5 DE JULHO":
            return ('https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg');


        default:
            if (nivelDeEnsino.codigo_nivel_ensino === 345) {
                return ("https://dirin.s3.amazonaws.com/ecossistema_v5/assets/fortaleza_horizontal_branca.png")
            }
            return ("/logo_white.svg");
    }

}

export default useNivelEnsino;