import React, { createContext } from "react";
import { PropsModalContext } from "./models/PropsModalContext";

const DEFAULT_VALUE: PropsModalContext = {
	openCadastro: false,
	setOpenCadastro: () => { },
	openAlterar: false,
	setOpenAlterar: () => { },
	openDelete: false,
	setOpenDelete: () => { },
	handleOpenCadastro: () => { },
	handleCloseCadastro: () => { },
	handleOpenAlterar: () => { },
	handleCloseAlterar: () => { },
	handleOpenDelete: () => { },
	handleCloseDelete: () => { },
	refresh: false,
	setRefresh: () => { },
	handleRefresh: () => { },
};

export const ModalContext = createContext<PropsModalContext>(DEFAULT_VALUE);

export const ModalContextProvider: React.FC = ({ children }) => {
	const [openCadastro, setOpenCadastro] = React.useState(false);
	const [openAlterar, setOpenAlterar] = React.useState(false);
	const [openDelete, setOpenDelete] = React.useState(false);
	const [refresh, setRefresh] = React.useState(false);

	function handleRefresh() {
		setRefresh(!refresh);
	}

	function handleOpenCadastro() {
		setOpenCadastro(true);
	}

	function handleCloseCadastro() {
		setOpenCadastro(false);
	}

	function handleOpenAlterar() {
		setOpenAlterar(true);
	}

	function handleCloseAlterar() {
		setOpenAlterar(false);
	}

	function handleOpenDelete() {
		setOpenDelete(true);
	}

	function handleCloseDelete() {
		setOpenDelete(false);
	}

	return (
		<ModalContext.Provider
			value={{
				openCadastro,
				setOpenCadastro,
				openAlterar,
				setOpenAlterar,
				openDelete,
				setOpenDelete,
				handleOpenCadastro,
				handleCloseCadastro,
				handleOpenAlterar,
				handleCloseAlterar,
				handleOpenDelete,
				handleCloseDelete,
				refresh,
				setRefresh,
				handleRefresh,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};
