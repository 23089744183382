import { Box } from "@material-ui/core";
import "./styles.scss";
import { useDepoimentos } from "context/depoimentos";
import CardDepoimento from "components/Oportunidades/CardsOportunidades/CardDepoimento";

export default function ListaDepoimentosView(): JSX.Element {
	const { depoimentos } = useDepoimentos();

	return (
		<Box className='lista-depoimentos'>
			{depoimentos.map((depoimento, index): JSX.Element => {
				return <CardDepoimento key={index} depoimento={depoimento} />;
			})}
		</Box>
	);
}
