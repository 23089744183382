import React, { useEffect, useState } from "react";

// Adicionando importações do Material UI 4

import {
	Card,
	Button,
	CardMedia,
	Typography,
	useTheme,
	CardContent,
	CardActions,
	Box,
} from "@material-ui/core";

// Importando estilos

import "./styles.scss";

// Importando interfaces
import { cardInterface } from "./card.model";

export default function CardTrilhaAprendizagemView({ dados }: cardInterface): React.ReactElement {
	const [image, setImage] = useState("");
	const theme = useTheme();

	useEffect(() => {
		setImage(dados.image);
	}, [dados.image]);

	return (
		<Card className='card-trilha-aprendizagem'>
			{image ? (
				<CardMedia
					className='box-image'
					component='img'
					alt=''
					src={image}
					title='Imagem aleatória'
				/>
			) : (
				<CardMedia
					className='box-image'
					component='img'
					alt='banner'
					src='https://dirin.s3.amazonaws.com/assets_eco_front/Imersao_em_Ambiente_Profissional_-_img_de_erro.png'
					title='Imagem aleatória'
				/>
			)}

			<CardContent className='box-content'>
				<Box className='box-azul'>
					<Typography component='span'>Trilhas de Aprendizagem</Typography>
				</Box>

				<Typography component='h2'>{dados.nome}</Typography>
			</CardContent>

			<CardActions className='box-botao-acessar'>
				<Button
					className='botao-acessar'
					style={{ backgroundColor: theme.palette.primary.main }}
				>
					Acessar
				</Button>
			</CardActions>
		</Card>
	);
}
