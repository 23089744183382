import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { SubjectCollectionInterface } from "context/Disciplinas/defaults";

export interface SubjectCollection {
	courses: any[];
	subjects: any[];
	subjectCollection: SubjectCollectionInterface[] | [];
	status: "idle" | "loading" | "done";
}

export const initialState: SubjectCollection = {
	courses: [],
	subjects: [],
	subjectCollection: [],
	status: "idle",
};

export const subjectSlice = createSlice({
	name: "subject",
	initialState: initialState,
	reducers: {
		setCourses: (state, action) => {
			state.courses = action.payload;
		},
		clearCourses: (state) => {
			state.courses = [];
		},
		setTempSubjects: (state, action) => {
			state.subjects = action.payload;
		},
		clearSubjects: (state) => {
			state.subjects = [];
		},
		loadCollection: (state, action) => {
			state.subjectCollection = action.payload;
		},
		setCollection: (state, action) => {
			if (!state.subjectCollection) {
				state.subjectCollection = [...action.payload];
			} else {
				state.subjectCollection = [...state.subjectCollection, action.payload];
			}
		},
		setSubjectCollection: (state, action) => {
			if (state.subjectCollection) {
				if (
					state.subjectCollection.findIndex(
						(item) => item.title === action.payload.title,
					) === -1
				) {
					if (Array.isArray(action.payload.subjects)) {
						state.subjectCollection = [
							...state.subjectCollection,
							{
								title: action.payload.title,
								subjects: action.payload.subjects,
							},
						];
					} else {
						state.subjectCollection = [
							{
								title: action.payload.title,
								subjects: [action.payload.subjects],
							},
						];
					}
				} else {
					const index = state.subjectCollection.findIndex(
						(item) => item.title === action.payload.title,
					);
					if (state.subjectCollection[index].subjects !== null) {
						state.subjectCollection[index].subjects = [
							...state.subjectCollection[index].subjects,
							action.payload.subjects,
						];
					}
				}
			} else {
				state.subjectCollection = [action.payload];
			}
		},
		clearSubjectCollection: (state) => {
			state.subjectCollection = [];
		},
	},
});

export const {
	setCourses,
	clearCourses,
	setTempSubjects,
	clearSubjects,
	setCollection,
	setSubjectCollection,
	clearSubjectCollection,
	loadCollection,
} = subjectSlice.actions;

export const selectSubject = (state: RootState) => state.subject;
export default subjectSlice.reducer;
