import React, { useEffect } from "react";
import {
	AppBar,
	Badge,
	Box,
	Button,
	Container,
	Hidden,
	IconButton,
	ListItem,
	Menu,
	Toolbar,
	Typography,
	useTheme,
} from "@material-ui/core";
import {
	Description,
	LibraryAddCheck,
	Notifications,
	PaymentOutlined,
	PermContactCalendar,
	QuestionAnswer,
	School,
	ViewComfy,
} from "@material-ui/icons";
import AvatarProfile from "components/AvatarProfile";
import { useAuth } from "context/Auth/hook";
import { Link, useNavigate } from "react-router-dom";

import "./styles.scss";

import {
	getNotifications,
	readedNotification,
	clearAllNotifications,
	getNumberPendingReviewsTutor,
} from "./navbar.services";
import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import { Menu as MenuIcon } from "@material-ui/icons";
import DialogOportunidades from "components/Dialogs/DialogOportunidades";
import DialogTutorialMobile from "components/Dialogs/DialogTutorialMobile";
import useNivelEnsino from "hooks/useNivelEnsino";

const PopupNotifications = React.lazy(
	() =>
		import(
			"pages/PedagogicalCommunication/components/DialogQuestionsAnswers/PopupNotifications"
		),
);
const DialogViewReview = React.lazy(
	() =>
		import(
			"pages/PedagogicalCommunication/components/DialogReviewQuestion/FormReviewQuestion/DialogRequestConfirmation"
		),
);
const DialogViewSchedule = React.lazy(
	() =>
		import(
			"pages/PedagogicalCommunication/components/DialogScheduleTime/DialogScheduleConfirmation"
		),
);
const DialogViewQuestion = React.lazy(
	() =>
		import(
			"pages/PedagogicalCommunication/components/DialogQuestionsAnswers/Questions/DialogViewQuestion"
		),
);

const DrawerMenu = React.lazy(() => import("./Components/DrawerMenu"));

function Navbar() {
	const theme = useTheme();
	const { user, nivelDeEnsino, Logout } = useAuth();
	const history = useNavigate();
	const { updateNumReviews } = usePedagogicalCommunication();
	const { openDrawer, setOpenDrawer, setRunReactJoyride } = useAuth();
	const [openProfile, setOpenProfile] = React.useState(false);
	const [openNotifications, setOpenNotifications] = React.useState(false);
	const [listNotifications, setListNotifications] = React.useState<any[]>([]);
	const [reloadNotifications, setReloadNotifications] = React.useState(false);
	const [countNotifications, setCountNotifications] = React.useState(0);
	const [openViewReview, setOpenViewReview] = React.useState(false);
	const [idModule, setIdModule] = React.useState("");
	const [openViewSchedule, setOpenViewSchedule] = React.useState(false);
	const [openViewQuestion, setOpenViewQuestion] = React.useState(false);
	const [openDialogOportunidades, setOpenDialogOportunidades] = React.useState(false);
	const [openDialogTutorialMobile, setOpenDialogTutorialMobile] = React.useState<boolean>(false);

	const onReadedNotification = (idNotification: string) => {
		if (idNotification) {
			readedNotification(idNotification)
				.then((data) => {
					if (data.success) {
						setReloadNotifications(!reloadNotifications);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	const onClearNotifications = () => {
		if (user.ra && listNotifications.length > 0) {
			clearAllNotifications(user.ra)
				.then((data) => {
					console.log(data);
					if (data.success) {
						setReloadNotifications(!reloadNotifications);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	React.useEffect(() => {
		if (user.ra && user.typeUser === "student") {
			getNotifications(user.ra)
				.then((data) => {
					if (data.success) {
						setCountNotifications(data.count);

						setListNotifications(
							data.data.map((notify) => {
								return {
									title: notify.mensagem,
									date: notify.created_at,
									readed: notify.status,
									actionClick: async () => {
										await onReadedNotification(notify.id);

										setIdModule(notify.modulo_id);
										setOpenNotifications(false);
										if (notify.modulo === "revisoes") {
											setOpenViewReview(true);
										}

										if (notify.modulo === "agendamentos") {
											setOpenViewSchedule(true);
										}

										if (notify.modulo === "perguntas") {
											setOpenViewQuestion(true);
										}
									},
								};
							}),
						);
					} else {
						setCountNotifications(0);
						setListNotifications([]);
					}
				})
				.catch((e) => {
					setCountNotifications(0);
					setListNotifications([]);
					console.log(e);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.ra, reloadNotifications]);

	React.useEffect(() => {
		if (user.typeUser === "tutor") {
			getNumberPendingReviewsTutor()
				.then((data) => {
					if (data.success) {
						setCountNotifications(data.count);

						if (data.count > 0) {
							setListNotifications([
								{
									title: `Existem ${data.count} revisões a serem feitas.`,
									date: new Date(),
									readed: 1,
									actionClick: () => {
										window.location.href = "/comunicacao-pedagogica/reviews";
									},
								},
							]);
						}
					}
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [user.typeUser, updateNumReviews]);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		console.log(openProfile, countNotifications, open);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<AppBar
				position='fixed'
				elevation={0}
				className={"appBar-animated"}
				style={{
					backgroundColor: theme.palette.primary.main,
				}}
			>
				{/* <Box style={{
					minHeight: "24px",
					padding: "6px",
					display: user?.typeUser === "student" ? "none" :  "flex",
					flexDirection: "row",
					justifyContent: "center",
					backgroundColor: theme.palette.primary.dark
				}}>
					<VisibilityOutlined />					
					<Typography variant="body1" style={{ textTransform: "capitalize", marginLeft: "6px" }}>
						{ user?.typeUser === "student" ? "Aluno" : "Tutor" }
					</Typography>
				</Box> */}
				<Toolbar>
					<Container
						maxWidth='xl'
						className='navbar'
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							{/* <IconButton>
                                    <HomeOutlined style={{color: "white"}}/>
                                </IconButton>	 */}
							<Box>
								{<Link to={"/"}>
									<img
										src={useNivelEnsino(nivelDeEnsino)}
										className='logo'
										alt='Logo branca'
										style={{ padding: nivelDeEnsino.codigo_nivel_ensino === 345 ? "12px" : "" }}
									/>
								</Link>}
							</Box>
						</Box>
						<Hidden mdDown>
							{user.signed ? (
								<Box>
									<React.Fragment>
										{/* <Button color="inherit" component={Link} to="/" style={{ textTransform: "capitalize", margin: "0 6px" }}>Acadêmico</Button> */}
										<Button
											className='academico-navbar'
											aria-controls='simple-menu'
											aria-haspopup='true'
											onClick={handleClick}
											style={{
												textTransform: "capitalize",
												color: "white",
												margin: "0 6px",
											}}
										>
											Acadêmico
										</Button>
										<Menu
											id='simple-menu'
											anchorEl={anchorEl}
											keepMounted
											open={Boolean(anchorEl)}
											onClose={handleClose}
										>
											<ListItem
												button
												className='item_menu'
												onClick={() => {
													window.open(
														"https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/requerimentos",
														"_blank",
													);
												}}
											>
												<PermContactCalendar />

												<Typography variant='subtitle2'>
													Requerimento Online
												</Typography>
											</ListItem>

											<ListItem
												button
												className='item_menu'
												onClick={() => {
													window.open(
														"https://academico.aiamis.com.br/FrameHTML/web/app/edu/PortalEducacional/login/?redirect=financeiro.new",
														"_blank",
													);
												}}
											>
												<PaymentOutlined />

												<Typography variant='subtitle2'>Boletos</Typography>
											</ListItem>

											<ListItem
												button
												className='item_menu'
												onClick={() => {
													window.open(
														"https://academico.aiamis.com.br/FrameHTML/web/app/edu/PortalEducacional/login/?redirect=es%2Fmatricula%2Fapresentacao",
														"_blank",
													);
												}}
											>
												<School />

												<Typography variant='subtitle2'>
													Rematrícula
												</Typography>
											</ListItem>

											<ListItem
												button
												className='item_menu'
												onClick={() => {
													window.open(
														"https://academico.aiamis.com.br/FrameHTML/web/app/edu/PortalEducacional/login/?redirect=negociacao%2Fintroducao",
														"_blank",
													);
												}}
											>
												<QuestionAnswer />

												<Typography variant='subtitle2'>
													Negociação
												</Typography>
											</ListItem>

											<ListItem
												button
												className='item_menu'
												onClick={() => {
													window.open(
														"https://academico.aiamis.com.br/FrameHTML/web/app/edu/PortalEducacional/login/?redirect=grade-curricular",
														"_blank",
													);
												}}
											>
												<ViewComfy />

												<Typography variant='subtitle2'>
													Matriz Curricular
												</Typography>
											</ListItem>

											<ListItem
												button
												className='item_menu'
												onClick={() => {
													window.open(
														"https://academico.aiamis.com.br/FrameHTML/web/app/edu/PortalEducacional/login/?redirect=relatorios",
														"_blank",
													);
												}}
											>
												<Description />

												<Typography variant='subtitle2'>
													Relatórios
												</Typography>
											</ListItem>

											<ListItem
												button
												className='item_menu'
												onClick={() => {
													window.open(
														"https://academico.aiamis.com.br/FrameHTML/web/app/edu/PortalEducacional/login/?redirect=faltas",
														"_blank",
													);
												}}
											>
												<LibraryAddCheck />

												<Typography variant='subtitle2'>Faltas</Typography>
											</ListItem>
										</Menu>
									</React.Fragment>
									<Button
										className='button-navbar biblioteca-navbar'
										color='inherit'
										onClick={() =>
											window.open(
												nivelDeEnsino.nome_nivel_ensino ===
													"FACULDADE 5 DE JULHO - EAD" ||
													nivelDeEnsino.nome_nivel_ensino ===
													"FACULDADE ALENCARINA"
													? "https://minhabiblioteca.aiamis.com.br/2/access"
													: "https://minhabiblioteca.aiamis.com.br/1/access",
												"blank",
											)
										}
										style={{ textTransform: "capitalize", margin: "0 6px" }}
									>
										Minha biblioteca
									</Button>
									<Button
										color='inherit'
										className='button-navbar comunicacao-navbar'
										onClick={() => history("/comunicacao-pedagogica")}
										style={{ textTransform: "capitalize", margin: "0 6px" }}
									>
										Comunicação Pedagógica
									</Button>
									<Button
										className='button-navbar calendario-navbar'
										color='inherit'
										onClick={() => history("/calendario")}
										style={{ textTransform: "capitalize", margin: "0 6px" }}
									>
										Calendário
									</Button>
									<Button
										className='button-navbar gamificacao-navbar'
										color='inherit'
										onClick={() => {
											window.open(
												"https://gamificacao.uninta.edu.br/sign-in",
											);
										}}
										style={{ textTransform: "capitalize", margin: "0 6px" }}
									>
										Gamificação
									</Button>
									<Button
										className='button-navbar oportunidades-navbar'
										color='inherit'
										onClick={() => {
											setOpenDialogOportunidades(true);
										}}
										style={{ textTransform: "capitalize", margin: "0 6px" }}
									>
										Oportunidades
									</Button>
									<Button
										className='button-navbar telegram-navbar'
										color='inherit'
										onClick={() => {
											// setOpenDialogOportunidades(true);
											window.open("https://t.me/+teEctz3ihTM2OTA5");
										}}
										style={{ textTransform: "capitalize", margin: "0 6px" }}
									>
										Comunidade
									</Button>
								</Box>
							) : (
								<></>
							)}
							<Box
								style={{
									minWidth: "250px",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								{
									user.signed ? (
										<React.Fragment>
											<IconButton
												style={{ display: "none" }}
												className="botao-tutorial"
												onClick={() => {
													setRunReactJoyride(true);
												}}
											/>
											<IconButton
												onClick={() => {
													setOpenNotifications(!openNotifications);
												}}
												className='notificacoes-navbar'
											>
												<Badge
													overlap='rectangular'
													badgeContent={countNotifications}
													color='secondary'
												>
													<Notifications style={{ color: "white" }} />
												</Badge>
												<PopupNotifications
													title='Notificações'
													list={listNotifications}
													open={openNotifications}
													setOpen={setOpenNotifications}
													fullWidthText
													withDate
													clearNotification
													onClearNotification={() => {
														onClearNotifications();
													}}
												/>
											</IconButton>

											<Button
												className='button_portfolio_avatar'
												onClick={() => {
													history("/portfolio");
												}}
											>
												<AvatarProfile classname='imgPerfil' />
												<Typography className='title_button_portifolio'>
													Meu Portfólio
												</Typography>
											</Button>
											<Button
												variant='text'
												style={{ color: "white" }}
												onClick={() => Logout()}
											>
												Sair
											</Button>
										</React.Fragment>
									) : (
										<React.Fragment></React.Fragment>
									)
									// : <Link to="/login" style={{ textDecoration: "none", color: "white" }}>
									//     <Button variant="text" style={{ color: "white" }}>
									//         <AccountCircle style={{ marginRight: "6px" }}/>
									//         Login
									//     </Button>
									// </Link>
								}
							</Box>
						</Hidden>
						{user.signed && (
							<Hidden lgUp>
								<Box>
									<IconButton
										style={{ display: "none" }}
										className="botao-tutorial"
										onClick={() => {
											setOpenDialogTutorialMobile(true);
										}}
									/>
									<IconButton
										onClick={() => {
											setOpenNotifications(!openNotifications);
										}}
									>
										<Badge
											badgeContent={4}
											color='secondary'
											overlap='rectangular'
										>
											<Notifications style={{ color: "white" }} />
										</Badge>
										<PopupNotifications
											title='Notificações'
											list={listNotifications}
											open={openNotifications}
											setOpen={setOpenNotifications}
											fullWidthText
											withDate
											clearNotification
											onClearNotification={() => {
												onClearNotifications();
											}}
										/>
									</IconButton>
									<IconButton onClick={() => setOpenDrawer(true)}>
										<MenuIcon className='iconMenu' />
									</IconButton>
								</Box>
								<DrawerMenu
									open={openDrawer}
									setOpen={setOpenDrawer}
									setOpenProfile={setOpenProfile}
								/>
							</Hidden>
						)}
					</Container>
				</Toolbar>
				<DialogViewReview
					open={openViewReview}
					idSolicitation={idModule}
					setIdSolicitation={setIdModule}
					setOpen={setOpenViewReview}
				/>

				<DialogViewSchedule
					open={openViewSchedule}
					idSchedule={idModule}
					setOpen={setOpenViewSchedule}
				/>

				<DialogViewQuestion
					open={openViewQuestion}
					id={idModule.toString()}
					setId={setIdModule}
					setOpen={setOpenViewQuestion}
				/>

				<DialogOportunidades
					open={openDialogOportunidades}
					setOpen={setOpenDialogOportunidades}
				/>

				<DialogTutorialMobile
					open={openDialogTutorialMobile}
					setOpen={setOpenDialogTutorialMobile}
				/>
			</AppBar>
		</div>
	);
}

export default Navbar;
