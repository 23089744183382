import React from "react";
import { Route, Routes } from "react-router-dom";

import LoginScreen from "pages/Login";

import NotFound from "pages/NotFound";
// import PublicPage from "pages/PublicPage";
import SearchResult from "../pages/SearchResult/SearchResult";

export default function PublicRoutes(): JSX.Element {
	return (
		<Routes>
			{/* <Route path="/" element={<PublicPage /> }  /> */}
			<Route path={"/search/result/:id"} element={<SearchResult />} />
			<Route path='/' element={<LoginScreen />} />
			<Route path='*' element={<NotFound />} />
			{/* 
				<Route path="/:nivelDeEnsino" element={<LoginScreen /> }  /> 
			*/}
		</Routes>
	);
}
