import { useState } from "react";
import { DisciplinasContext } from "./context";
import { INI_VALUES } from "./defaults";


export default function DisciplinasProvider({ children }): any {	
	let [curso, setCurso]                               = useState<any>(INI_VALUES.curso);      // <Curso>:              Seleciona um curso
    let [cursos, setCursos]                             = useState<any[]>(INI_VALUES.cursos);      // Array<Curso[]>:       Lista todos os cursos da API
    let [disciplina, setDisciplina]                     = useState<any>(INI_VALUES.disciplina);      // <Disciplina>:         Seleciona uma disciplina do curso atual
    let [disciplinas, setDisciplinas]                   = useState<any[]>(INI_VALUES.disciplinas);      // Array<Disciplina[]>:  Armazena as disciplinas do curso atual
    let [minhasDisciplinas, setMinhasDisciplinas]       = useState<any[]>(INI_VALUES.minhasDisciplinas);      // Array<Disciplina[]>:  Armazena as disciplinas adicionadas atualmente

	
	// -- Define o Provider do contexto
	return (
		<DisciplinasContext.Provider
			value={{
				curso,
				cursos,
				disciplina,
				disciplinas,
				minhasDisciplinas,
				setCurso,
				setCursos,
				setDisciplina,
				setDisciplinas,
				setMinhasDisciplinas,
			}}
		>
			{children}
		</DisciplinasContext.Provider>
	);
}
