import React, { useEffect } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";

import ArrowBack from "@material-ui/icons/ArrowBack";
import RateReview from "@material-ui/icons/RateReview";

import ButtonInfo from "../../DialogQuestionsAnswers/ButtonsInfo";
import PopupList from "../../PopupList";

import { HeaderProps } from "./header.model";

import { getNumSchedule } from "../scheduletime.services";
import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import "./styles.scss";

export default function Header(props: HeaderProps): React.ReactElement {
	const { user, nivelDeEnsino } = useAuth();
	const { updateNumSchedule } = usePedagogicalCommunication();
	const colorsTheme = useTheme();

	const [openPopup, setOpenPopup] = React.useState(false);
	const [numSolicitations, setNumSolicitations] = React.useState(0);

	useEffect(() => {
		getNumSchedule(user?.ra).then((data: any) => {
			setNumSolicitations(data?.qntAgendamentos ? data?.qntAgendamentos : 0);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateNumSchedule]);

	return (
		<AppBar position='static' style={{ backgroundColor: colorsTheme.palette.primary.main }}>
			<Toolbar className='navbar_header'>
				<Box display='flex' justifyContent='flex-start' alignItems='center'>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='menu'
						onClick={() => {
							props.onClosed(false);
						}}
					>
						<ArrowBack />
					</IconButton>

					<img
						src={`${
							nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
							nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO"
								? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg"
								: "/logo_white.svg"
						}`}
						className='logo'
						alt='Logo branca'
					/>
				</Box>

				<Box display='flex' justifyContent='flex-start' alignItems='center'>
					<ButtonInfo
						startIconButton={
							<RateReview
								className='iconButtonAppBar'
								style={{
									color: colorsTheme.palette.secondary.main,
									background: colorsTheme.palette.primary.main,
								}}
							/>
						}
						titleButton='Respostas Dada'
						value={numSolicitations.toString()}
						actionButton={() => setOpenPopup(!openPopup)}
					/>
				</Box>
			</Toolbar>

			<PopupList
				open={openPopup}
				setOpen={setOpenPopup}
				title='Meus Agendamentos'
				type='schedule'
			/>
		</AppBar>
	);
}
