import Nofity from "utils/Notification";

export function validationSetFile(error: string): void {
	if (error === "too-many-files") {
		Nofity("Selecione Apenas 1 arquivo.", "warning");
		return;
	}

	if (error === "file-too-large") {
		Nofity("Arquivo não suportado. Tamanho máximo 5MB.", "warning");
		return;
	}

	if (error === "file-invalid-type") {
		Nofity("Arquivo permitidos: .PDF, .PNG, .JPEG, .JPG", "warning");
		return;
	}
}
