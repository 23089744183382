import React from "react";
import { useParams } from "react-router-dom";

import DialogQuestionsAnswers from "../DialogQuestionsAnswers";
import ListSolicitationsReviewQuestion from "../ListSolicitationsReviewQuestion";
import CardNavigate from "../CardNavegate";

import Forum from "@material-ui/icons/Forum";
import VoiceChat from "@material-ui/icons/VoiceChat";
import ListAlt from "@material-ui/icons/ListAlt";
import CalendarScreen from "../CalendarScreen";

export default function CardsMenuTutor() {
	const { area } = useParams<{ area: "reviews" | "schedules" | "questions" }>();

	const [openQA, setOpenQA] = React.useState(false);
	const [openReviewQuestion, setOpenReviewQuestion] = React.useState(false);
	const [openCalendar, setOpenCalendar] = React.useState(false);

	React.useEffect(() => {
		if (area) {
			if (area === "reviews") {
				setOpenReviewQuestion(true);
			}
		}
	}, [area]);

	return (
		<>
			<CardNavigate
				type='tutor'
				imageCard='/perguntas-respostas.png'
				titleCard='Dúvidas de conteúdo'
				iconStartButton={<Forum style={{ fontSize: 32 }} />}
				titleButton='Perguntar e Responder'
				actionButton={() => {
					setOpenQA(true);
				}}
			/>

			<CardNavigate
				type='tutor'
				imageCard='/agenda-horario.png'
				titleCard='Suporte à aprendizagem'
				iconStartButton={<VoiceChat style={{ fontSize: 32 }} />}
				titleButton='Realizar Atendimento'
				actionButton={() => {
					setOpenCalendar(true);
				}}
			/>

			<CardNavigate
				type='tutor'
				imageCard='/revisao-questao.jpg'
				titleCard='Revisão de avaliações'
				iconStartButton={<ListAlt style={{ fontSize: 32 }} />}
				titleButton='Atendimento de Revisões'
				actionButton={() => {
					setOpenReviewQuestion(true);
				}}
			/>

			<DialogQuestionsAnswers open={openQA} setOpen={setOpenQA} />
			<CalendarScreen open={openCalendar} onClosed={setOpenCalendar} />
			<ListSolicitationsReviewQuestion
				open={openReviewQuestion}
				setOpen={setOpenReviewQuestion}
			/>
		</>
	);
}
