import React from "react";

import Dropzone from "react-dropzone";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Description from "@material-ui/icons/Description";
import GetApp from "@material-ui/icons/GetApp";
import Close from "@material-ui/icons/Close";

import { validationSetFile } from "./selectorFile.functions";

import "./styles.scss";

interface SelectorFileProps {
	onUpload: (e: File[]) => void;
}

export default function SelectorFile(props: SelectorFileProps): React.ReactElement {
	const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
		if (isDragReject) {
			return (
				<Box className='initial_type_error'>
					<Close className='iconClose' />
					<Typography className='type_error'>Arquivo não suportado</Typography>
					<Typography className='type_error_length'>
						Tamanho do arquivo até 5MB
					</Typography>
					<Typography className='type_error_type'>
						Permitidos: .pdf, .jpge, .jpg, .png
					</Typography>
				</Box>
			);
		}

		if (!isDragActive) {
			return (
				<Box className='initial_type_default'>
					<Description className='iconDescription' />
					<Typography className='type_default'>
						Clique ou arraste e solte o arquivo aqui
					</Typography>
					<Typography className='type_default_length'>
						Tamanho do arquivo até 5MB
					</Typography>
					<Typography className='type_default_type'>
						Permitidos: .pdf, .jpeg, .jpg, .png
					</Typography>
				</Box>
			);
		}

		return (
			<Box className='initial_type_success'>
				<GetApp className='iconGetApp' />
				<Typography className='type_success'>Solte os arquivos aqui</Typography>
				<Typography className='type_success_length'>Tamanho do arquivo até 5MB</Typography>
				<Typography className='type_success_type'>
					Permitidos: .pdf, .jpge, .jpg, .png
				</Typography>
			</Box>
		);
	};

	return (
		<Dropzone
			accept='application/pdf, image/*'
			maxSize={5242880}
			maxFiles={1}
			onDropAccepted={props.onUpload}
			onDropRejected={(e) => {
				validationSetFile(e[0]?.errors[0]?.code);
			}}
		>
			{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
				<Box
					{...getRootProps()}
					className='container'
					style={{
						border: `1px dashed ${
							isDragReject ? "#e57878" : isDragActive ? "#25ac97" : "#9c9999"
						}`,
					}}
				>
					<input {...getInputProps()} />
					{renderDragMessage(isDragActive, isDragReject)}
				</Box>
			)}
		</Dropzone>
	);
}
