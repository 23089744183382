import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import Close from "@material-ui/icons/Close";

import { DialogIsisProps } from "./dialogisis.model";

export default function DialogIsis(props: DialogIsisProps): React.ReactElement {
	return (
		<Dialog
			open={props.open}
			onClose={() => {
				props.setOpen(false);
			}}
			fullWidth
			maxWidth='xs'
		>
			<DialogContent style={{ padding: 0 }}>
				<Box display='flex' justifyContent='flex-end' alignItems='center'>
					<IconButton onClick={() => props.setOpen(false)}>
						<Close />
					</IconButton>
				</Box>
				<iframe
					src='https://dirin.s3.amazonaws.com/webpages/central_de_comunicacao/chat.html'
					allow='microphone;'
					width='100%'
					height='530'
					title='Isis Assistente'
				/>
			</DialogContent>
		</Dialog>
	);
}
