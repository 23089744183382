import React from "react";
// import Button from '@material-ui/core/Button';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";

function Alert(props: AlertProps) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

interface SnackbarProps {
	msg: string;
	tipo: any; // "error" | "warning" | "info" | "success",
	aberto: boolean;
	setAberto: (e: boolean) => void;
}

export default function CustomizedSnackbars(props: SnackbarProps) {
	const classes = useStyles();

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		props.setAberto(false);
	};

	return (
		<div className={classes.root}>
			<Snackbar open={props.aberto} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={props.tipo}>
					{props.msg}
				</Alert>
			</Snackbar>
		</div>
	);
}
