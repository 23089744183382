import React from "react";

import Hidden from "@material-ui/core/Hidden";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useTheme } from "@material-ui/core/styles";

import EventNote from "@material-ui/icons/EventNote";

import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import FormInitial from "./FormInitial";

import "./styles.scss";

interface DialogScheduleTimeProps {
	open: boolean;
	setOpen: (e: boolean) => void;
}

export default function DialogScheduleTime(props: DialogScheduleTimeProps): React.ReactElement {
	const colorsTheme = useTheme();

	return (
		<Dialog
			open={props.open}
			onClose={() => {
				props.setOpen(false);
			}}
			fullScreen
		>
			<Hidden mdDown>
				<Header onClosed={props.setOpen} />
			</Hidden>

			<Hidden lgUp>
				<HeaderMobile onClosed={props.setOpen} />
			</Hidden>

			<DialogContent>
				<Container>
					<Box className='area_header_dialog'>
						<EventNote
							style={{
								backgroundColor: colorsTheme.palette.primary.main,
							}}
						/>
						<Typography>Agende um horário ideal para você</Typography>
					</Box>

					<FormInitial />
				</Container>
			</DialogContent>
		</Dialog>
	);
}
