import React, { useEffect, useState } from "react";

// Adicionando importações do Material UI 4

import {
	Card,
	CardMedia,
	CardContent,
	CardActions,
	Button,
	Typography,
	useTheme,
} from "@material-ui/core";

// Importando estilos

import "./styles.scss";

// Importando interfaces
import { cardInterface } from "./card.model";
import { Add } from "@material-ui/icons";

export default function CardVagaView({ dados }: cardInterface): React.ReactElement {
	const [image, setImage] = useState("");
	const theme = useTheme();

	useEffect(() => {
		setImage(dados.image);
	}, [dados.image]);

	return (
		<Card className='card-vaga'>
			{image ? (
				<CardMedia
					className='box-image'
					component='img'
					alt=''
					src={image}
					title='Imagem aleatória'
				/>
			) : (
				<CardMedia
					className='box-image'
					component='img'
					alt='banner'
					src='https://dirin.s3.amazonaws.com/assets_eco_front/Imersao_em_Ambiente_Profissional_-_img_de_erro.png'
					title='Imagem aleatória'
				/>
			)}

			<CardContent className='box-content'>
				<Typography component='h2'>{dados.nome}</Typography>
				<Typography component='p' color='textSecondary'>
					{dados.descricao}
				</Typography>
				<Typography component='span'>{dados.localizacao}</Typography>
			</CardContent>

			<CardActions className='box-botoes'>
				<Button
					// style={{backgroundColor: theme.palette.primary.main}}
					className='botao-detalhes'
					startIcon={<Add />}
				>
					Detalhes
				</Button>
				<Button
					style={{ backgroundColor: theme.palette.primary.main }}
					className='botao-candidatar'
				>
					Candidatar-se
				</Button>
			</CardActions>
		</Card>
	);
}
