import React, { createContext, Dispatch, SetStateAction } from "react";
import { MediaInterface } from "models/media";

type SelectedContextType = {
	selected: MediaInterface | undefined | null | any;
	setSelected: Dispatch<SetStateAction<SelectedContextType>>;
};

const INITIAL_SELECTION_VALUES: SelectedContextType = {
	selected: undefined,
	setSelected: (selected) => console.log(selected),
};

// Adicionando um contexto para selecionar uma mídia

export const SelectedContext = createContext({
	selected: INITIAL_SELECTION_VALUES.selected,
	setSelected: INITIAL_SELECTION_VALUES.setSelected,
});

export const SelectedProvider: React.FC = ({ children }) => {
	const [selected, setSelected] = React.useState<SelectedContextType>(
		INITIAL_SELECTION_VALUES.selected,
	);

	// useEffect(()=>{
	// 	const selection = localStorage.getItem("@App:selected");
	// 	setSelected(JSON.parse(selection || ""));
	// },[selected, setSelected]);

	return (
		<SelectedContext.Provider
			value={{
				selected: selected,
				setSelected: setSelected,
			}}
		>
			{children}
		</SelectedContext.Provider>
	);
};

// Hook para selected media

export function useSelected(): SelectedContextType {
	const context = React.useContext(SelectedContext);
	return context;
}
