import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";

import { useAuth } from "context/Auth/hook";

import { HeaderProps } from "./header.model";

import "./styles.scss";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: "#FFFFFF",
	},
}));

export default function Header(props: HeaderProps): React.ReactElement {
	const { nivelDeEnsino } = useAuth();
	const theme = useTheme();
	const classes = useStyles();

	return (
		<>
			<AppBar position='static' className={classes.appBar}>
				<Toolbar className='navbar_question_answers'>
					<Box
						display='flex'
						justifyContent='center'
						alignItems='center'
						style={{ width: "100%" }}
					>
						<img
							onClick={() => {
								props.setOpen(false);
							}}
							src={`${
								(nivelDeEnsino.codigo_nivel_ensino !== 351)								&&
								(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE ALENCARINA")			&&
								(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO - EAD")	&&
								(nivelDeEnsino.nome_nivel_ensino   !== "FACULDADE 5 DE JULHO")																	
								? nivelDeEnsino.codigo_nivel_ensino === 345 ? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/fortaleza_horizontal_vermelha.png" : "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-01.svg"
								: "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-01.svg"
							}`}
							style={{ cursor: "pointer", width: "auto", padding: "12px" }}
							className='logo'
							alt='Logo UNINTA'
						/>

						<h1 style={{ color: theme.palette.primary.main }}>&nbsp;| Oportunidades</h1>
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
}
