import { centralPedagogica } from "services/api";

export async function getNotifications(ra: string) {
	return await centralPedagogica
		.get(`aluno/notificationList/${ra}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getNumberPendingReviewsTutor() {
	return await centralPedagogica
		.get("tutor/contarRevisoesPendentes")
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function readedNotification(idNotification: string) {
	return await centralPedagogica
		.get(`aluno/notificationRead/${idNotification}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function clearAllNotifications(ra: string) {
	return await centralPedagogica
		.get(`aluno/notificationClear/${ra}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
