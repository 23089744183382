import { Box, Typography, Dialog, useTheme, Button } from "@material-ui/core";
// import MDEditor, { bold, italic, image, link, quote, codeLive, fullscreen } from "@uiw/react-md-editor";
import RichTextEditor, { EditorValue } from "react-rte";
import { useRef, useState, useEffect } from "react";
import { getBlocoNotas, postBlocoNotas, putBlocoNotas } from "services/TabelaDisciplinas";
import Notify from "../../../utils/Notification";
import "./styles.scss";
import { Edit } from "@material-ui/icons";

export default function DialogBlocoNotasView(props): JSX.Element {
	const theme = useTheme();
	const dialogBlocoNotas = useRef(null);
	const [editorValue, setEditorValue] = useState<EditorValue>(RichTextEditor.createEmptyValue());
	const [editorEstaVazio, setEditorEstaVazio] = useState<boolean | undefined>(undefined);
	const idUnidadeDisciplinaBlocoNotas = Number(
		localStorage.getItem("idUnidadeDisciplinaBlocoNotas") as string,
	);

	useEffect(() => {
		if (idUnidadeDisciplinaBlocoNotas !== 0) {
			getBlocoNotas(idUnidadeDisciplinaBlocoNotas.toString())
				.then((res) => {
					setEditorEstaVazio(false);
					setEditorValue(
						RichTextEditor.createValueFromString(res.data.bloco.texto, "markdown"),
					);
				})
				.catch(() => {
					Notify("Não há bloco de notas para a unidade referente!", "warning");
					setEditorEstaVazio(true);
				});
		}
	}, [idUnidadeDisciplinaBlocoNotas]);

	function retornarAcao() {
		if (editorEstaVazio === true) {
			postBlocoNotas(idUnidadeDisciplinaBlocoNotas.toString(), {
				texto: editorValue.toString("markdown"),
			})
				.then(() => {
					setEditorValue(RichTextEditor.createEmptyValue());
					localStorage.removeItem("idUnidadeDisciplinaBlocoNotas");
					props.dados.setEnableDialogBlocoNotas(false);
					Notify("O seu bloco de notas foi salvo!", "success");
				})
				.catch(() => {
					props.dados.setEnableDialogBlocoNotas(false);
					Notify("Erro ao salvar o seu bloco de notas! Tente novamente!", "error");
				});
		} else if (editorEstaVazio === false) {
			putBlocoNotas(idUnidadeDisciplinaBlocoNotas.toString(), {
				texto: editorValue.toString("markdown"),
			})
				.then(() => {
					setEditorValue(RichTextEditor.createEmptyValue());
					localStorage.removeItem("idUnidadeDisciplinaBlocoNotas");
					props.dados.setEnableDialogBlocoNotas(false);
					Notify("O seu bloco de notas foi atualizado!", "success");
				})
				.catch(() => {
					props.dados.setEnableDialogBlocoNotas(false);
					Notify("Erro ao atualizar o seu bloco de notas! Tente novamente!", "error");
				});
		}
	}

	return (
		<Dialog
			maxWidth={false}
			className='dialogBlocoNotas'
			open={props.dados.enableDialogBlocoNotas}
			onClose={() => {
				localStorage.removeItem("idUnidadeDisciplinaBlocoNotas");
				setEditorValue(RichTextEditor.createEmptyValue());
				props.dados.setEnableDialogBlocoNotas(false);
			}}
		>
			<div>
				<div className='body'>
					<Box className='head' style={{ backgroundColor: theme.palette.primary.main }}>
						<Edit />
						<Typography variant='body1'>MINHAS ANOTAÇÕES</Typography>
					</Box>

					<Box style={{ border: "none" }} className='editorBlocoNotas'>
						<RichTextEditor
							ref={dialogBlocoNotas}
							value={editorValue}
							placeholder='Digite aqui suas anotações...'
							editorStyle={{ height: "20em" }}
							onChange={(value) => {
								setEditorValue(value);
							}}
						/>
					</Box>

					<Box className='box-botoes'>
						<Box className='botao-salvar'>
							<Button
								style={{ backgroundColor: "#0A7541", color: "#FFFFFF" }}
								onClick={retornarAcao}
							>
								Salvar
							</Button>
						</Box>
					</Box>
				</div>
			</div>
		</Dialog>
	);
}
