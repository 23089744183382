import { CircularProgress, Fade, Typography } from "@material-ui/core";
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
// import OutrosEventos from './OutrosEventos';
import "./style.scss";
import { useEventosContext } from "../../../context/EventosPublicacoes/hooks/useEventos";
import Publicacoes from "./Publicacoes";
import OutrosEventos from "./OutrosEventos";

const FadeSpinner = ({ loading }) => (
    <div className="fadeSpinner" style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    }}>
        <Fade
            in={loading}
            style={{
                transitionDelay: loading ? "800ms" : "0ms"
            }}
            unmountOnExit
        >
            <CircularProgress />
        </Fade>
        <Typography style={{ marginTop: "10px" }}>
            Buscando Eventos Nacionais
        </Typography>
    </div >
)

function date(data_impressao: string) {
    let arrayData = data_impressao.split(" ");
    arrayData.splice(1, 1);
    arrayData = arrayData[0].split("-");
    return arrayData.reverse().join("/");
}

const Nacionais = ({ categorias, certificados
}) => (
    <List className='listNa ulListNa'>
        {
            // eslint-disable-next-line array-callback-return
            categorias.map((categoria, index) => {
                if (
                    categoria !== "Eventos Internacionais" &&
                    categoria !== "Curso Livre" &&
                    categoria !== "Lab"
                ) {
                    return (
                        <>
                            <div key={index} style={{ paddingLeft: "17px" }}>
                                <Typography className='subCategoria'>
                                    {categoria}
                                </Typography>
                            </div>
                            {
                                // eslint-disable-next-line array-callback-return
                                certificados.map((certificado, index) => {
                                    if (
                                        certificado.categoria !==
                                        "Eventos Internacionais" &&
                                        certificado.categoria !== "Curso Livre" &&
                                        certificado.categoria !== "Lab"
                                    ) {
                                        if (certificado.categoria === categoria) {
                                            return (
                                                <>
                                                    <ListItem
                                                        alignItems='flex-start'
                                                        // className='listaItems'
                                                        className='listItemPrimary'
                                                    >
                                                        <ListItemText
                                                            className='listItemPrimaryNa'
                                                            primary={String(
                                                                certificado.nome_curso,
                                                            )
                                                                .trim()
                                                                .replace(/^d[a,o]/, "")}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <div className='dadosEventosNa'>
                                                                        <Typography
                                                                            component='span'
                                                                            variant='body2'
                                                                            className='typographyTituloNa'
                                                                        >
                                                                            Realização:
                                                                            &nbsp;
                                                                        </Typography>
                                                                        <Typography
                                                                            component='span'
                                                                            variant='body2'
                                                                            color='textPrimary'
                                                                        >
                                                                            Centro
                                                                            Universitário
                                                                            Inta - UNINTA
                                                                        </Typography>
                                                                    </div>
                                                                    <div className='dadosEventosNa secondaryNa'>
                                                                        <div className='divSecondaryNa'>
                                                                            <Typography
                                                                                component='span'
                                                                                variant='body2'
                                                                                className='typographyTituloNa'
                                                                            >
                                                                                Certificação
                                                                                emitida em:
                                                                                &nbsp;
                                                                            </Typography>
                                                                            <Typography
                                                                                component='span'
                                                                                variant='body2'
                                                                                color='textPrimary'
                                                                            >
                                                                                {date(certificado.data_impressao,)}
                                                                            </Typography>
                                                                        </div>
                                                                        <div className='divSecondaryNa'>
                                                                            <Typography
                                                                                component='span'
                                                                                variant='body2'
                                                                                className='typographyTituloNa'
                                                                            >
                                                                                Carga
                                                                                horaria:
                                                                                &nbsp;
                                                                            </Typography>
                                                                            <Typography
                                                                                component='span'
                                                                                variant='body2'
                                                                                color='textPrimary'
                                                                            >
                                                                                {
                                                                                    certificado.carga_horaria
                                                                                }
                                                                            </Typography>
                                                                        </div>
                                                                        <div className='divSecondaryNa'>
                                                                            <Typography
                                                                                component='span'
                                                                                variant='body2'
                                                                                className='typographyTituloNa'
                                                                            >
                                                                                Local:
                                                                                &nbsp;
                                                                            </Typography>
                                                                            <Typography
                                                                                component='span'
                                                                                variant='body2'
                                                                                color='textPrimary'
                                                                            >
                                                                                Online
                                                                            </Typography>
                                                                        </div>
                                                                        <div className='divSecondaryNa'>
                                                                            <Typography
                                                                                component='span'
                                                                                variant='body2'
                                                                                className='typographyTituloNa'
                                                                            >
                                                                                Certificado:
                                                                                &nbsp;
                                                                            </Typography>
                                                                            <a
                                                                                href={`https://virtual.uninta.edu.br/mod/certificate/view.php?id=${certificado.id_modulo}&action=get`}
                                                                                target='_blank'
                                                                                rel='noreferrer'
                                                                                className='link'
                                                                            >
                                                                                <Typography
                                                                                    component='span'
                                                                                    variant='body2'
                                                                                    color='primary'
                                                                                >
                                                                                    Acesse
                                                                                    aqui
                                                                                </Typography>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItem>
                                                    <Divider
                                                        variant='inset'
                                                        component='li'
                                                    />
                                                </>
                                            );
                                        }
                                    }
                                })
                            }
                        </>
                    );
                }
            })
        }
        <OutrosEventos />
        <Publicacoes />
    </List>
)


export default function EventosNacionaisView(): React.ReactElement {
    const { certificados, categorias } = useEventosContext();
    const [loading,] = React.useState(true);
    const { requestSpinner } = useEventosContext();

    return (
        <div className='containerEventosNa'>
            <div className='divtypographyEventoNa'>
                <Typography variant='body1' className='typographyEventoNa'>
                    PARTICIPAÇÃO EM EVENTOS NACIONAIS
                </Typography>
                <div className='nacionais' style={{ padding: "12px", fontSize: "16px" }}>
                    🇧🇷
                </div>
            </div>
            {requestSpinner ?
                <Nacionais categorias={categorias} certificados={certificados} />
                : <FadeSpinner loading={loading} />
            }

        </div>
    );
}
