import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@material-ui/core";
import "./style.scss";
import DialogContribuicoes from "components/Dialogs/DialogContribuicoes";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DialogBlocoNotas from "components/Dialogs/DialogBlocoNotas";
import { getUnidadesDisciplina } from "services/TabelaDisciplinas";
import { UnidadeDisciplina } from "models/tabelaDisciplinas";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

export default function UnidadesDisciplinaView({ idDisciplinaCuradoria }): JSX.Element {
	const theme = useTheme();
	const [unidadesDisciplina, setUnidadesDisciplina] = useState<UnidadeDisciplina[]>([]);
	const [exibirUnidadesDisciplina, setExibirUnidadesDisciplina] = useState<boolean>(false);
	const [enableDialogBlocoNotas, setEnableDialogBlocoNotas] = useState<boolean>(false);
	const [enableDialogContribuicoes, setEnableDialogContribuicoes] = useState<boolean>(false);

	useEffect(() => {
		getUnidadesDisciplina(idDisciplinaCuradoria)
			.then((response) => {
				setUnidadesDisciplina(response.data);
				setExibirUnidadesDisciplina(true);
			})
			.catch(() => {
				setExibirUnidadesDisciplina(false);
			})
	}, [idDisciplinaCuradoria]);

	return (
		<>
			<Box margin={1}>
				<Typography component='h6' style={{ fontSize: "14px", fontWeight: "bold" }}>
					Unidades de Estudo
				</Typography>

				<Table size='small' aria-label='purchases'>
					<TableHead>
						<TableRow>
							<TableCell align='center'>Id</TableCell>
							<TableCell align='center'>Título</TableCell>
							<TableCell align='center'>Minhas Anotações</TableCell>
							<TableCell align='center'>Meus Arquivos</TableCell>
						</TableRow>
					</TableHead>

					{
						exibirUnidadesDisciplina ?

							<TableBody>
								{
									unidadesDisciplina.map((unidadeDisciplina) => {
										return (
											<TableRow
												key={unidadeDisciplina.id}
												className='rowUnidadesDisciplina'
											>
												<TableCell align='center'>{unidadeDisciplina.id}</TableCell>
												<TableCell
													align='center'
													style={{ textTransform: "uppercase" }}
												>

													{unidadeDisciplina.titulo_unidade}
												</TableCell>
												<TableCell align='center'>
													<Link
														to='/portfolio'
														onClick={() => {
															localStorage.setItem(
																"idUnidadeDisciplinaBlocoNotas",
																unidadeDisciplina.id.toString(),
															);
															setEnableDialogBlocoNotas(true);
														}}
														className='links'
														style={{ color: theme.palette.primary.main }}
													>
														Minhas Notas
													</Link>
												</TableCell>
												<TableCell align='center'>
													<Link
														to='/portfolio'
														onClick={() => {
															localStorage.setItem(
																"idUnidadeDisciplinaContribuicao",
																unidadeDisciplina.id.toString(),
															);
															setEnableDialogContribuicoes(true);
														}}
														className='links'
														style={{ color: theme.palette.primary.main }}
													>
														Meus Documentos
													</Link>
												</TableCell>
											</TableRow>
										);
									})
								}
							</TableBody>

							:
							<TableBody>
								<TableRow
									className='rowUnidadesDisciplina'
								>
									<TableCell align='center'>
										{
											<ReactPlaceholder type='text' rows={1} ready={exibirUnidadesDisciplina} showLoadingAnimation={true}>
												<></>
											</ReactPlaceholder>
										}
									</TableCell>

									<TableCell
										align='center'
										style={{ textTransform: "uppercase" }}
									>

										{
											<ReactPlaceholder type='text' rows={1} ready={exibirUnidadesDisciplina} showLoadingAnimation={true}>
												<></>
											</ReactPlaceholder>
										}
									</TableCell>

									<TableCell align='center'>
										{
											<ReactPlaceholder type='text' rows={1} ready={exibirUnidadesDisciplina} showLoadingAnimation={true}>
												<></>
											</ReactPlaceholder>
										}
									</TableCell>
									<TableCell align='center'>
										{
											<ReactPlaceholder type='text' rows={1} ready={exibirUnidadesDisciplina} showLoadingAnimation={true}>
												<></>
											</ReactPlaceholder>
										}
									</TableCell>
								</TableRow>
							</TableBody>

					}
				</Table>
			</Box>

			{
				enableDialogBlocoNotas ?

					<DialogBlocoNotas
						dados={{
							enableDialogBlocoNotas: enableDialogBlocoNotas,
							setEnableDialogBlocoNotas: setEnableDialogBlocoNotas,
						}}
					/> :
					<></>
			}

			{
				enableDialogContribuicoes ?

					<DialogContribuicoes
						dados={{
							enableDialogContribuicoes: enableDialogContribuicoes,
							setEnableDialogContribuicoes: setEnableDialogContribuicoes,
						}}
					/> :
					<></>
			}

		</>
	);
}
