import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Info from "@material-ui/icons/Info";
import Close from "@material-ui/icons/Close";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import DialogRequestConfirmation from "../DialogReviewQuestion/FormReviewQuestion/DialogRequestConfirmation";
import DialogScheduleConfirmation from "../DialogScheduleTime/DialogScheduleConfirmation";

import { DialogHistoricProps } from "./dialoghistoric.model";
import { getSolicitations } from "../DialogReviewQuestion/FormReviewQuestion/formreviewquestion.services";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import "./styles.scss";
import { getSchedules } from "../DialogScheduleTime/scheduletime.services";

export default function DialogHistoric(props: DialogHistoricProps): React.ReactElement {
	const { user } = useAuth();
	const { updateNumReviews, updateNumSchedule } = usePedagogicalCommunication();
	const colorsTheme = useTheme();

	const [dataFormat, setDataFormat] = React.useState<any[]>([]);
	const [openView, setOpenView] = React.useState(false);
	const [idSolicitation, setIdSolicitation] = React.useState("");

	React.useEffect(() => {
		if (props.open) {
			if (props.type === "review") {
				getSolicitations(user?.ra)
					.then((data) => {
						setDataFormat(
							data.data.map((dt) => {
								const colorIcon =
									dt.situacao === "deferido"
										? "#429a25"
										: dt.situacao === "indeferido"
										? "#aa1122"
										: "#346599";

								let date: any;
								let colorDate = "";

								date = dt.created_at.split("T")?.[0];
								date = date.split("-");
								date = `${date[2]}/${date[1]}/${date[0]}`;

								if (props.device === "mobile") {
									colorDate =
										dt.situacao === "deferido"
											? "finish"
											: dt.situacao === "indeferido"
											? "cancel"
											: "pendent";
								}

								return {
									id: dt.id,
									status: <FiberManualRecord style={{ color: colorIcon }} />,
									date: (
										<Box className={`area_date ${colorDate}`}>
											<Typography variant='subtitle2'>{date}</Typography>
										</Box>
									),
									subject: dt.justificativa,
									actions: (
										<IconButton
											onClick={() => {
												setIdSolicitation(dt.id);
												setOpenView(true);
											}}
										>
											<Info style={{ color: "#763273" }} />
										</IconButton>
									),
								};
							}),
						);
					})
					.catch((e) => {
						console.log(e);
					});
			}

			if (props.type === "schedule") {
				getSchedules(user?.ra)
					.then((data) => {
						setDataFormat(
							data.data.map((dt: any) => {
								const colorIcon =
									dt.situacao === "concluido"
										? "#429a25"
										: dt.situacao === "cancelado"
										? "#aa1122"
										: "#346599";

								let date: any;
								let colorDate = "";

								date = dt.created_at.split("T")?.[0];
								date = date.split("-");
								date = `${date[2]}/${date[1]}/${date[0]}`;

								if (props.device === "mobile") {
									colorDate =
										dt.situacao === "concluido"
											? "finish"
											: dt.situacao === "cancelado"
											? "cancel"
											: "pendent";
								}

								return {
									id: dt.id,
									status: <FiberManualRecord style={{ color: colorIcon }} />,
									date: (
										<Box className={`area_date ${colorDate}`}>
											<Typography variant='subtitle2'>{date}</Typography>
										</Box>
									),
									subject: dt.assunto_nome,
									actions: (
										<IconButton
											onClick={() => {
												setIdSolicitation(dt.id);
												setOpenView(true);
											}}
										>
											<Info style={{ color: "#763273" }} />
										</IconButton>
									),
								};
							}),
						);
					})
					.catch((e) => {
						console.log(e);
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open, updateNumReviews, updateNumSchedule]);

	return (
		<>
			<Dialog
				open={props.open}
				onClose={() => {
					props.setOpen(false);
				}}
				fullScreen={props.device === "mobile"}
				fullWidth={props.device === "desktop"}
				maxWidth='md'
			>
				<DialogTitle style={{ padding: 0 }}>
					<Box
						className='dialog_title'
						style={{ background: colorsTheme.palette.primary.main }}
					>
						<IconButton onClick={() => props.setOpen(false)}>
							<Close style={{ color: "#fff" }} />
						</IconButton>

						<Typography className='text_dialog_title'>{props.title}</Typography>
					</Box>
				</DialogTitle>

				<DialogContent>
					{dataFormat.length <= 0 ? (
						<Typography className='nullValue'>
							(Nenhum registro encontrado...)
						</Typography>
					) : (
						<>
							{props.device === "mobile" ? (
								<>
									{dataFormat.map((df, index) => {
										return (
											<Box
												key={index}
												className='line_record_item'
												onClick={() => {
													setIdSolicitation(df.id);
													setOpenView(true);
												}}
											>
												{df.date}

												<Box className='text_subject'>{df.subject}</Box>
											</Box>
										);
									})}
								</>
							) : (
								<DataTable
									paginator
									rows={10}
									emptyMessage='Nenhum registro encontrado...'
									resizableColumns
									value={dataFormat}
								>
									<Column field='status' style={{ width: "5%" }} />
									<Column
										header='Data Solicitação'
										field='date'
										style={{ width: "15%" }}
									/>
									<Column
										header='Assunto'
										field='subject'
										style={{ width: "70%" }}
									/>
									<Column
										header='Detalhes'
										field='actions'
										style={{ width: "10%" }}
									/>
								</DataTable>
							)}
						</>
					)}
				</DialogContent>
			</Dialog>

			{props.type === "review" ? (
				<DialogRequestConfirmation
					open={openView}
					setOpen={setOpenView}
					idSolicitation={idSolicitation}
					setIdSolicitation={setIdSolicitation}
				/>
			) : props.type === "schedule" ? (
				<DialogScheduleConfirmation
					open={openView}
					setOpen={setOpenView}
					idSchedule={idSolicitation}
				/>
			) : (
				<></>
			)}
		</>
	);
}
