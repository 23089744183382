import { List } from "@material-ui/core";
import { Vaga } from "models/vaga";
import ItemListaVagas from "./Components/ItemListaVagas";

interface PropsInterface {
	dados: {
		vagasBusca: Vaga[];
	};
}

export default function ListaVagasView({ dados }: PropsInterface): JSX.Element {
	return (
		<List className='lista-vagas'>
			{dados.vagasBusca.map((vaga) => {
				return (
					<>
						<ItemListaVagas vaga={vaga} />

						<hr />
					</>
				);
			})}
		</List>
	);
}
