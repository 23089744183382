import { AxiosResponse } from "axios";
import { ecossistema } from "services/api";

export const getCursos = async (ra: any) => {
	return await ecossistema.get(`nti/cursos/${ra}`).then((response: AxiosResponse<any>) => {
		return response.data;
	});
};

export const getHistorico = async (data: { ra: any; curso: any }) => {
	return await ecossistema
		.get(`/nti/historico/${data.ra}/cursos/${data.curso}`)
		.then((response: AxiosResponse<any>) => {
			return response.data;
		});
};

export const getGraphData = async (url: any | any[]) => {
	return await ecossistema
		.get(`avas/subjects/score?${url.join("&")}`)
		.then((r: AxiosResponse<any>) => {
			return r.data;
		});
};
