import { List } from "@material-ui/core";
import { Contribuicao } from "models/tabelaDisciplinas";
import ItemListaContribuicoes from "./Components/ItemListaContribuicoes";

interface PropType {
	contribuicoes: Contribuicao[];
}

export default function ListaContribuicoesView(props: PropType): JSX.Element {
	return (
		<List className='lista-contribuicoes'>
			{props.contribuicoes.map((contribuicao, index) => {
				return (
					<div style={{ marginBottom: "40px" }} key={index}>
						<ItemListaContribuicoes key={contribuicao.id} contribuicao={contribuicao} />
						<hr />
					</div>
				);
			})}
		</List>
	);
}
