import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { useParams } from "react-router";
import { LearningResourceInterface } from "components/LearningResources/resource.model";
// import SearchEngine from "components/SearchEngine";
// import IndexedResults from "components/IndexedResults";
// import { useSearch } from "context/search";
import SearchEngineV2 from "components/SearchEngineV2";
import { ecossistema } from "services/api";
import { motion, AnimatePresence } from "framer-motion";
import { apiLogsDoEcossistema } from "services/Logs";
import { useAuth } from "context/Auth/hook";
import { MATERIAL_DIDATICO } from "defaults/modules";
import { USUARIO_ACESSA_MAT_DIDAT } from "defaults/events";

const Container = React.lazy(() => import("@material-ui/core/Container"));
const Grid = React.lazy(() => import("@material-ui/core/Grid"));

const LearningResource = React.lazy(() => import("components/LearningResources"));
const Welcome = React.lazy(() => import("components/Welcome"));
// const Search = React.lazy(() => import("components/Search"));


export default function DisciplinaScreen(): React.ReactElement {
	const { id } = useParams<{ id: string }>();
	const { user } = useAuth();
	const [disc, setDisc] = useState<LearningResourceInterface>();
	// const { results } = useSearch();
	React.useEffect(() => {
		ecossistema.get(`avas/${id}`).then((res: AxiosResponse<any>) => {
			console.log(res);
			res.data.length > 0 ? setDisc(res.data[0]) : (window.location.href = "/");
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(()=>{
		apiLogsDoEcossistema.post('/',
			{
				ra: user.ra as string,
				module : MATERIAL_DIDATICO,
				evento: {		
					label : USUARIO_ACESSA_MAT_DIDAT,
					log_info: {
						disciplina: id,
						user_type: user.typeUser,
						page: `Página de disciplina ${id}`,
						description: `O usuário ${user.ra} do tipo ${user.typeUser} acessou a DISCIPLINA nos dias ${new Date().toLocaleDateString()}`,
					}
				},
			}
		)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return (
		<Container>
			<Grid container>
				<Grid item xs={12}>					
					<AnimatePresence>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0}}					
						>
							{disc 
								? <LearningResource {...disc} /> : <div style={{ height: "532px" }}></div>
							}
						</motion.div>
					</AnimatePresence>
				</Grid>

				<Grid item xs={12}>
					<Welcome />
				</Grid>

				<Grid item xs={12}>
					<SearchEngineV2 />
				</Grid>
			</Grid>
		</Container>
	);
}
