
export function phone(e: any) {
	e.target.minLength = 15;
	e.target.maxLength = 15;

	let value = e.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "($1) $2");
	value = value.replace(/(\d{5})(\d)/, "$1-$2");
	e.target.value = value;

	return value;
}

export function usPhoneMask(e: any) {
	e.target.minLength = 15;
	e.target.maxLength = 15;

	let value = e.target.value;

	const arrayStringIntermediario = e.target.value
		.replace(/\D/g, "")
		.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
	e.target.value = !arrayStringIntermediario[2]
		? arrayStringIntermediario[1]
		: "(" +
		  arrayStringIntermediario[1] +
		  ") " +
		  arrayStringIntermediario[2] +
		  (arrayStringIntermediario[3] ? "-" + arrayStringIntermediario[3] : "");

	value = e.target.value;

	return value;
}

export function ptPhoneMask(e: any) {
	let value = e.target.value;

	const arrayStringIntermediario = e.target.value
		.replace(/\D/g, "")
		.match(/(\d{0,2})(\d{0,3})(\d{0,4})/);

	e.target.value = !arrayStringIntermediario[2]
		? arrayStringIntermediario[1]
		: !arrayStringIntermediario[3]
		? arrayStringIntermediario[1] + "-" + arrayStringIntermediario[2]
		: arrayStringIntermediario[1] +
		  "-" +
		  arrayStringIntermediario[2] +
		  "-" +
		  arrayStringIntermediario[3];

	value = e.target.value;

	return value;
}

export function cepMask(e: any): string {
	const arrayStringIntermediario = e.target.value.replace(/\D/g, "").match(/(\d{0,5})(\d{0,3})/);

	e.target.value = !arrayStringIntermediario[2]
		? arrayStringIntermediario[1]
		: arrayStringIntermediario[1] + "-" + arrayStringIntermediario[2];

	const value = e.target.value;

	return value;
}

export function mobilePhoneMask(phone: any) {
	let value = phone;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "($1) $2");
	value = value.replace(/(\d{5})(\d)/, "$1-$2");

	return value;
}

export function stringPtMask(phone: any) {
	const arrayStringIntermediario = phone.replace(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,4})/);

	phone = !arrayStringIntermediario[2]
		? arrayStringIntermediario[1]
		: !arrayStringIntermediario[3]
		? arrayStringIntermediario[1] + "-" + arrayStringIntermediario[2]
		: arrayStringIntermediario[1] +
		  "-" +
		  arrayStringIntermediario[2] +
		  "-" +
		  arrayStringIntermediario[3];

	const value = phone;

	return value;
}

export function stringUSPhoneMask(phone: any) {
	const arrayStringIntermediario = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

	phone = !arrayStringIntermediario[2]
		? arrayStringIntermediario[1]
		: "(" +
		  arrayStringIntermediario[1] +
		  ") " +
		  arrayStringIntermediario[2] +
		  (arrayStringIntermediario[3] ? "-" + arrayStringIntermediario[3] : "");

	const value = phone;

	return value;
}

export function stringCepMask(cep: any) {
	const arrayStringIntermediario = cep.replace(/\D/g, "").match(/(\d{0,5})(\d{0,3})/);

	cep = !arrayStringIntermediario[2]
		? arrayStringIntermediario[1]
		: arrayStringIntermediario[1] + "-" + arrayStringIntermediario[2];

	const value = cep;

	return value;
}
