import { centralPedagogica } from "services/api";

import Notify from "utils/Notification";

import { StoreScheduleTimeProps } from "./scheduletime.model";

export async function getSchedules(ra: string | undefined, situacao = "") {
	return await centralPedagogica
		.get(`aluno/listarAgendamentos/${ra}/${situacao}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getNumSchedule(ra: string | undefined) {
	return await centralPedagogica
		.get(`contarAgendamentos/${ra}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			console.log(e);
		});
}

export async function getScheduleAluno(ra: string | undefined, id: string) {
	return await centralPedagogica
		.get(`aluno/verAgendamento/${ra}/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSubjects() {
	return await centralPedagogica
		.get("listarAssuntos")
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getTimesAvailable(subject: number, dayWeek: number) {
	if (!subject) {
		Notify("Selecione um assunto", "warning");
		return;
	}

	if (dayWeek < 0 || dayWeek > 6) {
		Notify("Dia informado é inválido", "warning");
		return;
	}

	return await centralPedagogica
		.get(`listarHorarios/${subject}/${dayWeek}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeScheduleEducationMentoring(props: StoreScheduleTimeProps) {
	const getData = props.data.toISOString().split("T")[0];
	const [idTime, time] = props.horario.split("T");

	let difficulties = "";

	const getDifficulties = props.dificuldades[0];

	// eslint-disable-next-line array-callback-return
	Object.keys(getDifficulties).map((dif) => {
		if (getDifficulties[dif].check) {
			difficulties += `${getDifficulties[dif].name}:`;
		}
	});

	return await centralPedagogica
		.post("aluno/criarAgendamento", {
			...props,
			assunto: props.assunto,
			horario: `${getData} ${time}`,
			link: idTime,
			dificuldades: difficulties ? difficulties : "Não",
			idade: props.idade ? props.idade : "0",
			telefone: props.telefone ? props.telefone : "0",
			medicamento: props.medicamento ? props.medicamento : "Não",
			diagnostico: props.diagnostico ? props.diagnostico : "Não",
			laudo: props.laudo ? props.laudo : "Não",
			queixa: props.queixa ? props.queixa : "Não",
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function deleteScheduleAluno(ra: string | undefined, id: number | undefined) {
	return await centralPedagogica
		.delete(`aluno/excluirAgendamento/${ra}/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
