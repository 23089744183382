/* eslint-disable array-callback-return */
import React from "react";
import { EventosContextType } from "./models/PropsEventosContext";
import { getCertificados } from "services/Certificados";
import { useAuth } from "context/Auth/hook";
import { CertificadosInterface } from "models/certificadosInterface";
import { INI_VALUES } from "./defaults";
export const EventosContext = React.createContext<EventosContextType>(INI_VALUES);

export const EventosPublicacoesProvider: React.FC = ({ children }) => {
	/**
	 * Contexto que contem informações necessarias para a requisição
	 */
	const { user } = useAuth();
	const [certificados, setCertificados] = React.useState<CertificadosInterface[]>([]);
	const [request, setRequest] = React.useState(INI_VALUES.request);
	const [requestSpinner, setRequestSpinner] = React.useState(INI_VALUES.requestSpinner);
	const [categorias, setCategorias] = React.useState<string[]>([]);

	React.useEffect(() => {
		const certf = localStorage.getItem("@certificados");
		const catg = localStorage.getItem("@categorias");

		if (certf && catg) {
			setCertificados(JSON.parse(certf));
			setCategorias(JSON.parse(catg));
			setRequestSpinner(true);
		} else {
			getCertificados(Number(user.ra))
				.then(({ data }) => {
					if (data.length <= 0) {
						setRequest(false);
					};
					const array = [] as string[];
					setCertificados(data);
					setRequestSpinner(true);
					/**
					 * map feito para pegarmos todas as categorias dos certificados e separarmos por Eventos incternacionais e nacionais
					 */
					data.map((certificado) => {
						if (
							!array.includes(certificado.categoria) &&
							certificado.categoria !== "Eventos Internacionais"
						) {
							array.push(certificado.categoria);
						}
					});

					/**
					 * Efeitos colaterais para fechar o BackDrop e confirmar se a requisisao foi feita corretamente
					 */
					setCategorias(array);
					localStorage.setItem("@certificados", JSON.stringify(data));
					localStorage.setItem("@categorias", JSON.stringify(array));
				})
				.catch(() => {
					setRequestSpinner(true);
					setRequest(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		getCertificados(Number(user.ra))
			.then(({ data }) => {
				if (data.length <= 0) {
					setRequest(false);
				};
				const array = [] as string[];
				setCertificados(data);
				setRequestSpinner(true);
				/**
				 * map feito para pegarmos todas as categorias dos certificados e separarmos por Eventos incternacionais e nacionais
				 */
				data.map((certificado) => {
					if (
						!array.includes(certificado.categoria) &&
						certificado.categoria !== "Eventos Internacionais"
					) {
						array.push(certificado.categoria);
					}
				});

				/**
				 * Efeitos colaterais para fechar o BackDrop e confirmar se a requisisao foi feita corretamente
				 */
				setCategorias(array);
				localStorage.setItem("@certificados", JSON.stringify(data));
				localStorage.setItem("@categorias", JSON.stringify(array));
			})
			.catch(() => {
				setRequestSpinner(true);
				setRequest(false);
			});
	}, [user.ra]);

	return (
		<EventosContext.Provider
			value={{
				certificados,
				setCertificados,
				request,
				setRequest,
				requestSpinner,
				setRequestSpinner,
				categorias,
				setCategorias,
			}}
		>
			{children}
		</EventosContext.Provider>
	);
};
