import { Box, CircularProgress, Fade, Typography } from "@material-ui/core";
import React from "react";
import PublicIcon from "@material-ui/icons/Public";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import "./style.scss";
import { useEventosContext } from "../../../context/EventosPublicacoes/hooks/useEventos";


const FadeSpinner = ({ loading }) => (
	<div className="fadeSpinner" style={{
		width: "100%",
		minHeight: "320px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	}}>
		<Fade
			in={loading}
			style={{
				transitionDelay: loading ? "800ms" : "0ms"
			}}
			unmountOnExit
		>
			<CircularProgress />
		</Fade>
		<Typography style={{ marginTop: "10px" }}>
			Buscando Eventos Internacionais
		</Typography>
	</div >
)

function date(data_impressao: string) {
	let arrayData = data_impressao.split(" ");
	arrayData.splice(1, 1);
	arrayData = arrayData[0].split("-");
	return arrayData.reverse().join("/");
}

function certificadosErro(certificados) {
	if (certificados.length > 0) {
		return (
			// eslint-disable-next-line array-callback-return
			certificados.map((certificado, index) => {
				if (certificado.categoria === "Eventos Internacionais") {
					return (
						<>
							<ListItem className='listItemPrimary' alignItems='flex-start'>
								<ListItemText
									className='listItemPrimaryNa'
									primary={String(certificado.nome_curso)
										.trim()
										.replace(/^d[a,o]/, "")}
									secondary={
										<React.Fragment>
											<div className='dadosEventosNa'>
												<Typography
													component='span'
													variant='body2'
													className='typographyTituloIn'
												>
													Realização: &nbsp;
												</Typography>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
												>
													Centro Universitário Inta - UNINTA
												</Typography>
											</div>
											<div className='dadosEventosIn secondaryIn'>
												<div>
													<Typography
														component='span'
														variant='body2'
														className='typographyTituloIn'
													>
														Certificação emitida em: &nbsp;
													</Typography>
													<Typography
														component='span'
														variant='body2'
														color='textPrimary'
													>
														{date(certificado.data_impressao)}
													</Typography>
												</div>
												<div className='divSecondaryNa'>
													<Typography
														component='span'
														variant='body2'
														className='typographyTituloIn'
													>
														Carga horaria: &nbsp;
													</Typography>
													<Typography
														component='span'
														variant='body2'
														color='textPrimary'
													>
														{certificado.carga_horaria}
													</Typography>
												</div>
												<div className='divSecondaryNa'>
													<Typography
														component='span'
														variant='body2'
														className='typographyTituloIn'
													>
														Local: &nbsp;
													</Typography>
													<Typography
														component='span'
														variant='body2'
														color='textPrimary'
													>
														Online
													</Typography>
												</div>
												<div className='divSecondaryNa'>
													<Typography
														component='span'
														variant='body2'
														className='typographyTituloIn'
													>
														Certificado: &nbsp;
													</Typography>
													<a
														href={`https://virtual.uninta.edu.br/mod/certificate/view.php?id=${certificado.id_modulo}&action=get`}
														target='_blank'
														rel='noreferrer'
														className='link'
													>
														<Typography
															component='span'
															variant='body2'
															color='primary'
														>
															Acesse aqui
														</Typography>
													</a>
												</div>
											</div>
										</React.Fragment>
									}
								/>
							</ListItem>
							<Divider variant='inset' component='li' />
						</>
					);
				}
			})
		);
	} else {
		return (
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "30vh",
					margin: "20px",
					padding: "20px",
				}}
			>
				<Typography variant='h5'>
					Certificados não encontrados
				</Typography>
			</Box>
		);
	}
}

const Internacionais = ({ certificados }) => (
	<List className='listIn'>
		{certificadosErro(certificados)}
	</List>
)

export default function EventosInternacionaisView(): React.ReactElement {
	const { certificados } = useEventosContext();
	const [loading,] = React.useState(true);
	const { requestSpinner } = useEventosContext();

	return (
		<div className='containerEventosIn'>
			<div className='divtypographyEventoIn'>
				<Typography variant='body1' className='typographyEventoIn'>
					PARTICIPAÇÃO EM EVENTOS INTERNACIONAIS
				</Typography>
				<div className='internacionais' style={{ padding: "12px", fontSize: "16px" }}>
					<PublicIcon />
				</div>
			</div>

			{requestSpinner ?
				<Internacionais certificados={certificados} />
				: <FadeSpinner loading={loading} />
			}
		</div>
	);
}
