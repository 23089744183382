import React from "react";
import { Box } from "@material-ui/core";

import "./styles.scss";

/**
 *
 * @returns Placeholder para o card de disciplina
 */
export default function CardMock(): React.ReactElement {
	return (
		<div className='card'>
			<div className='cover linear-background' />
			<Box
				display='flex'
				flexDirection='column'
				justifyContent='space-between'
				alignItems='center'
			>
				<div
					className='item linear-background'
					style={{ width: "100px", height: "24px" }}
				/>
				<div
					className='item linear-background'
					style={{ width: "210px", height: "24px" }}
				/>
				<div className='item linear-background' style={{ width: "70px", height: "24px" }} />
				<div
					className='item linear-background'
					style={{ width: "210px", height: "24px" }}
				/>
				<div className='item linear-background' style={{ width: "70px", height: "24px" }} />
			</Box>
		</div>
	);
}
