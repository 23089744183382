import React from "react";
import { Grid, Typography, TextField, Button, FormControl, useTheme, makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useModalContext } from "context/modal/hooks/useModal";
import { useForm } from "react-hook-form";
import { postEvents } from "services/OutrosEventos";
import Notify from "../../../../utils/Notification";
import { usePortfolio } from "context/Portfolio";
import { useOutrosEventosContext } from "context/OutrosEventos/hooks/useOutrosEventos";

export default function FormularioCadastroOutrosEventosView(): React.ReactElement {
	const { register, handleSubmit, setValue, formState: { errors }, } = useForm({ mode: "onSubmit" });
	const { handleCloseCadastro, handleRefresh } = useModalContext();
	const theme = useTheme();
	const { state } = usePortfolio();
	const [disabled, setDisabled] = React.useState(false);
	const { addOutrosEventos } = useOutrosEventosContext();

	const useStyles = makeStyles((theme) => ({
		root: {
			color: theme?.palette.secondary.main,
		},
		uninta: {
			color: "#bd0e0e",
		},
		select: {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey ",
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey !important",
			},
			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: "black !important",
			},
		},
		inputLabel: {
			"&.Mui-focused": {
				color: "black !important",
			},
		},
		textField: {
			"& label.Mui-focused": {
				color: "black",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));

	const classes = useStyles();

	async function cadastroOutrosEventos(data) {
		console.log("🚀 ~ data", data)
		if (data.end_date < data.start_date) {
			Notify("Atenção, a data de início deve anteceder a data de encerramento!", "warning");
			return;
		} else {
			setDisabled(true);
			postEvents(data).then(({ data }) => {
				if (data !== undefined) {
					addOutrosEventos(data)
					Notify("Evento cadastrado com sucesso!");
					handleCloseCadastro();
					handleRefresh();
				} else {
					Notify("Evento não foi cadastrado!", "error");
					handleCloseCadastro();
					handleRefresh();
				}
			}).catch((e) => {
				Notify("Evento não foi cadastrado!", "error");
				handleCloseCadastro();
				handleRefresh();
			});
		}
	}

	function Integer(v) {
		return v.replace(/\D/g, "");
	}


	return (
		<form
			onSubmit={handleSubmit((data) =>
				cadastroOutrosEventos({
					name: data.name,
					promoted: data.promoted,
					start_date: data.start_date,
					end_date: data.end_date,
					workload: data.workload,
					local: data.local,
					profile_id: state.id,
				}),
			)}
		>
			<Box mt={3} mb={3}>
				<Typography variant='body1'>DADOS CADASTRAIS</Typography>
			</Box>
			<Box mt={3}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<FormControl size='small' fullWidth>
							<TextField
								className={classes.textField}
								label='Nome de Evento'
								variant='outlined'
								style={{
									width: "100%",
								}}
								inputProps={{
									maxLength: 40,
								}}
								{...register("name", { required: true })}
								error={errors.name?.type === "required"}
								helperText={
									errors.name?.type === "required" &&
									"O campo 'Nome de Evento' é obrigatório"
								}
							/>
						</FormControl>
						<FormControl size='small' fullWidth>
							<TextField
								className={classes.textField}
								label='Realização'
								variant='outlined'
								style={{
									width: "100%",
								}}
								{...register("promoted", { required: true })}
								error={errors.promoted?.type === "required"}
								helperText={
									errors.promoted?.type === "required" &&
									"O campo 'Realização' é obrigatório"
								}
							/>
						</FormControl>
						<div
							style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
						>
							<FormControl size='small' fullWidth style={{ marginRight: "5px" }}>
								<TextField
									className={classes.textField}
									label='Data inicial'
									variant='outlined'
									type='date'
									style={{
										width: "100%",
									}}
									InputLabelProps={{
										shrink: true,
									}}
									{...register("start_date", { required: true })}
									error={errors.start_date?.type === "required"}
									helperText={
										errors.start_date?.type === "required" &&
										"O campo 'Realização' é obrigatório"
									}
								/>
							</FormControl>
							<FormControl size='small' fullWidth>
								<TextField
									className={classes.textField}
									label='Data final'
									variant='outlined'
									type='date'
									style={{
										width: "100%",
									}}
									InputLabelProps={{
										shrink: true,
									}}
									{...register("end_date", { required: true })}
									error={errors.end_date?.type === "required"}
									helperText={
										errors.end_date?.type === "required" &&
										"O campo 'Realização' é obrigatório"
									}
								/>
							</FormControl>
						</div>
						<FormControl size='small' fullWidth>
							<TextField
								className={classes.textField}
								label='Carga horaria'
								placeholder="Em horas"
								variant='outlined'
								style={{
									width: "100%",
								}}
								{...register("workload", { required: true })}
								onChange={(e) => { setValue("workload", Integer(e.target.value)) }}
								error={errors.workload?.type === "required"}
								helperText={
									errors.workload?.type === "required" &&
									"O campo 'Carga horaria' é obrigatório"
								}
							/>
						</FormControl>
						<FormControl size='small' fullWidth>
							<TextField
								className={classes.textField}
								label='Local'
								variant='outlined'
								style={{
									width: "100%",
								}}
								{...register("local", { required: true })}
								error={errors.local?.type === "required"}
								helperText={
									errors.local?.type === "required" &&
									"O campo 'Local' é obrigatório"
								}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Box>
							<Button
								style={{
									backgroundColor: theme.palette.primary.main,
									color: "white",
									width: "100%",
								}}
								disableElevation
								variant='contained'
								type={"submit"}
								disabled={disabled}
							>
								Cadastrar{" "}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
}
