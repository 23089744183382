export default function reducer(state, action) {
    switch (action.type) {
        case 'INITIALIZING':
            return action.payload;
        case "ADD":
            return {
                ...state,
                publicacoes: [...state.publicacoes, action.payload],
            };
        case "DELETE":
            return {
                ...state,
                publicacoes: state.publicacoes.filter((publicacao) => publicacao.id !== action.payload),
            };
        case "UPDATE":
            const updatedPublicacao = action.payload;

            const updatedPublicacoes = state.publicacoes.map((publicacao) => {
                if (publicacao.id === updatedPublicacao.id) {
                    return updatedPublicacao;
                }
                return publicacao;
            });
            return {
                ...state,
                publicacoes: updatedPublicacoes,
            };
    }
}