import { Contribuicao } from "models/tabelaDisciplinas";
import ListaContribuicoesView from "./ListaContribuicoes.view";

interface PropType {
	contribuicoes: Contribuicao[];
}

export default function ListaContribuicoes(props: PropType): JSX.Element {
	return <ListaContribuicoesView contribuicoes={props.contribuicoes} />;
}
