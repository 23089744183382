import React, { createContext, useContext, Dispatch } from "react";

type PedagogicalCommunicationContextProps = {
	updateNumQuestions: boolean;
	updateNumAnswers: boolean;
	reloadListQuestions: boolean;
	updateNumReviews: boolean;
	updateNumSchedule: boolean;
	setUpdateNumQuestions: Dispatch<React.SetStateAction<boolean>>;
	setUpdateNumAnswers: Dispatch<React.SetStateAction<boolean>>;
	setReloadListQuestions: Dispatch<React.SetStateAction<boolean>>;
	setUpdateNumReviews: Dispatch<React.SetStateAction<boolean>>;
	setUpdateNumSchedule: Dispatch<React.SetStateAction<boolean>>;
};

const INI_VALUES: PedagogicalCommunicationContextProps = {
	updateNumQuestions: false,
	updateNumAnswers: false,
	reloadListQuestions: false,
	updateNumReviews: false,
	updateNumSchedule: false,
	setUpdateNumQuestions: () => {},
	setUpdateNumAnswers: () => {},
	setReloadListQuestions: () => {},
	setUpdateNumReviews: () => {},
	setUpdateNumSchedule: () => {},
};

export const PedagogicalCommunicationContext = createContext(INI_VALUES);

export const PedagogicalCommunicationProvider: React.FC = ({ children }) => {
	const [updateNumQuestions, setUpdateNumQuestions] = React.useState(false);
	const [updateNumAnswers, setUpdateNumAnswers] = React.useState(false);
	const [reloadListQuestions, setReloadListQuestions] = React.useState(false);

	const [updateNumReviews, setUpdateNumReviews] = React.useState(false);
	const [updateNumSchedule, setUpdateNumSchedule] = React.useState(false);

	return (
		<PedagogicalCommunicationContext.Provider
			value={{
				updateNumQuestions,
				updateNumAnswers,
				reloadListQuestions,
				updateNumReviews,
				updateNumSchedule,
				setUpdateNumQuestions,
				setUpdateNumAnswers,
				setReloadListQuestions,
				setUpdateNumReviews,
				setUpdateNumSchedule,
			}}
		>
			{children}
		</PedagogicalCommunicationContext.Provider>
	);
};

export function usePedagogicalCommunication(): PedagogicalCommunicationContextProps {
	const context = useContext(PedagogicalCommunicationContext);
	return context;
}
