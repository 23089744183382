import axios from "axios";

export const ecossistema = axios.create({
	baseURL: process.env.REACT_APP_API_ECOSSISTEMA,
});

export const centralPedagogica = axios.create({
	baseURL: process.env.REACT_APP_API_CENTRAL_PEDAGOGICA,
	// baseURL: "http://10.0.29.116:8008/api"
	// baseURL: "https://api-centralp.uninta.edu.br/api"
});

export const blocoNotasArquivos = axios.create({
	baseURL: process.env.REACT_APP_API_BLOCO_NOTAS_ARQUIVOS,
	// baseURL: "http://10.0.254.82:5555/"
});

export const portfólio = axios.create({
	baseURL: process.env.REACT_APP_API_PORTFOLIO_URL,
});
export const eventosVirtuais = axios.create({
	baseURL: process.env.REACT_APP_API_EVENTOS_VIRTUAIS,
})

blocoNotasArquivos.interceptors.request.use((config) => {
	let user: any = sessionStorage.getItem("@Application:User");
	let token: any = localStorage.getItem("token");

	if (user && token && config.headers) {
		user = JSON.parse(user);
		token = JSON.parse(token);

		config.headers["Authorization"] = `Bearer ${token}`;
		config.headers["ra_aluno"] = user?.ra;
	}

	return config;
});
