import React from "react";

// Adicionando importações do Material UI 4

import { Avatar, Card, CardContent, CardHeader, Typography } from "@material-ui/core";

// Importando estilos

import "./styles.scss";

// Importando interfaces
import { cardInterface } from "./card.model";
import { formatarTexto } from "utils/Oportunidades/formatarNome";

export default function CardDepoimentoView({ depoimento }: cardInterface): React.ReactElement {
	return (
		<Card className='card-depoimento'>
			<CardHeader
				avatar={
					// <Avatar alt={depoimento.avatar} />
					<Avatar style={{ width: "45px", height: "45px" }}>R</Avatar>
				}
				title={formatarTexto(depoimento.nome)}
				subheader={
					<>
						<span id='subtitulo01'>{formatarTexto(depoimento.curso_nome)}</span>
						<br />
						<span id='subtitulo02'>{depoimento.cidade}</span>
					</>
				}
			/>
			<CardContent style={{ paddingTop: "0px" }}>
				<Typography
					className='texto-depoimento'
					variant='body2'
					color='textSecondary'
					component='p'
				>
					{depoimento.depoimento}
				</Typography>
			</CardContent>
		</Card>
	);
}
