import React, { useEffect, useState } from "react";
// import Banner from "../BannerTeste";
import Banner from "../Banner";
import { Box, Button, CircularProgress, Container, Grid, makeStyles, MenuItem, TextField, Typography, useTheme } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import { useTabelaDisciplinas } from "context/tabelaDisciplinas";
import { getCursos } from "context/Auth/services";
import { ecossistema } from "services/api";
import { useAuth } from "context/Auth/hook";
import TabelaDisciplinas from "components/Tabelas/TabelaDisciplinas";
import { Controller, useForm } from "react-hook-form";
import "./style.scss";

export default function BlocoNotasArquivosView(): React.ReactElement {
	const theme = useTheme();
	const { user } = useAuth();
	const { getValues, control, setValue } = useForm();
	const [cursos, setCursos] = useState<any[]>();
	const [showBlocoDeNotas, setShowBlocoDeNotas] = useState<boolean>(false);
	const [showConteudoNaoDisponivel, setShowConteudoNaoDisponivel] = useState<boolean>(false);
	const { setExibirTabelaDisciplinas, setCodigoCurso, setOpenGeral } = useTabelaDisciplinas();
	const useStyles = makeStyles((theme) => ({
		root: {
			color: theme?.palette.secondary.main,
		},
		uninta: {
			color: "#bd0e0e",
		},
		textField: {
			"& label.Mui-focused": {
				color: "black",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));

	const classes = useStyles();

	useEffect(() => {

		getCursos(user.ra, ecossistema)
			.then(({ data }) => {
				setCursos(data);
				setValue("curso", data[0].codigo_curso);
				setCodigoCurso(data[0].codigo_curso);
				setShowBlocoDeNotas(true);
				setShowConteudoNaoDisponivel(false);
			})
			.catch(() => {
				setShowBlocoDeNotas(false);
				setShowConteudoNaoDisponivel(true);
			})

	}, [user.ra, setCodigoCurso, setValue]);

	return (
		<Box style={{ backgroundColor: "#f5f5f5" }}>
			<Container maxWidth='xl' style={{ marginTop: "5vh", marginBottom: "5vh" }}>
				<Grid container spacing={2}>
					<Grid item md={2}>
						<Banner />
					</Grid>

					<Grid item xs={12} md={10}>
						{
							showBlocoDeNotas
								? (
									<Box className='box-content' component='div'>

										<Box className='box-busca' component='div'>

											<Controller
												control={control}
												name="curso"
												render={({ field: { value, onChange } }) => (
													<TextField
														className={classes.textField}
														select
														size='small'
														label='Cursos disponíveis'
														fullWidth
														variant='outlined'
														value={getValues("curso") ? value : ""}
														style={{
															flexGrow: 1,
														}}
														onChange={
															(e) => {
																onChange(e);
																setExibirTabelaDisciplinas(true);
																setCodigoCurso(e.target.value);
																setOpenGeral(false);
															}
														}
													>
														{
															cursos?.map((curso) => {
																return (
																	<MenuItem
																		key={curso.idperlet}
																		value={curso.codigo_curso}
																		style={{
																			fontSize: window.innerWidth <= 959 ? "12px" : "14px"
																		}}
																	>
																		{curso.nome_curso}
																	</MenuItem>
																)
															})
														}
													</TextField>
												)}
											/>

										</Box>

										<Box className='box-botoes' component='div'>
											<Button
												style={{
													backgroundColor: theme.palette.primary.main,
													color: "white",
													fontSize: window.innerWidth <= 959 ? "12px" : "14px"
												}}
												onClick={() => {
													setOpenGeral(false);
												}}
											>
												<Remove style={{ fontSize: window.innerWidth <= 959 ? "12px" : "16px" }} />
												Recolher todas
											</Button>
										</Box>

										<TabelaDisciplinas />

									</Box>)
								: (
									<Box style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										minHeight: "90vh",
										maxHeight: "90vh",
										backgroundColor: "#fff",
										borderRadius: "4px"
									}}>
										{
											showConteudoNaoDisponivel ?

												<Typography variant="h5">Nenhum conteúdo disponível</Typography>

												:

												<CircularProgress style={{ color: theme.palette.primary.main }} />
										}
									</Box>
								)
						}
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
