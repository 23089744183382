import React from "react";
import {
	AppBar,
	Box,
	Button,
	CircularProgress,
	Dialog,
	IconButton,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import EventosPublicacoes from "pages/Portfolio/EventosPublicacoes";
import BlocoNotasArquivos from "pages/Portfolio/BlocoNotasArquivos";
import EstagiosProjetosNotas from "pages/Portfolio/EstagiosProjetosNotas";
import { useNavigate } from "react-router-dom";

import "./nav.scss";
import "./index.scss";

// TODO: Remover o comentário da linha abaixo para carregar o Core Next
// import CoreNextApp from "core";

const PV1 = React.lazy(() => import("pages/Portfolio"));

/**
 *
 * @returns {JSX.Element}
 * @description Retorna a nova tela de Portfolio
 */
export default function Portfolio() {
	const [id, setId] = React.useState<number>(0); // Alterna a exibição do portfólio para  a aba selecionada
	// const { user } = useAuth();
	const history = useNavigate();
	// Retorna as diferentes views do portfólio
	// useEffect(()=>{
	// 	apiLogsDoEcossistema.post('/',
	// 		{
	// 			ra: user.ra as string,
	// 			module : PORTFOLIO,
	// 			evento: {		
	// 				label : USUARIO_ACESSA_PORTFOLIO,
	// 				log_info: {
	// 					disciplina: id,
	// 					page: `Página do portfólio`,
	// 					description: `O usuário ${user.ra} do tipo ${user.typeUser} acessou o PORTFOLIO no dia ${new Date().toLocaleDateString()}`,
	// 					date: new Date(),
	// 				}		
	// 			},
	// 		}
	// 	)
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// },[])
	
	function getPortfolioScreen(): JSX.Element {
		switch (id) {
			case 0:
				return <PV1 />;
			case 1:
				return <EstagiosProjetosNotas />;
			case 2:
				return <EventosPublicacoes />;
			case 3:
				return <BlocoNotasArquivos />;
			default:
				return (
					<Box className='informative'>
						<CircularProgress />
						<Box mt={3}>
							<Typography variant='h5'>Tela não encontrada</Typography>
						</Box>
					</Box>
				);
		}
	}
	
	return (
		<React.Fragment>
			<Dialog fullScreen open={true}>
				<AppBar elevation={0} color='transparent' position='static'>
					<Toolbar>
						<Box
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-evenly",
								width: "100%",
							}}
						>
							<Box style={{ flexGrow: 1 }} >
								<IconButton
									onClick={() => {
										history("/");
										// window.location.href = "/";
									}}
								>
									<ArrowBack></ArrowBack>
								</IconButton>
							</Box>
							<Box className='nav-link'>
								<Button
									className={`nav-link-item ${id === 0 ? "active" : ""}`}
									variant='text'
									onClick={() => {
										setId(0);
									}}
								>
									Perfil
								</Button>
								<Button
									className={`nav-link-item ${
										id === 1 ? "active" : ""
									} estagios-projetos-notas`}
									variant='text'
									onClick={() => {
										setId(1);
									}}
								>
									Estágio, Projetos e Notas
								</Button>
								<Button
									className={`nav-link-item ${
										id === 2 ? "active" : ""
									} eventos-publicacoes`}
									variant='text'
									onClick={() => {
										setId(2);
									}}
								>
									Eventos e Publicações
								</Button>
								<Button
									className={`nav-link-item ${
										id === 3 ? "active" : ""
									} bloco-notas-arquivos`}
									variant='text'
									onClick={() => {
										setId(3);
									}}
								>
									Bloco de Notas
								</Button>
								{/* <Button className={`nav-link-item ${id === 4 ? "active" : ""}`} variant="text" onClick={() => { setId(4)}}>Core/Next</Button> */}
							</Box>
							<span style={{ flexGrow: "1" }}></span>
						</Box>
					</Toolbar>
				</AppBar>
				{getPortfolioScreen()}
			</Dialog>
		</React.Fragment>
	);
}
