import { AxiosResponse } from "axios";
import { Publicacoes } from "models/publicacoes";

import { portfólio } from "services/api";
// const BASE_URL = "http://10.0.254.82:7999/api/alunos/v1";

export async function postPublications(data): Promise<AxiosResponse<Array<any>>> {
	return await portfólio.post(`/api/alunos/v1/publications`, data)
}

export async function getPublicationsProfile(idProfile): Promise<AxiosResponse<Array<any>>> {
	return await portfólio.get(`/api/alunos/v1/publications/profile/${idProfile}`)
}

export async function getPublication(idPublication): Promise<AxiosResponse<Publicacoes>> {
	return await portfólio.get(`/api/alunos/v1/publications/${idPublication}`)
}

export async function putPublication(idPublication, data): Promise<AxiosResponse<Array<any>>> {
	return await portfólio.put(`/api/alunos/v1/publications/${idPublication}`, data)
}

export async function deletePublication(idPublication): Promise<AxiosResponse<Array<any>>> {
	return await portfólio.delete(`/api/alunos/v1/publications/${idPublication}`)
}
