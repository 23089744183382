import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DateRangeIcon from "@material-ui/icons/DateRangeTwoTone";
import Divider from "@material-ui/core/Divider";
import "./styles.scss";
import { getSubject } from "../../services/calendar.request";

interface listasProps {
	setId: (e: number) => void;
	reload: boolean;
}

export default function Listas(props: listasProps): any {
	const [subjects, setSubjects] = React.useState<
		undefined | { id: number; titulo: string; pendencias: 0 }[]
	>(undefined);

	React.useEffect(() => {
		getSubject()
			.then((data) => {
				setSubjects(data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [props.reload]);

	return (
		<section className='lista'>
			<List component='nav' aria-label='main mailbox folders'>
				<ListItem
					button
					selected={true}
					onClick={() => {
						props.setId(0);
					}}
				>
					<ListItemText primary='Calendários por assuntos' />
				</ListItem>
			</List>
			<Divider />
			<List component='nav'>
				{subjects?.map((lista: any) => {
					return (
						<ListItem
							key={lista?.id}
							button
							onClick={() => {
								props.setId(lista.id);
							}}
						>
							<ListItemIcon>
								<DateRangeIcon />
							</ListItemIcon>
							<ListItemText
								primary={lista?.titulo}
								secondary={
									lista.pendentes > 0
										? lista.pendentes === 1
											? `${lista.pendentes} agendamento pendente`
											: `${lista.pendentes} agendamentos pendentes`
										: "Sem agendamentos"
								}
							/>
						</ListItem>
					);
				})}
			</List>
		</section>
	);
}
