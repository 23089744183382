import React, { Dispatch, SetStateAction } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Hidden from "@material-ui/core/Hidden";
import "./index.scss";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import SeccaoDepoimentos from "../../Oportunidades/SeccoesOportunidades/SeccaoDepoimentos";
import SeccaoTrilhasAprendizagem from "../../Oportunidades/SeccoesOportunidades/SeccaoTrilhasAprendizagem";
import SeccaoOficinaCurriculo from "../../Oportunidades/SeccoesOportunidades/SeccaoOficinaCurriculo";
import SeccaoVagas from "../../Oportunidades/SeccoesOportunidades/SeccaoVagas";

interface DialogOportunidadesProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function DialogOportunidadesView(
	props: DialogOportunidadesProps,
): React.ReactElement {
	return (
		<Dialog
			open={props.open}
			onClose={() => {
				props.setOpen(!props.open);
			}}
			fullScreen
		>
			<Hidden mdDown>
				<Header setOpen={props.setOpen} />
			</Hidden>

			<Hidden lgUp>
				<HeaderMobile setOpen={props.setOpen} />
			</Hidden>

			<DialogContent className="containerOportunidades">
				<SeccaoDepoimentos />
				<SeccaoTrilhasAprendizagem />
				<SeccaoOficinaCurriculo />
				<SeccaoVagas />
			</DialogContent>
		</Dialog>
	);
}
