import { ModalContextProvider } from "context/modal/modal.context";
import { PublicacoesProvider } from "context/Publicacoes/publicacoes.context";
import React from "react";
import PublicacoesView from "./Publicacoes.view";

export default function Publicacoes(): React.ReactElement {
	return (
		<ModalContextProvider>
			<PublicacoesProvider>
				<PublicacoesView/>
			</PublicacoesProvider>

		</ModalContextProvider>
	);
}
