import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface BuscaInterface {
	resultados: any[] | null;
	metadados: any[] | null;
	status: "loading" | "idle" | "done";
}

export const initialState: BuscaInterface = {
	resultados: null,
	metadados: null,
	status: "idle",
};

export const buscaSlice = createSlice({
	name: "busca",
	initialState: initialState,
	reducers: {
		setResultados: (state, action) => {
			state.resultados = action.payload;
		},
		setMetadados: (state, action) => {
			state.metadados = action.payload.meta;
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
	},
});

export const { setResultados, setMetadados, setStatus } = buscaSlice.actions;

export const selectBusca = (state: RootState) => state.busca;

export default buscaSlice.reducer;
