import React from "react";
import EventosPublicacoesView from "./EventosPublicacoes.view";
import { EventosPublicacoesProvider } from "../../../context/EventosPublicacoes/eventos.context";

export default function EventosPublicacoes(): React.ReactElement {
	return (
		<EventosPublicacoesProvider>
			<EventosPublicacoesView />
		</EventosPublicacoesProvider>
	);
}
