/**
 * @function formatarTexto
 * Transforma a primeira letra de cada parte do nome recebido em maiúscula e retorna o nome completo formatado.
 * @param {string} nome - Nome completo.
 */

export function formatarTexto(nome: string) {
	let arrayPartesDoNome = nome.toLocaleLowerCase().split(" ");

	arrayPartesDoNome = arrayPartesDoNome.map((parteDoNome) => {
		if (parteDoNome.length > 2) {
			parteDoNome = parteDoNome.replace(parteDoNome[0], parteDoNome[0].toUpperCase());
		}
		return parteDoNome;
	});

	return arrayPartesDoNome.join(" ");
}
