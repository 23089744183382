import { Dialog } from "@material-ui/core";
// import { ArrowBack } from "@material-ui/icons";
import CardTutorialMobile from "components/CardTutorialMobile";
import { EtapaTutorialMobile } from "components/CardTutorialMobile/card.model";
import { useState } from "react";
// import { Carousel } from "primereact/carousel";
import "./styles.scss";

export default function DialogTutorialMobileView({ open, setOpen }): JSX.Element {
	const [etapa, setEtapa] = useState<number>(0);

	const etapasTutorialMobile: EtapaTutorialMobile[] = [
		{
			titulo: "Vamos iniciar a sua jornada!",
			texto: "Seja Bem-Vindo ao Ecossistema!",
			imagem: "imagens-react-joyride/boas-vindas.svg",
		},
		{
			titulo: "Menu de Navegação",
			texto: "Aqui você encontra todos os links de acessos principais do Ecossistema.",
			imagem: "imagens-react-joyride/boas-vindas.svg",
		},
		{
			titulo: "Meu Portfólio",
			texto: "O portfólio é uma coleção com todo o seu desenvolvimento profissional e de habilidades construídas ao longo do curso. Acompanhe: estágios e projetos realizados, registre suas experiências profissionais, seus eventos e atividades realizadas.",
			imagem: "imagens-react-joyride/meu-portfolio.svg",
		},
		{
			titulo: "Newsletter",
			texto: "Assine nossa newsletter e não perca nenhuma novidade!",
			imagem: "imagens-react-joyride/newsletter.svg",
		},
		{
			titulo: "Fim do Tutorial",
			texto: "Parabéns por concluir o tutorial! Você conquistou 20 pontos na gamificação!",
			imagem: "imagens-react-joyride/fim-tutorial.svg",
		},
	];

	function chamarProximaEtapa() {
		switch (etapa) {
			case 0:
				return (
					<CardTutorialMobile
						etapaTutorialMobile={etapasTutorialMobile[0]}
						qtdEtapas={etapasTutorialMobile.length}
						etapa={etapa}
						setEtapa={setEtapa}
						setOpenDialog={setOpen}
					/>
				);
			case 1:
				return (
					<CardTutorialMobile
						etapaTutorialMobile={etapasTutorialMobile[1]}
						qtdEtapas={etapasTutorialMobile.length}
						etapa={etapa}
						setEtapa={setEtapa}
						setOpenDialog={setOpen}
					/>
				);
			case 2:
				return (
					<CardTutorialMobile
						etapaTutorialMobile={etapasTutorialMobile[2]}
						qtdEtapas={etapasTutorialMobile.length}
						etapa={etapa}
						setEtapa={setEtapa}
						setOpenDialog={setOpen}
					/>
				);
			case 3:
				return (
					<CardTutorialMobile
						etapaTutorialMobile={etapasTutorialMobile[3]}
						qtdEtapas={etapasTutorialMobile.length}
						etapa={etapa}
						setEtapa={setEtapa}
						setOpenDialog={setOpen}
					/>
				);
			case 4:
				return (
					<CardTutorialMobile
						etapaTutorialMobile={etapasTutorialMobile[4]}
						qtdEtapas={etapasTutorialMobile.length}
						etapa={etapa}
						setEtapa={setEtapa}
						setOpenDialog={setOpen}
					/>
				);
		}
	}

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			className='dialog-tutorial-mobile'
		>
			<div className='container-dialog'>{chamarProximaEtapa()}</div>

			{/* <div className='container-carousel'>
				<Carousel
					className='carousel-etapas-tutorial-mobile'
					value={etapasTutorialMobile}
					itemTemplate={itemTemplate}
					numVisible={1}
					numScroll={1}
				/>
			</div> */}
		</Dialog>
	);
}
