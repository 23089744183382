import { centralPedagogica } from "services/api";

import { StoreRequestProps } from "./formreviewquestion.model";

export async function storeRequest(props: StoreRequestProps) {
	const formData = new FormData();
	formData.append("ra", `${props?.ra}`);
	formData.append("curso", props.curso.toString());
	formData.append("disciplina", props.disciplina.toString());
	formData.append("questao", props.questao.toString());
	formData.append("avaliacao", props.avaliacao.toString());
	formData.append("justificativa", props.justificativa.toString());
	formData.append("anexo", props.anexo);

	return await centralPedagogica.post("aluno/criarRevisao", formData).then(({ data }) => {
		return JSON.parse(JSON.stringify(data));
	});
}

export async function getSolicitation(id: string, ra: string | undefined) {
	return await centralPedagogica
		.get(`aluno/verRevisao/${ra}/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSolicitations(ra: string | undefined, situacao = "") {
	return await centralPedagogica
		.get(`aluno/listarRevisoes/${ra}/${situacao}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getNumSolicitations(ra: string | undefined) {
	return await centralPedagogica
		.get(`contarRevisoes/${ra}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function deleteSolicitation(id: number | undefined, ra: string | undefined) {
	return await centralPedagogica
		.delete(`aluno/excluirRevisao/${ra}/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSolicitationsTutor() {
	return await centralPedagogica
		.get("tutor/listarRevisoes")
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSolicitationTutor(id: number) {
	return await centralPedagogica
		.get(`tutor/verRevisao/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function toMeetSolicitation(
	id: number,
	response: string,
	situation: string,
	tutor_id: string | undefined,
	tutor_name: string,
) {
	return await centralPedagogica
		.put(`/tutor/atenderRevisao/${id}`, {
			parecer: response,
			situacao: situation,
			tutor_id,
			tutor_nome: tutor_name,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
