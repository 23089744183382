import React from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";

import FormEducationMentoring from "../FormEducationalMentoring";
import FormPsychopedagogy from "../FormPsychopedagogy";
import FormClarificationOfDoubts from "../FormClarificationOfDoubts";
import DialogScheduleConfirmation from "../DialogScheduleConfirmation";

import { StoreScheduleTimeProps } from "../scheduletime.model";
import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";
import Notify from "utils/Notification";

import { storeScheduleEducationMentoring } from "../scheduletime.services";
import { getCoursesStudent } from "../../DialogQuestionsAnswers/dialogquestionsanswers.services";

import "./styles.scss";
import { useTheme } from "@material-ui/core";

import { CENTRAL_PEDAGOGICA } from "defaults/modules";
import { ALUNO_SOLICITA_AGENDAMENTO_DEFER_INDEFER } from "defaults/events";
import { apiLogsDoEcossistema } from "services/Logs";


export default function FormInitial(): React.ReactElement {
	const theme = useTheme();
	const { user, nivelDeEnsino } = useAuth();
	const { updateNumSchedule, setUpdateNumSchedule } = usePedagogicalCommunication();

	const [loadingPage, setLoadingPage] = React.useState(true);

	const [loadingSend, setLoadingSend] = React.useState(false);
	const [courses, setCourses] = React.useState([]);

	const INIT_VALUES_DATA_FORM = {
		curso: "",
		ra: user.ra ? user.ra : "",
		assunto: 0,
		data: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
		horario: "",
		link: 0, // enviar o id do horario
		idade: "",
		telefone: "",
		diagnostico: "",
		laudo: "",
		dificuldades: [
			{
				concentracao: {
					name: "Concentração",
					check: false,
				},
				assimilacao: {
					name: "Assimilação",
					check: false,
				},
				memoria: {
					name: "Memória",
					check: false,
				},
				interpretacao: {
					name: "Interpretação",
					check: false,
				},
				interacao: {
					name: "Interação",
					check: false,
				},
				rendimento: {
					name: "Baixo rendimento escolar",
					check: false,
				},
				raciocinio: {
					name: "Raciocínio lógico matemático",
					check: false,
				},
			},
		],
		medicamento: "",
		queixa: "",
	};

	const [typeForm, setTypeForm] = React.useState("");

	const [dataForm, setDataForm] = React.useState<StoreScheduleTimeProps>(INIT_VALUES_DATA_FORM);

	const [openConfirmation, setOpenConfirmation] = React.useState(false);
	const [idSchedule, setIdSchedule] = React.useState("");

	const handleDataForm = (position: string, value: unknown) => {
		setDataForm({ ...dataForm, [position]: value });
	};

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		setLoadingSend(true);

		storeScheduleEducationMentoring(dataForm)
			.then((data) => {
				if (!data.isCreated) {
					Notify("Não foi possível realizar o agendamento", "error");
					setLoadingSend(false);
					return;
				}

				setIdSchedule(data.id);
				setDataForm({ ...INIT_VALUES_DATA_FORM, curso: dataForm?.curso });
				setTypeForm("");
				setOpenConfirmation(true);
				setUpdateNumSchedule(!updateNumSchedule);
				setLoadingSend(false);
				apiLogsDoEcossistema.post('/',
					{
						ra: user.ra as string,
						module: CENTRAL_PEDAGOGICA,
						evento: {
							label: ALUNO_SOLICITA_AGENDAMENTO_DEFER_INDEFER,
							log_info: {
								codigo_nivel_ensino: nivelDeEnsino.codigo_nivel_ensino,
								nome_nivel_ensino: nivelDeEnsino.nome_nivel_ensino,
								nome_curso: nivelDeEnsino.nome_curso,
								periodo: nivelDeEnsino.periodo,
							}
						},
					}
				)
			})
			.catch((e) => {
				console.log(e);
				setLoadingSend(false);
			});
	};

	React.useEffect(() => {
		getCoursesStudent(user.ra)
			.then((data: any) => {
				setCourses(data);
				handleDataForm("curso", `${data[0].id}:${data[0].name}`);
				setLoadingPage(false);
			})
			.catch((e) => {
				console.log(e);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.ra]);

	return (
		<Box className='area_form'>
			{loadingPage ? (
				<Box display='flex' justifyContent='center' alignItems='center'>
					<CircularProgress />
				</Box>
			) : (
				<form onSubmit={onSubmit}>
					<Grid container spacing={1}>
						<Grid
							item
							xs={12}
							md={typeForm && dataForm.assunto === 2 ? 6 : typeForm ? 4 : 6}
						>
							<FormControl variant='outlined' fullWidth>
								<InputLabel id='demo-simple-select-outlined-label'>
									Curso:
								</InputLabel>
								<Select
									labelId='demo-simple-select-outlined-label'
									id='demo-simple-select-outlined'
									label='Curso:'
									value={dataForm?.curso}
									onChange={(e) => handleDataForm("curso", e.target.value)}
									required
								>
									{courses.map((course: any) => (
										<MenuItem
											key={course.id}
											value={`${course.id}:${course.name}`}
										>
											{course.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>

						<Grid
							item
							xs={12}
							md={typeForm && dataForm.assunto === 2 ? 6 : typeForm ? 2 : 6}
						>
							<FormControl variant='outlined' fullWidth required color='primary'>
								<InputLabel>Área de Apoio:</InputLabel>
								<Select
									label='Área de Apoio: *'
									value={typeForm}
									onChange={(e) => {
										setTypeForm(`${e.target.value}`);
										setDataForm({
											...INIT_VALUES_DATA_FORM,
											curso: dataForm?.curso,
											assunto:
												e.target.value === "psicopedagogia"
													? 1
													: e.target.value === "esclarecimento"
														? 2
														: 0,
										});
									}}
								>
									<MenuItem value='psicopedagogia'>Psicopedagogia</MenuItem>
									<MenuItem value='mentoria'>Mentoria Educacional</MenuItem>
									<MenuItem value='esclarecimento'>
										Esclarecimento de Dúvidas
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>

						{typeForm === "mentoria" && (
							<FormEducationMentoring data={dataForm} setData={handleDataForm} />
						)}

						{typeForm === "psicopedagogia" && (
							<FormPsychopedagogy data={dataForm} setData={handleDataForm} />
						)}

						{typeForm === "esclarecimento" && (
							<FormClarificationOfDoubts data={dataForm} setData={handleDataForm} />
						)}

						<Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
							<Button
								variant='contained'
								style={{
									backgroundColor: theme.palette.primary.main,
									color: "white",
								}}
								type='submit'
								disabled={loadingSend}
								startIcon={
									loadingSend && (
										<CircularProgress
											style={{
												color: "#fff",
												width: "1.5rem",
												height: "1.5rem",
											}}
											thickness={4.4}
										/>
									)
								}
							>
								{loadingSend ? "Agendando..." : "Agendar"}
							</Button>
						</Grid>
					</Grid>
				</form>
			)}

			<DialogScheduleConfirmation
				open={openConfirmation}
				setOpen={setOpenConfirmation}
				idSchedule={idSchedule}
			/>
		</Box>
	);
}
