import React from "react";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import Close from "@material-ui/icons/Close";

import "./styles.scss";

import { formatBytes } from "./filelist.functions";
import { FileListProps } from "./filelist.model";

export default function FileList({ fileSelected, onSetFile }: FileListProps): React.ReactElement {
	const urlTemp = URL.createObjectURL(fileSelected as Blob | MediaSource);

	return (
		<Box className='line_item'>
			<Box className='file_info'>
				<Box
					className='preview'
					style={{
						backgroundImage: `url('${urlTemp}')`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: "50% 50%",
					}}
				/>

				<Box className='area_name_file'>
					<Typography className='name_file'>{fileSelected?.name}</Typography>
					<Typography className='length_file'>
						{formatBytes(fileSelected?.size)}
					</Typography>
				</Box>
			</Box>

			<Box>
				<IconButton className='iconTrash' onClick={onSetFile}>
					<Close />
				</IconButton>
			</Box>
		</Box>
	);
}
