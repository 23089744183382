import React from "react";
import SearchEngineV2View from "./SearchEngineV2.view";
// import SearchEngineV2View from './view';

// import { Container } from './styles';

const SearchEngineV2: React.FC = ({ children }) => {
	return <SearchEngineV2View></SearchEngineV2View>;
};

export default SearchEngineV2;
