import {
	Box,
	Button,
	Container,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	EventNote,
	Facebook,
	Instagram,
	Keyboard,
	Language,
	Send,
	Telegram,
	YouTube,
} from "@material-ui/icons";
import React, { useState } from "react";
import VimeoSvg from "assets/Vimeo";
import Notify from "utils/Notification";

import "./styles.scss";
// import { useAuth } from "context/Auth/hook";

import { useTheme } from "@material-ui/core";
import { useAuth } from "context/Auth/hook";
import { sendConfirmEmail } from "services/EnvioEmailFooter";
import { AxiosResponse } from "axios";

interface RespostaApiEmail {
	success: string;
	msg: string;
}

export default function FooterView() {
	const theme = useTheme();
	const tempo = new Date();
	const ano = tempo.getFullYear();
	const { nivelDeEnsino } = useAuth();
	// const { devPreview, setDevPreview } = useAuth();

	const [email, setEmail] = useState<string>("");

	function verificarEmail(): boolean {
		return email !== "" && email.search(/@/g) !== -1 && email.search(/\.$| $/) === -1;
	}

	return (
		<>
			<Box position='static' className='footer'>
				<Container maxWidth='lg'>
					<Grid container spacing={2}>
						<Grid item md={12} sm={12}>
							<Box
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}
								className='boxEventosHoje'
							>
								<Typography
									variant='body1'
									color='inherit'>
									Acompanhe a nossa programação de eventos diários
								</Typography>
								<Button
									variant='contained'
									disableElevation
									style={{
										backgroundColor: theme.palette.primary.main,
										color: "white",
									}}
									// style={{
									//     backgroundColor:
									//         nivelDeEnsino.nome_nivel_ensino    !== "FACULDADE ALENCARINA"
									//         && nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
									//         && nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD"
									//         && nivelDeEnsino.nome_nivel_ensino !== "FAL" ? "#bd0e0e" : "#001D7E",
									//     color: "white",
									// }}
									target='_blank'
									href={
										nivelDeEnsino.codigo_nivel_ensino !== 351 &&
										nivelDeEnsino.nome_nivel_ensino !==
											"FACULDADE ALENCARINA" &&
										nivelDeEnsino.nome_nivel_ensino !==
											"FACULDADE 5 DE JULHO - EAD" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
											? nivelDeEnsino.codigo_nivel_ensino === 345
												? "https://eventosvirtuais.aiamis.com.br/uninta/agenda"
												: "https://eventosvirtuais.aiamis.com.br/uninta/agenda"
											: "https://eventosvirtuais.aiamis.com.br/f5/agenda"
									}
									data-testid='daily-events-link'
									className='buttonFooter eventos-hoje-footer'
								>
									<EventNote style={{ marginRight: "6px" }} />
									Eventos de Hoje
								</Button>
							</Box>
						</Grid>
					</Grid>
					<Divider style={{ backgroundColor: "#3f3f3f" }} />
					<Grid
						container
						direction='row'
						spacing={2}
						style={{
							marginBottom: "5vh",
						}}
					>
						<Grid item xs={12} sm={3}>
							<Typography
								variant='body1'
								color='inherit'
								className='list-item-title-link'
							>
								INSTITUIÇÃO
							</Typography>
							{nivelDeEnsino.codigo_nivel_ensino !== 351 &&
							nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
							nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD" &&
							nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO" ? (
								nivelDeEnsino.codigo_nivel_ensino === 345 ? (
									<Typography variant='body1' className='list-item-link'>
										<Link
											href='https://fortaleza.faculdadeuninta.com.br/instituicao/#historico'
											color='inherit'
											target='_blank'
										>
											Histórico
										</Link>
									</Typography>
								) : (
									<React.Fragment>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='https://uninta.edu.br/site/instituicao/'
												color='inherit'
												target='_blank'
											>
												Conheça a Instituição
											</Link>
										</Typography>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='https://uninta.edu.br/site/graduacao-presencial/'
												color='inherit'
												target='_blank'
											>
												Cursos de Graduação
											</Link>
										</Typography>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='http://www.inta.edu.br/posgraduacao/'
												color='inherit'
												target='_blank'
											>
												Cursos de Pós-graduação
											</Link>
										</Typography>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='http://biotecnologia.inta.edu.br/'
												color='inherit'
												target='_blank'
											>
												Cursos de Mestrado
											</Link>
										</Typography>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='http://ead.inta.edu.br/graduacao-ead/'
												color='inherit'
												target='_blank'
											>
												Cursos EaD
											</Link>
										</Typography>
										{/* <Typography variant="body1">
											<Link href="http://mooc.intaead.com.br/login/index.php" color="inherit" target="_blank">
												Cursos online
											</Link>
										</Typography> */}
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='https://uninta.edu.br/site/processos-seletivos/'
												color='inherit'
												target='_blank'
											>
												Processos Seletivos
											</Link>
										</Typography>
									</React.Fragment>
								)
							) : (
								<React.Fragment>
									<Typography variant='body1' className='list-item-link'>
										<Link
											href='https://faculdadef5.com.br/a-f5/'
											color='inherit'
											target='_blank'
										>
											Conheça a F5
										</Link>
									</Typography>
									<Typography variant='body1' className='list-item-link'>
										<Link
											href='https://faculdadef5.com.br/graduacao/'
											color='inherit'
											target='_blank'
										>
											Cursos de Graduação
										</Link>
									</Typography>
									<Typography variant='body1' className='list-item-link'>
										<Link
											href='https://faculdadef5.com.br/processos-seletivos/'
											color='inherit'
											target='_blank'
										>
											Processos Seletivos
										</Link>
									</Typography>
								</React.Fragment>
							)}
						</Grid>

						<Grid item xs={12} sm={3}>
							<Typography
								variant='body1'
								color='inherit'
								className='list-item-title-link'
							>
								ACESSO RÁPIDO
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit' target='_blank'>
									Agendamento de Mentoria
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit' target='_blank'>
									Perguntar & Responder
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit' target='_blank'>
									Solicitação de Revisão
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit' target='_blank'>
									Bloco de Notas e Arquivos
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit'>
									Minhas Trilhas
								</Link>
							</Typography>
						</Grid>

						<Grid item xs={12} sm={3}>
							<Typography
								variant='body1'
								color='inherit'
								className='list-item-title-link'
							>
								LINKS ÚTEIS
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit' target='_blank'>
									Tutoriais
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									href='http://prodipeuninta.online/cronograma/'
									color='inherit'
									target='_blank'
								>
									Planejamento de Estudo Online
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									href='https://ecossistema.uninta.edu.br/index.php/welcome/card/38'
									color='inherit'
									target='_blank'
								>
									Central de Integridade Acadêmica
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									href={
										nivelDeEnsino.nome_nivel_ensino ===
											"FACULDADE 5 DE JULHO - EAD" ||
										nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA"
											? "https://eventosvirtuais.aiamis.com.br/f5/agenda"
											: "https://eventosvirtuais.aiamis.com.br/uninta/agenda"
									}
									color='inherit'
									target='_blank'
								>
									Eventos Virtuais
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									href='https://virtual.uninta.edu.br/blocks/verify_certificate/index.php'
									color='inherit'
									target='_blank'
								>
									Validação de Certificados
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									href='https://academico.aiamis.com.br/FrameHTML/web/app/edu/portaleducacional/login/'
									color='inherit'
									target='_blank'
								>
									Sistema Acadêmico
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									href='https://docs.google.com/presentation/d/e/2PACX-1vQvPbShlWq3WORLkLCGVxjFoLayYetmA2SkS2RZd_hJKCltta-MHJR828sz7Qp_u3uitGn-ELwRnQbB/pub?start=false&loop=false&delayms=3000&slide=id.gc6f83aa91_0_56'
									color='inherit'
									target='_blank'
								>
									Ferramentas de Acessibilidade
								</Link>
							</Typography>
						</Grid>

						<Grid item xs={12} sm={3}>
							<Typography variant='body1' className='text-helper-email'>
								Receba atualizações de conteúdo em sua caixa de entrada
							</Typography>
							<FormControl variant='outlined' fullWidth>
								<TextField
									id='outlined-email-input'
									label='Digite seu e-mail'
									className='newsletter-footer'
									type='email'
									name='email'
									size='small'
									required={true}
									autoComplete='email'
									margin='normal'
									variant='outlined'
									data-testid='newsletter-input'
									onChange={(e) => setEmail(e.target.value)}
									value={email ? email : ""}
									style={{
										color: "#fff",
									}}
									InputProps={{
										style: {
											color: "#fff",
										},
										startAdornment: (
											<InputAdornment position='start'>
												<Keyboard style={{ color: "#fff" }} />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle email visibility'
													data-testid='newsletter-submit'
													size='small'
													onClick={() => {
														if (email !== "") {
															if (verificarEmail()) {
																sendConfirmEmail(email).then(
																	(
																		response: AxiosResponse<RespostaApiEmail>,
																	) => {
																		Notify(
																			response.data.msg,
																			"success",
																		);
																	},
																);
															} else {
																Notify(
																	"Verifique se o e-mail foi digitado incorretamente!",
																	"error",
																);
															}
														} else {
															Notify(
																"Para efetuar o envio é necessário o seu e-mail!",
																"error",
															);
														}
													}}
												>
													<Send style={{ color: "#fff" }} />
												</IconButton>
											</InputAdornment>
										),
									}}
								></TextField>
							</FormControl>
							<Typography variant='body1' className='text-helper-email'>
								Você pode cancelar a assinatura a qualquer momento usando o link em
								nossos e-mails.
								{/* Para mais detalhes, verifique nossa 
                            <Link href="#" color="inherit" target="_blank">política de privacidade.
                            </Link> */}
							</Typography>
						</Grid>
					</Grid>
					<Divider style={{ backgroundColor: "#3f3f3f" }} />
					<Grid container direction='row' spacing={2} style={{ paddingBottom: "6vh"}}>
						<Grid item xs={12} sm={8} md={12} lg={12}>
							<Box
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "flex-start",
								}}
								className='boxPrivacidade'
							>
								<Typography
									variant='body1'
									color='inherit'
									style={{ fontFamily: "inherit" }}
									data-testid='privacy'
								>
									Política de Privacidade
								</Typography>
								<Button
									variant='text'
									style={{ color: "white", fontFamily: "inherit" }}
								>
									<Language style={{ marginRight: "6px", color: "white" }} />{" "}
									Português (Brasil)
								</Button>
							</Box>
						</Grid>
						<Grid 
							item 
							xs={12} 
							sm={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}>
							<Typography
								variant='body1'
								className='textoDireitosReservados'
								color='inherit'
								style={{ fontFamily: "inherit" }}
							>
								{
									nivelDeEnsino.codigo_nivel_ensino !== 351 &&
									nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
									nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD" &&
									nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO" ? (
									nivelDeEnsino.codigo_nivel_ensino === 345 ? (
										<>© {ano} Todos os direitos reservados. Centro Universitário INTA - UNINTA</>
									) : (
										<>© {ano} Todos os direitos reservados. Centro Universitário INTA - UNINTA</>
									)
								) : (
									<>
										© {ano} Todos os direitos reservados. F5 - Faculdade 05 de
										Julho de Sobral
									</>
								)}
							</Typography>
							{/* <Typography variant="body1" className="text-helper-email">
                            Você pode cancelar a assinatura a qualquer momento usando o link em nossos em-mails. Para mais detalhes, verifique nossa <Link href="#" color="inherit" target="_blank">política de privacidade.</Link>
                        </Typography> */}
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='links'>
							{/* <Grid container> */}
								<Grid 
									item 
									md={12}
									className='link-icons'>
									<Link
										href='https://www.youtube.com/c/EcossistemaAIAMIS/'
										target='_blank'
									>
										<YouTube />
									</Link>
									<Link
										href='https://vimeo.com/ecoaprendizagemaiamis'
										color='inherit'
										target='_blank'
										style={{
											width: '48px',
											height: '48px',
										}}
									>
										<VimeoSvg />
									</Link>
									<Link
										href='https://www.instagram.com/unintaead/'
										color='inherit'
										target='_blank'
									>
										<Instagram />
									</Link>
									<Link
										href='https://www.facebook.com/eaduninta'
										color='inherit'
										target='_blank'
									>
										<Facebook />
									</Link>
									<Link
										href='https://t.me/+teEctz3ihTM2OTA5'
										color='inherit'
										target='_blank'
									>
										<Telegram />
									</Link>
								</Grid>
							</Grid>
						</Grid>
					{/* </Grid> */}
				</Container>
			</Box>
		</>
	);
}
