import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { Typography, Dialog } from "@material-ui/core";
import { useModalContext } from "context/modal/hooks/useModal";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { IconButton } from "@material-ui/core";
import FormularioCadastroPublicacoes from "components/Formularios/Cadastro/FormularioCadastroPublicacoes";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';

export default function DialogCadatroPublicacoesView(): React.ReactElement {
	const theme = useTheme();
	const { openCadastro, handleOpenCadastro, handleCloseCadastro } = useModalContext();
	const fullScreen = useMediaQuery(theme.breakpoints.down(800));

	return (
		<>
			<IconButton
				onClick={() => {
					handleOpenCadastro();
				}}
			>
				<AddCircleOutlineIcon />
			</IconButton>

			<Dialog open={openCadastro} onClose={handleCloseCadastro} fullScreen={fullScreen}>
				<div>
					<div>
						<div
							style={{
								backgroundColor: theme.palette.primary.main,
								width: "100%",
								padding: "12px 24px",
								color: "white",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Typography variant='body1'> Cadastrar Publicação</Typography>
							<CloseIcon
								style={{ marginLeft: "25px", cursor: "pointer" }}
								onClick={handleCloseCadastro}
							/>
						</div>
						<Box p={3} style={{ border: "none" }}>
							<div>
								<Box mb={3} mt={2}>
									<Typography variant='body1'>
										Preencha o formulário para cadastrar a sua Publicação no
										Ecossistema de Aprendizagem
									</Typography>
								</Box>
								<Box>
									<FormularioCadastroPublicacoes />
								</Box>
							</div>
						</Box>
					</div>
				</div>
			</Dialog>
		</>
	);
}
