import { EventosContextType } from "./models/PropsEventosContext";

/**
 * Define o estado inicial que vai conter os dados da requisição. Como também estados que são ultilizados para o Backdrop e para a verificação se a requisição foi completa com sucesso
 */
export const INI_VALUES: EventosContextType = {
	certificados: [
		{
			id_certificado: 0,
			id_impressao: 0,
			id_curso: 0,
			id_usuario: 0,
			id_modulo: "",
			nome_certificado: "",
			nome_curso: "",
			nome_usuario: "",
			carga_horaria: "",
			data_impressao: "",
			categoria: "",
		},
	],
	setCertificados: () => { },
	request: true,
	requestSpinner: false,
	setRequest: () => {},
	setRequestSpinner: () => {},
	categorias: [""],
	setCategorias: () => { },
};
