import React from "react";
import parse from "html-react-parser";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Paginator } from "primereact/paginator";

import DialogAnswer from "./DialogAnswer";

import {
	getCoursesStudent,
	getListQuestions,
	getSubjectsStudent,
} from "../dialogquestionsanswers.services";
import {
	getListCourses,
	getListSubjects,
} from "../../ListSolicitationsReviewQuestion/listsolicitationreview.services";
import { DialogAnswerProps } from "./DialogAnswer/dialoganswer.model";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import "./styles.scss";
import AvatarProfile from "../../../../../components/AvatarProfile";

export default function Response() {
	const { user } = useAuth();
	const { reloadListQuestions } = usePedagogicalCommunication();
	const [loadingPage, setLoadingPage] = React.useState(true);

	// Paginação
	const [currentPage, setCurrentPage] = React.useState(0);
	const [totalQuestions, setTotalQuestions] = React.useState(0);

	const [filterCourseSelected, setFilterCourseSelected] = React.useState("");
	const [filterSubjectSelected, setFilterSubjectSelected] = React.useState("all");

	const [listCourses, setListCourses] = React.useState<{ id: string; name: string }[]>([]);
	const [listSubjects, setListSubjects] = React.useState([]);

	const [listQuestions, setListQuestions] = React.useState([]);

	const [openAnswer, setOpenAnswer] = React.useState(false);
	const [detailsAnswer, setDetailsAnswer] =
		React.useState<Omit<DialogAnswerProps, "open" | "setOpen" | "course">>();

	const onOpenAnswer = (
		authorId = "",
		authorAvatar = "",
		authorName: string,
		authorCourse: string,
		authorSubject: string,
		answer: string,
		answerId: string,
	) => {
		setOpenAnswer(true);
		setDetailsAnswer({
			authorId,
			authorAvatar,
			authorName,
			authorCourse,
			authorSubject,
			answer,
			answerId,
		});
	};

	const getListFilteredQuestion = (type?: string, value?: string, page = 1) => {
		setLoadingPage(true);
		getListQuestions(type, value, page)
			.then((data) => {
				if (!Array.isArray(data.data)) {
					setListQuestions(data.data?.data);
					setTotalQuestions(data.data?.total);
				} else {
					setListQuestions([]);
					setTotalQuestions(0);
				}
				setLoadingPage(false);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	React.useEffect(() => {
		if (user.typeUser === "tutor") {
			getListCourses()
				.then((data) => {
					setFilterCourseSelected(data[0].codigo);

					setListCourses(data);
					getListFilteredQuestion("curso_id", data[0].codigo);
				})
				.catch((e) => {
					console.log(e);
				});

			getListSubjects()
				.then((data) => {
					setListSubjects(data);
				})
				.catch((e) => {
					console.log(e);
				});

			return;
		}

		getCoursesStudent(user?.ra)
			.then((data: any) => {
				setFilterCourseSelected(`${data[0].id}:${data[0].name}`);

				setListCourses(data);
				getListFilteredQuestion("curso_id", data[0].id);
			})
			.catch((e) => {
				console.log(e);
			});

		getSubjectsStudent(user?.ra)
			.then((data: any) => {
				setListSubjects(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [user.ra, user.typeUser]);

	React.useEffect(() => {
		if (currentPage) {
			const page = currentPage / 10 + 1;

			getListFilteredQuestion(
				filterSubjectSelected !== "all" ? "disciplina_id" : "curso_id",
				filterSubjectSelected !== "all"
					? filterSubjectSelected.split(":")[0]
					: filterCourseSelected.split(":")[0],
				page,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadListQuestions]);

	return (
		<Container>
			<Grid container>
				{loadingPage ? (
					<Grid item xs={12}>
						<Box className='awaiting'>
							<Typography variant='subtitle2'>Por favor, aguarde...</Typography>
							<CircularProgress />
						</Box>
					</Grid>
				) : (
					<>
						<Grid item xs={12} className='head_response_filter'>
							<Typography className='title_select_filtro'>
								Listagem de Perguntas
							</Typography>
							<Typography className='info_filter'>
								Utilize os filtros para um busca mais rápida
							</Typography>

							<Box className='filters_questions_in_respnse'>
								<FormControl variant='outlined'>
									<InputLabel>Cursos:</InputLabel>
									<Select
										label='Cursos:'
										id='selectCourseAnswer'
										value={filterCourseSelected}
										onChange={(e) => {
											const val = e.target.value as string;

											setFilterCourseSelected(val);
											getListFilteredQuestion("curso_id", val.split(":")[0]);
										}}
									>
										{listCourses.map((course: any) => (
											<MenuItem
												key={
													user.typeUser === "tutor"
														? course.codigo
														: course.id
												}
												value={
													user.typeUser === "tutor"
														? course.codigo
														: `${course.id}:${course.name}`
												}
											>
												{user.typeUser === "tutor"
													? course.nome
													: course.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<FormControl variant='outlined'>
									<InputLabel>Disciplinas:</InputLabel>
									<Select
										id='selectSubjectAnswer'
										label='Disciplinas:'
										value={filterSubjectSelected}
										onChange={(e) => {
											setFilterSubjectSelected(e.target.value as string);

											if (e.target.value === "all") {
												getListFilteredQuestion(
													"curso_id",
													filterCourseSelected,
												);
											} else {
												getListFilteredQuestion(
													"disciplina_id",
													e.target.value as string,
												);
											}
										}}
									>
										<MenuItem value='all'>Todos</MenuItem>
										{listSubjects.map((course: any) => (
											<MenuItem key={course.id} value={course.id}>
												{course.nome}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</Grid>

						{listQuestions.length <= 0 ? (
							<Box className='nothing'>
								<Typography className='text_no_find'>
									Nenhum resultado encontrado
								</Typography>
								<Typography className='subtext_no_find'>
									Tente filtrar por outro curso ou disciplina
								</Typography>
							</Box>
						) : (
							<>
								{listQuestions.map((qt: any) => (
									<Grid
										item
										xs={12}
										className='questions_area_response'
										key={qt.id}
									>
										<Box className='question_author'>
											<AvatarProfile
												isAuthor={qt.autor_id === user.ra}
												urlImage={qt.autor_avatar}
												authorName={qt.autor_nome ?? ""}
											/>

											<Box className='author_name_course'>
												<Typography className='author_name'>
													{qt.autor_nome.toLocaleLowerCase()}
												</Typography>
												<Typography className='author_course'>
													{qt.curso_nome} - {qt.disciplina_nome}
												</Typography>
											</Box>
										</Box>

										<Box className='question_view'>
											<Typography variant='subtitle2'>
												{parse(qt.pergunta)}
											</Typography>
										</Box>

										<Box className='question_view_actions'>
											<Button
												variant='contained'
												color='primary'
												onClick={() => {
													onOpenAnswer(
														qt.autor_id,
														qt.autor_avatar,
														qt.autor_nome,
														qt.curso_nome,
														qt.disciplina_nome,
														qt.pergunta,
														qt.id,
													);
												}}
											>
												Responder
											</Button>
										</Box>
									</Grid>
								))}

								<Paginator
									rows={10}
									totalRecords={totalQuestions}
									first={currentPage}
									onPageChange={(e) => {
										setCurrentPage(e.first);
										getListFilteredQuestion(
											filterSubjectSelected !== "all"
												? "disciplina_id"
												: "curso_id",
											filterSubjectSelected !== "all"
												? filterSubjectSelected.split(":")[0]
												: filterCourseSelected.split(":")[0],
											e.page + 1,
										);
									}}
									style={{
										background: "#f7f7f7",
										marginTop: 10,
										width: "100%",
									}}
								></Paginator>
							</>
						)}
					</>
				)}
			</Grid>

			<DialogAnswer
				open={openAnswer}
				course={filterCourseSelected}
				authorId={detailsAnswer?.authorId ?? ""}
				authorName={detailsAnswer?.authorName}
				authorCourse={detailsAnswer?.authorCourse}
				authorSubject={detailsAnswer?.authorSubject}
				authorAvatar={detailsAnswer?.authorAvatar ?? ""}
				answer={detailsAnswer?.answer}
				answerId={detailsAnswer?.answerId}
				setOpen={setOpenAnswer}
			/>
		</Container>
	);
}
