import { Box, Typography } from "@material-ui/core";
import { useProjects } from "context/project";
import { Link } from "react-router-dom";

import "./styles.scss";
export default function Result(props: any): React.ReactElement {
	const { applets } = useProjects();

	return (
		<Link
			to={`/search/result/${props.id}`}
			style={{
				textDecoration: "none",
				color: "#333",
			}}
		>
			<Box mb={2} mt={1} p={2} className='result'>
				<Box
					className='project-icon'
					// style={{
					//     backgroundColor: props.projeto && props.projeto.color ? props.projeto.color : "#fafafa",
					// }}>
					style={{
						backgroundColor:
							props && props.id_project
								? applets &&
								applets.find((applet: any) => applet.id === props.id_project).color
								: "#fafafa",
					}}
				>
					<img
						src={
							props && applets && applets.length > 0
								? applets.find((applet: any) => applet.id === props.id_project)
										.link_image
								: "https://via.placeholder.com/150"
						}
						alt='Icone do projeto'
						width='90%'
					/>
				</Box>
				<Box className='project-content'>
					<Typography
						variant='body1'
						style={{
							fontWeight: "bold",
							marginBottom: "12px",
						}}
					>
						{/* <Link
							to={`/search/result/${props.id}`}
							style={{
								textDecoration: "none",
								color: "#333",
							}}
						> */}
							{" "}
							{props.title.toUpperCase()}{" "}
						{/* </Link> */}
					</Typography>
					<Typography
						variant='body1'
						style={{
							lineClamp: 2,
							textOverflow: "ellipsis",
							overflow: "hidden",
							// whiteSpace: "wrap"
						}}
					>
						<div
							dangerouslySetInnerHTML={{ __html: props.description }}
							style={{
								height: "3.4em",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						/>
					</Typography>
				</Box>
			</Box>
		</Link>
	);
}
