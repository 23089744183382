import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { LearningResourceInterface } from "components/LearningResources/resource.model";
import { AnimatePresence, motion } from "framer-motion";
import { UserContext } from "models/auth";
import LearningResource from "components/LearningResources";
import CardMock from "components/CardMock";
import Subject from "pages/Private/Homepage/Subject";
import Course from "pages/Private/Homepage/Course";
import Cards from "components/Cards";

/**
 * 
 * @param tab // alterna entre disciplinas e cursos livres
 * @param disciplinaAva caso exista disciplina com este idAva ela exibe o LernResource, do contrário, exibe os cards
 * @param carregaDisciplinas caso seja true, exibe o placeholder do card, caso falso, exibe os cards de disciplinas
 * @param profile // extrai as disciplinas de profile para exibir
 * @param profiles // extrai as disciplinas de profile[0] caso o usuário seja do tipo tutor para exibir
 * @param minhasDisciplinas // extrai as disciplinas do contexto para exibir
 * @param user 
 * @returns View com carrossel de cards de disciplinas ou cursos livres
 */
export const selectLearningResource = (
        tab: number, 
        disciplinaAva: LearningResourceInterface | null, 
        carregaDisciplinas: boolean, 
        profile: { disciplinas: any[]; cursosLivres: any[]; } | any, 
        profiles: any[], 
        minhasDisciplinas: any[], 
        user: UserContext,
    ): React.ReactElement => {
    switch (tab) {
        case 0:
            return (
                <React.Fragment>
                    {
                        disciplinaAva 
                        ? (<LearningResource {...disciplinaAva} />) 
                        : (
                        <Grid
                            container
                            spacing={3}
                            style={{
                                marginBottom: "5vh",
                            }}
                        >
                            <Grid
                                item
                                md={12}
                                xs={12}
                                lg={12}
                                sm={12}
                                style={{
                                    // backgroundColor: "rgb(252, 252, 252)",
                                    borderRadius: "12px",
                                }}>                                    
                                <Box
                                    style={{
                                        backgroundColor: "transparent",
                                        paddingBottom: "24px",
                                        // padding: "12px",
                                        // borderRadius: "12px",
                                    }}
                                >
                                    {carregaDisciplinas ? (
                                        <Cards>
                                            <CardMock />
                                            <CardMock />
                                            <CardMock />
                                            <CardMock />
                                            <CardMock />
                                        </Cards>
                                    ) :
                                        (
                                            profile &&
                                            profile.disciplinas &&
                                            profile.disciplinas.length > 0
                                        ) || (
                                                minhasDisciplinas &&
                                                minhasDisciplinas.length > 0
                                            ) || (
                                                profiles &&
                                                profiles[0] &&
                                                profiles[0].disciplinas
                                            ) ? (
                                            <Cards>
                                                {
                                                    profiles &&
                                                    profiles[0].disciplinas &&
                                                    profiles[0].disciplinas.map((disciplina, index) => {
                                                        const data = {
                                                            nome: disciplina.nome,
                                                            id: disciplina.id,
                                                            idturmadisc_rm: disciplina.id,
                                                        };
                                                        return data.id !== "" ? (
                                                            <AnimatePresence key={index}>
                                                                <motion.div
                                                                    initial={{ opacity: 0 }}
                                                                    animate={{
                                                                        opacity: 1,
                                                                        transition: {
                                                                            duration: 0.1,
                                                                            easing: "easeInOut",
                                                                            delay: 0.1 * index,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Subject {...disciplina} key={index} />
                                                                </motion.div>
                                                            </AnimatePresence>
                                                        ) : null;
                                                    })
                                                }
                                                {minhasDisciplinas &&
                                                    minhasDisciplinas.map((disciplina, index) => {
                                                        const data = {
                                                            nome: disciplina.name,
                                                            id:
                                                                disciplina.avas.length > 0
                                                                    ? disciplina.avas[0].id_ava
                                                                    : "",
                                                            idturmadisc_rm: disciplina.id,
                                                        };
                                                        return data.id !== "" ? (
                                                            <AnimatePresence key={index}>
                                                                <motion.div
                                                                    initial={{ opacity: 0 }}
                                                                    animate={{
                                                                        opacity: 1,
                                                                        transition: {
                                                                            duration: 0.1,
                                                                            easing: "easeInOut",
                                                                            delay: 0.1 * index,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Subject {...data} key={index} />
                                                                </motion.div>
                                                            </AnimatePresence>
                                                        ) : null;
                                                    })
                                                }
                                                {
                                                    user.typeUser === "tutor" &&
                                                    profile &&
                                                    profile.disciplinas.length > 0 &&
                                                    profile.disciplinas.map((disciplina, index) => {
                                                        return (
                                                            <AnimatePresence key={index}>
                                                                <motion.div
                                                                    initial={{ opacity: 0 }}
                                                                    animate={{
                                                                        opacity: 1,
                                                                        transition: {
                                                                            duration: 0.1,
                                                                            easing: "easeInOut",
                                                                            delay: 0.1 * index,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Subject
                                                                        {...disciplina}
                                                                    />
                                                                </motion.div>
                                                            </AnimatePresence>
                                                        );
                                                    })}
                                            </Cards>
                                        ) : (
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    minHeight: "20vh",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant='h5'>
                                                    {user.typeUser === "student" ? (
                                                        <>Nenhuma disciplina disponível</>
                                                    ) : (
                                                        <>
                                                            Nenhuma disciplina foi encontrada.
                                                        </>
                                                    )}
                                                </Typography>
                                            </Box>
                                        )}
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </React.Fragment>
            );
        case 1:
            return (
                <React.Fragment>
                    <Grid
                        container
                        spacing={3}
                        style={{
                            marginBottom: "5vh",
                        }}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                            lg={12}
                            sm={12}
                            style={{
                                borderRadius: "12px",
                            }}
                        >
                            <Box
                                style={{
                                    backgroundColor: "transparent",
                                    paddingBottom: "24px",
                                }}
                            >
                                {                            
                                    profile &&
                                    profile.cursosLivres &&
                                    profile.cursosLivres.length > 0 ?(
                                    <Cards>
                                    {
                                        profile.cursosLivres.map((disciplina, index) => {
                                            return (
                                                <AnimatePresence key={index}>
                                                    <motion.div
                                                        initial={{ opacity: 0 }}
                                                        animate={{
                                                            opacity: 1,
                                                            transition: {
                                                                duration: 0.1,
                                                                easing: "easeInOut",
                                                                delay: 0.1 * index,
                                                            },
                                                        }}
                                                    >
                                                        <Course {...disciplina} />
                                                    </motion.div>
                                                </AnimatePresence>
                                            );
                                        }) 
                                    }
                                </Cards>)
                                : (
                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minHeight: "20vh",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography variant='h5'>
                                            Nenhum curso livre foi encontrado.
                                        </Typography>
                                    </Box>
                                )
                            }
                            </Box>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        default:
            return <></>;
    }
};