import React from "react";
import parse from "html-react-parser";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { BoxDetailsProps } from "./boxdetails.model";

import "./styles.scss";

export default function BoxDetails(props: BoxDetailsProps) {
	const [copied, setCopied] = React.useState(false);

	const copyText = (txt: string) => {
		navigator.clipboard.writeText(txt).then(() => {
			setCopied(true);
		});
	};

	React.useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 5000);
		}
	}, [copied]);

	return (
		<Grid item xs={props.xs} md={props.md} style={{ padding: 4 }}>
			<Box
				className={`boxDetail ${props.isHeader && "isHeader"}`}
				onClick={
					props.isFile
						? props.onOpenFile
						: props.isCopy
						? () => {
								if (!copied) {
									copyText(props.value);
								}
						  }
						: () => {}
				}
			>
				{props.icon}

				<Box className='infos'>
					<Typography className='info_title'>{props.title}</Typography>
					<Typography className='info_value'>{parse(props.value)}</Typography>
				</Box>
			</Box>
			{props.isCopy && (
				<Typography
					className={`isCopy ${props.isHeader && "isHeader"} ${copied && "isCopied"}`}
				>
					{copied ? "Copiado!" : "Clique para copiar"}
				</Typography>
			)}
		</Grid>
	);
}
