import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import { useTheme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import AttachFile from "@material-ui/icons/AttachFile";

import { getSolicitation, deleteSolicitation } from "../formreviewquestion.services";

import { DialogRequestConfirmationProps, StoreRequestReturn } from "./requestConfirmation.model";

import Notify from "utils/Notification";
import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/pedagogicalcommunication";

import "./styles.scss";

export default function DialogRequestConfirmation(
	props: DialogRequestConfirmationProps,
): React.ReactElement {
	const { user } = useAuth();
	const { updateNumReviews, setUpdateNumReviews } = usePedagogicalCommunication();
	const colorsTheme = useTheme();

	const [data, setData] = React.useState<StoreRequestReturn>({});
	const [loading, setLoading] = React.useState(true);
	const [loadingDel, setLoadingDel] = React.useState(false);

	const [openDelete, setOpenDelete] = React.useState(false);

	const handleDeleteSolicitation = () => {
		setLoadingDel(true);

		if (!user?.ra) {
			Notify("RA não está inserido", "warning");
			setOpenDelete(false);
			setLoadingDel(false);
			return;
		}

		deleteSolicitation(data.data?.id, user?.ra)
			.then((data) => {
				if (data.isDeleted) {
					setUpdateNumReviews(!updateNumReviews);
					props.setOpen(false);
					props.setIdSolicitation("");
					setData({});
					setOpenDelete(false);
					setLoadingDel(false);
					Notify("Solicitação excluída.", "success");
				}
			})
			.catch(() => {
				Notify("[Erro]: Ocorreu um erro, por favor, tente novamente.", "error");
				setLoadingDel(false);
			});
	};

	React.useEffect(() => {
		setLoading(true);

		if (props.open && props.idSolicitation) {
			getSolicitation(props.idSolicitation, user?.ra)
				.then((data) => {
					setData(data);
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open, props.idSolicitation]);

	return (
		<>
			<Dialog
				open={props.open}
				onClose={
					loading
						? (): void => {
								return;
						  }
						: () => {
								props.setOpen(false);
								props.setIdSolicitation("");
								setData({});
						  }
				}
				fullWidth
				maxWidth='md'
			>
				<DialogContent>
					{loading ? (
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							flexDirection='column'
						>
							<Typography
								className='await_request'
								style={{ color: colorsTheme.palette.primary.main }}
							>
								Por favor, aguarde...
							</Typography>
							<CircularProgress />
						</Box>
					) : (
						<>
							<Box className='area_header_dialog'>
								<Check
									style={{
										backgroundColor: colorsTheme.palette.primary.main,
									}}
								/>
								<Typography>Sua solicitação foi enviada</Typography>
							</Box>

							<Box className='area_data_confirmation'>
								<Typography className='info_data'>
									O comprovante da solicitação de revisão de questão foi enviado
									para o e-mail: <span>{data?.data?.email}</span>
								</Typography>
								<Typography className='info_data'>
									Curso:{" "}
									<span style={{ color: colorsTheme.palette.primary.main }}>
										{data?.data?.curso_nome}
									</span>
								</Typography>
								<Typography className='info_data'>
									Disciplina:{" "}
									<span style={{ color: colorsTheme.palette.primary.main }}>
										{data?.data?.disciplina_nome}
									</span>
								</Typography>
								<Typography className='info_data'>
									Avaliação:{" "}
									<span style={{ color: colorsTheme.palette.primary.main }}>
										{data?.data?.avaliacao}
									</span>
								</Typography>
								<Typography className='info_data'>
									Questão:{" "}
									<span style={{ color: colorsTheme.palette.primary.main }}>
										{data?.data?.questao}
									</span>
								</Typography>
								<Typography className='info_data'>
									Justificativa:{" "}
									<span style={{ color: colorsTheme.palette.primary.main }}>
										{data?.data?.justificativa}
									</span>
								</Typography>

								<Button
									startIcon={<AttachFile />}
									href={`${data.link}`}
									target='_blank'
								>
									Abrir Arquivo
								</Button>

								<hr />

								<Box>
									<Typography className='info_data'>
										Situação:{" "}
										<span
											className={`tag_situation ${
												data?.data?.situacao === "deferido"
													? "deferred"
													: data?.data?.situacao === "indeferido"
													? "rejected"
													: ""
											}`}
										>
											{data?.data?.situacao}
										</span>
									</Typography>
									<Typography className='info_data'>
										Resolução:{" "}
										<span style={{ color: colorsTheme.palette.primary.main }}>
											{data?.data?.parecer}
										</span>
									</Typography>
								</Box>

								{data?.data?.situacao === "pendente" && (
									<Box className='footer_undo'>
										<Typography variant='subtitle2'>
											Solicitação de Revisão
										</Typography>
										<Button onClick={() => setOpenDelete(true)}>Excluir</Button>
									</Box>
								)}
							</Box>
						</>
					)}
				</DialogContent>
			</Dialog>

			{/* Dialog para confirmar exclusão de solicitação */}
			<Dialog
				open={openDelete}
				onClose={
					loadingDel
						? () => {
								return;
						  }
						: () => {
								setOpenDelete(false);
						  }
				}
			>
				<DialogTitle>
					<Typography className='textDelete'>
						{loadingDel ? (
							<CircularProgress />
						) : (
							"Confirma a exclusão permanente desta solicitação ?"
						)}
					</Typography>
				</DialogTitle>

				{!loadingDel && (
					<DialogActions>
						<Button
							variant='contained'
							color='primary'
							onClick={handleDeleteSolicitation}
						>
							Sim
						</Button>

						<Button
							variant='outlined'
							color='primary'
							onClick={() => {
								setOpenDelete(false);
							}}
						>
							Não
						</Button>
					</DialogActions>
				)}
			</Dialog>
		</>
	);
}
